import { GetCustomerDetailResponse } from "@repo/service";
import { useTranslation } from "@repo/config";
import { Separator } from "@repo/ui/components/ui/Separator";
import { Mail, Phone, MapPin } from "lucide-react";
import { Avatar, AvatarFallback } from "@repo/ui/components/ui/Avatar";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@repo/ui/components/ui/Tooltip";

interface CustomerDetailContentUserCardProps {
  customer: GetCustomerDetailResponse;
}

export function CustomerDetailContentUserCard({
  customer,
}: Readonly<CustomerDetailContentUserCardProps>) {
  const { t } = useTranslation("customers");

  // Helper function to handle empty values
  const getDisplayValue = (value: string | undefined | null) => {
    if (!value || value.trim() === "") {
      return "-";
    }
    return value;
  };

  // Get initials for avatar
  const getInitials = () => {
    const firstInitial = customer.first_name?.charAt(0) ?? "";
    const lastInitial = customer.last_name?.charAt(0) ?? "";
    return (firstInitial + lastInitial).toUpperCase();
  };

  return (
    <div className="flex flex-col gap-6">
      {/* Header with Avatar */}
      <div className="flex items-center gap-4 text-secondary px-6">
        <div className="flex items-center gap-3 shrink-0">
          <Avatar className="size-12">
            <AvatarFallback className="bg-primary/10 text-primary text-sm">
              {getInitials()}
            </AvatarFallback>
          </Avatar>
          <div className="flex flex-col">
            <p className="text-sm font-medium text-foreground">
              {getDisplayValue(
                `${customer.first_name} ${customer.last_name}`.trim(),
              )}
            </p>
            <p className="text-xs text-muted-foreground">
              {getDisplayValue(customer.profile?.title)}
            </p>
          </div>
        </div>
      </div>

      {/* Contact Information */}
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-2 text-secondary overflow-hidden px-6">
          <div className="flex items-center gap-2 shrink-0">
            <Mail className="size-5" />
            <p className="text-sm">{t("customers.detail.contact_info")}</p>
          </div>
          <Separator className="bg-secondary/50" />
        </div>
        <div className="flex flex-col gap-2 px-6">
          {/* Email */}
          <div className="flex justify-between text-sm gap-6">
            <p className="shrink-0 text-muted-foreground">
              {t("customers.email")}
            </p>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div className="truncate">
                    {customer.email ? (
                      <a
                        href={`mailto:${customer.email}`}
                        className="hover:underline flex items-center gap-2"
                      >
                        <span className="truncate">{customer.email}</span>
                        <Mail className="size-4 shrink-0" />
                      </a>
                    ) : (
                      "-"
                    )}
                  </div>
                </TooltipTrigger>
                <TooltipContent side="left">
                  <p className="font-medium">
                    {getDisplayValue(customer.email)}
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>

          {/* Phone */}
          <div className="flex justify-between text-sm gap-6">
            <p className="shrink-0 text-muted-foreground">
              {t("customers.phone_number")}
            </p>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div className="truncate">
                    {customer.phone_number ? (
                      <a
                        href={`tel:${customer.phone_number}`}
                        className="hover:underline flex items-center gap-2"
                      >
                        <span className="truncate">
                          {customer.phone_number}
                        </span>
                        <Phone className="size-4 shrink-0" />
                      </a>
                    ) : (
                      "-"
                    )}
                  </div>
                </TooltipTrigger>
                <TooltipContent side="left">
                  <p className="font-medium">
                    {getDisplayValue(customer.phone_number)}
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>

          {/* Address */}
          <div className="flex justify-between text-sm gap-6">
            <p className="shrink-0 text-muted-foreground">
              {t("customers.address")}
            </p>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div className="truncate flex items-center gap-2">
                    <span className="truncate">
                      {getDisplayValue(customer.address)}
                    </span>
                    {customer.address && <MapPin className="size-4 shrink-0" />}
                  </div>
                </TooltipTrigger>
                <TooltipContent side="left">
                  <p className="font-medium">
                    {getDisplayValue(customer.address)}
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </div>
      </div>
    </div>
  );
}
