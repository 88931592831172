import { Card, CardContent } from "@repo/ui/components/ui/Card";
import { Skeleton } from "@repo/ui/components/ui/Skeleton";

export default function ReservationDetailContentSkeleton() {
  return (
    <div className="space-y-2">
      {/* Talep Bilgileri Card */}
      <Card>
        <CardContent className="flex flex-col gap-4 pt-6 pb-4 px-0 h-full">
          {/* DEMAND INFORMATION */}
          <div className="flex flex-col gap-2">
            {/* HEADER */}
            <div className="flex items-center gap-4 text-secondary">
              <div className="pl-6 flex items-center gap-2 shrink-0">
                <Skeleton className="size-5" />
                <Skeleton className="h-4 w-24" />
              </div>
              <span className="h-px w-full bg-secondary/50" />
            </div>

            {/* BODY */}
            <div className="flex flex-row gap-8 px-6">
              {/* PICK UP DATE */}
              <div className="flex justify-center items-center flex-col gap-2 whitespace-nowrap">
                <Skeleton className="size-12 rounded-full" />
                <div className="text-center">
                  <Skeleton className="h-5 w-24 mx-auto" />
                  <Skeleton className="h-4 w-32 mt-1 mx-auto" />
                </div>
              </div>

              <div className="flex flex-col gap-4 w-full">
                {/* DEMAND OWNER */}
                <div className="flex flex-col text-center">
                  <Skeleton className="h-4 w-24 mx-auto" />
                  <Skeleton className="h-5 w-32 mt-1 mx-auto" />
                </div>

                {/* LINE SEPARATOR */}
                <Skeleton className="w-full h-2 rounded-full" />

                {/* ESTIMATED USE TIME */}
                <div className="flex flex-col text-center justify-center items-center">
                  <Skeleton className="h-5 w-32 mx-auto" />
                  <Skeleton className="h-4 w-24 mt-1 mx-auto" />
                  <Skeleton className="size-5 mt-2" />
                </div>
              </div>

              {/* DROP OFF DATE */}
              <div className="flex justify-center items-center flex-col gap-2 whitespace-nowrap">
                <Skeleton className="size-12 rounded-full" />
                <div className="text-center">
                  <Skeleton className="h-5 w-24 mx-auto" />
                  <Skeleton className="h-4 w-32 mt-1 mx-auto" />
                </div>
              </div>
            </div>
          </div>

          {/* DESCRIPTION */}
          <div className="flex flex-col gap-2">
            {/* HEADER */}
            <div className="flex items-center gap-4 text-secondary">
              <div className="pl-6 flex items-center gap-2 shrink-0">
                <Skeleton className="size-5" />
                <Skeleton className="h-4 w-24" />
              </div>
              <span className="h-px w-full bg-secondary/50" />
            </div>
            {/* BODY */}
            <div className="flex flex-col gap-2 px-6">
              <Skeleton className="h-10 w-full rounded-lg" />
            </div>
          </div>

          {/* CREATION DATE */}
          <div className="mt-auto flex flex-col gap-2">
            <Skeleton className="h-px w-full" />
            <div className="px-6 flex items-center gap-2 pt-2">
              <Skeleton className="size-5 shrink-0" />
              <Skeleton className="h-4 w-48" />
            </div>
          </div>
        </CardContent>
      </Card>

      {/* Araç Yönetim Card */}
      <Card>
        <CardContent className="flex flex-col gap-4 pt-6 pb-4 px-0">
          {/* Header */}
          <div className="flex items-center justify-center space-x-4 px-6">
            <span className="h-[1px] bg-secondary/50 flex-1" />
            <Skeleton className="h-5 w-32" />
            <span className="h-[1px] bg-secondary/50 flex-1" />
          </div>

          {/* Body */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 px-6">
            {/* Available Vehicles Card */}
            <Card>
              <CardContent className="flex flex-col gap-4 p-4">
                <Skeleton className="h-5 w-32" />
                <div className="flex flex-col gap-2">
                  {Array.from({ length: 2 }).map((_, index) => (
                    <Skeleton
                      key={index}
                      className="h-[60px] w-full rounded-lg"
                    />
                  ))}
                </div>
                <Skeleton className="h-9 w-full" />
              </CardContent>
            </Card>

            {/* Approve/Reject Card */}
            <Card>
              <CardContent className="flex flex-col gap-4 p-4">
                <Skeleton className="h-5 w-32" />
                <Skeleton className="h-[100px] w-full rounded-lg" />
                <div className="flex flex-col gap-2">
                  <Skeleton className="h-9 w-full" />
                  <Skeleton className="h-9 w-full" />
                </div>
              </CardContent>
            </Card>
          </div>
        </CardContent>
      </Card>
    </div>
  );
}
