import "@repo/ui/globals.css";
import { createRoot } from "react-dom/client";
import App from "./App.tsx";
import { initI18n, createEnv } from "@repo/config";
import {
  createApiClient,
  createAuthService,
  createLoadService,
  createProfileService,
  createReportService,
  createOrderService,
  createHeskService,
  createFirebaseService,
  createAnalyticsService,
  createPerformanceService,
  createCompanyService,
  createCustomerService,
  createDocumentService,
  createReservationService,
} from "@repo/service";
import { createRouteConfig } from "./config/routeConfig.ts";

// i18n resources from packages
import { i18n as otsI18n } from "@repo/ots";
import { i18n as settingsI18n } from "@repo/user-settings";
import { i18n as heskI18n } from "@repo/hesk";
import { i18n as chatI18n } from "@repo/chat-panel";

// Create env instance
export const env = createEnv(import.meta.env);

if (!env.API.ERP_BASE_URL) {
  throw new Error("ERP_BASE_URL is not defined");
}

// Initialize services

// HUB services
export const authService = createAuthService(env);
export const apiClient = createApiClient(env, authService);
export const orderService = createOrderService(apiClient);
export const customerService = createCustomerService(apiClient);
export const companyService = createCompanyService(apiClient);
export const documentService = createDocumentService(apiClient);
export const reservationService = createReservationService(apiClient);

// ERP services
export const loadService = createLoadService(apiClient, env.API.ERP_BASE_URL);
export const reportService = createReportService(
  apiClient,
  env.API.ERP_BASE_URL,
);

export const profileService = createProfileService(
  apiClient,
  env.API.ERP_BASE_URL,
);

export const heskService = createHeskService(env, authService);

// Firebase services
export const firebaseService = createFirebaseService(env.FIREBASE);
export const analyticsService = createAnalyticsService(firebaseService);
export const performanceService = createPerformanceService(firebaseService);

// Enable performance monitoring in production
if (env.NODE_ENV === "production") {
  const perfInstance = performanceService.getInstance();
  if (perfInstance) {
    perfInstance.instrumentationEnabled = true;
    perfInstance.dataCollectionEnabled = true;
  }
}

// Create route config
export const { routes } = createRouteConfig({
  authService,
  loadService,
});

// Initialize app with i18n
(async () => {
  try {
    await initI18n(
      env,
      ["common", "library", "orders", "reports", "reservations", "customers"],
      {},
      [otsI18n, settingsI18n, heskI18n, chatI18n],
    );

    createRoot(document.getElementById("root")!).render(<App />);
  } catch (error) {
    console.error("Failed to initialize i18n:", error);
  }
})();
