import { useCurrentLanguage } from "@repo/config";
import { customerService } from "@/main";
import { GetCustomerDetailParams } from "@repo/service";
import { useQuery } from "@tanstack/react-query";

export const CUSTOMER_DETAIL_QUERY_KEY = "customers";

export const useCustomer = (params: GetCustomerDetailParams) => {
  const currentLanguage = useCurrentLanguage();

  const queryKey = [
    CUSTOMER_DETAIL_QUERY_KEY,
    currentLanguage,
    params.customer_id,
  ];

  const query = useQuery({
    queryKey,
    queryFn: () =>
      customerService.getCustomerDetail({
        ...params,
        language: currentLanguage,
      }),
    enabled: !!params.customer_id,
  });

  return {
    ...query,
  };
};
