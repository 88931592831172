import { Calendar as CalendarIcon } from "lucide-react";
import { Locale, format } from "date-fns";
import { DateRange, isDateRange } from "react-day-picker";
import { cn } from "@repo/ui/lib/utils";
import { Button } from "../ui/Button";
import { Calendar } from "../ui/Calendar";
import { Popover, PopoverContent, PopoverTrigger } from "../ui/Popover";

interface DateRangePickerProps {
  readonly date: DateRange | undefined;
  readonly onDateChange: (date: DateRange | undefined) => void;
  readonly className?: string;
  readonly placeholder?: string;
  readonly locale?: Locale;
  readonly mode?: "single" | "range";
}

export function DateRangePicker({
  date,
  onDateChange,
  className,
  placeholder,
  locale,
  mode = "range",
}: DateRangePickerProps) {
  const handleSelect = (value: Date | DateRange | undefined) => {
    if (mode === "single" && value instanceof Date) {
      onDateChange({ from: value, to: value });
    } else if (mode === "range" && isDateRange(value)) {
      onDateChange(value);
    } else {
      onDateChange(undefined);
    }
  };

  const renderDate = () => {
    if (!date?.from) return <span>{placeholder}</span>;

    if (mode === "single") {
      return format(date.from, "dd MMMM yyyy", { locale });
    }

    return (
      <>
        {format(date.from, "dd MMMM yyyy", { locale })}
        &nbsp;-&nbsp;
        {date.to ? format(date.to, "dd MMMM yyyy", { locale }) : "..."}
      </>
    );
  };

  return (
    <div className={cn("grid gap-2", className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"outline"}
            className={cn(
              "justify-start text-left font-normal",
              !date && "text-muted-foreground",
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {renderDate()}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          {mode === "single" ? (
            <Calendar
              initialFocus
              mode="single"
              defaultMonth={date?.from || new Date()}
              selected={date?.from}
              onSelect={handleSelect}
              numberOfMonths={1}
              locale={locale}
              disabled={(date) => date > new Date()}
            />
          ) : (
            <Calendar
              initialFocus
              mode="range"
              defaultMonth={date?.from || new Date()}
              selected={date}
              onSelect={handleSelect}
              numberOfMonths={2}
              locale={locale}
              disabled={(date) => date > new Date()}
            />
          )}
        </PopoverContent>
      </Popover>
    </div>
  );
}
