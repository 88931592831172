import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@repo/ui/components/ui/Card";
import { useReservationsTableColumns } from "./ReservationsTableColumns";
import { DataTable } from "@repo/ui/components/table/DataTable";
import { useCallback, useMemo, useTransition } from "react";
import { useQueryStates, parseAsInteger, parseAsString } from "nuqs";
import { useTranslation } from "@repo/config";
import { AlertCircle } from "lucide-react";
import {
  DEFAULT_PAGE_SIZE,
  TABLE_PAGE_SIZE_OPTIONS,
} from "@/features/orders/utils/constants";
import { TableTranslations } from "@repo/ui/components/table/types";
import { ReservationsTableFilters } from "./ReservationsTableFilters";
import { useReservations } from "../hooks/useReservations";
import AddReservationDialog from "./AddReservationDialog";
import { AddNewVehicleDialog } from "./AddNewVehicleDialog";
import { withPermission } from "@repo/provider";

const RESERVATION_QUERY_PARAMS = {
  PAGE: "reservation_page",
  SIZE: "reservation_size",
  SEARCH: "reservation_search",
  STATUS: "approval_status",
  VEHICLE_STATUS: "vehicle_status",
} as const;

// Permission with AddNewVehicleDialog
const ProtectedAddNewVehicle = withPermission(AddNewVehicleDialog, {
  requiredPermissions: ["add_vehicle"],
});

export default function ReservationsTable() {
  const columns = useReservationsTableColumns();
  const { t } = useTranslation("reservations");

  const translations: TableTranslations = {
    loading: {
      data: t("loading.data"),
    },
    table: {
      no_records: t("table.no_records"),
      page_size: t("table.page_size"),
      page: t("table.page"),
      first_page: t("table.first_page"),
      previous_page: t("table.previous_page"),
      next_page: t("table.next_page"),
      last_page: t("table.last_page"),
      refresh: t("table.refresh"),
    },
    pagination: {
      total_records: t("pagination.total_records"),
      of: t("pagination.of"),
    },
  };
  const [isTransitionPending, startTransition] = useTransition();

  const [queryParams, setQueryParams] = useQueryStates(
    {
      [RESERVATION_QUERY_PARAMS.PAGE]: parseAsInteger.withDefault(0),
      [RESERVATION_QUERY_PARAMS.SIZE]:
        parseAsInteger.withDefault(DEFAULT_PAGE_SIZE),
      [RESERVATION_QUERY_PARAMS.SEARCH]: parseAsString.withDefault(""),
      [RESERVATION_QUERY_PARAMS.STATUS]: parseAsString.withDefault(""),
      //   [RESERVATION_QUERY_PARAMS.VEHICLE_STATUS]: parseAsString.withDefault(""),
    },
    {
      history: "push",
      shallow: true,
      throttleMs: 500,
    },
  );

  const {
    [RESERVATION_QUERY_PARAMS.PAGE]: pageIndex,
    [RESERVATION_QUERY_PARAMS.SIZE]: pageSize,
    [RESERVATION_QUERY_PARAMS.SEARCH]: search,
    [RESERVATION_QUERY_PARAMS.STATUS]: status,
    // [RESERVATION_QUERY_PARAMS.VEHICLE_STATUS]: vehicleStatus,
  } = queryParams;

  const handleSearchChange = useCallback(
    (value: string) => {
      startTransition(() => {
        setQueryParams(
          {
            [RESERVATION_QUERY_PARAMS.SEARCH]: value,
            [RESERVATION_QUERY_PARAMS.PAGE]: 0,
          },
          { history: "push", shallow: true },
        );
      });
    },
    [setQueryParams],
  );

  const handlePageChange = useCallback(
    (newPage: number) => {
      startTransition(() => {
        setQueryParams(
          { [RESERVATION_QUERY_PARAMS.PAGE]: newPage },
          { history: "push", shallow: true },
        );
      });
    },
    [setQueryParams],
  );

  const handlePageSizeChange = useCallback(
    (newPageSize: number) => {
      startTransition(() => {
        setQueryParams(
          {
            [RESERVATION_QUERY_PARAMS.SIZE]: newPageSize,
            [RESERVATION_QUERY_PARAMS.PAGE]: 0,
          },
          { history: "push", shallow: true },
        );
      });
    },
    [setQueryParams],
  );

  const handleStatusChange = useCallback(
    (value: string) => {
      startTransition(() => {
        setQueryParams(
          {
            [RESERVATION_QUERY_PARAMS.STATUS]: value,
            [RESERVATION_QUERY_PARAMS.PAGE]: 0,
          },
          { history: "push", shallow: true },
        );
      });
    },
    [setQueryParams],
  );

  //   const handleVehicleStatusChange = useCallback(
  //     (value: string) => {
  //       startTransition(() => {
  //         setQueryParams(
  //           {
  //             [RESERVATION_QUERY_PARAMS.VEHICLE_STATUS]: value,
  //             [RESERVATION_QUERY_PARAMS.PAGE]: 0,
  //           },
  //           { history: "push", shallow: true },
  //         );
  //       });
  //     },
  //     [setQueryParams],
  //   );

  const apiParams = useMemo(
    () => ({
      limit: pageSize,
      offset: pageIndex * pageSize,
      filters: {
        search: search && search.trim() !== "" ? search : undefined,
        status: status && status.trim() !== "" ? status : undefined,
        // vehicle_status:
        //   vehicleStatus && vehicleStatus.trim() !== ""
        //     ? vehicleStatus
        //     : undefined,
      },
    }),
    [pageIndex, pageSize, search, status],
  );

  const {
    data: reservations,
    error,
    isLoading,
    isPending: isFetching,
    refetch,
  } = useReservations(apiParams);

  const isLoadingState = isLoading || isTransitionPending || isFetching;

  const totalPageCount = useMemo(
    () => (reservations ? Math.ceil(reservations.count / pageSize) : 0),
    [reservations?.count, pageSize],
  );

  if (error) {
    return (
      <Card className="space-y-4 col-span-2">
        <CardHeader>
          <CardTitle>{t("title")}</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex flex-col items-center justify-center py-8">
            <AlertCircle className="h-12 w-12 text-destructive mb-3" />
            <p className="text-lg font-medium text-destructive">
              {t("error.reservation.detail.fetch_title")}
            </p>
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="space-y-2 col-span-2">
      <CardHeader className="flex flex-row items-start justify-between max-h-20">
        <CardTitle>{t("title")}</CardTitle>
        {/* Add Reservation Form & Add Reservation Vehicle Form */}
        <div className="flex flex-row gap-2">
          <ProtectedAddNewVehicle />
          <AddReservationDialog />
        </div>
      </CardHeader>
      <CardContent>
        <DataTable
          columns={columns}
          data={reservations?.results ?? []}
          pageCount={totalPageCount}
          pageIndex={pageIndex}
          filterComponent={
            <ReservationsTableFilters
              searchValue={search}
              onSearchChange={handleSearchChange}
              statusValue={status}
              onStatusChange={handleStatusChange}
              //   vehicleStatusValue={vehicleStatus}
              //   onVehicleStatusChange={handleVehicleStatusChange}
            />
          }
          onRefetch={refetch}
          pageSize={pageSize}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          isPending={isLoadingState}
          totalRecords={reservations?.count ?? 0}
          translations={translations}
          TABLE_PAGE_SIZE_OPTIONS={TABLE_PAGE_SIZE_OPTIONS}
        />
      </CardContent>
    </Card>
  );
}
