import ReservationsDetailContentVehiclesCard from "./ReservationDetailContentVehiclesCard";
import ReservationsDetailContentApproveRejectCard from "./ReservationDetailContentApproveRejectCard";
import { withPermission } from "@repo/provider";
import { ReservationVehicle } from "@repo/service";

interface ReservationDetailContentEmployeeContentProps {
  reservation_id: string;
  action_id: string;
  action_status_slug: string;
  reservationVehicle: ReservationVehicle | null;
}

const ProtectedReservationDetailContentVehiclesCard = withPermission(
  ReservationsDetailContentVehiclesCard,
  {
    requiredPermissions: ["view_passengervehicle", "add_vehicle_reservation"],
  },
);

const ProtectedReservationDetailContentApproveRejectCard = withPermission(
  ReservationsDetailContentApproveRejectCard,
  {
    requiredPermissions: ["approve_reservation"],
  },
);

export default function ReservationDetailContentEmployeeContent({
  reservation_id,
  action_id,
  action_status_slug,
  reservationVehicle,
}: Readonly<ReservationDetailContentEmployeeContentProps>) {
  return (
    <>
      {/* Available Vehicles */}
      {!reservationVehicle?.plate && (
        <ProtectedReservationDetailContentVehiclesCard />
      )}

      {/* Approve & Reject Card*/}
      <ProtectedReservationDetailContentApproveRejectCard
        reservationVehicle={reservationVehicle}
        reservation_id={reservation_id}
        action_id={action_id}
        action_status_slug={action_status_slug}
      />
    </>
  );
}
