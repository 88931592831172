import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@repo/ui/components/ui/Card";
import { ScrollArea } from "@repo/ui/components/ui/ScrollArea";
import { VehicleCard } from "./ReservationDetailContentVehicleCard";
import { useTranslation } from "@repo/config";
import { useCallback, useState, useMemo } from "react";
import { useReservationSelection } from "../../../store/reservation";
import { AvailableVehicle } from "@repo/service";
import { useReservationQuery } from "../hooks/useReservation";
import { LoadingProgress } from "@repo/ui/components/ui/LoadingProgress";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@repo/ui/components/ui/Tabs";

export default function ReservationsDetailContentVehiclesCard() {
  const { t } = useTranslation("reservations");
  const { selectVehicle, selectedVehicle } = useReservationSelection();
  const { selectedReservation } = useReservationSelection();
  const { useAvailableVehicles } = useReservationQuery();
  const [activeTab, setActiveTab] = useState<string>("available");

  // Single query for all vehicles
  const {
    data: allVehicles,
    error: vehiclesError,
    isLoading: isVehiclesLoading,
  } = useAvailableVehicles({
    search: "",
    start_time: selectedReservation?.reservation.start_time ?? "",
  });

  // Filter vehicles based on has_reservation value
  const availableVehicles = useMemo(() => {
    if (!allVehicles?.results) return [];
    return allVehicles.results.filter((vehicle) => !vehicle.has_reservation);
  }, [allVehicles]);

  const unavailableVehicles = useMemo(() => {
    if (!allVehicles?.results) return [];
    return allVehicles.results.filter((vehicle) => vehicle.has_reservation);
  }, [allVehicles]);

  const handleVehicleSelect = useCallback(
    (vehicle: AvailableVehicle) => {
      selectVehicle(vehicle);
    },
    [selectVehicle],
  );

  if (vehiclesError) {
    return (
      <div className="flex items-center justify-center h-full">
        <p className="text-sm text-destructive">
          {t("detail.error.available_vehicles")}
        </p>
      </div>
    );
  }

  return (
    <Card className="w-full relative">
      <CardHeader className="px-4 pt-5 pb-2">
        <CardTitle className="text-sm md:text-base">
          {t("available_vehicles")}
        </CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col gap-4 px-4">
        <Tabs
          defaultValue="available"
          value={activeTab}
          onValueChange={setActiveTab}
        >
          <TabsList className="grid grid-cols-2 mb-2">
            <TabsTrigger value="available">
              {t("vehicles.available")} ({availableVehicles.length ?? 0})
            </TabsTrigger>
            <TabsTrigger value="unavailable">
              {t("vehicles.unavailable")} ({unavailableVehicles.length ?? 0})
            </TabsTrigger>
          </TabsList>

          <TabsContent value="available">
            <ScrollArea className="h-[128px] pr-2 -mr-2">
              {!availableVehicles.length ? (
                <div className="flex flex-col gap-2 items-center justify-center h-[128px]">
                  <p>{t("no_vehicles_available")}</p>
                </div>
              ) : (
                <div className="flex flex-col gap-2">
                  {availableVehicles.map((vehicle) => (
                    <VehicleCard
                      key={vehicle.id}
                      vehicle={vehicle}
                      onSelect={() => handleVehicleSelect(vehicle)}
                      isSelected={selectedVehicle?.id === vehicle.id}
                      isAvailable={true}
                    />
                  ))}
                </div>
              )}
            </ScrollArea>
          </TabsContent>

          <TabsContent value="unavailable">
            <ScrollArea className="h-[128px] pr-2 -mr-2">
              {!unavailableVehicles.length ? (
                <div className="flex flex-col gap-2 items-center justify-center h-[128px]">
                  <p>{t("no_vehicles_unavailable")}</p>
                </div>
              ) : (
                <div className="flex flex-col gap-2">
                  {unavailableVehicles.map((vehicle) => (
                    <VehicleCard
                      key={vehicle.id}
                      vehicle={vehicle}
                      isAvailable={false}
                    />
                  ))}
                </div>
              )}
            </ScrollArea>
          </TabsContent>
        </Tabs>
      </CardContent>
      {isVehiclesLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-background/80 z-10">
          <LoadingProgress
            showTruck={false}
            showSpinner={true}
            title={t("loading_vehicles")}
          />
        </div>
      )}
    </Card>
  );
}
