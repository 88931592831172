import { LucideIcon } from "lucide-react";
import UserSidebarProfile from "../user/UserSidebarProfile";
import {
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarGroup,
  SidebarGroupContent,
  SidebarGroupLabel,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
} from "../ui/Sidebar";

export interface SidebarItem {
  title: string;
  url: string;
  icon: LucideIcon;
}

interface User {
  first_name: string;
  last_name: string;
  email: string;
  avatar?: string;
}

interface DashboardSidebarProps {
  readonly items: SidebarItem[];
  readonly logo: React.ReactNode;
  readonly groupLabel?: string;
  readonly isActiveUrl: (url: string) => boolean;
  readonly getLinkUrl: (url: string) => string;
  readonly renderLink: (props: {
    url: string;
    children: React.ReactNode;
    isActive: boolean;
  }) => React.ReactNode;
  readonly actions?: React.ReactNode;
  readonly className?: string;
  readonly user: User;
}

export function DashboardSidebar({
  items = [],
  user,
  logo,
  groupLabel,
  isActiveUrl,
  getLinkUrl,
  renderLink,
  actions,
  className,
}: DashboardSidebarProps) {
  return (
    <Sidebar collapsible="icon" className={className}>
      <div className="flex flex-col gap-4 min-h-svh">
        <SidebarHeader>{logo}</SidebarHeader>
        <div className="rounded-tr-lg overflow-hidden shadow-sidebar-shadow bg-sidebar h-full flex flex-col">
          <SidebarContent>
            <SidebarGroup>
              {groupLabel && (
                <SidebarGroupLabel>{groupLabel}</SidebarGroupLabel>
              )}
              <SidebarGroupContent>
                <SidebarMenu>
                  {items.map((item) => (
                    <SidebarMenuItem key={item.title}>
                      <SidebarMenuButton
                        size="lg"
                        isActive={isActiveUrl(item.url)}
                        asChild
                      >
                        {renderLink({
                          url: getLinkUrl(item.url),
                          isActive: isActiveUrl(item.url),
                          children: (
                            <>
                              <item.icon />
                              <span>{item.title}</span>
                            </>
                          ),
                        })}
                      </SidebarMenuButton>
                    </SidebarMenuItem>
                  ))}
                </SidebarMenu>
              </SidebarGroupContent>
            </SidebarGroup>
            <SidebarFooter>
              <UserSidebarProfile
                firstName={user.first_name}
                lastName={user.last_name}
                email={user.email}
                avatar={user.avatar ?? ""}
                actions={actions}
              />
            </SidebarFooter>
          </SidebarContent>
        </div>
      </div>
    </Sidebar>
  );
}
