import * as React from "react";
import { Label } from "@repo/ui/components/ui/Label";
import { TimePickerInput } from "./TimePickerInput";
import { Locale } from "date-fns";

interface TimePickerDemoProps {
  date: Date | undefined;
  setDate: (date: Date | undefined) => void;
  locale: Locale;
  showSeconds?: boolean;
  showLabel?: boolean;
  showColon?: boolean;
}

export function TimePicker({
  date,
  setDate,
  locale,
  showSeconds = true,
  showLabel = true,
  showColon = true,
}: Readonly<TimePickerDemoProps>) {
  const minuteRef = React.useRef<HTMLInputElement>(null);
  const hourRef = React.useRef<HTMLInputElement>(null);
  const secondRef = React.useRef<HTMLInputElement>(null);

  return (
    <div className="flex items-end gap-2">
      <div className="grid gap-1 text-center">
        {showLabel && (
          <Label htmlFor="hours" className="text-xs">
            {locale.code === "tr" ? "Saat" : "Hours"}
          </Label>
        )}
        <TimePickerInput
          picker="hours"
          date={date}
          setDate={setDate}
          ref={hourRef}
          onRightFocus={() => minuteRef.current?.focus()}
        />
      </div>
      <span className="text-xs self-center">:</span>
      <div className="grid gap-1 text-center">
        {showLabel && (
          <Label htmlFor="minutes" className="text-xs">
            {locale.code === "tr" ? "Dakika" : "Minutes"}
          </Label>
        )}
        <TimePickerInput
          picker="minutes"
          date={date}
          setDate={setDate}
          ref={minuteRef}
          onLeftFocus={() => hourRef.current?.focus()}
          onRightFocus={() => secondRef.current?.focus()}
        />
      </div>
      {showSeconds && (
        <>
          {showColon && <span className="text-xs self-center">:</span>}
          <div className="grid gap-1 text-center">
            {showLabel && (
              <Label htmlFor="seconds" className="text-xs">
                {locale.code === "tr" ? "Saniye" : "Seconds"}
              </Label>
            )}
            <TimePickerInput
              picker="seconds"
              date={date}
              setDate={setDate}
              ref={secondRef}
              onLeftFocus={() => minuteRef.current?.focus()}
            />
          </div>
        </>
      )}
    </div>
  );
}
