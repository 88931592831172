import { create } from "zustand";
import { OrderDetail } from "@repo/service";
import { useOrderQuery } from "@/features/orders/hooks/useOrder";
import { useEffect } from "react";
import { parseAsString, useQueryState } from "nuqs";

// Store interface
interface OrderState {
  selectedOrder: OrderDetail | null;
  isSheetOpen: boolean;
  openSheet: () => void;
  closeSheet: () => void;
}

// Create store with basic UI state
export const useOrderStore = create<OrderState>((set) => ({
  selectedOrder: null,
  isSheetOpen: false,
  openSheet: () => set({ isSheetOpen: true }),
  closeSheet: () => set({ isSheetOpen: false, selectedOrder: null }),
}));

// Hook to manage order selection and data fetching
export const useOrderSelection = () => {
  const { useOrder } = useOrderQuery();

  // URL state management
  const [selectedOrderNo, setSelectedOrderNo] = useQueryState(
    "order_no",
    parseAsString.withDefault(""),
  );

  // Store actions
  const { openSheet, closeSheet } = useOrderStore();

  // Fetch order details when URL changes
  const {
    data: selectedOrder,
    isLoading,
    error,
  } = useOrder({
    orderNo: selectedOrderNo,
  });

  // Update store with fetched data
  useEffect(() => {
    useOrderStore.setState({ selectedOrder: selectedOrder || null });
  }, [selectedOrder]);

  // Auto open sheet when there's an order number in URL
  useEffect(() => {
    if (selectedOrderNo) {
      openSheet();
    }
  }, [selectedOrderNo]);

  // Handle order selection
  const selectOrder = async (orderNo: string) => {
    await setSelectedOrderNo(orderNo);
    openSheet();
  };

  // Handle order deselection
  const clearOrder = async () => {
    await setSelectedOrderNo(null);
    closeSheet();
  };

  return {
    selectedOrderNo,
    selectedOrder: selectedOrder || null,
    isLoading,
    error,
    selectOrder,
    clearOrder,
  };
};
