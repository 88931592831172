import { formatDateTime, useTranslation } from "@repo/config";
import { Separator } from "@repo/ui/components/ui/Separator";
import {
  MapPin,
  Flag,
  User,
  CarFront,
  MessageSquare,
  CalendarClock,
} from "lucide-react";

import { Card, CardContent } from "@repo/ui/components/ui/Card";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@repo/ui/components/ui/Tooltip";

interface ReservationsDetailContentDemandInformationsProps {
  reservation: {
    demand_owner: string;
    pick_up_date: string;
    drop_off_date: string;
    estimated_use_time: string;
    description: string;
    created_at: string;
    reservation_type: string;
  };
}

export default function ReservationsDetailContentDemandInformations({
  reservation,
}: Readonly<ReservationsDetailContentDemandInformationsProps>) {
  const { t } = useTranslation("reservations");

  return (
    <Card className="flex-1">
      <CardContent className="flex flex-col gap-4 pt-6 pb-4 px-0 h-full">
        {/* DEMAND INFORMATION */}
        <div className="flex flex-col gap-2">
          {/* HEADER */}
          <div className="flex items-center gap-4 text-secondary">
            <div className="pl-6 flex items-center gap-2 shrink-0">
              <User className="size-5" />
              <p className="text-sm">{t("detail.demand_info")}</p>
            </div>
            <span className="h-px w-full bg-secondary/50" />
          </div>

          {/* BODY */}
          <div className="flex flex-row gap-8 px-6">
            {/* PICK UP DATE */}
            <div className="flex justify-center items-center flex-col gap-2 whitespace-nowrap">
              <div className="rounded-full size-12 bg-secondary flex items-center justify-center">
                <MapPin className="size-5 text-white" />
              </div>
              <div className="text-center">
                <p className="text-base font-medium">{t("pick_up_date")}</p>
                <p className="text-sm">
                  {formatDateTime(reservation.pick_up_date)}
                </p>
              </div>
            </div>
            <div className="flex flex-col gap-4 w-full">
              {/* DEMAND OWNER */}
              <div className="flex flex-col text-center">
                <p className="text-sm text-muted-foreground">
                  {t("demand_owner")}
                </p>
                <p className="text-sm">{reservation.demand_owner}</p>
              </div>

              {/* LINE SEPARATOR */}
              <Separator className="w-full h-2 rounded-full bg-foreground/80" />
              {/* ESTIMATED USE TIME */}
              <div className="flex flex-col text-center justify-center items-center">
                <p className="text-base font-medium">
                  {t("estimated_use_time")}
                </p>
                <p className="text-sm">{reservation.estimated_use_time}</p>
                <CarFront className="size-5 text-muted-foreground mt-2" />
              </div>
            </div>

            {/* DROP OFF DATE */}
            <div className="flex justify-center items-center flex-col gap-2 whitespace-nowrap">
              <div className="rounded-full size-12 bg-success flex items-center justify-center">
                <Flag className="size-5 text-white" />
              </div>
              <div className="text-center">
                <p className="text-base font-medium">{t("drop_off_date")}</p>
                <p className="text-sm">
                  {formatDateTime(reservation.drop_off_date)}
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* DESCRIPTION */}
        <div className="flex flex-col gap-2">
          {/* HEADER */}
          <div className="flex items-center gap-4 text-secondary">
            <div className="pl-6 flex items-center gap-2 shrink-0">
              <MessageSquare className="size-5" />
              <p className="text-sm capitalize">
                {t("detail.description_title")} - {"("}
                {reservation.reservation_type}
                {")"}
              </p>
            </div>
            <span className="h-px w-full bg-secondary/50" />
          </div>
          {/* BODY */}
          <div className="flex flex-col gap-2 px-6">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <p className="text-sm line-clamp-2">
                    {reservation.description}
                  </p>
                </TooltipTrigger>
                <TooltipContent side="bottom">
                  <p>{reservation.description}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </div>
        {/* CREATION DATE */}
        <div className="mt-auto flex flex-col gap-2">
          <Separator className="bg-secondary/50" />
          <div className="px-6 flex items-center gap-2 text-secondary pt-2">
            <CalendarClock className="size-5 shrink-0" />
            <p className="text-sm leading-snug tracking-tight truncate">
              {t("detail.created_at", {
                date: formatDateTime(reservation?.created_at),
              })}
            </p>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
