import { CalendarIcon } from "lucide-react";
import { format, Locale } from "date-fns";

import { cn } from "@repo/ui/lib/utils";
import { Button } from "@repo/ui/components/ui/Button";
import { Calendar } from "@repo/ui/components/ui/Calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@repo/ui/components/ui/Popover";

interface DatePickerProps {
  date?: Date;
  setDate: (date: Date | undefined) => void;
  locale?: Locale;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  fromDate?: Date;
}

export function DatePicker({
  date,
  setDate,
  locale,
  placeholder = "Pick a date",
  className,
  disabled = false,
  fromDate = new Date(),
}: Readonly<DatePickerProps>) {
  // Format date based on locale
  const formattedDate = date ? format(date, "PPP", { locale }) : null;

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "w-full justify-start text-left font-normal",
            !date && "text-muted-foreground",
            className,
          )}
          disabled={disabled}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {formattedDate ?? <span>{placeholder}</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <Calendar
          mode="single"
          selected={date}
          onSelect={setDate}
          initialFocus
          locale={locale}
          disabled={disabled}
          fromDate={fromDate}
        />
      </PopoverContent>
    </Popover>
  );
}
