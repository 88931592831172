import { Card, CardContent } from "@repo/ui/components/ui/Card";
import { User, MessageSquare, Phone, Mail, CalendarClock } from "lucide-react";
import { useOrderStore } from "@/store/order";
import {
  Tooltip,
  TooltipTrigger,
  TooltipContent,
  TooltipProvider,
} from "@repo/ui/components/ui/Tooltip";
import { useTranslation, formatDateMonthName } from "@repo/config";
import { Separator } from "@repo/ui/components/ui/Separator";

export default function OrderDetailContentUserDetailCard() {
  const { selectedOrder } = useOrderStore();
  const { t } = useTranslation("orders");

  // Helper function to handle empty values
  const getDisplayValue = (value: string | undefined | null) => {
    if (!value || value.trim() === "") {
      return t("order.detail.no_value");
    }
    return value;
  };

  return (
    <Card>
      <CardContent className="flex flex-col gap-4 pt-6 pb-4 px-0 h-full">
        {/* USER DETAILS */}
        <div className="flex flex-col gap-2">
          {/* HEADER */}
          <div className="flex items-center gap-4 text-secondary">
            <div className="pl-6 flex items-center gap-2 shrink-0">
              <User className="size-5" />
              <p className="text-sm">{t("order.detail.contact_info")}</p>
            </div>
            <span className="h-px w-full bg-secondary/50" />
          </div>

          {/* BODY */}
          <div className="flex flex-col gap-2 px-6">
            {/* Company Name */}
            <div className="flex justify-between text-sm gap-6">
              <div>
                <p className="shrink-0">{t("order.detail.company")}</p>
              </div>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <p className="truncate">
                      {getDisplayValue(selectedOrder?.company_name)}
                    </p>
                  </TooltipTrigger>
                  <TooltipContent side="left">
                    <p className="font-medium">
                      {getDisplayValue(selectedOrder?.company_name)}
                    </p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>

            {/* Full Name */}
            <div className="flex justify-between text-sm gap-6">
              <div>
                <p className="shrink-0">{t("order.detail.customer")}</p>
              </div>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <p className="truncate">
                      {getDisplayValue(
                        `${selectedOrder?.first_name ?? ""} ${selectedOrder?.last_name ?? ""}`.trim(),
                      )}
                    </p>
                  </TooltipTrigger>
                  <TooltipContent side="left">
                    <p className="font-medium">
                      {getDisplayValue(
                        `${selectedOrder?.first_name ?? ""} ${selectedOrder?.last_name ?? ""}`.trim(),
                      )}
                    </p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>

            {/* Phone */}
            <div className="flex justify-between text-sm gap-6">
              <div>
                <p className="shrink-0">{t("order.detail.phone")}</p>
              </div>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <div className="truncate">
                      {selectedOrder?.phone_number ? (
                        <a
                          href={`tel:${selectedOrder.phone_number}`}
                          className="hover:underline flex items-center gap-2"
                        >
                          <span className="truncate">
                            {selectedOrder.phone_number}
                          </span>
                          <Phone className="size-4 shrink-0" />
                        </a>
                      ) : (
                        t("order.detail.no_value")
                      )}
                    </div>
                  </TooltipTrigger>
                  <TooltipContent side="left">
                    <p className="font-medium">
                      {getDisplayValue(selectedOrder?.phone_number)}
                    </p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>

            {/* Email */}
            <div className="flex justify-between text-sm gap-6">
              <div>
                <p className="shrink-0 whitespace-nowrap">
                  {t("order.detail.email")}
                </p>
              </div>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <div className="truncate">
                      {selectedOrder?.email ? (
                        <a
                          href={`mailto:${selectedOrder.email}`}
                          className="hover:underline flex items-center gap-2"
                        >
                          <span className="truncate">
                            {selectedOrder.email}
                          </span>
                          <Mail className="size-4 shrink-0" />
                        </a>
                      ) : (
                        t("order.detail.no_value")
                      )}
                    </div>
                  </TooltipTrigger>
                  <TooltipContent side="left">
                    <p className="font-medium">
                      {getDisplayValue(selectedOrder?.email)}
                    </p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          </div>
        </div>

        {/* USER DESCRIPTION */}
        <div className="flex flex-col gap-2">
          {/* HEADER */}
          <div className="flex items-center gap-4 text-secondary">
            <div className="pl-6 flex items-center gap-2 shrink-0">
              <MessageSquare className="size-5" />
              <p className="text-sm">
                {t("order.detail.customer_description")}
              </p>
            </div>
            <span className="h-px w-full bg-secondary/50" />
          </div>

          {/* BODY */}
          <div className="px-6">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <p className="text-sm line-clamp-2">
                    {getDisplayValue(selectedOrder?.description)}
                  </p>
                </TooltipTrigger>
                <TooltipContent side="left" className="max-w-[300px]">
                  <p className="font-medium whitespace-pre-wrap">
                    {getDisplayValue(selectedOrder?.description)}
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>

          {/* Creation Date */}
          <div className="mt-auto flex flex-col gap-2">
            <Separator className="bg-secondary/50" />
            <div className="px-6 flex items-center gap-2 text-secondary pt-2">
              <CalendarClock className="size-5 shrink-0" />
              <p className="text-sm leading-snug tracking-tight truncate">
                {t("order.detail.created_at", {
                  date: formatDateMonthName(selectedOrder?.created_at),
                })}
              </p>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
