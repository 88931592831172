import React, { createContext, useState, useMemo, useEffect } from "react";
import { UserContextType, UserProviderProps } from "./types";
import { useAuthQuery, useProfileQuery } from "../../hooks";
import { useTokenRefresh } from "../../hooks/useTokenRefresh";
import { RoleManager } from "../../utils/roleManager";
import type { Permission, ProfileUpdateData, User } from "@repo/service";
import { useCurrentLanguage } from "@repo/config";

/**
 * UserProvider component
 *
 * Manages user authentication state, profile data, and token refresh
 *
 * @param {UserProviderProps} props - Component props
 * @returns {React.ReactElement} Rendered component
 */
export const UserProvider: React.FC<UserProviderProps> = ({
  children,
  authService,
  profileService,
  env,
}: UserProviderProps): React.ReactElement => {
  // Ensure AUTH_APP_URL is provided in the environment configuration
  if (!env?.AUTH.AUTH_APP_URL) {
    throw new Error("AUTH_APP_URL is required in env configuration");
  }

  // Fetch and manage user profile data
  const {
    profileQuery,
    permissionsQuery,
    updateProfileQuery,
    refreshProfile,
    accountTypesQuery,
  } = useProfileQuery({
    profileService,
    language: useCurrentLanguage(),
    isAuthenticated: !authService.isTokenExpired(),
  });

  // Initialize authentication state
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(() => {
    return !authService.isTokenExpired();
  });

  const { logoutMutation } = useAuthQuery(authService, env);

  // Handle token refresh
  useTokenRefresh({
    isAuthenticated,
    authService,
    refreshProfile,
    onLogout: () => {
      // First set isAuthenticated to false, so the UI responds immediately
      setIsAuthenticated(false);

      // Then perform the logout
      logoutMutation.mutate(undefined, {
        onError: () => {
          // Even if logout fails, we consider the user session ended
          console.error("Logout operation failed, but user session ended");
        },
      });
    },
  });

  // Update authentication state when profile data or auth service changes
  useEffect(() => {
    const hasValidToken = !authService.isTokenExpired();
    setIsAuthenticated(hasValidToken);
  }, [profileQuery.data, authService]);

  const roleManager = useMemo(
    () => RoleManager.createFromRole(profileQuery.data?.role_level),
    [profileQuery.data?.role_level],
  );

  const permissions = useMemo(() => {
    const permissionData = permissionsQuery.data;
    if (!permissionData?.length) return [];
    return permissionData.map((permission: Permission) => permission.codename);
  }, [permissionsQuery.data]);

  const updateProfile = async (data: Partial<ProfileUpdateData>) => {
    if (!profileQuery.data) return;

    // Prepare profile data
    let profileData;

    if (data.profile) {
      // Case 1: New profile data is provided
      profileData = {
        title: data.profile.title ?? profileQuery.data.profile?.title ?? "",
        account_type:
          data.profile.account_type ??
          profileQuery.data.profile?.account_type?.slug ??
          "",
      };
    } else if (profileQuery.data.profile) {
      // Case 2: Use existing profile data
      profileData = {
        title: profileQuery.data.profile.title,
        account_type: profileQuery.data.profile.account_type?.slug ?? "",
      };
    } else {
      // Case 3: No profile data
      profileData = undefined;
    }

    const updatedProfile = {
      ...profileQuery.data,
      ...data,
      profile: profileData,
    };

    return updateProfileQuery.mutateAsync(updatedProfile);
  };

  // Memoize context value to prevent unnecessary re-renders
  const value = useMemo(
    () => ({
      user: profileQuery.data || null,
      permissions,
      isAuthenticated,
      isLoading: profileQuery.isLoading || permissionsQuery.isLoading,
      refreshProfile,
      roleManager,
      updateProfile,
      isUpdating: updateProfileQuery.isPending,
      accountTypes: accountTypesQuery,
    }),
    [
      isAuthenticated,
      profileQuery.data,
      profileQuery.isLoading,
      permissions,
      permissionsQuery.isLoading,
      refreshProfile,
      roleManager,
      updateProfileQuery.isPending,
      accountTypesQuery,
    ],
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

/**
 * Context for user-related data and operations
 */
export const UserContext = createContext<UserContextType | undefined>(
  undefined,
);
