import { Approvals } from "@repo/service";
import { Avatar, AvatarFallback } from "@repo/ui/components/ui/Avatar";
import { Card, CardContent } from "@repo/ui/components/ui/Card";
import {
  getReservationStatusColor,
  getReservationStatusBadgeVariant,
  getBorderColorClass,
  getInitialsFromName,
} from "../utils/getBadgeVariant";
import { cn } from "@repo/ui/lib/utils";
import { formatDateTime, useTranslation } from "@repo/config";
import { Badge } from "@repo/ui/components/ui/Badge";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@repo/ui/components/ui/Tooltip";

interface ReservationDetailContentApprovalStepsProps {
  reservation: {
    approvals: Approvals[];
  };
}

export default function ReservationDetailContentApprovalSteps({
  reservation,
}: Readonly<ReservationDetailContentApprovalStepsProps>) {
  const { t } = useTranslation("reservations");

  // Sort approvals by priority
  const sortedApprovals = [...reservation.approvals].sort(
    (a, b) => a.priority - b.priority,
  );

  // Filter approvals to show only up to the first rejected one
  const filteredApprovals = sortedApprovals.reduce(
    (acc: Approvals[], current) => {
      // If we already found a rejected approval, don't add any more
      if (acc.some((a) => a.approval_status.slug === "rejected")) {
        return acc;
      }

      // Add current approval
      acc.push(current);

      // If current is rejected, this will be the last one
      return acc;
    },
    [],
  );

  return (
    <Card className="shrink-0">
      <CardContent className="pt-6 pb-4 px-0 space-y-2">
        {/* HEADER */}
        <div className="flex items-center gap-2 text-secondary">
          <div className="pl-1 flex items-center gap-2 shrink-0">
            <p className="text-sm">{t("detail.approval_steps")}</p>
          </div>
          <span className="h-px w-full bg-secondary/50" />
        </div>
        <div className="flex px-3 flex-col items-center relative">
          {filteredApprovals.map((approval, index) => {
            const isLast = index === filteredApprovals.length - 1;
            const statusColor = getReservationStatusColor(
              approval.approval_status.slug,
            );
            const badgeVariant = getReservationStatusBadgeVariant(
              approval.approval_status.slug,
            );
            const borderColor = getBorderColorClass(
              approval.approval_status.slug,
            );

            // Get user name and initials
            const userName = approval.approved_by?.user || t("unknown_user");
            const initials = getInitialsFromName(userName);

            return (
              <div key={approval.id} className="relative mb-4">
                {/* Stepper line */}
                {!isLast && (
                  <div
                    className={cn(
                      "absolute left-1/2 top-10 w-0.5 h-6 -translate-x-1/2",
                      statusColor,
                    )}
                  />
                )}

                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div className="cursor-pointer">
                        <Avatar
                          className={cn(
                            "h-10 w-10 border-2 z-10 bg-background",
                            borderColor,
                          )}
                        >
                          <AvatarFallback className="text-xs">
                            {initials.toUpperCase()}
                          </AvatarFallback>
                        </Avatar>
                      </div>
                    </TooltipTrigger>
                    <TooltipContent side="left" className="w-64 p-3">
                      <div className="space-y-2">
                        <p className="text-sm font-medium">{userName}</p>
                        <Badge variant={badgeVariant}>
                          {approval.approval_status.name}
                        </Badge>
                        {approval.comment && (
                          <p className="text-xs text-muted-foreground">
                            {approval.comment}
                          </p>
                        )}
                        <p className="text-xs text-muted-foreground">
                          {formatDateTime(approval.created_at)}
                        </p>
                      </div>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
            );
          })}
        </div>
      </CardContent>
    </Card>
  );
}
