import { useEffect, useState, useMemo } from "react";
import { Theme, ThemeProviderContext } from "./themeContext";
import { ThemeProviderProps } from "./types";
import Cookies from "js-cookie";

// Cookie constants
const THEME_COOKIE_NAME = "theme";
const COOKIE_EXPIRES_DAYS = 365; // 1 year

const getCookieExpirationDate = () => {
  const date = new Date();
  date.setDate(date.getDate() + COOKIE_EXPIRES_DAYS);
  return date;
};

export function ThemeProvider({
  children,
  defaultTheme = "system",
  ...props
}: Readonly<ThemeProviderProps>) {
  // Get theme from cookie or default theme
  const [theme, setTheme] = useState<Theme>(
    () => (Cookies.get(THEME_COOKIE_NAME) as Theme) || defaultTheme,
  );

  useEffect(() => {
    const root = window.document.documentElement;

    root.classList.remove("light", "dark");

    if (theme === "system") {
      const systemTheme = window.matchMedia("(prefers-color-scheme: dark)")
        .matches
        ? "dark"
        : "light";

      root.classList.add(systemTheme);
      return;
    }

    root.classList.add(theme);
  }, [theme]);

  const value = useMemo(
    () => ({
      theme,
      setTheme: (theme: Theme) => {
        // Update theme in cookie
        Cookies.set(THEME_COOKIE_NAME, theme, {
          expires: getCookieExpirationDate(),
          path: ".abclojistik.com",
          sameSite: "lax" as const,
        });
        setTheme(theme);
      },
    }),
    [theme],
  );

  return (
    <ThemeProviderContext.Provider {...props} value={value}>
      {children}
    </ThemeProviderContext.Provider>
  );
}
