import { create } from "zustand";

interface CustomerSelectionState {
  selectedCustomerId: string | null;
  selectCustomer: (customerId: string) => void;
  clearSelectedCustomer: () => void;
}

export const useCustomerSelection = create<CustomerSelectionState>((set) => ({
  selectedCustomerId: null,
  selectCustomer: (customerId) => set({ selectedCustomerId: customerId }),
  clearSelectedCustomer: () => set({ selectedCustomerId: null }),
}));
