import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@repo/ui/components/ui/Card";
import { Button } from "@repo/ui/components/ui/Button";
import { useTranslation } from "@repo/config";
import { useReservationSelection } from "../../../store/reservation";
import { ChoosenVehicleCard } from "./ReservationDetailContentChoosenVehicleCard";
import { useState } from "react";
import ReservationsDetailContentRejectDialog from "./ReservationDetailContentRejectDialog";
import { useReservationQuery } from "../hooks/useReservation";
import { toastManager } from "@repo/ui/utils";
import { Check, X } from "lucide-react";
import { usePermission, useFirebaseQuery } from "@repo/provider";
import { isApprovalCompleted } from "../utils/reservationManagementHelper";
import { ReservationVehicle } from "@repo/service";

interface ReservationsDetailContentApproveRejectCardProps {
  reservation_id: string;
  action_id: string;
  action_status_slug: string;
  reservationVehicle: ReservationVehicle | null;
}

export default function ReservationsDetailContentApproveRejectCard({
  reservation_id,
  action_id,
  action_status_slug,
  reservationVehicle,
}: Readonly<ReservationsDetailContentApproveRejectCardProps>) {
  const { t } = useTranslation("reservations");
  const { selectedVehicle, selectedReservation } = useReservationSelection();
  const [isRejectDialogOpen, setIsRejectDialogOpen] = useState(false);
  const { hasPermission } = usePermission();
  const {
    logEventMutation,
    startTraceMutation,
    addTraceAttributesMutation,
    stopTraceMutation,
  } = useFirebaseQuery();

  const { useApproveReservation, useRejectReservation } = useReservationQuery();

  const approveMutation = useApproveReservation({
    reservation_id,
    action_id,
    plate: "",
  });

  const rejectMutation = useRejectReservation({
    reservation_id,
    action_id,
    comment: "",
  });

  // Get trace name based on user permission
  const getTraceName = () => {
    return hasPermission("add_vehicle_reservation")
      ? "reservation_vehicle_assignment_time"
      : "reservation_department_manager_approval_time";
  };

  // Calculate duration between reservation update and approval/rejection
  const calculateDuration = () => {
    if (!selectedReservation?.reservation?.updated_at) return 0;

    const updatedAt = new Date(
      selectedReservation.reservation.updated_at,
    ).getTime();
    const now = Date.now();
    return Math.floor((now - updatedAt) / 1000); // Duration in seconds
  };

  // Log analytics event
  const logAnalytics = (action: string, extraParams?: Record<string, any>) => {
    logEventMutation.mutate({
      eventName: "reservation_approval_interaction",
      eventParams: {
        action,
        reservation_id,
        action_id,
        page_url: window.location.href,
        page_path: window.location.pathname,
        timestamp: new Date().toISOString(),
        ...extraParams,
      },
    });
  };

  const handleApprove = async () => {
    // Start trace
    const traceName = getTraceName();
    startTraceMutation.mutate({
      traceName,
    });

    // Add trace attributes
    addTraceAttributesMutation.mutate({
      traceName,
      attribute: "action",
      value: "approve",
    });

    addTraceAttributesMutation.mutate({
      traceName,
      attribute: "reservation_id",
      value: reservation_id,
    });

    if (hasPermission("add_vehicle_reservation")) {
      if (!selectedVehicle) {
        toastManager.error(t("no_vehicle_selected"));

        // Stop trace if error
        stopTraceMutation.mutate({
          traceName,
        });

        return;
      }
    }

    try {
      await approveMutation.mutateAsync({
        reservation_id,
        action_id,
        plate: selectedVehicle?.vehicle ?? "",
      });

      // Calculate duration
      const duration = calculateDuration();

      // Add duration to trace
      addTraceAttributesMutation.mutate({
        traceName,
        attribute: "duration_seconds",
        value: duration.toString(),
      });

      // Log analytics event
      logAnalytics("approve_success", {
        duration_seconds: duration,
        has_vehicle: !!selectedVehicle,
      });

      toastManager.success(t("success.approve"));
    } catch (error) {
      // Log error
      logAnalytics("approve_error", { error: String(error) });
    } finally {
      // Stop trace in all cases
      stopTraceMutation.mutate({
        traceName,
      });
    }
  };

  const handleReject = async (reason: string) => {
    // Start trace
    const traceName = getTraceName();
    startTraceMutation.mutate({
      traceName,
    });

    // Add trace attributes
    addTraceAttributesMutation.mutate({
      traceName,
      attribute: "action",
      value: "reject",
    });

    addTraceAttributesMutation.mutate({
      traceName,
      attribute: "reservation_id",
      value: reservation_id,
    });

    try {
      await rejectMutation.mutateAsync({
        reservation_id,
        action_id,
        comment: reason,
      });

      // Calculate duration
      const duration = calculateDuration();

      // Add duration to trace
      addTraceAttributesMutation.mutate({
        traceName,
        attribute: "duration_seconds",
        value: duration.toString(),
      });

      // Log analytics event
      logAnalytics("reject_success", {
        duration_seconds: duration,
        has_reason: !!reason,
      });

      toastManager.success(t("success.reject"));
    } catch (error) {
      // Log error
      logAnalytics("reject_error", { error: String(error) });
    } finally {
      // Stop trace in all cases
      stopTraceMutation.mutate({
        traceName,
      });
    }
  };

  return (
    <>
      <Card className="flex flex-col min-w-[350px] min-h-[250px]">
        {/* Header show only if user has add_vehicle_reservation permission */}
        {hasPermission("add_vehicle_reservation") && (
          <CardHeader className="px-4 pt-5 pb-2">
            <CardTitle className="text-sm md:text-base">
              {selectedVehicle || reservationVehicle?.plate
                ? t("selected_vehicle")
                : t("no_vehicle_selected")}
            </CardTitle>
          </CardHeader>
        )}

        {/* Body */}
        <CardContent className="flex flex-col gap-4 px-4 flex-1">
          {/* Choosen Vehicle Card */}
          {(() => {
            if (reservationVehicle?.plate) {
              return <ChoosenVehicleCard vehicle={reservationVehicle} />;
            }
            if (selectedVehicle) {
              return <ChoosenVehicleCard vehicle={selectedVehicle} />;
            }
            return null;
          })()}
          {/* approve & reject buttons */}
          <div
            className={`flex flex-1 flex-col gap-2 size-full items-center ${!selectedVehicle && !reservationVehicle?.plate ? "justify-center" : "justify-end"}`}
          >
            {hasPermission("add_vehicle_reservation") ? (
              <Button
                variant="success"
                className="w-full"
                disabled={
                  !selectedVehicle ||
                  approveMutation.isPending ||
                  isApprovalCompleted(action_status_slug) ||
                  !!reservationVehicle?.plate
                }
                onClick={handleApprove}
              >
                <Check className="size-5" />
                {(() => {
                  if (approveMutation.isPending) {
                    return t("approving");
                  } else if (isApprovalCompleted(action_status_slug)) {
                    return t("approved");
                  }
                  return t("approve");
                })()}
              </Button>
            ) : (
              <Button
                variant="success"
                className="w-full"
                disabled={
                  approveMutation.isPending ||
                  isApprovalCompleted(action_status_slug) ||
                  !!reservationVehicle?.plate
                }
                onClick={handleApprove}
              >
                <Check className="size-5" />
                {(() => {
                  if (approveMutation.isPending) {
                    return t("approving");
                  } else if (isApprovalCompleted(action_status_slug)) {
                    return t("approved");
                  }
                  return t("approve");
                })()}
              </Button>
            )}
            <Button
              variant="destructiveOutline"
              className="w-full"
              onClick={() => setIsRejectDialogOpen(true)}
              disabled={
                rejectMutation.isPending ||
                isApprovalCompleted(action_status_slug) ||
                !!reservationVehicle?.plate
              }
            >
              <X className="size-5" />
              {rejectMutation.isPending ? t("rejecting") : t("reject")}
            </Button>
          </div>
        </CardContent>
      </Card>

      <ReservationsDetailContentRejectDialog
        open={isRejectDialogOpen}
        onOpenChange={setIsRejectDialogOpen}
        onConfirm={handleReject}
        isLoading={rejectMutation.isPending}
      />
    </>
  );
}
