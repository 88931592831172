import axios, { AxiosError } from "axios";
import {
  ApiErrorCode,
  ApiErrorDetail,
  isApiErrorResponse,
  isOAuthError,
} from "../types";
import { ApiError } from "../infrastructure/apiError";

export class ErrorNormalizer {
  private static instance: ErrorNormalizer;

  private constructor() {}

  public static getInstance(): ErrorNormalizer {
    if (!ErrorNormalizer.instance) {
      ErrorNormalizer.instance = new ErrorNormalizer();
    }
    return ErrorNormalizer.instance;
  }

  public normalize(error: unknown): ApiError {
    // If already normalized, return as is
    if (error instanceof ApiError) {
      return error;
    }

    // Check for offline status first
    if (typeof navigator !== "undefined" && !navigator.onLine) {
      return this.createError({
        code: "network",
        errorCode: "offline",
      });
    }

    // Handle Axios errors
    if (axios.isAxiosError(error)) {
      return this.handleAxiosError(error);
    }

    // Handle regular Error objects
    if (error instanceof Error) {
      return this.createError({
        code: "unknown",
        message: error.message,
      });
    }

    // Handle unknown errors
    return this.createError({
      code: "unknown",
      message: "An unexpected error occurred",
    });
  }

  private handleAxiosError(error: AxiosError): ApiError {
    const status = error.response?.status;
    const responseData = error.response?.data;

    // Handle timeout errors
    if (error.code === "ECONNABORTED") {
      return this.createError({
        code: "timeout",
        errorCode: "request_timeout",
        status,
      });
    }

    // Handle network errors
    if (error.code === "ERR_NETWORK") {
      return this.createError({
        code: "network",
        errorCode: "server_unreachable",
        status,
      });
    }

    // Handle missing response data
    if (!responseData) {
      return this.createError({
        code: "unknown",
        message: error.message ?? "No response data",
        status,
      });
    }

    // Handle API errors (Django style)
    if (isApiErrorResponse(responseData)) {
      const firstError = responseData.errors[0];
      const code = this.mapApiErrorCodeToInternal(responseData.type);

      // Format error messages with field names if available
      const combinedMessage = responseData.errors
        .map((error) => {
          const fieldName = error.attr ? this.capitalizeField(error.attr) : "";
          return fieldName ? `${fieldName}: ${error.detail}` : error.detail;
        })
        .join("\n");

      // For API errors, we want to show all error messages from the response
      return this.createError({
        code,
        message: combinedMessage ?? "Unknown error",
        status,
        errorCode: firstError?.code,
        attribute: firstError?.attr ?? null,
        errors: responseData.errors,
      });
    }

    // Handle OAuth errors
    if (isOAuthError(responseData)) {
      return this.createError({
        code: "auth",
        errorCode: responseData.error,
        status,
      });
    }

    // If the error is an Axios error but not in a format we recognize,
    // try to extract data from the response
    if (error.response?.data) {
      const data = error.response.data;
      if (typeof data === "object" && data !== null) {
        const type = (data as any).type;
        if (type) {
          return this.createError({
            code: this.mapApiErrorCodeToInternal(type),
            message: (data as any).message ?? "Unknown error",
            status,
          });
        }
      }
    }

    // Handle unrecognized errors
    return this.createError({
      code: "unknown",
      message: error.message ?? "An unexpected error occurred",
      status,
    });
  }

  private capitalizeField(field: string): string {
    // Convert snake_case to Title Case
    return field
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  }

  private mapApiErrorCodeToInternal(apiCode: string): ApiErrorCode {
    const codeMap: Record<string, ApiErrorCode> = {
      validation_error: "validation",
      validation: "validation",
      client_error: "client_error",
      server_error: "server",
      not_found: "not_found",
      forbidden_error: "forbidden",
      forbidden: "forbidden",
      authentication_error: "auth",
    };

    return codeMap[apiCode] ?? "unknown";
  }

  private createError(options: {
    code: ApiErrorCode;
    message?: string;
    status?: number;
    errorCode?: string;
    attribute?: string | null;
    errors?: ApiErrorDetail[];
  }): ApiError {
    let translationKey = `error.${options.code}`;

    if (options.errorCode) {
      translationKey = `error.${options.code}.${options.errorCode}`;
    } else if (options.attribute) {
      translationKey = `error.${options.code}.${options.attribute}`;
    }

    return new ApiError({
      ...options,
      severity: "error",
      translationKey,
      message: options.message ?? translationKey,
    });
  }
}

export const errorNormalizer = ErrorNormalizer.getInstance();
