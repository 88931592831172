import ExpeditionTimesTableSkeleton from "@/components/skeletons/ExpeditionTimesTableSkeleton";
import { Suspense, useEffect } from "react";
import { withPermission, useFirebaseQuery } from "@repo/provider";
import { AppName } from "@repo/config";
import ExpeditionTimesTable from "@/features/reports/components/ExpeditionTimesTable";

const ProtectedExpeditionTimesTable = withPermission(ExpeditionTimesTable, {
  requiredPermissions: ["view_siberexpedition"],
  targetApp: AppName.EMPLOYEE,
});

/**
 * @function Dashboard
 * @description Dashboard page component
 * @returns Panel dashboard page
 */
export default function Dashboard() {
  const { logEventMutation } = useFirebaseQuery();

  useEffect(() => {
    logEventMutation.mutate({
      eventName: "page_view",
      eventParams: {
        page_title: document.title,
        page_location: window.location.href,
        page_path: window.location.pathname,
      },
    });
  }, []); // Empty dependency array means this runs once on mount

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 pb-4">
      <Suspense fallback={<ExpeditionTimesTableSkeleton />}>
        <ProtectedExpeditionTimesTable />
      </Suspense>
    </div>
  );
}
