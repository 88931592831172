import type { ApiErrorCode } from "../types";
import { type ToastManager } from "@repo/ui/utils/toastManager";
import { ApiError } from "../infrastructure/apiError";

/**
 * Options for initializing the ErrorHandler
 */
export interface ErrorHandlerOptions {
  toastManager: ToastManager;
  i18n: {
    t: (key: string, options?: Record<string, unknown>) => string;
  };
}

/**
 * Handles API errors and displays them using a toast notification system
 * Implements the Singleton pattern to ensure only one instance exists
 */
export class ApiErrorHandler {
  private static instance: ApiErrorHandler;
  private readonly toastManager: ErrorHandlerOptions["toastManager"];
  private readonly t: ErrorHandlerOptions["i18n"]["t"];

  private constructor(options: ErrorHandlerOptions) {
    this.toastManager = options.toastManager;
    this.t = options.i18n.t;
  }

  /**
   * Initializes or returns the existing singleton instance
   * @param options Configuration options for the error handler
   */
  public static init(options: ErrorHandlerOptions): ApiErrorHandler {
    if (!ApiErrorHandler.instance) {
      ApiErrorHandler.instance = new ApiErrorHandler(options);
    }
    return ApiErrorHandler.instance;
  }

  /**
   * Handles an API error by displaying it as a toast notification
   * @param error The API error to handle
   */
  public handleError(error: ApiError): void {
    const config = {
      title: this.getErrorTitle(error),
      description: this.getErrorDescription(error),
      duration: this.getDurationByType(error.code),
      severity: error.severity,
    };

    this.toastManager.showWithSeverity(
      config.severity,
      config.title,
      config.description,
      { duration: config.duration },
    );
  }

  /**
   * Gets the translated error title
   * Uses custom translation key if provided, otherwise uses default error code based key
   */
  private getErrorTitle(error: ApiError): string {
    // Try to get specific error title with translationKey if available
    if (error.translationKey) {
      const specificTitleKey = `${error.translationKey}.title`;
      const specificTitle = this.t(specificTitleKey, { defaultValue: null });

      if (specificTitle && specificTitle !== specificTitleKey) {
        return specificTitle;
      }
    }

    // If no specific title found, try to get the general error type title
    const generalTitleKey = `error.${error.code}.title`;
    const generalTitle = this.t(generalTitleKey, { defaultValue: null });

    // Special case for validation error only when using the default validation error key
    if (
      error.code === "validation" &&
      (!error.translationKey || error.translationKey === "error.validation") &&
      !error.errors
    ) {
      return "Validation Error";
    }

    // Return general title if it's translated (not the same as the key)
    if (generalTitle && generalTitle !== generalTitleKey) {
      return generalTitle;
    }

    // Fallback to default error title
    const defaultTitle = this.t("error.default.title", { defaultValue: null });
    return defaultTitle || "error.default.title";
  }

  /**
   * Gets the translated error description with error details
   * Uses custom translation key if provided, otherwise uses default error code based key
   */
  private getErrorDescription(error: ApiError): string {
    // Prepare message for interpolation
    const message = error.message || "An unknown error occurred";

    // For errors with errorCode, try to get specific message first
    if (error.errorCode) {
      const specificMessageKey = `${error.translationKey}.message`;
      const specificMessage = this.t(specificMessageKey, {
        defaultValue: null,
        message,
      });

      if (specificMessage && specificMessage !== specificMessageKey) {
        return specificMessage;
      }
    }

    // For API errors (Django style), show the actual error message
    if (error.message && error.errors) {
      return error.message;
    }

    // For validation errors with custom translation key, use default message format
    if (
      error.code === "validation" &&
      error.translationKey &&
      error.translationKey !== "error.validation"
    ) {
      return this.t("error.default.message", { message });
    }

    // For validation errors with default or no translation key, return message directly
    if (error.code === "validation") {
      return error.message;
    }

    // Try to get message from translation key
    if (error.translationKey) {
      // Handle nested error structure
      const messageKey = `${error.translationKey}.message`;
      const messageFromKey = this.t(messageKey, {
        defaultValue: null,
        message,
      });
      if (messageFromKey && messageFromKey !== messageKey) {
        return messageFromKey;
      }
    }

    // If no translation found, use default error message with the provided message
    return this.t("error.default.message", { message });
  }

  /**
   * Gets the toast duration based on error type
   * Returns default duration (5000ms) if type is not mapped
   */
  private getDurationByType(type: ApiErrorCode): number {
    const durationMap: Record<ApiErrorCode, number> = {
      validation: 7000,
      auth: 5000,
      network: 10000,
      server: 5000,
      not_found: 4000,
      forbidden: 6000,
      unknown: 5000,
      timeout: 4000,
      client_error: 5000,
    };

    return durationMap[type] || 5000;
  }
}

/**
 * Helper function to create or get the singleton instance of ApiErrorHandler
 */
export const createErrorHandler = (
  options: ErrorHandlerOptions,
): ApiErrorHandler => ApiErrorHandler.init(options);
