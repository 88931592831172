import { useTranslation } from "@repo/config";
import { Copy, X } from "lucide-react";
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from "@repo/ui/components/ui/Sheet";
import { Button } from "@repo/ui/components/ui/Button";
import { toastManager } from "@repo/ui/utils";
import { getBadgeColor } from "../utils/getBadgeVariant";
import { cn } from "@repo/ui/lib/utils";
import { OrderDetailContent } from "./OrderDetailContent";
import { useOrderStore, useOrderSelection } from "@/store/order";

export const OrderDetailSheet = () => {
  const { t } = useTranslation("orders");
  const { isSheetOpen } = useOrderStore();
  const { selectedOrder, isLoading, error, clearOrder } = useOrderSelection();

  const handleCopyFreightNo = async () => {
    if (!selectedOrder?.freight_demand_no) return;

    try {
      await navigator.clipboard.writeText(selectedOrder.freight_demand_no);
      toastManager.success(t("order.detail.copy.success"));
    } catch (error: any) {
      toastManager.error(t("order.detail.copy.error"), error.message);
    }
  };

  return (
    <Sheet
      open={isSheetOpen}
      modal={true}
      onOpenChange={(open) => {
        if (!open) {
          clearOrder();
        }
      }}
    >
      <SheetContent
        showClose={false}
        side="right"
        className={cn(
          "flex flex-col gap-4 min-w-[96vw] md:min-w-[85vw] lg:min-w-[80vw] xl:min-w-[80vw] 2xl:min-w-[70vw] h-[calc(100dvh-3.75rem)] sm:h-[calc(100dvh-5rem)] top-[60px] sm:top-[80px] rounded-tl-lg",
          isLoading && "pointer-events-none",
        )}
      >
        <SheetHeader>
          <div className="flex items-center justify-between">
            {/* Left: Title , Status Copy Freight No Button*/}
            <div className="flex items-center justify-center gap-2">
              {selectedOrder?.freight_demand_status && (
                <div className="relative rounded-full overflow-hidden size-3.5 border border-border">
                  <div
                    className={cn(
                      "absolute size-full animate-pulse",
                      getBadgeColor(selectedOrder.freight_demand_status.slug),
                    )}
                  />
                </div>
              )}
              <SheetTitle className="text-sm sm:text-lg tracking-tight">
                {t("order.detail.title")}
              </SheetTitle>
              {selectedOrder && (
                <button
                  onClick={handleCopyFreightNo}
                  className="flex items-center gap-2 py-2 px-1  rounded-lg"
                >
                  <p className="text-xs">{selectedOrder.freight_demand_no}</p>
                  <span className="p-1" title={t("order.detail.copy.tooltip")}>
                    <Copy className="size-4 stroke-2" />
                  </span>
                </button>
              )}
            </div>

            {/* Right: Close */}
            <div className="flex items-center gap-4">
              <Button variant="outline" size="iconSm" onClick={clearOrder}>
                <X className="size-4" />
              </Button>
            </div>
          </div>
        </SheetHeader>
        {/* Hidden Description for sr-only */}
        <SheetDescription className="hidden">
          <span className="sr-only">{t("order.detail.description")}</span>
          {selectedOrder?.freight_demand_no}
        </SheetDescription>
        <OrderDetailContent
          error={error}
          isLoading={isLoading}
          selectedOrder={selectedOrder}
        />
      </SheetContent>
    </Sheet>
  );
};
