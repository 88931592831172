import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from "@repo/ui/components/ui/Sheet";
import { useTranslation } from "@repo/config";
import { useCustomerSelection } from "@/store/customer";
import { useCustomer } from "../hooks/useCustomer";
import { AlertCircle } from "lucide-react";
import { useQueryStates, parseAsString } from "nuqs";
import CustomerDetailContent from "./CustomerDetailContent";
import CustomerContentDetailSkeleton from "@/components/skeletons/CustomerContentDetailSkeleton";

export const CustomerDetailSheet = () => {
  const { t } = useTranslation("customers");
  const { clearSelectedCustomer } = useCustomerSelection();
  const [queryParams, setQueryParams] = useQueryStates(
    {
      selected_customer: parseAsString.withDefault(""),
    },
    {
      history: "push",
      shallow: true,
    },
  );

  const {
    data: customer,
    error,
    isPending,
  } = useCustomer({
    customer_id: queryParams.selected_customer ?? "",
  });

  const handleClose = () => {
    clearSelectedCustomer();
    setQueryParams({ selected_customer: "" });
  };

  const renderContent = () => {
    if (error) {
      return (
        <div className="flex flex-col items-center justify-center py-8">
          <AlertCircle className="h-12 w-12 text-destructive mb-3" />
          <p className="text-lg font-medium text-destructive">
            {t("error.customer.detail.fetch_title")}
          </p>
        </div>
      );
    }

    if (isPending) {
      return <CustomerContentDetailSkeleton />;
    }

    if (customer) {
      return (
        <div className="space-y-6 mt-6">
          <CustomerDetailContent customer={customer} />
        </div>
      );
    }

    return null;
  };

  return (
    <Sheet open={!!queryParams.selected_customer} onOpenChange={handleClose}>
      <SheetContent className="w-full transition-all duration-300 lg:max-w-[600px]">
        <SheetHeader>
          <SheetTitle>{t("customers.detail.title")}</SheetTitle>
        </SheetHeader>
        <SheetDescription className="hidden">
          <span className="sr-only">{t("order.detail.description")}</span>
          {customer?.first_name} {customer?.last_name}
        </SheetDescription>
        {renderContent()}
      </SheetContent>
    </Sheet>
  );
};
