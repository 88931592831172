import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@repo/ui/components/ui/Card";
import { useCustomersTableColumns } from "./CustomersTableColumns";
import { DataTable } from "@repo/ui/components/table/DataTable";
import { useCallback, useMemo, useTransition } from "react";
import { useQueryStates, parseAsInteger, parseAsString } from "nuqs";
import { useTranslation } from "@repo/config";
import { AlertCircle } from "lucide-react";
import {
  DEFAULT_PAGE_SIZE,
  TABLE_PAGE_SIZE_OPTIONS,
} from "@/features/orders/utils/constants";
import { useCustomers } from "../hooks/useCustomers";
import { Customer } from "@repo/service";
import { CustomersTableFilters } from "./CustomersTableFilters";
import { TableTranslations } from "@repo/ui/components/table/types";
import { useQueryClient } from "@tanstack/react-query";
import { CUSTOMER_DETAIL_QUERY_KEY } from "../hooks/useCustomer";

const CUSTOMER_QUERY_PARAMS = {
  PAGE: "customer_page",
  SIZE: "customer_size",
  SEARCH: "customer_search",
  DOCUMENT_STATUS: "customer_document_status",
} as const;

export default function CustomersTable() {
  const columns = useCustomersTableColumns();
  const { t } = useTranslation("customers");
  const queryClient = useQueryClient();

  const translations: TableTranslations = {
    loading: {
      data: t("loading.data"),
    },
    table: {
      no_records: t("table.no_records"),
      page_size: t("table.page_size"),
      page: t("table.page"),
      first_page: t("table.first_page"),
      previous_page: t("table.previous_page"),
      next_page: t("table.next_page"),
      last_page: t("table.last_page"),
      refresh: t("table.refresh"),
    },
    pagination: {
      total_records: t("pagination.total_records"),
      of: t("pagination.of"),
    },
  };
  const [isTransitionPending, startTransition] = useTransition();

  const [queryParams, setQueryParams] = useQueryStates(
    {
      [CUSTOMER_QUERY_PARAMS.PAGE]: parseAsInteger.withDefault(0),
      [CUSTOMER_QUERY_PARAMS.SIZE]:
        parseAsInteger.withDefault(DEFAULT_PAGE_SIZE),
      [CUSTOMER_QUERY_PARAMS.SEARCH]: parseAsString.withDefault(""),
      [CUSTOMER_QUERY_PARAMS.DOCUMENT_STATUS]: parseAsString.withDefault(""),
    },
    {
      history: "push",
      shallow: true,
      throttleMs: 500,
    },
  );

  const {
    [CUSTOMER_QUERY_PARAMS.PAGE]: pageIndex,
    [CUSTOMER_QUERY_PARAMS.SIZE]: pageSize,
    [CUSTOMER_QUERY_PARAMS.SEARCH]: search,
    [CUSTOMER_QUERY_PARAMS.DOCUMENT_STATUS]: documentStatus,
  } = queryParams;

  const handleSearchChange = useCallback(
    (value: string) => {
      startTransition(() => {
        setQueryParams(
          {
            [CUSTOMER_QUERY_PARAMS.SEARCH]: value,
            [CUSTOMER_QUERY_PARAMS.PAGE]: 0,
          },
          { history: "push", shallow: true },
        );
      });
    },
    [setQueryParams],
  );

  const handlePageChange = useCallback(
    (newPage: number) => {
      startTransition(() => {
        setQueryParams(
          { [CUSTOMER_QUERY_PARAMS.PAGE]: newPage },
          { history: "push", shallow: true },
        );
      });
    },
    [setQueryParams],
  );

  const handlePageSizeChange = useCallback(
    (newPageSize: number) => {
      startTransition(() => {
        setQueryParams(
          {
            [CUSTOMER_QUERY_PARAMS.SIZE]: newPageSize,
            [CUSTOMER_QUERY_PARAMS.PAGE]: 0,
          },
          { history: "push", shallow: true },
        );
      });
    },
    [setQueryParams],
  );

  const handleDocumentStatusChange = useCallback(
    (value: string) => {
      startTransition(() => {
        setQueryParams(
          {
            [CUSTOMER_QUERY_PARAMS.DOCUMENT_STATUS]: value,
            [CUSTOMER_QUERY_PARAMS.PAGE]: 0,
          },
          { history: "push", shallow: true },
        );
      });
    },
    [setQueryParams],
  );

  const apiParams = useMemo(
    () => ({
      page: pageIndex + 1,
      limit: pageSize,
      filters: {
        search: search ?? undefined,
        document_status: documentStatus ?? undefined,
      },
    }),
    [pageIndex, pageSize, search, documentStatus],
  );

  const {
    data: customers,
    error,
    isPending: isQueryPending,
    refetch,
  } = useCustomers(apiParams);

  const handleRefetch = useCallback(async () => {
    await Promise.all([
      // Refetch customers list
      refetch(),
      // Invalidate all customer detail queries
      queryClient.invalidateQueries({
        queryKey: [CUSTOMER_DETAIL_QUERY_KEY],
        exact: false,
      }),
    ]);
  }, [refetch, queryClient]);

  const isLoadingState = isQueryPending || isTransitionPending;

  const totalPageCount = useMemo(
    () => (customers ? Math.ceil(customers.count / pageSize) : 0),
    [customers?.count, pageSize],
  );

  if (error) {
    return (
      <Card className="space-y-4 col-span-2">
        <CardHeader>
          <CardTitle>{t("customers.title")}</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex flex-col items-center justify-center py-8">
            <AlertCircle className="h-12 w-12 text-destructive mb-3" />
            <p className="text-lg font-medium text-destructive">
              {t("error.customer.detail.fetch_title")}
            </p>
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="space-y-2 col-span-2">
      <CardHeader>
        <CardTitle>{t("customers.title")}</CardTitle>
      </CardHeader>
      <CardContent>
        <DataTable<Customer>
          columns={columns}
          data={customers?.results ?? []}
          pageCount={totalPageCount}
          pageIndex={pageIndex}
          filterComponent={
            <CustomersTableFilters
              searchValue={search}
              onSearchChange={handleSearchChange}
              documentStatusValue={documentStatus}
              onDocumentStatusChange={handleDocumentStatusChange}
            />
          }
          onRefetch={handleRefetch}
          pageSize={pageSize}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          isPending={isLoadingState}
          totalRecords={customers?.count ?? 0}
          translations={translations}
          TABLE_PAGE_SIZE_OPTIONS={TABLE_PAGE_SIZE_OPTIONS}
        />
      </CardContent>
    </Card>
  );
}
