export const getReservationStatusBadgeVariant = (slug: string) => {
  switch (slug) {
    case "pending":
      return "warning";
    case "approved":
      return "success";
    case "rejected":
      return "destructive";
    case "vehicle-assignment-pending":
      return "warningOutline";
    default:
      return "secondary";
  }
};

// Reservation status color
export const getReservationStatusColor = (slug: string) => {
  switch (slug) {
    case "pending":
      return "bg-warning";
    case "approved":
      return "bg-success";
    case "rejected":
      return "bg-destructive";
    case "vehicle-assignment-pending":
      return "bg-warning";
    default:
      return "bg-secondary";
  }
};

// Vehicle status badge variant
export const getVehicleStatusBadgeVariant = (slug: string) => {
  switch (slug) {
    case "waiting-exit":
      return "outline";
    case "waiting-delivery":
      return "secondary";
    case "vehicle-delivered":
      return "success";
    default:
      return "secondary";
  }
};

// Determine border color class based on status slug
export const getBorderColorClass = (slug: string): string => {
  switch (slug) {
    case "pending":
      return "border-warning";
    case "approved":
      return "border-success";
    case "rejected":
      return "border-destructive";
    default:
      return "border-primary";
  }
};

// Get activity badge variant
export const getActivityBadgeVariant = (slug: string) => {
  switch (slug) {
    case "entered":
      return "successOutline";
    case "exited":
      return "secondaryOutline";
    default:
      return "secondaryOutline";
  }
};

// Get initials from name
export const getInitialsFromName = (name: string): string => {
  if (!name) return "??";

  const nameParts = name.split(" ");
  if (nameParts.length > 1) {
    return `${nameParts[0][0] || ""}${nameParts[nameParts.length - 1][0] || ""}`;
  }
  return nameParts[0]?.substring(0, 2) || "??";
};

// Checks if value is null or undefined if so returns " --- "
export const checkIfValueIsEmptyOrNullOrUndefined = (value: any) => {
  if (value === null || value === undefined || value === "") {
    return " --- ";
  }
  return value;
};
