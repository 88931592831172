import { ColumnDef } from "@tanstack/react-table";
import { DataTableColumnHeader } from "@repo/ui/components/table/DataTableColumnHeader";
import { useTranslation, formatDateTime } from "@repo/config";
import { Badge } from "@repo/ui/components/ui/Badge";
import { TableCellContent } from "@repo/ui/components/table/TableCellContent";
import { Button } from "@repo/ui/components/ui/Button";
import { Eye } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@repo/ui/components/ui/Tooltip";
import { useReservationSelection } from "@/store/reservation";
import { Reservation } from "@repo/service";
import {
  getVehicleStatusBadgeVariant,
  getReservationStatusBadgeVariant,
} from "../utils/getBadgeVariant";
import { usePermission } from "@repo/provider";

export const useReservationsTableColumns = () => {
  const { t } = useTranslation("reservations");
  const { selectReservation } = useReservationSelection();
  const { hasPermission } = usePermission();

  const handleReservationSelect = (reservation: Reservation) => {
    selectReservation(reservation.id);
  };

  // Check if user is from security department
  const isUserSecurityCheck = hasPermission("track_reservation");

  const columns: ColumnDef<Reservation>[] = [
    {
      id: "reservation_no",
      accessorFn: (row) => row.reservation.reservation_no,
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={t("reservation_no")} />
      ),
      cell: ({ row }) => {
        const reservation = row.original;
        return (
          <button
            onClick={() => handleReservationSelect(reservation)}
            className="cursor-pointer group-hover:underline"
          >
            <TableCellContent content={row.getValue("reservation_no")} />
          </button>
        );
      },
      enableSorting: true,
    },
    {
      id: "plate",
      accessorFn: (row) => row.reservation.vehicle?.plate,
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={t("plate")} />
      ),
      cell: ({ row }) => <TableCellContent content={row.getValue("plate")} />,
    },
    // created_at column - only show if not security
    ...(!isUserSecurityCheck
      ? [
          {
            id: "created_at",
            accessorFn: (row: Reservation) => row.created_at,
            header: ({ column }: { column: any }) => (
              <DataTableColumnHeader column={column} title={t("created_at")} />
            ),
            cell: ({ row }: { row: any }) => (
              <TableCellContent
                content={formatDateTime(row.getValue("created_at"))}
              />
            ),
          },
        ]
      : []),
    {
      id: "user",
      accessorFn: (row: Reservation) => row.reservation.user,
      header: ({ column }: { column: any }) => (
        <DataTableColumnHeader column={column} title={t("demand_owner")} />
      ),
      cell: ({ row }: { row: any }) => (
        <TableCellContent content={row.getValue("user")} />
      ),
    },
    // reservation_type column - only show if not security
    ...(!isUserSecurityCheck
      ? [
          {
            id: "reservation_type",
            accessorFn: (row: Reservation) => row.reservation.reservation_type,
            header: ({ column }: { column: any }) => (
              <DataTableColumnHeader
                column={column}
                title={t("reservation_type")}
              />
            ),
            cell: ({ row }: { row: any }) => (
              <TableCellContent content={row.getValue("reservation_type")} />
            ),
          },
        ]
      : []),
    {
      id: "expected_delivery_date",
      accessorFn: (row) => row.reservation.expected_delivery_date,
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={t("delivery_time")} />
      ),
      cell: ({ row }) => (
        <TableCellContent
          content={formatDateTime(row.getValue("expected_delivery_date"))}
        />
      ),
    },
    // estimated_time column - only show if not security
    ...(!isUserSecurityCheck
      ? [
          {
            id: "estimated_time",
            accessorFn: (row: Reservation) => row.reservation.estimated_time,
            header: ({ column }: { column: any }) => (
              <DataTableColumnHeader
                column={column}
                title={t("estimated_use_time")}
              />
            ),
            cell: ({ row }: { row: any }) => (
              <TableCellContent content={row.getValue("estimated_time")} />
            ),
          },
        ]
      : []),
    // vehicle_exited_date column - only show if security
    ...(isUserSecurityCheck
      ? [
          {
            id: "vehicle_exited_date",
            accessorFn: (row: Reservation) =>
              row.reservation_activities.vehicle_exited_date,
            header: ({ column }: { column: any }) => (
              <DataTableColumnHeader
                column={column}
                title={t("vehicle_exited_date")}
              />
            ),
            cell: ({ row }: { row: any }) => (
              <TableCellContent
                content={formatDateTime(row.getValue("vehicle_exited_date"))}
              />
            ),
          },
        ]
      : []),
    // vehicle_entered_date column - only show if security
    ...(isUserSecurityCheck
      ? [
          {
            id: "vehicle_entered_date",
            accessorFn: (row: Reservation) =>
              row.reservation_activities.vehicle_entered_date,
            header: ({ column }: { column: any }) => (
              <DataTableColumnHeader
                column={column}
                title={t("vehicle_entered_date")}
              />
            ),
            cell: ({ row }: { row: any }) => (
              <TableCellContent
                content={formatDateTime(row.getValue("vehicle_entered_date"))}
              />
            ),
          },
        ]
      : []),
    {
      id: "approval_status",
      accessorFn: (row: Reservation): Reservation["approval_status"] =>
        row.approval_status,
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={t("status")} />
      ),
      cell: ({ row }) => {
        const approvalStatus: Reservation["approval_status"] =
          row.getValue("approval_status");
        if (!approvalStatus) return null;

        return (
          <Badge
            variant={getReservationStatusBadgeVariant(approvalStatus.slug)}
          >
            <TableCellContent content={approvalStatus.name} />
          </Badge>
        );
      },
    },
    {
      id: "vehicle_status",
      accessorFn: (row: Reservation): Reservation["vehicle_status"] =>
        row.vehicle_status,
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={t("vehicle_status")} />
      ),
      cell: ({ row }) => {
        const vehicleStatus: Reservation["vehicle_status"] =
          row.getValue("vehicle_status");
        if (!vehicleStatus) return null;

        return (
          <Badge variant={getVehicleStatusBadgeVariant(vehicleStatus.slug)}>
            <TableCellContent content={vehicleStatus.name} />
          </Badge>
        );
      },
    },
    {
      id: "actions",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          className="flex justify-center"
          title={t("actions.title")}
        />
      ),
      cell: ({ row }) => {
        const reservation = row.original;
        return (
          <div className="flex justify-center">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="outline"
                    size="icon"
                    className="h-8 w-8 group-hover:bg-success-background group-hover:text-success"
                    onClick={() => handleReservationSelect(reservation)}
                  >
                    <Eye className="size-4" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>{t("actions.detail")}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        );
      },
      meta: {
        className: "sticky right-0 bg-card shadow-[-2.5px_0_4px_-4px_gray]",
      },
    },
  ];

  return columns;
};
