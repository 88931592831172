export const getDocumentStatusBadgeColor = (slug: string) => {
  switch (slug) {
    case "no-missing-documents":
      return "bg-primary/40";
    case "rejected":
      return "bg-destructive";
    case "under-review":
      return "bg-warning";
    case "missing-documents":
      return "bg-warning";
    case "approved":
      return "bg-success";
    case "awaiting-document":
      return "bg-warning";
    case "awaiting-approval":
      return "bg-warning border-warning";
    default:
      return "bg-primary/40";
  }
};

export const getDocumentBadgeVariant = (slug: string) => {
  switch (slug) {
    case "no-missing-documents":
      return "outline";
    case "rejected":
      return "destructive";
    case "under-review":
      return "warning";
    case "missing-documents":
      return "warning";
    case "approved":
      return "success";
    case "awaiting-document":
      return "warning";
    case "awaiting-approval":
      return "warningOutline";
    default:
      return "outline";
  }
};
