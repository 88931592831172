import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@repo/ui/lib/utils";

export type BadgeVariant =
  | "default"
  | "secondary"
  | "destructive"
  | "warning"
  | "outline"
  | "success"
  | "secondaryOutline"
  | "destructiveOutline"
  | "warningOutline"
  | "successOutline";

const badgeVariants = cva(
  "inline-flex items-center rounded-full border px-2.5 py-0.5 text-xs font-normal transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2",
  {
    variants: {
      variant: {
        default:
          "border-transparent bg-primary text-primary-foreground hover:bg-primary/80",
        secondary:
          "border-transparent bg-secondary-background text-secondary hover:bg-secondary-background/80",
        secondaryOutline:
          "border-transparent bg-secondary-background text-secondary border-secondary",
        destructive:
          "border-transparent bg-destructive-background text-destructive hover:bg-destructive-background/80",
        destructiveOutline:
          "border-transparent bg-destructive-background text-destructive border-destructive",
        warning:
          "border-transparent bg-warning-background text-warning hover:bg-warning-background/80",
        warningOutline:
          "border-transparent bg-warning-background text-warning border-warning",
        outline: "text-foreground",
        success:
          "border-transparent bg-success-background text-success hover:bg-success-background/80",
        successOutline:
          "border-transparent bg-success-background text-success border-success",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return (
    <div className={cn(badgeVariants({ variant }), className)} {...props} />
  );
}

export { Badge, badgeVariants };
