import { ApiErrorCode, ErrorSeverity, ApiErrorDetail } from "../types";

interface ApiErrorOptions {
  message: string;
  code: ApiErrorCode;
  status?: number;
  severity?: ErrorSeverity;
  translationKey?: string;
  errorCode?: string;
  attribute?: string | null;
  errors?: ApiErrorDetail[];
}

export class ApiError extends Error {
  public readonly code: ApiErrorCode;
  public readonly status?: number;
  public readonly severity: ErrorSeverity;
  public readonly translationKey?: string;
  public readonly errorCode?: string;
  public readonly attribute?: string | null;
  public readonly errors?: ApiErrorDetail[];

  constructor(options: ApiErrorOptions) {
    super(options.message);
    this.name = "ApiError";
    this.code = options.code;
    this.status = options.status;
    this.severity = options.severity ?? "error";
    this.translationKey = options.translationKey;
    this.errorCode = options.errorCode;
    this.attribute = options.attribute;
    this.errors = options.errors;

    Object.setPrototypeOf(this, ApiError.prototype);
  }
}
