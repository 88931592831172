import { initializeApp, FirebaseApp } from "firebase/app";
import { Analytics, getAnalytics } from "firebase/analytics";
import { getPerformance, trace } from "firebase/performance";
import type {
  FirebasePerformance,
  PerformanceTrace,
} from "@firebase/performance-types";
import { FirebaseServiceType } from "../../types/firebase.types";
import { FirebaseEnv } from "@repo/config";

export class FirebaseService {
  private readonly app: FirebaseApp | null = null;
  private analytics: Analytics | null = null;
  private performance: FirebasePerformance | null = null;

  constructor(config: FirebaseEnv) {
    this.app = initializeApp(config);
  }

  public getService<T>(type: FirebaseServiceType): T | null {
    if (!this.app) {
      throw new Error("Firebase has not been initialized.");
    }

    switch (type) {
      case "analytics":
        if (!this.analytics) {
          this.analytics = getAnalytics(this.app);
        }
        return this.analytics as T;

      case "performance":
        if (!this.performance) {
          const perf = getPerformance(this.app);
          // Add required interface properties
          this.performance = {
            ...perf,
            trace: (traceName: string): PerformanceTrace => {
              const perfTrace = trace(perf, traceName);
              return perfTrace;
            },
            instrumentationEnabled: true,
            dataCollectionEnabled: true,
          };
        }
        return this.performance as T;

      default:
        return null;
    }
  }

  public isInitialized(): boolean {
    return this.app !== null;
  }
}

export const createFirebaseService = (config: FirebaseEnv): FirebaseService => {
  return new FirebaseService(config);
};
