import { Skeleton } from "@repo/ui/components/ui/Skeleton.tsx";
import { Card, CardContent, CardHeader } from "@repo/ui/components/ui/Card.tsx";
import { createSkeletonKey } from "@/utils";
export default function ExpeditionTimesTableSkeleton() {
  return (
    <Card className="space-y-4 col-span-2">
      <CardHeader>
        <Skeleton className="h-7 w-64" />
      </CardHeader>
      <CardContent className="space-y-6">
        {/* Filters Section */}
        <div className="flex flex-wrap gap-4">
          <Skeleton className="h-10 w-[350px]" /> {/* Search */}
          <Skeleton className="h-10 w-[180px]" /> {/* Type Filter */}
          <Skeleton className="h-10 w-[180px]" /> {/* Status Filter */}
          <Skeleton className="h-10 ml-auto w-[250px]" /> {/* Date Range */}
        </div>

        {/* Table Section */}
        <div className="rounded-md max-h-[calc(100svh-21.5rem)] overflow-hidden">
          {/* Table Header */}
          <div className="bg-muted/50">
            <div className="flex p-4 gap-6">
              {Array.from({ length: 12 }).map((_, index) => (
                <Skeleton key={createSkeletonKey(index)} className="h-4 w-28" />
              ))}
            </div>
          </div>

          {/* Table Rows */}
          <div>
            {Array.from({ length: 10 }).map((_, index) => (
              <div key={createSkeletonKey(index)} className={`p-4`}>
                <div className="flex items-center gap-6">
                  {Array.from({ length: 12 }).map((_, index) => (
                    <Skeleton
                      key={createSkeletonKey(index)}
                      className="h-6 w-28"
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Pagination Section */}
        <div className="flex items-center justify-between px-2">
          <Skeleton className="h-5 w-36" /> {/* Total records */}
          <div className="flex items-center gap-4">
            <div className="flex items-center gap-2">
              <Skeleton className="h-4 w-24" /> {/* Page size text */}
              <Skeleton className="h-9 w-[70px]" /> {/* Page size select */}
            </div>
            <div className="flex items-center gap-2">
              <Skeleton className="h-4 w-24" /> {/* Page info */}
              <div className="flex gap-2">
                <Skeleton className="h-8 w-8" /> {/* First page */}
                <Skeleton className="h-8 w-8" /> {/* Previous */}
                <Skeleton className="h-8 w-8" /> {/* Next */}
                <Skeleton className="h-8 w-8" /> {/* Last page */}
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
