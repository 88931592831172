import { Input } from "@repo/ui/components/ui/Input";
import { useTranslation, useDebounce } from "@repo/config";
import { Search } from "lucide-react";
import { useState, useEffect } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@repo/ui/components/ui/Select";
import { useReservationQuery } from "../hooks/useReservation";
import {
  getReservationStatusColor,
  //   getVehicleStatusBadgeVariant,
} from "../utils/getBadgeVariant";
import { cn } from "@repo/ui/lib/utils";

interface ReservationsTableFiltersProps {
  searchValue: string;
  onSearchChange: (value: string) => void;
  statusValue?: string;
  onStatusChange?: (value: string) => void;
  //   vehicleStatusValue?: string;
  //   onVehicleStatusChange?: (value: string) => void;
}

// Helper function to get vehicle status color class
// const getVehicleStatusColorClass = (slug: string): string => {
//   const variant = getVehicleStatusBadgeVariant(slug);
//   switch (variant) {
//     case "outline":
//       return "bg-primary";
//     case "success":
//       return "bg-success";
//     case "secondary":
//       return "bg-secondary";
//     default:
//       return "bg-secondary";
//   }
// };

export const ReservationsTableFilters = ({
  searchValue,
  onSearchChange,
  statusValue,
  onStatusChange,
  //   vehicleStatusValue,
  //   onVehicleStatusChange,
}: ReservationsTableFiltersProps) => {
  const { t } = useTranslation("reservations");
  const [localSearch, setLocalSearch] = useState(searchValue);
  const debouncedSearch = useDebounce(localSearch, 500);

  const { useApprovalStatuses } = useReservationQuery();
  const { data: statuses } = useApprovalStatuses();
  //   const { data: vehicleStatuses } = useVehicleStatuses();

  useEffect(() => {
    if (debouncedSearch !== searchValue) {
      onSearchChange(debouncedSearch);
    }
  }, [debouncedSearch, onSearchChange, searchValue]);

  useEffect(() => {
    setLocalSearch(searchValue);
  }, [searchValue]);

  return (
    <div className="flex overflow-x-auto items-center gap-4 w-full p-1">
      <div className="flex flex-wrap items-center gap-4 w-full">
        {/* Search input */}
        <div className="relative">
          <Input
            placeholder={t("search.placeholder")}
            value={localSearch}
            onChange={(event) => setLocalSearch(event.target.value)}
            className="max-w-sm pl-9"
          />
          <Search className="absolute left-3 top-1/2 -translate-y-1/2 size-4 text-muted-foreground" />
        </div>

        {/* Status filter */}
        <div className="w-[200px]">
          <Select
            value={statusValue ?? "all"}
            onValueChange={(value) =>
              onStatusChange?.(value === "all" ? "" : value)
            }
          >
            <SelectTrigger>
              <SelectValue placeholder={t("filters.status.placeholder")}>
                {statusValue && statuses ? (
                  <div className="flex items-center gap-2">
                    <span className="relative flex size-2">
                      <span
                        className={cn(
                          "relative inline-flex rounded-full size-2",
                          getReservationStatusColor(statusValue),
                        )}
                      />
                    </span>
                    {
                      statuses.results.find(
                        (status) => status.slug === statusValue,
                      )?.name
                    }
                  </div>
                ) : null}
              </SelectValue>
            </SelectTrigger>
            <SelectContent>
              <SelectItem
                value="all"
                className="flex items-center gap-2 py-2 cursor-pointer"
              >
                <div className="flex items-center gap-2 flex-1">
                  <span className="relative flex size-2">
                    <span className="relative inline-flex rounded-full size-2 bg-muted" />
                  </span>
                  <span className="flex-1 line-clamp-1">
                    {t("filters.status.all")}
                  </span>
                </div>
              </SelectItem>
              {statuses?.results.map((status) => (
                <SelectItem
                  key={status.row_id}
                  value={status.slug}
                  className="flex items-center gap-2 py-2 cursor-pointer"
                >
                  <div className="flex items-center gap-2 flex-1">
                    <span className="relative flex size-2">
                      <span
                        className={cn(
                          "relative inline-flex rounded-full size-2",
                          getReservationStatusColor(status.slug),
                        )}
                      />
                    </span>
                    <span className="flex-1 line-clamp-1">{status.name}</span>
                    <span className="ml-auto text-xs text-muted-foreground bg-muted px-2 py-0.5 rounded-md">
                      {status.count}
                    </span>
                  </div>
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        {/* Vehicle status filter TODO: version 2 */}
        {/* <div className="w-[200px]">
          <Select
            value={vehicleStatusValue ?? "all"}
            onValueChange={(value) =>
              onVehicleStatusChange?.(value === "all" ? "" : value)
            }
          >
            <SelectTrigger>
              <SelectValue
                placeholder={t("filters.vehicle_status.placeholder")}
              >
                {vehicleStatusValue && vehicleStatuses ? (
                  <div className="flex items-center gap-2">
                    <span className="relative flex size-2">
                      <span
                        className={cn(
                          "relative inline-flex rounded-full size-2",
                          getVehicleStatusColorClass(vehicleStatusValue),
                        )}
                      />
                    </span>
                    <span className="flex-1 line-clamp-1">
                      {
                        vehicleStatuses.results.find(
                          (status) => status.slug === vehicleStatusValue,
                        )?.name
                      }
                    </span>
                  </div>
                ) : null}
              </SelectValue>
            </SelectTrigger>
            <SelectContent>
              <SelectItem
                value="all"
                className="flex items-center gap-2 py-2 cursor-pointer"
              >
                <div className="flex items-center gap-2 flex-1">
                  <span className="relative flex size-2">
                    <span className="relative inline-flex rounded-full size-2 bg-muted" />
                  </span>
                  <span className="flex-1 line-clamp-1">
                    {t("filters.vehicle_status.all")}
                  </span>
                </div>
              </SelectItem>
              {vehicleStatuses?.results.map((status) => (
                <SelectItem
                  key={status.row_id}
                  value={status.slug}
                  className="flex items-center gap-2 py-2 cursor-pointer"
                >
                  <div className="flex items-center gap-2 flex-1">
                    <span className="relative flex size-2">
                      <span
                        className={cn(
                          "relative inline-flex rounded-full size-2",
                          getVehicleStatusColorClass(status.slug),
                        )}
                      />
                    </span>
                    <span className="flex-1 line-clamp-1">{status.name}</span>
                  </div>
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div> */}
      </div>
    </div>
  );
};
