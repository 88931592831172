import { BadgeVariant } from "@repo/ui/components/ui/Badge";

export const getBadgeVariant = (slug: string): BadgeVariant => {
  switch (slug) {
    case "price-not-accepted":
    case "out-of-services":
      return "destructive";
    case "order-demand-completed":
      return "success";
    case "price-offer-pending":
      return "outline";
    case "price-approval-pending":
      return "warning";
    case "order-demand-rejected":
      return "destructive";
    default:
      return "warning";
  }
};

export const getBadgeColor = (slug: string): string => {
  switch (slug) {
    case "price-not-accepted":
    case "out-of-services":
      return "bg-destructive";
    case "order-demand-completed":
      return "bg-success";
    case "price-offer-pending":
      return "bg-primary/40";
    case "price-approval-pending":
      return "bg-warning";
    case "order-demand-rejected":
      return "bg-destructive";
    default:
      return "bg-warning";
  }
};

export const getBadgeBackground = (slug: string): string => {
  switch (slug) {
    case "price-not-accepted":
    case "out-of-services":
      return "bg-destructive-background";
    case "order-demand-completed":
      return "bg-success-background";
    case "price-offer-pending":
      return "bg-primary/20";
    case "price-approval-pending":
      return "bg-warning-background";
    case "order-demand-rejected":
      return "bg-destructive-background";
    default:
      return "bg-warning-background";
  }
};

export const getTextColor = (slug: string): string => {
  switch (slug) {
    case "price-not-accepted":
    case "out-of-services":
      return "text-destructive";
    case "order-demand-completed":
      return "text-success";
    case "price-offer-pending":
      return "text-primary";
    case "price-approval-pending":
      return "text-warning";
    case "order-demand-rejected":
      return "text-destructive";
    default:
      return "text-warning";
  }
};
