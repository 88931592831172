import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronsLeftIcon,
  ChevronsRightIcon,
} from "lucide-react";
import { Table } from "@tanstack/react-table";
import { Button } from "../ui/Button";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../ui/Select";
import { TableTranslations } from "./types";

interface DataTablePaginationProps<TData> {
  readonly table: Table<TData>;
  readonly pageCount: number;
  readonly totalRecords: number;
  readonly TABLE_PAGE_SIZE_OPTIONS: number[];
  readonly translations: TableTranslations;
}

export function DataTablePagination<TData>({
  table,
  pageCount,
  totalRecords,
  TABLE_PAGE_SIZE_OPTIONS,
  translations,
}: DataTablePaginationProps<TData>) {
  return (
    <div className="flex flex-col sm:flex-row gap-4 items-center justify-between px-1 py-2">
      {/* Pagination controls */}
      <div className="text-xs whitespace-nowrap md:text-sm text-muted-foreground">
        {/* Total records */}
        {translations.pagination.total_records}: {totalRecords}
      </div>
      <div className="flex flex-wrap items-center justify-center gap-4 sm:gap-0  space-x-6 lg:space-x-8">
        <div className="flex items-center space-x-2">
          <p className="text-xs whitespace-nowrap md:text-sm font-medium">
            {translations.table.page_size}
          </p>
          {/* Page size select */}
          <Select
            value={`${table.getState().pagination.pageSize}`}
            onValueChange={(value) => {
              table.setPageSize(Number(value));
            }}
          >
            <SelectTrigger className="h-8 w-[70px]">
              <SelectValue placeholder={table.getState().pagination.pageSize} />
            </SelectTrigger>
            <SelectContent side="top">
              {TABLE_PAGE_SIZE_OPTIONS.map((pageSize) => (
                <SelectItem key={pageSize} value={`${pageSize}`}>
                  {pageSize}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="flex items-center space-x-2">
          <div className="flex whitespace-nowrap truncate text-xs md:text-sm font-medium">
            {/* Current page */}
            {translations.table.page}{" "}
            {table.getState().pagination.pageIndex + 1}{" "}
            {translations.pagination.of} {pageCount}
          </div>
          <div className="flex items-center space-x-2">
            {/* Pagination buttons */}
            <Button
              variant="outline"
              className="hidden h-8 w-8 p-0 lg:flex"
              onClick={() => table.setPageIndex(0)}
              disabled={table.getState().pagination.pageIndex === 0}
            >
              {/* First page button */}
              <span className="sr-only">{translations.table.first_page}</span>
              <ChevronsLeftIcon className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              className="h-8 w-8 p-0"
              onClick={() => table.previousPage()}
              disabled={table.getState().pagination.pageIndex === 0}
            >
              {/* Previous page button */}
              <span className="sr-only">
                {translations.table.previous_page}
              </span>
              <ChevronLeftIcon className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              className="h-8 w-8 p-0"
              onClick={() => table.nextPage()}
              disabled={table.getState().pagination.pageIndex === pageCount - 1}
            >
              {/* Next page button */}
              <span className="sr-only">{translations.table.next_page}</span>
              <ChevronRightIcon className="h-4 w-4" />
            </Button>
            <Button
              variant="outline"
              className="hidden h-8 w-8 p-0 lg:flex"
              onClick={() => table.setPageIndex(pageCount - 1)}
              disabled={table.getState().pagination.pageIndex === pageCount - 1}
            >
              {/* Last page button */}
              <span className="sr-only">{translations.table.last_page}</span>
              <ChevronsRightIcon className="h-4 w-4" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
