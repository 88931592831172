import { create } from "zustand";
import { GetReservationDetailResponse, AvailableVehicle } from "@repo/service";
import { useReservationQuery } from "@/features/reservations/hooks/useReservation";
import { useEffect } from "react";
import { parseAsString, useQueryState } from "nuqs";

// Store interface
interface ReservationState {
  selectedReservation: GetReservationDetailResponse | null;
  selectedVehicle: AvailableVehicle | null;
  isSheetOpen: boolean;
  openSheet: () => void;
  closeSheet: () => void;
  setReservation: (reservation: GetReservationDetailResponse | null) => void;
  selectVehicle: (vehicle: AvailableVehicle | null) => void;
  clearSelectedVehicle: () => void;
}

// Create store
export const useReservationStore = create<ReservationState>((set) => ({
  selectedReservation: null,
  selectedVehicle: null,
  isSheetOpen: false,
  openSheet: () => set({ isSheetOpen: true }),
  closeSheet: () =>
    set({
      isSheetOpen: false,
      selectedReservation: null,
      selectedVehicle: null,
    }),
  setReservation: (reservation) => set({ selectedReservation: reservation }),
  selectVehicle: (vehicle) => set({ selectedVehicle: vehicle }),
  clearSelectedVehicle: () => set({ selectedVehicle: null }),
}));

// Hook to manage reservation selection and data fetching
export const useReservationSelection = () => {
  const { useReservationDetail } = useReservationQuery();

  // URL state management
  const [selectedReservationId, setSelectedReservationId] = useQueryState(
    "reservation_id",
    parseAsString.withDefault(""),
  );

  // Store actions
  const {
    openSheet,
    closeSheet,
    setReservation,
    selectVehicle,
    clearSelectedVehicle,
    selectedVehicle,
  } = useReservationStore();

  // Fetch reservation details when URL changes
  const {
    data: selectedReservation,
    isLoading,
    error,
  } = useReservationDetail({
    reservation_id: selectedReservationId,
  });

  // Handle data updates and sheet state
  useEffect(() => {
    if (selectedReservationId) {
      openSheet();
    }

    if (selectedReservation) {
      setReservation(selectedReservation);
    }
  }, [selectedReservationId, selectedReservation]);

  // Handle reservation selection
  const selectReservation = async (reservationId: string) => {
    if (selectedReservationId === reservationId) {
      openSheet();
      return;
    }

    openSheet();
    await setSelectedReservationId(reservationId);
  };

  // Handle reservation deselection
  const clearReservation = async () => {
    closeSheet();
    clearSelectedVehicle();
    await setSelectedReservationId("");
  };

  return {
    selectedReservationId,
    selectedReservation,
    selectedVehicle,
    isLoading,
    error,
    selectReservation,
    clearReservation,
    selectVehicle,
    clearSelectedVehicle,
  };
};
