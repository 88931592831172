import { Card, CardContent } from "@repo/ui/components/ui/Card";
import { Skeleton } from "@repo/ui/components/ui/Skeleton";
import { ScrollArea } from "@repo/ui/components/ui/ScrollArea";
import { Separator } from "@repo/ui/components/ui/Separator";

export default function CustomerContentDetailSkeleton() {
  return (
    <ScrollArea className="h-[calc(100dvh-6rem)]">
      <div className="flex flex-col gap-4">
        {/* User/Company Info Card */}
        <Card>
          <CardContent className="p-0">
            {/* Tabs */}
            <div className="flex items-center gap-4 border-b py-2 px-4">
              <Skeleton className="h-8 w-32" />
              <Skeleton className="h-8 w-32" />
            </div>

            {/* Content */}
            <div className="p-6 flex flex-col gap-6">
              {/* Header with Avatar */}
              <div className="flex items-center gap-4">
                <Skeleton className="size-12 rounded-full" />
                <div className="space-y-2">
                  <Skeleton className="h-4 w-32" />
                  <Skeleton className="h-3 w-24" />
                </div>
              </div>

              {/* Info Section */}
              <div className="space-y-6">
                {/* Section Header */}
                <div className="flex items-center gap-2">
                  <Skeleton className="size-5" />
                  <Skeleton className="h-4 w-24" />
                  <Separator className="bg-secondary/50" />
                </div>

                {/* Info Items */}
                <div className="space-y-4">
                  {[1, 2, 3].map((item) => (
                    <div key={item} className="flex justify-between">
                      <Skeleton className="h-4 w-24" />
                      <Skeleton className="h-4 w-48" />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </CardContent>
        </Card>

        {/* Documents Card */}
        <Card>
          <CardContent className="pb-4 pt-6 px-0 gap-4 flex flex-col">
            {/* Header */}
            <div className="flex items-center gap-2 text-secondary overflow-hidden pl-6">
              <div className="flex items-center gap-2 shrink-0">
                <Skeleton className="size-5" />
                <Skeleton className="h-4 w-24" />
              </div>
              <Separator className="bg-secondary/50" />
            </div>

            {/* Action Bar */}
            <div className="flex items-center justify-between px-6">
              <Skeleton className="h-8 w-48" />
              <Skeleton className="h-8 w-32" />
            </div>

            {/* Document List */}
            <div className="flex flex-col gap-4 px-6">
              {[1, 2, 3].map((item) => (
                <div
                  key={item}
                  className="flex items-center justify-between gap-4 p-3 rounded-lg border"
                >
                  <div className="flex items-center gap-3">
                    <Skeleton className="size-5" />
                    <div className="space-y-2">
                      <Skeleton className="h-4 w-48" />
                      <div className="flex items-center gap-2">
                        <Skeleton className="h-5 w-24 rounded-full" />
                        <Skeleton className="h-4 w-32" />
                      </div>
                    </div>
                  </div>

                  <div className="flex items-center gap-2">
                    <Skeleton className="size-8 rounded-md" />
                    <Skeleton className="size-8 rounded-md" />
                  </div>
                </div>
              ))}
            </div>
          </CardContent>
        </Card>
      </div>
    </ScrollArea>
  );
}
