import { ApiClient } from "../../infrastructure/apiClient";
import {
  GetOrdersParams,
  GetOrderParams,
  OrderDetail,
  SendPriceOfferParams,
  GetPriceOfferParams,
  OrdersResponse,
  PriceOffersResponse,
  PriceOfferResponse,
  GetPriceOffersParams,
  OrderStatusesResponse,
  RejectOrderDemandParams,
} from "./types";

export class OrderService {
  constructor(private readonly apiClient: ApiClient) {}

  /**
   * Get the freight demands
   * @param params - The parameters for getting freight demands
   * @returns Promise<OrdersResponse>
   */
  async getOrders(
    params: GetOrdersParams & { language: string },
  ): Promise<OrdersResponse> {
    const { page, limit = 15, filters, language } = params;
    const offset = (page - 1) * limit;

    // Remove undefined values from filters
    const cleanParams = {
      offset,
      limit,
      search: filters?.search,
      freight_demand_status: filters?.freight_demand_status,
      ordering: filters?.ordering,
    };

    // Remove undefined values
    const queryParams = Object.fromEntries(
      Object.entries(cleanParams).filter(
        ([_key, value]) => value !== undefined,
      ),
    );

    return this.apiClient.get<OrdersResponse>(
      `${language}/orders/freight-demands`,
      {
        params: queryParams,
      },
    );
  }

  /**
   * Get the order detail
   * @param params - The parameters for getting the order detail
   * @returns Promise<OrderDetail>
   */
  async getOrder(
    params: GetOrderParams & { language: string },
  ): Promise<OrderDetail> {
    const { orderNo, language } = params;
    return this.apiClient.get<OrderDetail>(
      `${language}/orders/freight-demands/${orderNo}`,
    );
  }

  /**
   * Get order statuses
   */
  async getOrderStatuses(params: {
    language: string;
  }): Promise<OrderStatusesResponse> {
    const { language } = params;
    return this.apiClient.get<OrderStatusesResponse>(
      `${language}/orders/freight-demand-statuses`,
    );
  }

  /**
   * Send the price offer
   * @param params - The parameters for sending the price offer
   * @returns Promise<void>
   */
  async sendPriceOffer(
    params: SendPriceOfferParams & { language: string },
  ): Promise<void> {
    const { language } = params;
    return this.apiClient.post<void>(
      `${language}/orders/freight-demands/${params.order_no}/offers`,
      params,
    );
  }

  /**
   * Get the price offer
   * @param params - The parameters for getting the price offer
   * @returns Promise<PriceOffer>
   */
  async getPriceOffers(
    params: GetPriceOffersParams & { language: string },
  ): Promise<PriceOffersResponse> {
    const { language } = params;
    return this.apiClient.get<PriceOffersResponse>(
      `${language}/orders/freight-demands/${params.order_no}/offers`,
    );
  }

  /**
   * Get the price offer
   * @param params - The parameters for getting the price offer
   * @returns Promise<PriceOffer>
   */
  async getPriceOffer(
    params: GetPriceOfferParams & { language: string },
  ): Promise<PriceOfferResponse> {
    const { language } = params;
    return this.apiClient.get<PriceOfferResponse>(
      `${language}/orders/freight-demands/${params.order_no}/offers/${params.offer_no}`,
    );
  }

  /**
   * Reject the order demand
   * @param params - The parameters for rejecting the order demand
   * @returns Promise<void>
   */
  async rejectOrderDemand(
    params: RejectOrderDemandParams & { language: string },
  ): Promise<void> {
    const { language } = params;
    return this.apiClient.post<void>(
      `${language}/orders/freight-demands/${params.order_no}/reject`,
      params,
    );
  }
}

export const createOrderService = (apiClient: ApiClient) =>
  new OrderService(apiClient);
