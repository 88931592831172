import OrdersTableSkeleton from "@/components/skeletons/OrdersTableSkeleton";
import { Suspense, useEffect } from "react";
import { withPermission, useFirebaseQuery } from "@repo/provider";
import { AppName } from "@repo/config";
import { OrderDetailSheet } from "@/features/orders/components/OrderDetailSheet";
import OrdersTable from "@/features/orders/components/OrdersTable";

export default function Orders() {
  const { logEventMutation } = useFirebaseQuery();

  useEffect(() => {
    logEventMutation.mutate({
      eventName: "page_view",
      eventParams: {
        page_title: document.title,
        page_location: window.location.href,
        page_path: window.location.pathname,
      },
    });
  }, []); // Empty dependency array means this runs once on mount

  const ProtectedOrdersTable = withPermission(OrdersTable, {
    requiredPermissions: ["view_freightdemand", "view_freightoffer"],
    targetApp: AppName.EMPLOYEE,
  });

  const ProtectedOrderDetailSheet = withPermission(OrderDetailSheet, {
    requiredPermissions: ["view_freightdemand", "view_freightoffer"],
    targetApp: AppName.EMPLOYEE,
  });

  return (
    <Suspense fallback={<OrdersTableSkeleton />}>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 pb-4">
        <ProtectedOrdersTable />
        <ProtectedOrderDetailSheet />
      </div>
    </Suspense>
  );
}
