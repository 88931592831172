import { DataTablePagination } from "./DataTablePagination";
import { LoadingProgress } from "../ui/LoadingProgress";
import { useState } from "react";
import { DataTableProps } from "./types";
import { Button } from "@repo/ui/components/ui/Button";
import { RefreshCcw } from "lucide-react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@repo/ui/components/ui/Table";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
  SortingState,
  getSortedRowModel,
} from "@tanstack/react-table";
import { ScrollArea, ScrollBar } from "../ui/ScrollArea";
import { cn } from "@repo/ui/lib/utils";

export function DataTable<TData>({
  columns,
  data,
  pageCount,
  pageIndex,
  pageSize,
  onPageChange,
  onPageSizeChange,
  onRefetch,
  isPending,
  totalRecords,
  filterComponent,
  translations,
  TABLE_PAGE_SIZE_OPTIONS,
}: Readonly<DataTableProps<TData>>) {
  const [sorting, setSorting] = useState<SortingState>([]);

  const table = useReactTable({
    data,
    columns,
    pageCount,
    state: {
      sorting,
      pagination: {
        pageIndex,
        pageSize,
      },
    },
    manualPagination: true,
    manualSorting: true,
    onSortingChange: setSorting,
    onPaginationChange: (updater) => {
      if (typeof updater === "function") {
        const newState = updater({
          pageIndex,
          pageSize,
        });
        if (newState.pageIndex !== pageIndex) {
          onPageChange(newState.pageIndex);
        }
        if (newState.pageSize !== pageSize) {
          onPageSizeChange(newState.pageSize);
        }
      }
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  return (
    <div className="flex flex-col">
      {/* Filter Component */}
      <div className="flex w-full items-center gap-1">
        {filterComponent}
        <Button
          title={translations.table.refresh}
          className="ml-auto"
          variant="outline"
          disabled={isPending}
          onClick={onRefetch}
        >
          <RefreshCcw className={cn("size-4", isPending && "animate-spin")} />
        </Button>
      </div>
      {/* Invisible Div for Filter Component */}
      <div className="rounded-lg relative">
        <ScrollArea className="h-[calc(100dvh-23rem)] sm:h-[calc(100dvh-20rem)]">
          <Table>
            <TableHeader className="sticky -top-1 bg-card z-20">
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <TableHead
                      key={header.id}
                      className={header.column.columnDef.meta?.className}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                    </TableHead>
                  ))}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                    className="hover:bg-muted group"
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell
                        key={cell.id}
                        className={cell.column.columnDef.meta?.className}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext(),
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={columns.length}
                    className="h-24 text-center"
                  >
                    {translations.table.no_records}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <ScrollBar orientation="horizontal" />
        </ScrollArea>
        {isPending && (
          <LoadingProgress
            className="rounded-lg overflow-hidden"
            title={translations.loading.data}
            showSpinner={false}
          />
        )}
      </div>
      <DataTablePagination
        translations={translations}
        TABLE_PAGE_SIZE_OPTIONS={TABLE_PAGE_SIZE_OPTIONS}
        table={table}
        pageCount={pageCount}
        totalRecords={totalRecords}
      />
    </div>
  );
}
