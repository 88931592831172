import { useMutation } from "@tanstack/react-query";
import { useFirebaseContext } from "../providers/firebase";

/**
 * Type for analytics event parameters
 */
interface AnalyticsEventParams {
  eventName: string;
  eventParams?: Record<string, any>;
}

/**
 * Type for starting performance trace parameters
 */
interface StartTraceParams {
  traceName: string;
}

/**
 * Type for stopping performance trace parameters
 */
interface StopTraceParams {
  traceName: string;
}

interface AddTraceAttributesParams {
  traceName: string;
  attribute: string;
  value: string;
}

/**
 * Query keys for Firebase-related queries
 */
export const FIREBASE_KEYS = {
  analytics: ["firebase", "analytics"] as const,
  performance: ["firebase", "performance"] as const,
} as const;

/**
 * Custom hook to manage Firebase operations with React Query
 * Analytics and Performance monitoring are disabled in development mode
 * @returns Firebase operations with React Query integration
 */
export function useFirebaseQuery() {
  const { analyticsService, performanceService, nodeEnv } =
    useFirebaseContext();
  const isProduction = nodeEnv === "production";

  /**
   * Mutation for logging analytics events
   */
  const logEventMutation = useMutation({
    mutationFn: async ({ eventName, eventParams }: AnalyticsEventParams) => {
      if (!isProduction) {
        console.log("[DEV] Analytics event:", { eventName, eventParams });
        return;
      }
      analyticsService.logEvent(eventName, eventParams);
    },
  });

  /**
   * Mutation for starting performance trace
   */
  const startTraceMutation = useMutation({
    mutationFn: async ({ traceName }: StartTraceParams) => {
      if (!isProduction) {
        console.log("[DEV] Start trace:", { traceName });
        return;
      }
      performanceService.startTrace(traceName);
    },
  });

  /**
   * Add trace attributes
   */
  const addTraceAttributesMutation = useMutation({
    mutationFn: async ({
      traceName,
      attribute,
      value,
    }: AddTraceAttributesParams) => {
      if (!isProduction) {
        console.log("[DEV] Add trace attributes:", {
          traceName,
          attribute,
          value,
        });
        return;
      }
      performanceService.putTraceAttribute(traceName, attribute, value);
    },
  });

  /**
   * Mutation for stopping performance trace
   */
  const stopTraceMutation = useMutation({
    mutationFn: async ({ traceName }: StopTraceParams) => {
      if (!isProduction) {
        console.log("[DEV] Stop trace:", { traceName });
        return;
      }
      performanceService.stopTrace(traceName);
    },
  });

  return {
    logEventMutation,
    startTraceMutation,
    addTraceAttributesMutation,
    stopTraceMutation,
    isProduction,
  };
}
