import { Input } from "@repo/ui/components/ui/Input";
import { useTranslation, useDebounce } from "@repo/config";
import { Search, ArrowDownIcon, ArrowUpIcon } from "lucide-react";
import { useState, useEffect } from "react";
import { useOrderQuery } from "../hooks/useOrder";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@repo/ui/components/ui/Select";
import { getBadgeColor } from "../utils/getBadgeVariant";
import { cn } from "@repo/ui/lib/utils";
import { Button } from "@repo/ui/components/ui/Button";

interface OrdersTableFiltersProps {
  searchValue: string;
  onSearchChange: (value: string) => void;
  statusValue?: string;
  onStatusChange?: (value: string) => void;
  orderingValue?: string;
  onOrderingChange?: (value: string) => void;
}

export const OrdersTableFilters = ({
  searchValue,
  onSearchChange,
  statusValue,
  onStatusChange,
  orderingValue,
  onOrderingChange,
}: OrdersTableFiltersProps) => {
  const { t } = useTranslation("orders");
  const [localSearch, setLocalSearch] = useState(searchValue);
  const debouncedSearch = useDebounce(localSearch, 500);
  const { useOrderStatuses } = useOrderQuery();
  const { data: statuses } = useOrderStatuses();

  useEffect(() => {
    if (debouncedSearch !== searchValue) {
      onSearchChange(debouncedSearch);
    }
  }, [debouncedSearch, onSearchChange, searchValue]);

  useEffect(() => {
    setLocalSearch(searchValue);
  }, [searchValue]);

  const handleOrderingClick = (field: string) => {
    if (orderingValue?.includes(field)) {
      // For the same field, change ASC/DESC
      onOrderingChange?.(orderingValue.startsWith("-") ? field : `-${field}`);
    } else {
      // Different field selected, default to DESC
      onOrderingChange?.(`-${field}`);
    }
  };

  return (
    <div className="flex overflow-x-auto items-center gap-4 w-full p-1">
      <div className="flex flex-wrap items-center gap-4 w-full">
        {/* Search input */}
        <div className="relative">
          <Input
            placeholder={t("orders.search.placeholder")}
            value={localSearch}
            onChange={(event) => setLocalSearch(event.target.value)}
            className="max-w-sm pl-9"
          />
          <Search className="absolute left-3 top-1/2 -translate-y-1/2 size-4 text-muted-foreground" />
        </div>

        {/* Ordering filter */}
        <div className="w-[200px]">
          <Select
            value={orderingValue ?? "-created_at"}
            onValueChange={(value) =>
              handleOrderingClick(value.replace(/^-/, ""))
            }
          >
            <SelectTrigger>
              <SelectValue>
                <div className="flex items-center gap-2">
                  {orderingValue?.includes("loading_date") ? (
                    <>
                      <span>{t("orders.filters.ordering.loadingDate")}</span>
                      {orderingValue?.startsWith("-") ? (
                        <ArrowDownIcon className="size-3" />
                      ) : (
                        <ArrowUpIcon className="size-3" />
                      )}
                    </>
                  ) : (
                    <>
                      <span>{t("orders.filters.ordering.createdAt")}</span>
                      {!orderingValue || orderingValue?.startsWith("-") ? (
                        <ArrowDownIcon className="size-3" />
                      ) : (
                        <ArrowUpIcon className="size-3" />
                      )}
                    </>
                  )}
                </div>
              </SelectValue>
            </SelectTrigger>
            <SelectContent>
              {/* Created At */}
              <Button
                variant="ghost"
                className={cn(
                  "w-full justify-start gap-2",
                  (!orderingValue || orderingValue?.includes("created_at")) &&
                    "bg-accent/50 dark:bg-accent/20",
                )}
                onClick={() => handleOrderingClick("created_at")}
              >
                <span>{t("orders.filters.ordering.createdAt")}</span>
                {!orderingValue || orderingValue === "-created_at" ? (
                  <ArrowDownIcon className="size-3 ml-auto" />
                ) : orderingValue === "created_at" ? (
                  <ArrowUpIcon className="size-3 ml-auto" />
                ) : null}
              </Button>

              {/* Loading Date */}
              <Button
                variant="ghost"
                className={cn(
                  "w-full justify-start gap-2",
                  orderingValue?.includes("loading_date") &&
                    "bg-accent/50 dark:bg-accent/20",
                )}
                onClick={() => handleOrderingClick("loading_date")}
              >
                <span>{t("orders.filters.ordering.loadingDate")}</span>
                {orderingValue === "-loading_date" ? (
                  <ArrowDownIcon className="size-3 ml-auto" />
                ) : orderingValue === "loading_date" ? (
                  <ArrowUpIcon className="size-3 ml-auto" />
                ) : null}
              </Button>
            </SelectContent>
          </Select>
        </div>

        {/* Status filter */}
        <div className="w-[200px]">
          <Select
            value={statusValue ?? "all"}
            onValueChange={(value) =>
              onStatusChange?.(value === "all" ? "" : value)
            }
          >
            <SelectTrigger>
              <SelectValue placeholder={t("orders.filters.status.placeholder")}>
                {statusValue ? (
                  <div className="flex items-center gap-2">
                    <span className="relative flex size-2">
                      <span
                        className={cn(
                          "relative inline-flex rounded-full size-2",
                          getBadgeColor(statusValue),
                        )}
                      />
                    </span>
                    {
                      statuses?.results.find(
                        (s) => s.status_slug === statusValue,
                      )?.status_name
                    }
                  </div>
                ) : null}
              </SelectValue>
            </SelectTrigger>
            <SelectContent>
              <SelectItem
                value="all"
                className="flex items-center gap-2 py-2 cursor-pointer"
              >
                <div className="flex items-center gap-2 flex-1">
                  <span className="relative flex size-2">
                    <span className="relative inline-flex rounded-full size-2 bg-muted" />
                  </span>
                  <span className="flex-1 line-clamp-1">
                    {t("orders.filters.status.all")}
                  </span>
                </div>
              </SelectItem>
              {statuses?.results.map((status) => (
                <SelectItem
                  key={status.status_slug}
                  value={status.status_slug}
                  className="flex items-center gap-2 py-2 cursor-pointer"
                >
                  <div className="flex items-center gap-2 flex-1">
                    <span className="relative flex size-2">
                      <span
                        className={cn(
                          "relative inline-flex rounded-full size-2",
                          getBadgeColor(status.status_slug),
                        )}
                      />
                    </span>
                    <span className="flex-1 line-clamp-1">
                      {status.status_name}
                    </span>
                    <span className="ml-auto text-xs text-muted-foreground bg-muted px-2 py-0.5 rounded-md">
                      {status.item_count}
                    </span>
                  </div>
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>
    </div>
  );
};
