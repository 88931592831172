import { type ToasterToast, type ToastType } from "../hooks/useToast";
import { generateUUID } from "./generateUUID";

const SEVERITY_MAP = {
  error: "error",
  warning: "warning",
  info: "info",
  success: "success",
} as const;

export type ErrorSeverity = keyof typeof SEVERITY_MAP;

type ToastOptions = {
  duration?: number;
  action?: ToasterToast["action"];
  className?: string;
};

type ToastMessage = {
  title?: ToasterToast["title"];
  description?: ToasterToast["description"];
} & ToastOptions;

export class ToastManager {
  private toastFn: ((props: ToasterToast) => void) | null = null;
  private readonly defaultDuration = 5000;

  setToastFunction(fn: (props: ToasterToast) => void): void {
    this.toastFn = fn;
  }

  private showToast(
    type: ToastType,
    { title, description, ...options }: ToastMessage,
  ): void {
    if (!this.toastFn) {
      console.warn("Toast function is not set yet");
      return;
    }

    const toast: ToasterToast = {
      id: generateUUID(),
      type,
      variant: type === "error" ? "destructive" : "default",
      title,
      description,
      duration: options.duration ?? this.defaultDuration,
      ...options,
    };

    this.toastFn(toast);
  }

  success(
    title: ToasterToast["title"],
    description?: ToasterToast["description"],
    options?: ToastOptions,
  ): void {
    this.showToast("success", { title, description, ...options });
  }

  error(
    title: ToasterToast["title"],
    description?: ToasterToast["description"],
    options?: ToastOptions,
  ): void {
    this.showToast("error", { title, description, ...options });
  }

  warning(
    title: ToasterToast["title"],
    description?: ToasterToast["description"],
    options?: ToastOptions,
  ): void {
    this.showToast("warning", { title, description, ...options });
  }

  info(
    title: ToasterToast["title"],
    description?: ToasterToast["description"],
    options?: ToastOptions,
  ): void {
    this.showToast("info", { title, description, ...options });
  }

  showWithSeverity(
    severity: ErrorSeverity,
    title: ToasterToast["title"],
    description?: ToasterToast["description"],
    options?: ToastOptions,
  ): void {
    const toastType = SEVERITY_MAP[severity] as ToastType;
    this.showToast(toastType, { title, description, ...options });
  }
}

export const toastManager = new ToastManager();
