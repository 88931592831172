import { ColumnDef } from "@tanstack/react-table";
import { ExpeditionTime } from "@repo/service";
import { DataTableColumnHeader } from "@repo/ui/components/table/DataTableColumnHeader";
import { Badge } from "@repo/ui/components/ui/Badge";
import { format, enUS, tr, useTranslation } from "@repo/config";
import { TableHeaderWithTooltip } from "@repo/ui/components/table/TableHeaderWithTooltip";
import { TableCellContent } from "@repo/ui/components/table/TableCellContent";

export const useExpeditionColumns = () => {
  const { t, i18n } = useTranslation("reports");
  const locale = i18n.language === "tr" ? tr : enUS;

  const columns: ColumnDef<ExpeditionTime>[] = [
    {
      accessorKey: "expedition_no",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("expedition.columns.expedition_no")}
        />
      ),
      enableSorting: true,
    },
    {
      accessorKey: "customer",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("expedition.columns.customer")}
        />
      ),
      cell: ({ row }) => (
        <TableCellContent content={row.getValue("customer")} />
      ),
    },
    {
      accessorKey: "load_country",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("expedition.columns.load_country")}
        />
      ),
      enableSorting: true,
      cell: ({ row }) => (
        <TableCellContent content={row.getValue("load_country")} />
      ),
    },
    {
      accessorKey: "load_city",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("expedition.columns.load_city")}
        />
      ),
      cell: ({ row }) => (
        <TableCellContent content={row.getValue("load_city")} />
      ),
    },
    {
      accessorKey: "target_country",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("expedition.columns.target_country")}
        />
      ),
      cell: ({ row }) => (
        <TableCellContent content={row.getValue("target_country")} />
      ),
    },
    {
      accessorKey: "target_city",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("expedition.columns.target_city")}
        />
      ),
      cell: ({ row }) => (
        <TableCellContent content={row.getValue("target_city")} />
      ),
    },
    {
      accessorKey: "start_date",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("expedition.columns.start_date")}
        />
      ),
      cell: ({ row }) => {
        const date = row.getValue<string>("start_date");
        return (
          <div className="max-w-[150px] truncate">
            {date
              ? format(new Date(date), "dd MMM yyyy HH:mm", { locale })
              : "-"}
          </div>
        );
      },
      enableSorting: true,
    },
    {
      accessorKey: "load_date",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("expedition.columns.load_date")}
        />
      ),
      cell: ({ row }) => {
        const date = row.getValue<string>("load_date");
        return (
          <div className="max-w-[150px] truncate">
            {date
              ? format(new Date(date), "dd MMM yyyy HH:mm", { locale })
              : "-"}
          </div>
        );
      },
    },
    {
      accessorKey: "end_date",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("expedition.columns.end_date")}
        />
      ),
      cell: ({ row }) => {
        const date = row.getValue<string>("end_date");
        return (
          <div className="max-w-[150px] truncate">
            {date
              ? format(new Date(date), "dd MMM yyyy HH:mm", { locale })
              : "-"}
          </div>
        );
      },
      enableSorting: true,
    },
    {
      accessorKey: "expedition_type",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("expedition.columns.expedition_type")}
        />
      ),
      cell: ({ row }) => (
        <Badge variant="outline">{row.getValue("expedition_type")}</Badge>
      ),
    },
    {
      accessorKey: "driver",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("expedition.columns.driver")}
        />
      ),
      enableSorting: true,
      cell: ({ row }) => <TableCellContent content={row.getValue("driver")} />,
    },
    {
      accessorKey: "truck",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("expedition.columns.truck")}
        />
      ),
      cell: ({ row }) => (
        <div className="max-w-[150px] truncate">{row.getValue("truck")}</div>
      ),
    },

    {
      accessorKey: "duration_by_load_start_time",
      header: ({ column }) => (
        <TableHeaderWithTooltip
          column={column}
          title={t("expedition.columns.duration_by_load_start_time")}
          tooltipContent={t(
            "expedition.columns.tooltip.duration_by_load_start_time",
          )}
        />
      ),
      cell: ({ row }) => {
        const duration = row.getValue<number>("duration_by_load_start_time");
        return duration ? `${duration} ${t("expedition.duration_unit")}` : "-";
      },
    },
    {
      accessorKey: "duration_by_entry_exit_load_time",
      header: ({ column }) => (
        <TableHeaderWithTooltip
          column={column}
          title={t("expedition.columns.duration_by_entry_exit_load_time")}
          tooltipContent={t(
            "expedition.columns.tooltip.duration_by_entry_exit_load_time",
          )}
        />
      ),
      cell: ({ row }) => {
        const duration = row.getValue<number>(
          "duration_by_entry_exit_load_time",
        );
        return duration ? `${duration} ${t("expedition.duration_unit")}` : "-";
      },
    },
    {
      accessorKey: "duration_by_entry_exit_time",
      header: ({ column }) => (
        <TableHeaderWithTooltip
          column={column}
          title={t("expedition.columns.duration_by_entry_exit_time")}
          tooltipContent={t(
            "expedition.columns.tooltip.duration_by_entry_exit_time",
          )}
        />
      ),
      cell: ({ row }) => {
        const duration = row.getValue<number>("duration_by_entry_exit_time");
        return duration ? `${duration} ${t("expedition.duration_unit")}` : "-";
      },
    },
    {
      accessorKey: "duration_by_vehicle_entry_exit_time",
      header: ({ column }) => (
        <TableHeaderWithTooltip
          column={column}
          title={t("expedition.columns.duration_by_vehicle_entry_exit_time")}
          tooltipContent={t(
            "expedition.columns.tooltip.duration_by_vehicle_entry_exit_time",
          )}
        />
      ),
      cell: ({ row }) => {
        const duration = row.getValue<number>(
          "duration_by_vehicle_entry_exit_time",
        );
        return duration ? `${duration} ${t("expedition.duration_unit")}` : "-";
      },
    },
    {
      accessorKey: "duration_by_end_entry_exit_time",
      header: ({ column }) => (
        <TableHeaderWithTooltip
          column={column}
          title={t("expedition.columns.duration_by_end_entry_exit_time")}
          tooltipContent={t(
            "expedition.columns.tooltip.duration_by_end_entry_exit_time",
          )}
        />
      ),
      cell: ({ row }) => {
        const duration = row.getValue<number>(
          "duration_by_end_entry_exit_time",
        );
        return duration ? `${duration} ${t("expedition.duration_unit")}` : "-";
      },
    },
    {
      accessorKey: "duration_by_end_vehicle_time",
      header: ({ column }) => (
        <TableHeaderWithTooltip
          column={column}
          title={t("expedition.columns.duration_by_end_vehicle_time")}
          tooltipContent={t(
            "expedition.columns.tooltip.duration_by_end_vehicle_time",
          )}
        />
      ),
      cell: ({ row }) => {
        const duration = row.getValue<number>("duration_by_end_vehicle_time");
        return duration ? `${duration} ${t("expedition.duration_unit")}` : "-";
      },
    },
    {
      accessorKey: "duration_by_load_end_time",
      header: ({ column }) => (
        <TableHeaderWithTooltip
          column={column}
          title={t("expedition.columns.duration_by_load_end_time")}
          tooltipContent={t(
            "expedition.columns.tooltip.duration_by_load_end_time",
          )}
        />
      ),
      cell: ({ row }) => {
        const duration = row.getValue<number>("duration_by_load_end_time");
        return duration ? `${duration} ${t("expedition.duration_unit")}` : "-";
      },
    },
    {
      accessorKey: "customer_representative",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("expedition.columns.customer_representative")}
        />
      ),
      cell: ({ row }) => {
        return (
          <div className="max-w-[150px] truncate">
            {row.getValue("customer_representative")}
          </div>
        );
      },
    },

    {
      accessorKey: "is_completed",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("expedition.columns.status")}
        />
      ),
      cell: ({ row }) => (
        <Badge variant={row.getValue("is_completed") ? "success" : "secondary"}>
          {row.getValue("is_completed")
            ? t("expedition.status.completed")
            : t("expedition.status.in_progress")}
        </Badge>
      ),
    },
  ];

  return columns;
};
