import { AxiosRequestConfig } from "axios";

export type ApiErrorCode =
  | "validation"
  | "auth"
  | "network"
  | "server"
  | "not_found"
  | "forbidden"
  | "timeout"
  | "unknown"
  | "client_error";

export type ErrorSeverity = "error" | "warning" | "info";

export interface ApiErrorDetail {
  code: string;
  detail: string;
  attr: string | null;
}

export interface ApiErrorResponse {
  type: string;
  errors: ApiErrorDetail[];
}

export interface OAuthErrorResponse {
  error: string;
  error_description: string;
}

export type ApiRequestConfig = AxiosRequestConfig & {
  retry?: number;
  retryDelay?: number;
  skipAuthRefresh?: boolean;
  _retryCount?: number;
};

export type ApiResponse<T = unknown> = {
  data: T;
  status: number;
  headers: Record<string, string>;
};

/**
 * Type guard to check if the error is an API error returned from the API server
 */
export const isApiErrorResponse = (data: unknown): data is ApiErrorResponse => {
  return (
    typeof data === "object" &&
    data !== null &&
    "type" in data &&
    "errors" in data &&
    Array.isArray((data as ApiErrorResponse).errors) &&
    (data as ApiErrorResponse).errors.every(isApiErrorDetail)
  );
};

export const isApiErrorDetail = (data: unknown): data is ApiErrorDetail => {
  return (
    typeof data === "object" &&
    data !== null &&
    "code" in data &&
    typeof (data as any).code === "string" &&
    "detail" in data &&
    typeof (data as any).detail === "string" &&
    "attr" in data &&
    ((data as any).attr === null || typeof (data as any).attr === "string")
  );
};

/**
 * Type guard to check if the error is an OAuth error
 */
export const isOAuthError = (data: unknown): data is OAuthErrorResponse => {
  return (
    typeof data === "object" &&
    data !== null &&
    "error" in data &&
    typeof (data as any).error === "string" &&
    "error_description" in data &&
    typeof (data as any).error_description === "string"
  );
};
