import { Button } from "@repo/ui/components/ui/Button";
// import { useTranslation } from "react-i18next"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuCheckboxItem,
} from "@repo/ui/components/ui/DropDownMenu";
import { Languages } from "lucide-react";
import { TR, GB } from "country-flag-icons/react/3x2";

interface LanguageSwitcherProps {
  readonly currentLanguage: string;
  readonly onLanguageChange: (lang: string) => void;
  readonly t: (key: string) => string;
}

/**
 * Language switcher component.
 */
export default function LanguageSwitcher({
  currentLanguage,
  onLanguageChange,
  t,
}: LanguageSwitcherProps) {
  const languages = [
    {
      code: "tr",
      name: t("language.tr"),
      flag: TR,
      countryCode: "TR",
    },
    {
      code: "en",
      name: t("language.en"),
      flag: GB,
      countryCode: "GB",
    },
  ];

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button className="size-7 md:size-8" variant="outline" size="icon">
          <Languages className="h-5 w-5" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        {languages.map((lang) => {
          const Flag = lang.flag;
          return (
            <DropdownMenuCheckboxItem
              key={lang.code}
              checked={currentLanguage === lang.code}
              onCheckedChange={() => onLanguageChange(lang.code)}
              className="flex items-center gap-2 cursor-pointer"
            >
              <span>{lang.name}</span>
              <Flag className="h-4 w-4 ml-auto" title={lang.name} />
            </DropdownMenuCheckboxItem>
          );
        })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
