import { useCurrentLanguage } from "@repo/config";
import { customerService } from "@/main";
import { GetCustomersParams } from "@repo/service";
import { useQuery } from "@tanstack/react-query";

export const CUSTOMERS_QUERY_KEY = "customers";

export const useCustomers = (params: GetCustomersParams) => {
  const currentLanguage = useCurrentLanguage();

  const queryKey = [
    CUSTOMERS_QUERY_KEY,
    currentLanguage,
    params.page,
    params.limit,
    params.filters?.search,
    params.filters?.document_status,
  ];

  const query = useQuery({
    queryKey,
    queryFn: () =>
      customerService.getCustomers({
        ...params,
        language: currentLanguage,
      }),
  });

  const isPending = query.isLoading || query.isFetching;

  return {
    ...query,
    isPending,
  };
};
