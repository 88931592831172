export interface Book {
  id: string;
  title: string;
  path: string;
  description?: string;
  coverImage?: string;
  author?: string;
}

const BASE_URL = "https://cdn.abclojistik.com/bookshelf/books";

export const BOOKS: Book[] = [
  {
    id: "gumrukkanunu",
    title: "Gümrük Kanunu",
    path: `${BASE_URL}/gumrukkanunu/mobile/index.html`,
    coverImage: `${BASE_URL}/gumrukkanunu/cover.jpg`,
    author: "Remzi ÖZMEN",
  },
  {
    id: "gumrukhukukunailiskin",
    title: "Gümrük Hukukuna İlişkin Uyuşmazlıklar ve Hukuki Çözüm Yolları",
    path: `${BASE_URL}/gumrukhukukunailiskin/mobile/index.html`,
    coverImage: `${BASE_URL}/gumrukhukukunailiskin/cover.jpg`,
    author: "Alper YEŞİLOVA",
  },
  {
    id: "gumrukkiymetvekiymettespiti",
    title: "Gümrük Kıymeti ve Kıymet Tespit Yöntemleri",
    path: `${BASE_URL}/gumrukkiymetvekiymettespiti/mobile/index.html`,
    coverImage: `${BASE_URL}/gumrukkiymetvekiymettespiti/cover.jpg`,
    author: "Ali DÖLEK",
  },
  {
    id: "karayoluileuluslararasi",
    title: "Karayolu ile Uluslararası Eşya Taşıma Hukuku ve CMR",
    path: `${BASE_URL}/karayoluileuluslararasi/mobile/index.html`,
    coverImage: `${BASE_URL}/karayoluileuluslararasi/cover.jpg`,
    author: "Muktedir LALE",
  },
  {
    id: "kavramlarlagumrukmevzuati",
    title: "Kavramlarla Gümrük Mevzuatı",
    path: `${BASE_URL}/kavramlarlagumrukmevzuati/mobile/index.html`,
    coverImage: `${BASE_URL}/kavramlarlagumrukmevzuati/cover.jpg`,
    author: "Ali DÖLEK",
  },
  {
    id: "lojistiknotlari",
    title: "Lojistik Notları - 1",
    path: `${BASE_URL}/lojistiknotlari/mobile/index.html`,
    coverImage: `${BASE_URL}/lojistiknotlari/cover.jpg`,
    author: "Ahmet CORA",
  },
  {
    id: "lojistiknotlari2",
    title: "Lojistik Notları - 2",
    path: `${BASE_URL}/lojistiknotlari2/mobile/index.html`,
    coverImage: `${BASE_URL}/lojistiknotlari2/cover.jpg`,
    author: "Ahmet CORA",
  },
  {
    id: "lojistikyonetimiveelojistik",
    title: "Lojistik Yönetimi ve E-Lojistik",
    path: `${BASE_URL}/lojistikyönetimivee-lojistik/mobile/index.html`,
    coverImage: `${BASE_URL}/lojistikyönetimivee-lojistik/cover.jpg`,
    author: "Yrd. Doç. Dr. Muharrem BAKKAL, Uğur DEMİR",
  },
  {
    id: "ornekyazismalar",
    title: "Örnek Yazışmalarla İhracat İthalat İngilizcesi",
    path: `${BASE_URL}/ornekyazismalar/mobile/index.html`,
    coverImage: `${BASE_URL}/ornekyazismalar/cover.jpg`,
    author: "Aslıhan KOCAEFE CEBECİ",
  },
  {
    id: "uluslararasilojistikhukuku",
    title: "Uluslararası Lojistik Hukuku",
    path: `${BASE_URL}/uluslararasilojistikhukuku/mobile/index.html`,
    coverImage: `${BASE_URL}/uluslararasilojistikhukuku/cover.jpg`,
    author: "Yrd. Doç. Dr. Tunay KÖKSAL, B. Yalın ÖZKARA",
  },
  {
    id: "uluslararasilojistik",
    title: "Uluslararası Lojistik",
    path: `${BASE_URL}/uluslararasilojistik/mobile/index.html`,
    coverImage: `${BASE_URL}/uluslararasilojistik/cover.jpeg`,
    author: "Dr. Öğretim Üyesi Mehmet Ragıp GÖRGÜN",
  },
  {
    id: "outli̇ers",
    title: "Outliers - Çizginin Dışındakiler",
    path: `${BASE_URL}/outli̇ers/mobile/index.html`,
    coverImage: `${BASE_URL}/outli̇ers/cover.jpg`,
    author: "Malcolm GLADWELL",
  },
  {
    id: "noromarketing",
    title: "Nöro Marketing",
    path: `${BASE_URL}/noromarketing/mobile/index.html`,
    coverImage: `${BASE_URL}/noromarketing/cover.jpg`,
    author: "Patrick Renvoise & Christophe Morin",
  },
  {
    id: "iyidenmukemmelsirkete",
    title: "İyiden Mükemmel Şirkete",
    path: `${BASE_URL}/iyidenmukemmelsirkete/mobile/index.html`,
    coverImage: `${BASE_URL}/iyidenmukemmelsirkete/cover.jpg`,
    author: "Jim Collins",
  },
  {
    id: "etkiliinsanların7aliskanligi",
    title: "Etkili İnsanların 7 Alışkanlığı",
    path: `${BASE_URL}/etkiliinsanların7aliskanligi/mobile/index.html`,
    coverImage: `${BASE_URL}/etkiliinsanların7aliskanligi/cover.jpg`,
    author: "Stephen R. Covey",
  },
];
