import axios, { AxiosInstance, AxiosRequestHeaders } from "axios";
import type { Env } from "@repo/config";
import type { ApiRequestConfig, ApiResponse } from "../types";
import { errorNormalizer } from "../utils/errorNormalizer";
import type { AuthService } from "../services";

/**
 * ApiClient class implementing the Singleton pattern for handling HTTP requests.
 * Provides centralized request handling with automatic token management,
 * request retry logic, and error normalization.
 *
 * @class ApiClient
 */
class ApiClient {
  /** Singleton instance of the ApiClient */
  private static instance: ApiClient;

  /** Axios instance for making HTTP requests */
  private readonly axiosInstance: AxiosInstance;

  /** Flag indicating whether a token refresh is in progress */
  private isRefreshing = false;

  /** Queue of callbacks to be executed after token refresh */
  private refreshSubscribers: ((token: string) => void)[] = [];

  /**
   * Private constructor to prevent direct instantiation.
   * Initializes the Axios instance with default configuration.
   */
  private constructor(
    private readonly env: Env,
    private readonly authService: AuthService,
  ) {
    this.axiosInstance = axios.create({
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      withCredentials: true,
      timeout: 30000, // 30 seconds
    });

    this.setupInterceptors();
  }

  /**
   * Sets up request and response interceptors for the Axios instance.
   * Handles authentication, token refresh, and error normalization.
   */
  private setupInterceptors(): void {
    this.axiosInstance.interceptors.request.use(
      async (config) => {
        const token = this.authService.getAccessToken();
        if (token && !this.authService.isTokenExpired()) {
          config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
      },
      (error) => this.handleError(error),
    );

    this.axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config as ApiRequestConfig;

        // Token refresh process
        if (
          error.response?.status === 401 &&
          !originalRequest.skipAuthRefresh
        ) {
          return this.refreshAccessToken(originalRequest);
        }

        throw this.handleError(error);
      },
    );
  }

  /**
   * Handles token refresh and retries the original request.
   *
   * @param originalRequest - The original request that failed
   * @returns Promise that resolves with the retried request
   * @private
   */
  private async refreshAccessToken(
    originalRequest: ApiRequestConfig,
  ): Promise<any> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      try {
        await this.authService.refreshToken(true);
        const newToken = this.authService.getAccessToken();
        if (newToken) {
          this.onTokenRefreshed(newToken);
          originalRequest.headers = {
            ...originalRequest.headers,
            Authorization: `Bearer ${newToken}`,
          } as AxiosRequestHeaders;

          // Do not retry the request after token refresh
          originalRequest.skipAuthRefresh = true;
          return this.axiosInstance.request(originalRequest);
        }
        this.onTokenRefreshFailed();
        return Promise.reject(new Error("Token refresh failed"));
      } catch (refreshError) {
        this.onTokenRefreshFailed();
        return Promise.reject(new Error("Token refresh failed"));
      } finally {
        this.isRefreshing = false;
      }
    }

    return new Promise((resolve, reject) => {
      this.refreshSubscribers.push((token: string) => {
        originalRequest.headers = {
          ...originalRequest.headers,
          Authorization: `Bearer ${token}`,
        } as AxiosRequestHeaders;
        resolve(this.axiosInstance.request(originalRequest));
      });
    });
  }

  /**
   * Executes callbacks after a successful token refresh.
   *
   * @param {string} token - The new access token
   * @private
   */
  private onTokenRefreshed(token: string): void {
    this.refreshSubscribers.forEach((callback) => callback(token));
    this.refreshSubscribers = [];
  }

  /**
   * Clears the queue of callbacks when token refresh fails.
   *
   * @private
   */
  private onTokenRefreshFailed(): void {
    this.refreshSubscribers = [];
  }

  /**
   * Gets the singleton instance of ApiClient.
   * Creates a new instance if one doesn't exist.
   *
   * @static
   * @returns {ApiClient} The ApiClient instance
   */
  public static init(env: Env, authService: AuthService): ApiClient {
    if (!ApiClient.instance) {
      ApiClient.instance = new ApiClient(env, authService);
    }
    return ApiClient.instance;
  }

  /**
   * Makes a generic HTTP request.
   *
   * @template T - The expected response data type
   * @param {string} method - HTTP method
   * @param {string} url - Request URL
   * @param {ApiRequestConfig} [config] - Request configuration
   * @returns {Promise<ApiResponse<T>>} The API response
   */
  public async request<T = unknown>(
    method: string,
    url: string,
    config: ApiRequestConfig = {},
  ): Promise<ApiResponse<T>> {
    try {
      const response = await this.axiosInstance.request({
        method,
        url,
        ...config,
        baseURL: config.baseURL ?? this.env.API.BASE_URL, // This is for services that uses different base url other than the main api
      });

      return {
        data: response.data,
        status: response.status,
        headers: response.headers as Record<string, string>,
      };
    } catch (error) {
      throw this.handleError(error);
    }
  }

  /**
   * Makes a GET request.
   *
   * @template T - The expected response data type
   * @param {string} url - Request URL
   * @param {ApiRequestConfig} [config] - Request configuration
   * @returns {Promise<T>} The response data
   */
  public async get<T = unknown>(
    url: string,
    config?: ApiRequestConfig,
  ): Promise<T> {
    const response = await this.request<T>("GET", url, config);
    return response.data;
  }

  /**
   * Makes a POST request.
   *
   * @template T - The expected response data type
   * @param {string} url - Request URL
   * @param {unknown} [data] - Request payload
   * @param {ApiRequestConfig} [config] - Request configuration
   * @returns {Promise<T>} The response data
   */
  public async post<T = unknown>(
    url: string,
    data?: unknown,
    config?: ApiRequestConfig,
  ): Promise<T> {
    const response = await this.request<T>("POST", url, { ...config, data });
    return response.data;
  }

  /**
   * Makes a PUT request.
   *
   * @template T - The expected response data type
   * @param {string} url - Request URL
   * @param {unknown} [data] - Request payload
   * @param {ApiRequestConfig} [config] - Request configuration
   * @returns {Promise<T>} The response data
   */
  public async put<T = unknown>(
    url: string,
    data?: unknown,
    config?: ApiRequestConfig,
  ): Promise<T> {
    const response = await this.request<T>("PUT", url, { ...config, data });
    return response.data;
  }

  /**
   * Makes a DELETE request.
   *
   * @template T - The expected response data type
   * @param {string} url - Request URL
   * @param {ApiRequestConfig} [config] - Request configuration
   * @returns {Promise<T>} The response data
   */
  public async delete<T = unknown>(
    url: string,
    config?: ApiRequestConfig,
  ): Promise<T> {
    const response = await this.request<T>("DELETE", url, config);
    return response.data;
  }

  /**
   * Makes a PATCH request.
   *
   * @template T - The expected response data type
   * @param {string} url - Request URL
   * @param {unknown} [data] - Request payload
   * @param {ApiRequestConfig} [config] - Request configuration
   * @returns {Promise<T>} The response data
   */
  public async patch<T = unknown>(
    url: string,
    data?: unknown,
    config?: ApiRequestConfig,
  ): Promise<T> {
    const response = await this.request<T>("PATCH", url, { ...config, data });
    return response.data;
  }

  /**
   * Normalizes and throws errors in a consistent format.
   *
   * @param error - The error to normalize
   * @throws The normalized error
   * @private
   */
  private handleError(error: unknown): never {
    throw errorNormalizer.normalize(error);
  }
}

export type { ApiClient };
// Export factory function instead of instance
export const createApiClient = (env: Env, authService: AuthService) =>
  ApiClient.init(env, authService);
