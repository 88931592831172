import { GetCustomerDetailResponse } from "@repo/service";
import { useTranslation } from "@repo/config";
import { Separator } from "@repo/ui/components/ui/Separator";
import { Building, FileText, Building2, Mail, Phone } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@repo/ui/components/ui/Tooltip";

interface CustomerDetailContentCompanyCardProps {
  customer: GetCustomerDetailResponse;
}

export function CustomerDetailContentCompanyCard({
  customer,
}: CustomerDetailContentCompanyCardProps) {
  const { t } = useTranslation("customers");

  if (!customer.user_company) return null;

  // Helper function to handle empty values
  const getDisplayValue = (value: string | undefined | null) => {
    if (!value || value.trim() === "") {
      return "-";
    }
    return value;
  };

  // Get contact icon based on type
  const getContactIcon = (type: string) => {
    switch (type.toLowerCase()) {
      case "email":
        return <Mail className="size-4 shrink-0" />;
      case "phone":
        return <Phone className="size-4 shrink-0" />;
      default:
        return null;
    }
  };

  // Get contact link based on type
  const getContactLink = (type: string, value: string) => {
    switch (type.toLowerCase()) {
      case "email":
        return `mailto:${value}`;
      case "phone":
        return `tel:${value}`;
      default:
        return null;
    }
  };

  return (
    <div className="flex flex-col gap-6">
      {/* Header */}
      <div className="flex items-center gap-4 text-secondary px-6">
        <div className="flex items-center gap-3 shrink-0">
          <div className="rounded-full bg-primary/10 size-12 flex items-center justify-center">
            <Building2 className="size-5 text-primary/80" />
          </div>
          <div className="flex flex-col">
            <p className="text-sm font-medium text-foreground">
              {getDisplayValue(customer.user_company.company_name)}
            </p>
            <p className="text-xs text-muted-foreground">
              {t("customers.detail.company_info")}
            </p>
          </div>
        </div>
      </div>

      {/* Company Information */}
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-2 text-secondary overflow-hidden pl-6">
          <div className="flex items-center gap-2 shrink-0 ">
            <Building className="size-5" />
            <p className="text-sm">{t("customers.detail.company_details")}</p>
          </div>
          <Separator className="bg-secondary/50" />
        </div>
        <div className="flex flex-col gap-2 px-6">
          {/* Company Name */}
          <div className="flex justify-between text-sm gap-6">
            <p className="shrink-0 text-muted-foreground">
              {t("customers.company_name")}
            </p>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <p className="truncate font-medium">
                    {getDisplayValue(customer.user_company.company_name)}
                  </p>
                </TooltipTrigger>
                <TooltipContent side="left">
                  <p className="font-medium">
                    {getDisplayValue(customer.user_company.company_name)}
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>

          {/* Tax Number */}
          <div className="flex justify-between text-sm gap-6">
            <p className="shrink-0 text-muted-foreground">
              {t("customers.tax_number")}
            </p>
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <p className="truncate font-medium">
                    {getDisplayValue(customer.user_company.tax_number)}
                  </p>
                </TooltipTrigger>
                <TooltipContent side="left">
                  <p className="font-medium">
                    {getDisplayValue(customer.user_company.tax_number)}
                  </p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </div>
      </div>

      {/* Company Contacts */}
      {customer.user_company.contacts.length > 0 && (
        <div className="flex flex-col gap-2">
          <div className="flex items-center gap-2 text-secondary overflow-hidden pl-6">
            <div className="flex items-center gap-2 shrink-0">
              <FileText className="size-5" />
              <p className="text-sm">
                {t("customers.detail.company_contacts")}
              </p>
            </div>
            <Separator className="bg-secondary/50" />
          </div>
          <div className="flex flex-col gap-2 px-6">
            {customer.user_company.contacts.map((contact, index) => (
              <TooltipProvider key={index}>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <div className="flex justify-between text-sm gap-6">
                      <p className="shrink-0 text-muted-foreground">
                        {contact.contact_label}
                      </p>
                      {getContactLink(
                        contact.contact_type,
                        contact.contact_value,
                      ) ? (
                        <a
                          href={
                            getContactLink(
                              contact.contact_type,
                              contact.contact_value,
                            )!
                          }
                          className="hover:underline flex items-center gap-2 truncate font-medium"
                        >
                          <span className="truncate">
                            {contact.contact_value}
                          </span>
                          {getContactIcon(contact.contact_type)}
                        </a>
                      ) : (
                        <p className="truncate font-medium">
                          {contact.contact_value}
                        </p>
                      )}
                    </div>
                  </TooltipTrigger>
                  <TooltipContent side="left">
                    <div className="flex flex-col gap-1">
                      <p className="text-xs text-muted-foreground">
                        {contact.contact_label}
                      </p>
                      <p className="font-medium">{contact.contact_value}</p>
                    </div>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
