import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@repo/ui/components/ui/Card";
import { useOrdersTableColumns } from "./OrdersTableColumns";
import { DataTable } from "@repo/ui/components/table/DataTable";
import { useCallback, useMemo, useTransition } from "react";
import { useQueryStates, parseAsInteger, parseAsString } from "nuqs";
import { useTranslation } from "@repo/config";
import { AlertCircle } from "lucide-react";
import { DEFAULT_PAGE_SIZE, TABLE_PAGE_SIZE_OPTIONS } from "../utils/constants";
import { useOrders } from "../hooks/useOrders";
import { Order } from "@repo/service";
import { OrdersTableFilters } from "./OrdersTableFilters";
import { TableTranslations } from "@repo/ui/components/table/types";

const ORDER_QUERY_PARAMS = {
  PAGE: "order_page",
  SIZE: "order_size",
  SEARCH: "order_search",
  STATUS: "order_status",
  ORDERING: "order_ordering",
} as const;

export default function OrdersTable() {
  const columns = useOrdersTableColumns();
  const { t } = useTranslation("orders");

  const translations: TableTranslations = {
    loading: {
      data: t("loading.data"),
    },
    table: {
      no_records: t("table.no_records"),
      page_size: t("table.page_size"),
      page: t("table.page"),
      first_page: t("table.first_page"),
      previous_page: t("table.previous_page"),
      next_page: t("table.next_page"),
      last_page: t("table.last_page"),
      refresh: t("table.refresh"),
    },
    pagination: {
      total_records: t("pagination.total_records"),
      of: t("pagination.of"),
    },
  };
  const [isTransitionPending, startTransition] = useTransition();

  const [queryParams, setQueryParams] = useQueryStates(
    {
      [ORDER_QUERY_PARAMS.PAGE]: parseAsInteger.withDefault(0),
      [ORDER_QUERY_PARAMS.SIZE]: parseAsInteger.withDefault(DEFAULT_PAGE_SIZE),
      [ORDER_QUERY_PARAMS.SEARCH]: parseAsString.withDefault(""),
      [ORDER_QUERY_PARAMS.STATUS]: parseAsString.withDefault(""),
      [ORDER_QUERY_PARAMS.ORDERING]: parseAsString.withDefault("-created_at"),
    },
    {
      history: "push",
      shallow: true,
      throttleMs: 500,
    },
  );

  const {
    [ORDER_QUERY_PARAMS.PAGE]: pageIndex,
    [ORDER_QUERY_PARAMS.SIZE]: pageSize,
    [ORDER_QUERY_PARAMS.SEARCH]: search,
    [ORDER_QUERY_PARAMS.STATUS]: status,
    [ORDER_QUERY_PARAMS.ORDERING]: ordering,
  } = queryParams;

  const handleSearchChange = useCallback(
    (value: string) => {
      startTransition(() => {
        setQueryParams(
          {
            [ORDER_QUERY_PARAMS.SEARCH]: value,
            [ORDER_QUERY_PARAMS.PAGE]: 0,
          },
          { history: "push", shallow: true },
        );
      });
    },
    [setQueryParams],
  );

  const handlePageChange = useCallback(
    (newPage: number) => {
      startTransition(() => {
        setQueryParams(
          { [ORDER_QUERY_PARAMS.PAGE]: newPage },
          { history: "push", shallow: true },
        );
      });
    },
    [setQueryParams],
  );

  const handlePageSizeChange = useCallback(
    (newPageSize: number) => {
      startTransition(() => {
        setQueryParams(
          {
            [ORDER_QUERY_PARAMS.SIZE]: newPageSize,
            [ORDER_QUERY_PARAMS.PAGE]: 0,
          },
          { history: "push", shallow: true },
        );
      });
    },
    [setQueryParams],
  );

  const handleStatusChange = useCallback(
    (value: string) => {
      startTransition(() => {
        setQueryParams(
          {
            [ORDER_QUERY_PARAMS.STATUS]: value,
            [ORDER_QUERY_PARAMS.PAGE]: 0,
          },
          { history: "push", shallow: true },
        );
      });
    },
    [setQueryParams],
  );

  const handleOrderingChange = useCallback(
    (value: string) => {
      startTransition(() => {
        setQueryParams(
          {
            [ORDER_QUERY_PARAMS.ORDERING]: value,
            [ORDER_QUERY_PARAMS.PAGE]: 0,
          },
          { history: "push", shallow: true },
        );
      });
    },
    [setQueryParams],
  );

  const apiParams = useMemo(
    () => ({
      page: pageIndex + 1,
      limit: pageSize,
      filters: {
        search: search || undefined,
        freight_demand_status: status || undefined,
        ordering: ordering || undefined,
      },
    }),
    [pageIndex, pageSize, search, status, ordering],
  );

  const {
    data: orders,
    error,
    isPending: isQueryPending,
    refetch,
  } = useOrders(apiParams);

  const isLoadingState = isQueryPending || isTransitionPending;

  const totalPageCount = useMemo(
    () => (orders ? Math.ceil(orders.count / pageSize) : 0),
    [orders?.count, pageSize],
  );

  if (error) {
    return (
      <Card className="space-y-4 col-span-2">
        <CardHeader>
          <CardTitle>{t("orders.title")}</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex flex-col items-center justify-center py-8">
            <AlertCircle className="h-12 w-12 text-destructive mb-3" />
            <p className="text-lg font-medium text-destructive">
              {t("error.order.detail.fetch_title")}
            </p>
          </div>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="space-y-2 col-span-2">
      <CardHeader>
        <CardTitle>{t("orders.title")}</CardTitle>
      </CardHeader>
      <CardContent>
        <DataTable<Order>
          columns={columns}
          data={orders?.results ?? []}
          pageCount={totalPageCount}
          pageIndex={pageIndex}
          filterComponent={
            <OrdersTableFilters
              searchValue={search}
              onSearchChange={handleSearchChange}
              statusValue={status}
              onStatusChange={handleStatusChange}
              orderingValue={ordering}
              onOrderingChange={handleOrderingChange}
            />
          }
          onRefetch={refetch}
          pageSize={pageSize}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          isPending={isLoadingState}
          totalRecords={orders?.count ?? 0}
          translations={translations}
          TABLE_PAGE_SIZE_OPTIONS={TABLE_PAGE_SIZE_OPTIONS}
        />
      </CardContent>
    </Card>
  );
}
