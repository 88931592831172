import { useQuery } from "@tanstack/react-query";
import { reservationService } from "@/main";
import { GetReservationsParams } from "@repo/service";
import { useCurrentLanguage } from "@repo/config";

export const RESERVATIONS_QUERY_KEY = "reservations";

export const useReservations = (params: GetReservationsParams) => {
  const currentLanguage = useCurrentLanguage();

  const queryKey = [
    RESERVATIONS_QUERY_KEY,
    currentLanguage,
    params.limit,
    params.offset,
    params.filters?.search,
    params.filters?.status,
    params.filters?.ordering,
  ];

  /**
   * Get the reservations
   * @param params -  limit, offset, filters
   * @returns The reservations
   */
  const query = useQuery({
    queryKey,
    queryFn: () =>
      reservationService.getReservations({
        ...params,
        language: currentLanguage,
      }),
  });

  const isPending = query.isLoading || query.isFetching;

  return {
    ...query,
    isPending,
  };
};
