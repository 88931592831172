import { OrderDetail } from "@repo/service";

export const getOfferStatus = (selectedOrder: OrderDetail | null) => {
  const isOfferCompleted =
    selectedOrder?.freight_demand_status?.slug === "order-demand-completed";
  const isOfferPending =
    selectedOrder?.freight_demand_status?.slug === "price-approval-pending";
  const isOfferRejected =
    selectedOrder?.freight_demand_status?.slug === "price-not-accepted";
  const isOrderDemandRejected =
    selectedOrder?.freight_demand_status?.slug === "order-demand-rejected";

  const isOfferDisabled = isOfferCompleted || isOfferPending || isOfferRejected;

  return {
    isOfferCompleted,
    isOfferPending,
    isOfferRejected,
    isOrderDemandRejected,
    isOfferDisabled,
  };
};

export const cleanNonNumeric = (value: string) => {
  return value.replace(/\D/g, "");
};

export const calculateTotal = (basePrice: string, services: any[]) => {
  const basePriceNumber = Number(basePrice) ?? 0;
  const servicesTotal = services.reduce((total, service) => {
    return total + (service.excluded ? (Number(service.price) ?? 0) : 0);
  }, 0);
  return basePriceNumber + servicesTotal;
};
