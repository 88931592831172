import { Reservation, GetReservationDetailResponse } from "@repo/service";

export const isReservationApprovedAndHasVehicle = (
  reservation: Reservation | GetReservationDetailResponse,
) => {
  if (
    reservation.approval_status.slug === "approved" &&
    reservation.reservation.vehicle?.plate
  )
    return true;
  return false;
};

export const isReservationRejected = (
  reservation: Reservation | GetReservationDetailResponse,
) => {
  if (reservation.approval_status.slug === "rejected") return true;
  return false;
};

export const isReservationPending = (
  reservation: Reservation | GetReservationDetailResponse,
) => {
  if (reservation.approval_status.slug === "pending") return true;
  return false;
};

export const isReservationApproved = (
  reservation: Reservation | GetReservationDetailResponse,
) => {
  if (reservation.approval_status.slug === "approved") return true;
  return false;
};

export const isVehicleWaitingExit = (
  reservation: Reservation | GetReservationDetailResponse,
) => {
  if (reservation.vehicle_status.slug === "waiting-exit") return true;
  return false;
};

export const isVehicleHasLeft = (
  reservation: Reservation | GetReservationDetailResponse,
) => {
  if (reservation.vehicle_status.slug === "waiting-delivery") return true;
  return false;
};

export const isVehicleHasEntered = (
  reservation: Reservation | GetReservationDetailResponse,
) => {
  if (reservation.vehicle_status?.slug === "vehicle-delivered") return true;
  return false;
};

export const isVehicleAssignmentPending = (
  reservation: Reservation | GetReservationDetailResponse,
) => {
  return reservation.approval_status?.slug === "vehicle-assignment-pending";
};

export const showReservationStatusOverlay = (
  reservation: Reservation | GetReservationDetailResponse,
  isSecurity: boolean,
  isSelectedUser: boolean,
  action_status_slug: string,
) => {
  if (isReservationRejected(reservation)) return true;
  if (isVehicleHasLeft(reservation) && isSecurity) return false;
  if (isVehicleHasLeft(reservation)) return true;
  if (isVehicleHasEntered(reservation)) return true;
  if (
    !isVehicleAssignmentPending(reservation) &&
    isReservationPending(reservation) &&
    isSelectedUser
  )
    return true;

  if (!isSelectedUser && !isSecurity && !action_status_slug) return true;
  return false;
};

export const isApprovalCompleted = (action_status_slug: string) => {
  if (action_status_slug === "approved") return true;
  return false;
};
