import { useTranslation, formatCurrency, formatDateTime } from "@repo/config";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Button } from "@repo/ui/components/ui/Button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from "@repo/ui/components/ui/Form";
import { Input } from "@repo/ui/components/ui/Input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@repo/ui/components/ui/Select";
import { Separator } from "@repo/ui/components/ui/Separator";
import { RadioGroup, RadioGroupItem } from "@repo/ui/components/ui/RadioGroup";
import { Check, X } from "lucide-react";
import { ScrollArea } from "@repo/ui/components/ui/ScrollArea";
import { usePriceOfferSchema } from "../schemas/priceOfferSchema";
import { cleanNonNumeric, calculateTotal } from "../utils/priceOfferHelpers";
import { CompanyServiceItem, OrderDetail } from "@repo/service";
import { useEffect } from "react";
import { cn } from "@repo/ui/lib/utils";
import { Avatar, AvatarFallback } from "@repo/ui/components/ui/Avatar";
import { useOrderStore } from "@/store/order";
import { PRICE_OFFER_LIMITS } from "../constants/price";
import { Checkbox } from "@repo/ui/components/ui/Checkbox";
// import { withPermission } from "@repo/provider";

interface OrderDetailContentPriceOfferFormProps {
  onSubmit: (values: z.infer<ReturnType<typeof usePriceOfferSchema>>) => void;
  onReject: () => void;
  isPending: boolean;
  isOfferDisabled: boolean;
  isOrderDemandRejected: boolean;
  statusMessage?: string;
  statusColor?: string;
  statusBackground?: string;
  selectedOrder?: OrderDetail;
  companyServices: CompanyServiceItem[];
  priceOffer?: {
    price: string;
    currency: string;
    reject_reason?: string;
    updated_at?: string;
    offer_items: Array<{
      id: string;
      service_name: string;
      included_in_price: boolean;
      price: string;
    }>;
  };
}

export default function OrderDetailContentPriceOfferForm({
  onSubmit,
  onReject,
  isPending,
  isOfferDisabled,
  isOrderDemandRejected,
  statusMessage,
  statusColor,
  statusBackground,
  companyServices,
  priceOffer,
  selectedOrder,
}: Readonly<OrderDetailContentPriceOfferFormProps>) {
  const { t } = useTranslation("orders");
  const priceOfferSchema = usePriceOfferSchema();

  // Get selectedOrder from store as a fallback
  const storeSelectedOrder = useOrderStore(
    (state: { selectedOrder: OrderDetail | null }) => state.selectedOrder,
  );
  const order = selectedOrder ?? storeSelectedOrder;

  const form = useForm<z.infer<typeof priceOfferSchema>>({
    resolver: zodResolver(priceOfferSchema),
    defaultValues: {
      price: "",
      currency: "EUR",
      services: companyServices.map((service) => ({
        id: service.id,
        service_name: service.service_name,
        selected: false,
        included: true,
        excluded: false,
        price: "",
      })),
    },
  });

  // Update form when price offer data is loaded
  useEffect(() => {
    if (priceOffer && isOfferDisabled) {
      const services = companyServices.map((service) => {
        // Find offer item by service id
        const offerItem = priceOffer.offer_items.find(
          (item) => item.id === service.id,
        );

        return {
          id: service.id,
          service_name: service.service_name,
          selected: Boolean(offerItem),
          included: offerItem?.included_in_price ?? false,
          excluded: !(offerItem?.included_in_price ?? false),
          price: offerItem?.included_in_price ? "" : (offerItem?.price ?? ""),
        };
      });

      form.reset(
        {
          price: priceOffer.price,
          currency: priceOffer.currency.toUpperCase() as "TRY" | "USD" | "EUR",
          services,
        },
        {
          keepDefaultValues: false,
          keepDirtyValues: false,
        },
      );
    }
  }, [priceOffer, isOfferDisabled, companyServices, form]);

  const { watch } = form;
  const values = watch();

  // TODO: give this price permission after api provides
  // const ProtectedOrderSendPriceOfferButton = withPermission(Button, {
  //   requiredPermissions: ["give_price"],
  //   targetApp: AppName.EMPLOYEE,
  // });

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className="flex flex-col h-full relative"
      >
        {/* Main Form Content with Blur Effect when disabled */}
        <div className="flex-1 min-h-0 relative">
          <ScrollArea className="h-full">
            <div className="space-y-4 p-6">
              {/* Base Price and Currency */}
              <div className="flex gap-2">
                {/* BASE PRICE */}
                <FormField
                  control={form.control}
                  name="price"
                  render={({ field }) => (
                    <FormItem className="flex-1">
                      <FormControl>
                        <Input
                          type="text"
                          inputMode="numeric"
                          pattern="[0-9.]+"
                          required
                          maxLength={PRICE_OFFER_LIMITS.MAX_DIGITS}
                          placeholder={t(
                            "order.detail.price_offer.form.price.placeholder",
                          )}
                          onChange={(e) => {
                            const cleaned = cleanNonNumeric(e.target.value);
                            field.onChange(cleaned);
                          }}
                          value={field.value}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />

                {/* CURRENCY */}
                <FormField
                  control={form.control}
                  name="currency"
                  render={({ field }) => (
                    <FormItem className="w-24">
                      <Select
                        onValueChange={field.onChange}
                        defaultValue={field.value}
                      >
                        <FormControl>
                          <SelectTrigger>
                            <SelectValue
                              placeholder={t(
                                "order.detail.price_offer.form.currency.placeholder",
                              )}
                            />
                          </SelectTrigger>
                        </FormControl>
                        <SelectContent>
                          <SelectItem value="TRY">
                            {t("order.detail.price_offer.form.currency.TRY")}
                          </SelectItem>
                          <SelectItem value="USD">
                            {t("order.detail.price_offer.form.currency.USD")}
                          </SelectItem>
                          <SelectItem value="EUR">
                            {t("order.detail.price_offer.form.currency.EUR")}
                          </SelectItem>
                        </SelectContent>
                      </Select>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>

              {/* Additional Services */}
              <div className="space-y-4">
                {/* Services Header */}
                <div className="flex items-center justify-center gap-4">
                  <span className="h-[1px] bg-secondary/50 flex-1" />
                  <div className="flex items-center gap-2 whitespace-nowrap text-muted-foreground">
                    <p className="text-sm text-secondary font-medium">
                      {t("order.detail.price_offer.additional_services.title")}
                    </p>
                  </div>
                  <span className="h-[1px] bg-secondary/50 flex-1" />
                </div>

                {/* Services List */}
                {values.services.map((service, index) => (
                  <div
                    key={service.id}
                    className="rounded-lg border border-border p-4 space-y-4 w-full"
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-2">
                        <Checkbox
                          id={`service-${service.id}`}
                          checked={service.selected}
                          onCheckedChange={(checked) => {
                            const newServices = [...values.services];
                            newServices[index] = {
                              ...service,
                              selected: checked as boolean,
                              included: Boolean(checked),
                              excluded: false,
                              price: "",
                            };
                            form.setValue("services", newServices);
                          }}
                        />
                        <label
                          htmlFor={`service-${service.id}`}
                          className="text-sm font-medium cursor-pointer"
                        >
                          {service.service_name}
                        </label>
                      </div>
                    </div>

                    {service.selected && (
                      <>
                        <RadioGroup
                          onValueChange={(value) => {
                            const newServices = [...values.services];
                            if (value === "included") {
                              newServices[index] = {
                                ...service,
                                included: true,
                                excluded: false,
                                price: "",
                              };
                            } else {
                              newServices[index] = {
                                ...service,
                                included: false,
                                excluded: true,
                              };
                            }
                            form.setValue("services", newServices);
                          }}
                          value={service.included ? "included" : "excluded"}
                          className="grid grid-cols-2 gap-4 w-full"
                        >
                          <FormItem>
                            <FormControl>
                              <label
                                htmlFor={`service-included-${service.id}`}
                                title={t(
                                  "order.detail.price_offer.additional_services.included",
                                )}
                                className={`flex items-center space-x-2 rounded-md border border-border p-2.5 cursor-pointer hover:bg-accent ${service.included ? "bg-accent/50 dark:bg-accent/20" : "bg-background/40"}`}
                              >
                                <div className="flex items-center flex-1">
                                  <RadioGroupItem
                                    id={`service-included-${service.id}`}
                                    value="included"
                                  />
                                  <span className="text-xs font-medium ml-2 line-clamp-1">
                                    {t(
                                      "order.detail.price_offer.additional_services.included",
                                    )}
                                  </span>
                                </div>
                              </label>
                            </FormControl>
                          </FormItem>
                          <FormItem>
                            <FormControl>
                              <label
                                htmlFor={`service-excluded-${service.id}`}
                                title={t(
                                  "order.detail.price_offer.additional_services.excluded",
                                )}
                                className={`flex items-center space-x-2 rounded-md border border-border p-2.5 cursor-pointer hover:bg-accent ${service.excluded ? "bg-accent/50 dark:bg-accent/20" : "bg-background/40"}`}
                              >
                                <div className="flex items-center flex-1">
                                  <RadioGroupItem
                                    id={`service-excluded-${service.id}`}
                                    value="excluded"
                                  />
                                  <span className="text-xs font-medium ml-2 line-clamp-1">
                                    {t(
                                      "order.detail.price_offer.additional_services.excluded",
                                    )}
                                  </span>
                                </div>
                              </label>
                            </FormControl>
                          </FormItem>
                        </RadioGroup>

                        {/* Service Price Input */}
                        {service.excluded && (
                          <div className="pt-2">
                            <FormField
                              control={form.control}
                              name={`services.${index}.price`}
                              render={({ field }) => (
                                <FormItem>
                                  <FormControl>
                                    <Input
                                      type="text"
                                      inputMode="numeric"
                                      className="bg-background"
                                      required
                                      maxLength={PRICE_OFFER_LIMITS.MAX_DIGITS}
                                      placeholder={t(
                                        "order.detail.price_offer.additional_services.price.placeholder",
                                      )}
                                      onChange={(e) => {
                                        const cleaned = cleanNonNumeric(
                                          e.target.value,
                                        );
                                        field.onChange(cleaned);
                                      }}
                                      value={field.value ?? ""}
                                    />
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </ScrollArea>

          {/* Overlay Status Message */}
          {statusMessage && (
            <div className="absolute inset-0 z-50">
              {/* Blur Background - Only Form Content */}
              <div className="absolute inset-0 bg-background/80 backdrop-blur-lg" />

              {/* Status Message Container */}
              <div className="absolute inset-0 flex items-center justify-center p-6 pointer-events-none">
                <div className="w-full max-w-2xl bg-background rounded-lg shadow-lg border pointer-events-auto">
                  {/* Status Header */}
                  <div
                    className={cn(
                      "p-3 flex items-center gap-2 border-b",
                      statusBackground,
                    )}
                  >
                    <div
                      className={cn("h-2 w-2 rounded-full", statusBackground)}
                    />
                    <span className={cn("text-sm font-medium", statusColor)}>
                      {order?.freight_demand_status.name}
                    </span>
                  </div>

                  {/* Status Content */}
                  <div className="p-4">
                    {(() => {
                      if (
                        order?.freight_demand_status?.slug ===
                        "order-demand-rejected"
                      ) {
                        return (
                          <div className="flex items-start gap-3">
                            {/* Avatar */}
                            <Avatar className="size-8 border border-destructive/20">
                              <AvatarFallback className="bg-destructive/10 text-destructive text-xs font-medium">
                                {order?.rejected_by
                                  ?.split(" ")
                                  .map((n: string) => n[0])
                                  .join("")
                                  .toUpperCase()}
                              </AvatarFallback>
                            </Avatar>

                            {/* Comment Content */}
                            <div className="flex-1 min-w-0">
                              {/* User Info & Time */}
                              <div className="flex items-center gap-2 flex-wrap mb-1">
                                <span className="text-sm font-semibold text-foreground/90">
                                  {order.rejected_by}
                                </span>
                                <span className="text-xs text-muted-foreground/70">
                                  •
                                </span>
                                <time className="text-xs text-muted-foreground/70">
                                  {formatDateTime(order.updated_at)}
                                </time>
                              </div>

                              {/* Comment Box */}
                              <div className="mt-2 p-3 rounded-lg bg-muted border text-sm text-foreground/90 whitespace-pre-line">
                                {order.reject_reason}
                              </div>
                            </div>
                          </div>
                        );
                      } else if (priceOffer?.reject_reason) {
                        return (
                          <div className="flex items-start gap-3">
                            {/* Avatar */}
                            <Avatar className="size-8 border border-destructive/20">
                              <AvatarFallback className="bg-destructive/10 text-destructive text-xs font-medium">
                                {order?.first_name?.[0]?.toUpperCase()}
                                {order?.last_name?.[0]?.toUpperCase()}
                              </AvatarFallback>
                            </Avatar>

                            {/* Comment Content */}
                            <div className="flex-1 min-w-0">
                              {/* User Info & Time */}
                              <div className="flex items-center gap-2 flex-wrap mb-1">
                                <span className="text-sm font-semibold text-foreground/90">
                                  {order?.first_name} {order?.last_name}
                                </span>
                                <span className="text-xs text-muted-foreground/70">
                                  •
                                </span>
                                <time className="text-xs text-muted-foreground/70">
                                  {formatDateTime(priceOffer.updated_at ?? "")}
                                </time>
                              </div>

                              {/* Comment Box */}
                              <div className="mt-2 p-3 rounded-lg bg-muted border text-sm text-foreground/90 whitespace-pre-line">
                                {priceOffer.reject_reason}
                              </div>
                            </div>
                          </div>
                        );
                      } else {
                        return (
                          <div className="text-sm text-center py-2 text-foreground/90">
                            {t(statusMessage)}
                          </div>
                        );
                      }
                    })()}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Fixed Bottom Section - Always Visible */}
        <div className="border-t bg-background relative z-20">
          {/* Summary */}
          <div className="p-4 space-y-2">
            <div className="flex justify-between text-sm">
              <span className="text-muted-foreground">
                {t("order.detail.price_offer.summary.base_price")}:
              </span>
              <span className="font-medium">
                {formatCurrency(
                  Number(values.price ?? 0),
                  values.currency,
                  true,
                )}
              </span>
            </div>

            {values.services.map((service) =>
              service.selected ? (
                <div key={service.id} className="flex justify-between text-sm">
                  <span className="text-muted-foreground">
                    {service.service_name}:
                  </span>
                  <span className="font-medium">
                    {formatCurrency(
                      Number(service.price ?? 0),
                      values.currency,
                      true,
                    )}
                  </span>
                </div>
              ) : null,
            )}

            <Separator />
            <div className="flex justify-between text-sm">
              <span className="font-medium">
                {t("order.detail.price_offer.summary.total")}:
              </span>
              <span className="font-medium">
                {formatCurrency(
                  calculateTotal(values.price, values.services),
                  values.currency,
                  true,
                )}
              </span>
            </div>
          </div>

          {/* Action Buttons */}
          {!isOfferDisabled && !isOrderDemandRejected && (
            <div className="flex gap-2 p-4 border-t">
              <Button
                type="button"
                variant="destructiveOutline"
                className="flex-1 gap-2"
                onClick={onReject}
                disabled={isPending}
              >
                <X className="size-4" />
                {t("order.detail.price_offer.actions.reject")}
              </Button>

              <Button
                type="submit"
                variant="success"
                className="flex-1 gap-2"
                disabled={isPending}
              >
                <Check className="size-4" />
                {isPending
                  ? t("order.detail.price_offer.actions.sending")
                  : t("order.detail.price_offer.actions.send")}
              </Button>
            </div>
          )}
        </div>
      </form>
    </Form>
  );
}
