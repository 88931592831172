import { useTranslation, formatCurrency } from "@repo/config";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@repo/ui/components/ui/AlertDialog";
import { Separator } from "@repo/ui/components/ui/Separator";
import { buttonVariants } from "@repo/ui/components/ui/Button";

interface OrderDetailContentPriceOfferSendDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onConfirm: () => void;
  isLoading?: boolean;
  totalPrice: number;
  currency: string;
  services?: Array<{
    service_name: string;
    price: string;
    excluded: boolean;
    selected: boolean;
  }>;
  basePrice: string;
}

export default function OrderDetailContentPriceOfferSendDialog({
  open,
  onOpenChange,
  onConfirm,
  isLoading,
  totalPrice,
  currency,
  services = [],
  basePrice,
}: Readonly<OrderDetailContentPriceOfferSendDialogProps>) {
  const { t } = useTranslation("orders");

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            {t("order.detail.price_offer.send_dialog.title")}
          </AlertDialogTitle>
          <AlertDialogDescription>
            {t("order.detail.price_offer.send_dialog.description")}
          </AlertDialogDescription>
        </AlertDialogHeader>

        {/* Price Summary */}
        <div className="p-4 space-y-2 bg-muted/30 rounded-lg">
          <div className="flex justify-between text-sm">
            <span className="text-muted-foreground">
              {t("order.detail.price_offer.summary.base_price")}:
            </span>
            <span className="font-medium">
              {formatCurrency(Number(basePrice ?? 0), currency, true)}
            </span>
          </div>

          {services.map((service) =>
            service.selected ? (
              <div
                key={service.service_name}
                className="flex justify-between text-sm"
              >
                <span className="text-muted-foreground">
                  {service.service_name}:
                </span>
                <span className="font-medium">
                  {formatCurrency(Number(service.price ?? 0), currency, true)}
                </span>
              </div>
            ) : null,
          )}

          <Separator />
          <div className="flex justify-between text-sm">
            <span className="font-medium">
              {t("order.detail.price_offer.summary.total")}:
            </span>
            <span className="font-medium">
              {formatCurrency(totalPrice, currency, true)}
            </span>
          </div>
        </div>

        <AlertDialogFooter>
          <AlertDialogCancel disabled={isLoading}>
            {t("order.detail.price_offer.send_dialog.actions.cancel")}
          </AlertDialogCancel>
          <AlertDialogAction
            className={buttonVariants({ variant: "success" })}
            onClick={onConfirm}
            disabled={isLoading}
          >
            {isLoading
              ? t("order.detail.price_offer.send_dialog.actions.sending")
              : t("order.detail.price_offer.send_dialog.actions.confirm")}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
