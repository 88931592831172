import AppRoutes from "./routes";
import { Providers } from "./providers";
import { ErrorBoundary } from "@repo/provider";
function App() {
  return (
    <ErrorBoundary>
      <Providers>
        <AppRoutes />
      </Providers>
    </ErrorBoundary>
  );
}

export default App;
