import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  DialogTrigger,
} from "@repo/ui/components/ui/Dialog";
import { useTranslation } from "@repo/config";
import { Button, buttonVariants } from "@repo/ui/components/ui/Button";
import { Plus } from "lucide-react";
import { useDocumentsQuery } from "@/features/documents/hooks/useDocuments";
import { GetCustomerDetailResponse } from "@repo/service";
import { useState } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@repo/ui/components/ui/Select";
import { Label } from "@repo/ui/components/ui/Label";
import { toastManager } from "@repo/ui/utils";
import { cn } from "@repo/ui/lib/utils";

interface CustomerDetailContentAddRequiredDocumentDialogProps {
  customer: GetCustomerDetailResponse;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

export default function CustomerDetailContentAddRequiredDocumentDialog({
  customer,
  open,
  onOpenChange,
}: Readonly<CustomerDetailContentAddRequiredDocumentDialogProps>) {
  const { t } = useTranslation("customers");
  const {
    useGetDocumentTypes,
    useAddUserRequiredDocument,
    useAddCompanyRequiredDocument,
  } = useDocumentsQuery();
  const [selectedDocumentType, setSelectedDocumentType] = useState<string>("");

  const { data: documentTypes, isLoading: isLoadingDocumentTypes } =
    useGetDocumentTypes();

  const addUserDocument = useAddUserRequiredDocument(customer.id);
  const addCompanyDocument = useAddCompanyRequiredDocument(
    customer.user_company?.id ?? "",
  );

  const handleSubmit = async () => {
    if (!selectedDocumentType) return;

    if (customer.profile?.account_type?.slug === "PERSONAL") {
      await addUserDocument.mutateAsync({
        _type: selectedDocumentType,
      });
      toastManager.success(t("customers.detail.document_added"));
    } else {
      await addCompanyDocument.mutateAsync({
        _type: selectedDocumentType,
      });
      toastManager.success(t("customers.detail.document_added"));
    }

    onOpenChange(false);
  };

  const isLoading = addUserDocument.isPending || addCompanyDocument.isPending;

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogTrigger
        className={cn(
          buttonVariants({ variant: "secondaryOutline" }),
          "ml-auto",
        )}
      >
        <Plus className="size-4 mr-2" />
        {t("customers.detail.request_document")}
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("customers.detail.request_document")}</DialogTitle>
          <DialogDescription>
            {t("customers.detail.request_document_description")}
          </DialogDescription>
        </DialogHeader>

        <div className="space-y-4 py-4">
          {/* Document Type Selection */}
          <div className="space-y-2">
            <Label>{t("customers.detail.document_type")}</Label>
            <Select
              value={selectedDocumentType}
              onValueChange={setSelectedDocumentType}
              disabled={isLoadingDocumentTypes}
            >
              <SelectTrigger>
                <SelectValue
                  placeholder={t("customers.detail.select_document_type")}
                />
              </SelectTrigger>
              <SelectContent>
                {documentTypes?.results.map((type) => (
                  <SelectItem key={type.id} value={type.id}>
                    {type.text}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
        </div>

        <DialogFooter>
          <Button
            variant="outline"
            onClick={() => onOpenChange(false)}
            disabled={isLoading}
          >
            {t("common.cancel")}
          </Button>
          <Button
            onClick={handleSubmit}
            disabled={!selectedDocumentType || isLoading}
          >
            {isLoading ? (
              t("common.loading")
            ) : (
              <>
                <Plus className="size-4 mr-2" />
                {t("customers.detail.request_document")}
              </>
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
