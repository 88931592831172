import ReservationDetailContentEnterExitCard from "./ReservationDetailContentEnterExitCard";
import ReservationDetailContentSecurityInformation from "./ReservationDetailContentSecurityInformation";
import { GetReservationDetailResponse } from "@repo/service";

interface ReservationDetailContentSecurityContentProps {
  reservation: GetReservationDetailResponse;
}

export default function ReservationDetailContentSecurityContent({
  reservation,
}: Readonly<ReservationDetailContentSecurityContentProps>) {
  return (
    <>
      <ReservationDetailContentSecurityInformation reservation={reservation} />
      <ReservationDetailContentEnterExitCard reservation={reservation} />
    </>
  );
}
