import {
  AppCridentials,
  AppCridentialsResponse,
  RegisterResponse,
  RegisterCredentials,
} from "./types";
import axios from "axios";
import { createTokenManager } from "./tokenManager";
import { Env } from "@repo/config";
import type {
  LoginCredentials,
  AuthResponse,
  VerifyRegistrationParams,
  ResetPasswordParams,
  RevokeTokenParams,
} from "./types";
import { errorNormalizer } from "../../utils/errorNormalizer";
import { ChangePasswordParams } from "../../types";

/**
 * @class AuthClient
 * @description Singleton class for handling authentication operations including login, token refresh, and logout
 */
class AuthService {
  private static instance: AuthService;
  private readonly tokenManager: ReturnType<typeof createTokenManager>;
  private readonly BASE_URL: string;
  private readonly LOGIN_URL: string;
  private readonly CLIENT_ID: string;
  private readonly CLIENT_SECRET: string;
  private readonly GRANT_TYPE: string;
  private readonly REGISTER_CLIENT_ID: string;
  private readonly REGISTER_CLIENT_SECRET: string;
  private readonly NODE_ENV: string;
  private readonly REVOKE_URL: string;

  /**
   * @constructor
   * @private
   * @throws {Error} If LOGIN_URL is not defined in environment variables
   */
  private constructor(env: Env) {
    this.tokenManager = createTokenManager(env);
    this.BASE_URL = new URL(env.AUTH.LOGIN_URL).origin;
    this.LOGIN_URL = env.AUTH.LOGIN_URL;
    this.CLIENT_ID = env.AUTH.CLIENT_ID;
    this.CLIENT_SECRET = env.AUTH.CLIENT_SECRET;
    this.GRANT_TYPE = env.AUTH.GRANT_TYPE;
    this.REGISTER_CLIENT_ID = env.AUTH.REGISTER_CLIENT_ID;
    this.REGISTER_CLIENT_SECRET = env.AUTH.REGISTER_CLIENT_SECRET;
    this.NODE_ENV = env.NODE_ENV;
    this.REVOKE_URL = env.AUTH.REVOKE_URL;
  }

  /**
   * @method getInstance
   * @description Get singleton instance of AuthClient
   * @returns {AuthClient} Singleton instance of AuthClient
   */
  public static getInstance(env: Env): AuthService {
    if (!AuthService.instance) {
      AuthService.instance = new AuthService(env);
    }
    return AuthService.instance;
  }

  /**
   * @method isTokenExpired
   * @description Check if the current access token is expired
   * @returns {boolean} True if token is expired
   */
  public isTokenExpired(): boolean {
    return this.tokenManager.isTokenExpired();
  }

  /**
   * @method getAccessToken
   * @description Get the current access token
   * @returns {string|null} Current access token or null if not found
   */
  public getAccessToken(): string | null {
    return this.tokenManager.getAccessToken();
  }

  /**
   * @method getRefreshToken
   * @description Get the current refresh token
   * @returns {string|null} Current refresh token or null if not found
   */
  public getRefreshToken(): string | null {
    return this.tokenManager.getRefreshToken();
  }

  /**
   * @method getEndpoint
   * @description URL'e dil parametresi ekler
   */
  private getEndpoint(path: string, language: string): string {
    return `${this.BASE_URL}/${language}${path}`;
  }

  /**
   * @method login
   * @description Authenticate user with username and password
   */
  public async login(username: string, password: string): Promise<void> {
    try {
      const credentials: LoginCredentials = {
        username,
        password,
        grant_type: this.GRANT_TYPE,
        client_id: this.CLIENT_ID,
        client_secret: this.CLIENT_SECRET,
      };

      const response = await axios.post<AuthResponse>(
        this.LOGIN_URL,
        credentials,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      this.tokenManager.setTokens({
        accessToken: response.data.access_token,
        refreshToken: response.data.refresh_token,
        expiresIn: response.data.expires_in,
      });
    } catch (error) {
      throw this.handleError(error);
    }
  }

  /**
   * @method getAppCridentials
   * @description Get application credentials
   * @returns {Promise<void>}
   */
  private async getAppCridentials(): Promise<
    AppCridentialsResponse | undefined
  > {
    try {
      const cridentials: AppCridentials = {
        grant_type: "client_credentials",
        redirect_uri: "",
        client_id: this.REGISTER_CLIENT_ID,
        client_secret: this.REGISTER_CLIENT_SECRET,
      };

      const response = await axios.post<AppCridentialsResponse>(
        this.LOGIN_URL,
        cridentials,
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );
      return response.data;
    } catch (error) {
      throw this.handleError(error);
    }
  }

  /**
   * @method register
   * @description Register a new user
   */
  public async register(
    credentials: RegisterCredentials,
    language: string = "en",
  ): Promise<void> {
    try {
      const appCridentials = await this.getAppCridentials();
      const accessToken = appCridentials?.access_token;

      if (!accessToken) {
        throw new Error("Access token is not defined");
      }

      await axios.post<RegisterResponse>(
        this.getEndpoint("/accounts/register/", language),
        credentials,
        {
          headers: {
            "Content-Type": "application/json",
            ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
          },
        },
      );
    } catch (error) {
      throw this.handleError(error);
    }
  }

  /**
   * @method verifyRegistration
   * @description Verify user's email registration
   */
  public async verifyRegistration(
    params: VerifyRegistrationParams,
    language: string = "en",
  ): Promise<void> {
    try {
      const appCridentials = await this.getAppCridentials();
      const accessToken = appCridentials?.access_token;

      if (!accessToken) {
        throw new Error("Access token is not defined");
      }

      await axios.post(
        this.getEndpoint("/accounts/verify-registration/", language),
        params,
        {
          headers: {
            "Content-Type": "application/json",
            ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
          },
        },
      );
    } catch (error) {
      throw this.handleError(error);
    }
  }

  /**
   * @method refreshToken
   * @description Refresh the current access token using refresh token
   * @throws {Error} If refresh token is not found or refresh fails
   * @returns {Promise<void>}
   */
  public async refreshToken(suppressError: boolean = false): Promise<void> {
    try {
      const refreshToken = this.tokenManager.getRefreshToken();

      // if there is no refresh token log out quietly
      if (!refreshToken) {
        return;
      }

      const response = await axios.post<AuthResponse>(
        this.LOGIN_URL,
        {
          grant_type: "refresh_token",
          refresh_token: refreshToken,
          client_id: this.CLIENT_ID,
          client_secret: this.CLIENT_SECRET,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        },
      );

      this.tokenManager.setTokens({
        accessToken: response.data.access_token,
        refreshToken: response.data.refresh_token,
        expiresIn: response.data.expires_in,
      });
    } catch (error) {
      // Logout silently if refresh token fails
      await this.logout().catch(() => {
        // Continue even if logout fails
      });

      // Throw error
      throw this.handleError(error);
    }
  }

  /**
   * @method logout
   * @description Logout the user
   * @returns {Promise<void>}
   */
  public async logout(): Promise<void> {
    // revoke the token
    const params: RevokeTokenParams = {
      token: this.getAccessToken(),
      client_id: this.CLIENT_ID,
      client_secret: this.CLIENT_SECRET,
    };
    try {
      await axios.post(this.REVOKE_URL, params);
      // clear the tokens
      this.tokenManager.clearTokens();
    } catch (error) {
      throw this.handleError(error);
    }
  }

  /**
   * @method requestPasswordReset
   * @description Request password reset for a user
   */
  public async requestPasswordReset(
    email: string,
    language: string = "en",
  ): Promise<void> {
    try {
      const appCridentials = await this.getAppCridentials();
      const accessToken = appCridentials?.access_token;

      if (!accessToken) {
        throw new Error("Access token is not defined");
      }

      await axios.post(
        this.getEndpoint("/accounts/send-reset-password-link/", language),
        { login: email },
        {
          headers: {
            "Content-Type": "application/json",
            ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
          },
        },
      );
    } catch (error) {
      throw this.handleError(error);
    }
  }

  /**
   * @method changePassword
   * @description Change password
   */
  public async resetPassword(
    params: ResetPasswordParams,
    language: string = "en",
  ): Promise<void> {
    const appCridentials = await this.getAppCridentials();
    const accessToken = appCridentials?.access_token;

    if (!accessToken) {
      throw new Error("Access token is not defined");
    }

    try {
      await axios.post(
        this.getEndpoint("/accounts/reset-password/", language),
        params,
        {
          headers: {
            "Content-Type": "application/json",
            ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
          },
        },
      );
    } catch (error) {
      throw this.handleError(error);
    }
  }

  /**
   * @method changePassword
   * @description Change password - Only for this we use access token
   * @param params ChangePasswordParams
   * @param language string
   */
  public async changePassword(
    params: ChangePasswordParams,
    language: string = "en",
  ): Promise<void> {
    try {
      await axios.post(
        this.getEndpoint("/accounts/change-password/", language),
        params,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.getAccessToken()}`,
          },
        },
      );
    } catch (error) {
      throw this.handleError(error);
    }
  }

  /**
   * Normalizes and throws errors in a consistent format.
   *
   * @param error - The error to normalize
   * @throws The normalized error
   * @private
   */
  private handleError(error: unknown): never {
    throw errorNormalizer.normalize(error);
  }
}

export type { AuthService };

export const createAuthService = (env: Env) => AuthService.getInstance(env);
