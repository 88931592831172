import { ColumnDef } from "@tanstack/react-table";
import { Customer } from "@repo/service";
import { DataTableColumnHeader } from "@repo/ui/components/table/DataTableColumnHeader";
import { useTranslation } from "@repo/config";
import { Badge } from "@repo/ui/components/ui/Badge";
import { TableCellContent } from "@repo/ui/components/table/TableCellContent";
import { getDocumentBadgeVariant } from "../utils/getDocumentStatusColors";
import { Button } from "@repo/ui/components/ui/Button";
import { useCustomerSelection } from "@/store/customer";
import { Eye } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@repo/ui/components/ui/Tooltip";
import { useQueryStates, parseAsString } from "nuqs";

export const useCustomersTableColumns = () => {
  const { t } = useTranslation("customers");
  const { selectCustomer } = useCustomerSelection();
  const [, setQueryParams] = useQueryStates(
    {
      selected_customer: parseAsString.withDefault(""),
    },
    {
      history: "push",
      shallow: true,
    },
  );

  const handleCustomerSelect = (customerId: string) => {
    selectCustomer(customerId);
    setQueryParams({ selected_customer: customerId });
  };

  const columns: ColumnDef<Customer>[] = [
    {
      id: "tax_number",
      accessorFn: (row) => row.company?.tax_number,
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("customers.tax_number")}
        />
      ),
      cell: ({ row }) => (
        <TableCellContent content={row.getValue("tax_number") ?? "-"} />
      ),
    },
    {
      accessorKey: "first_name",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("customers.first_name")}
        />
      ),
      cell: ({ row }) => (
        <TableCellContent content={row.getValue("first_name")} />
      ),
      enableSorting: true,
    },
    {
      accessorKey: "last_name",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("customers.last_name")}
        />
      ),
      cell: ({ row }) => (
        <TableCellContent content={row.getValue("last_name")} />
      ),
    },
    {
      accessorKey: "email",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={t("customers.email")} />
      ),
      cell: ({ row }) => <TableCellContent content={row.getValue("email")} />,
    },
    {
      accessorKey: "phone_number",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("customers.phone_number")}
        />
      ),
      cell: ({ row }) => (
        <TableCellContent content={row.getValue("phone_number") ?? "-"} />
      ),
    },
    {
      id: "company_name",
      accessorFn: (row) => row.company?.company_name,
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("customers.company_name")}
        />
      ),
      cell: ({ row }) => (
        <TableCellContent content={row.getValue("company_name") ?? "-"} />
      ),
    },
    {
      accessorKey: "document_status",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("customers.document_status")}
        />
      ),
      cell: ({ row }) => {
        const status =
          row.getValue<Customer["document_status"]>("document_status");
        return (
          <Badge variant={getDocumentBadgeVariant(status.slug)}>
            <TableCellContent content={status.name} />
          </Badge>
        );
      },
    },
    {
      id: "actions",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          className="flex justify-center"
          title={t("customers.actions.title")}
        />
      ),
      cell: ({ row }) => {
        const customer = row.original;
        return (
          <div className="flex justify-center">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="ghost"
                    size="icon"
                    className="h-8 w-8"
                    onClick={() => handleCustomerSelect(customer.id)}
                  >
                    <Eye className="h-4 w-4" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>{t("customers.actions.detail")}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        );
      },
      meta: {
        className: "sticky right-0 bg-card shadow-[-2.5px_0_4px_-4px_gray]",
      },
    },
  ];

  return columns;
};
