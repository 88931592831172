import OrdersTableSkeleton from "@/components/skeletons/OrdersTableSkeleton";
import { Suspense, useEffect } from "react";
import { withPermission, useFirebaseQuery } from "@repo/provider";
import { AppName } from "@repo/config";
import CustomersTable from "@/features/customers/components/CustomersTable";
import { CustomerDetailSheet } from "@/features/customers/components/CustomerDetailSheet";

export default function Customers() {
  const { logEventMutation } = useFirebaseQuery();

  useEffect(() => {
    logEventMutation.mutate({
      eventName: "page_view",
      eventParams: {
        page_title: document.title,
        page_location: window.location.href,
        page_path: window.location.pathname,
      },
    });
  }, []); // Empty dependency array means this runs once on mount

  const ProtectedCustomersTable = withPermission(CustomersTable, {
    requiredPermissions: [], // TODO: Add permissions
    targetApp: AppName.EMPLOYEE,
  });

  const ProtectedCustomerDetailSheet = withPermission(CustomerDetailSheet, {
    requiredPermissions: [], // TODO: Add permissions
    targetApp: AppName.EMPLOYEE,
  });

  return (
    <Suspense fallback={<OrdersTableSkeleton />}>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 pb-4">
        <ProtectedCustomersTable />
        <ProtectedCustomerDetailSheet />
      </div>
    </Suspense>
  );
}
