import { useCurrentLanguage } from "@repo/config";
import { customerService } from "@/main";
import { useQuery } from "@tanstack/react-query";

const QUERY_KEY = "customer";

export const useCustomerDocumentQuery = () => {
  const currentLanguage = useCurrentLanguage();

  /**
   * Get the customer document statuses
   * @returns The customer document statuses
   */
  const useCustomerDocumentStatuses = () => {
    return useQuery({
      queryKey: [QUERY_KEY, "document-statuses", currentLanguage],
      queryFn: () =>
        customerService.getCustomerDocumentStatuses({
          language: currentLanguage,
        }),
    });
  };

  return {
    useCustomerDocumentStatuses,
  };
};
