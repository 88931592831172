import { ColumnDef } from "@tanstack/react-table";
import { Order } from "@repo/service";
import { DataTableColumnHeader } from "@repo/ui/components/table/DataTableColumnHeader";
import { formatDateMonthName, useTranslation } from "@repo/config";
import { Badge } from "@repo/ui/components/ui/Badge";
import { TableCellContent } from "@repo/ui/components/table/TableCellContent";
import { Button } from "@repo/ui/components/ui/Button";
import { getBadgeVariant } from "../utils/getBadgeVariant";
import { useOrderSelection } from "@/store/order";
import { Eye } from "lucide-react";
import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "@repo/ui/components/ui/Tooltip";

export const useOrdersTableColumns = () => {
  const { t } = useTranslation("orders");
  const { selectOrder } = useOrderSelection();

  const columns: ColumnDef<Order>[] = [
    {
      accessorKey: "freight_demand_no",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={t("orders.demand_no")} />
      ),
      cell: ({ row }) => (
        <button
          onClick={() => selectOrder(row.original.freight_demand_no)}
          className="p-0 h-auto font-normal group flex items-center gap-1.5 group-hover:underline"
        >
          <TableCellContent content={row.getValue("freight_demand_no")} />
        </button>
      ),
      enableSorting: true,
    },
    {
      accessorKey: "load_address",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("orders.load_address")}
        />
      ),
      cell: ({ row }) => (
        <TableCellContent content={row.getValue("load_address")} />
      ),
    },
    {
      accessorKey: "unload_address",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("orders.unload_address")}
        />
      ),
      cell: ({ row }) => (
        <TableCellContent content={row.getValue("unload_address")} />
      ),
    },
    {
      accessorKey: "created_at",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={t("orders.created_at")} />
      ),
      cell: ({ row }) => (
        <TableCellContent
          maxWidth="max-w-[150px]"
          content={formatDateMonthName(row.getValue("created_at"))}
        />
      ),
    },
    {
      accessorKey: "loading_date",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          title={t("orders.loading_date")}
        />
      ),
      cell: ({ row }) => (
        <TableCellContent
          maxWidth="max-w-[150px]"
          content={formatDateMonthName(row.getValue("loading_date"))}
        />
      ),
    },
    {
      accessorKey: "freight_demand_status",
      header: ({ column }) => (
        <DataTableColumnHeader column={column} title={t("orders.status")} />
      ),
      cell: ({ row }) => {
        const status = row.getValue<Order["freight_demand_status"]>(
          "freight_demand_status",
        );
        return (
          <Badge variant={getBadgeVariant(status.slug)}>
            <TableCellContent content={status.name} />
          </Badge>
        );
      },
    },
    {
      id: "actions",
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          className="flex justify-center"
          title={t("orders.actions.title")}
        />
      ),
      cell: ({ row }) => {
        const order = row.original;
        return (
          <div className="flex justify-center">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    variant="outline"
                    size="icon"
                    className="h-8 w-8 group-hover:bg-success-background group-hover:text-success"
                    onClick={() => selectOrder(order.freight_demand_no)}
                  >
                    <Eye className="size-4" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>{t("orders.actions.detail")}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        );
      },
      meta: {
        className: "sticky right-0 bg-card shadow-[-2.5px_0_4px_-4px_gray]",
      },
    },
  ];

  return columns;
};
