export const showActionButtons = (documentStatus: string) => {
  switch (documentStatus) {
    case "under-review":
      return true;
    case "awaiting-approval":
      return true;
    case "awaiting-document":
      return false;
    case "approved":
      return false;
    case "rejected":
      return false;
    default:
      return false;
  }
};
