import { useTranslation } from "@repo/config";
import { AlertCircle } from "lucide-react";
import { GetReservationDetailResponse } from "@repo/service";
import ReservationDetailContentSkeleton from "./skeletons/ReservationDetailContentSkeleton";
import ReservationsDetailContentDemandInformation from "./ReservationDetailContentDemandInformation";
import ReservationsDetailContentManagement from "./ReservationDetailContentManagement";
import { useReservationQuery } from "../hooks/useReservation";
import ReservationDetailContentApprovalSteps from "./ReservationDetailContentApprovalSteps";
import { ScrollArea } from "@repo/ui/components/ui/ScrollArea";

interface ReservationDetailContentProps {
  error: any;
  isLoading: boolean;
  selectedReservation: GetReservationDetailResponse | null;
}

export const ReservationDetailContent = ({
  error,
  isLoading: isReservationLoading,
  selectedReservation,
}: ReservationDetailContentProps) => {
  const { t } = useTranslation("reservations");
  const { useReservationApprovalActions } = useReservationQuery();

  const {
    data: reservationApprovalActions,
    error: reservationApprovalActionsError,
    isLoading: isReservationApprovalActionsLoading,
  } = useReservationApprovalActions({
    reservation_id: selectedReservation?.id ?? "",
  });

  if (error || reservationApprovalActionsError) {
    return (
      <div className="flex flex-col items-center justify-center py-8">
        <AlertCircle className="h-12 w-12 text-destructive mb-3" />
        <p className="text-lg font-medium text-destructive">
          {t("error.reservation.detail.fetch_title")}
        </p>
      </div>
    );
  }

  const isLoading = isReservationLoading || isReservationApprovalActionsLoading;

  if (isLoading) {
    return <ReservationDetailContentSkeleton />;
  }

  if (!selectedReservation) return null;

  return (
    <ScrollArea className="h-[calc(100dvh-10rem)]">
      <div className="flex flex-col gap-4 px-2">
        <div className="flex flex-row gap-4">
          <ReservationsDetailContentDemandInformation
            reservation={{
              demand_owner: selectedReservation.reservation.user,
              pick_up_date: selectedReservation.reservation.start_time,
              drop_off_date:
                selectedReservation.reservation.expected_delivery_date,
              estimated_use_time:
                selectedReservation.reservation.estimated_time,
              description: selectedReservation.reservation.purpose,
              created_at: selectedReservation.created_at,
              reservation_type:
                selectedReservation.reservation.reservation_type,
            }}
          />
          <ReservationDetailContentApprovalSteps
            reservation={{
              approvals: selectedReservation.reservation.approvals,
            }}
          />
        </div>
        <ReservationsDetailContentManagement
          reservation={{
            reservation_id: selectedReservation.id,
            action_id: reservationApprovalActions?.results?.[0]?.id ?? "",
            action_status_slug:
              reservationApprovalActions?.results?.[0]?.approval_status.slug ??
              "",
            start_time: selectedReservation.reservation.start_time,
          }}
          selectedReservation={selectedReservation}
        />
      </div>
    </ScrollArea>
  );
};
