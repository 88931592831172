import i18next from "i18next";
import {
  format,
  formatDistanceToNow,
  isValid,
  parseISO,
  startOfDay as dateFnsStartOfDay,
} from "date-fns";
import { tr, enUS } from "date-fns/locale";

/**
 * Make the first letter of a word capital
 * @param word - The word to make the first letter capital
 * @returns The word with the first letter capital
 */
export const capitalizeFirstLetter = (word: string) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

/**
 * Header title from pathname
 */
const getHeaderTitle = (pathname: string) => {
  return pathname.split("/").pop() ?? "Dashboard";
};

/**
 * Capitalized header title from pathname
 */
export const getCapitalizedHeaderTitle = (pathname: string) => {
  return capitalizeFirstLetter(getHeaderTitle(pathname));
};

/**
 * Format an address
 * @param address - Address object
 * @returns Formatted address string
 */
export const formatAddress = (
  address: { full_address?: string } | null | undefined,
) => {
  return address?.full_address ?? i18next.t("loads:ots.map.popup.noAddress");
};

/**
 * Format a number as currency
 * @param amount number
 * @param currency string
 * @param isVisible
 * @returns string
 */
export const formatCurrency = (
  amount: number,
  currency?: string,
  isVisible?: boolean,
) => {
  try {
    if (isVisible) {
      return new Intl.NumberFormat(i18next.language, {
        style: "currency",
        currency: currency,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(amount);
    }

    return "- - - -";
  } catch (error) {
    console.error("Para birimi formatı hatası:", error);
    return `${amount.toFixed(2)} ${currency}`;
  }
};

/**
 * Format a phone number
 * @param phone - Phone object
 * @returns Formatted phone number string
 */
export const formatPhoneNumber = (
  phone: { full_number?: string } | null | undefined,
) => {
  if (!phone?.full_number) return i18next.t("loads:ots.map.popup.noPhone");

  // clean the phone number from +90 and spaces
  const cleanNumber = phone.full_number.replace("+90", "").replace(/\s/g, "");

  // format the phone number to 5XX XXX XX XX
  return cleanNumber.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, "$1 $2 $3 $4");
};

/**
 * Format a name
 * @param name - Name string
 * @returns Formatted name with first letter capital
 */
export const formatName = (name: string | null | undefined) => {
  if (!name) return i18next.t("loads:ots.map.popup.noName");

  return name
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

/**
 * Format a date to localized format
 * @param date - Date string or Date object
 * @param formatPattern - Date format pattern (default: 'dd/MM/yyyy')
 * @returns Formatted date string
 */
export const formatDate = (
  date: string | Date | null | undefined,
  formatPattern: string = "dd/MM/yyyy",
): string => {
  if (!date) return "";

  try {
    const dateObj = typeof date === "string" ? parseISO(date) : date;

    if (!isValid(dateObj)) {
      throw new Error("Invalid date");
    }

    const locale = i18next.language === "tr" ? tr : enUS;

    return format(dateObj, formatPattern, { locale });
  } catch (error) {
    console.error("Tarih formatı hatası:", error);
    return typeof date === "string" ? date : "";
  }
};

/**
 * Format a date to localized format with time
 * @param date - Date string or Date object
 * @returns Formatted date string with time
 */
export const formatDateTime = (date: string | null | undefined) => {
  if (!date) return "-";
  const locale = i18next.language === "tr" ? tr : enUS;
  return format(date, "dd MMM yyyy HH:mm", { locale });
};

/**
 * Format a date to localized format without time
 * @param date - Date string or Date object
 * @returns Formatted date string without time
 */
export const formatDateSafe = (date: string | undefined | Date): string => {
  if (!date) return "";
  const dateObj = typeof date === "string" ? parseISO(date) : date;
  return format(dateObj, "yyyy-MM-dd");
};

/**
 * Format a date to localized format with time
 * @param date - Date string or Date object
 * @returns Formatted date string with time
 */
export const formatDateMonthName = (date: string | null | undefined) => {
  if (!date) return "-";
  const locale = i18next.language === "tr" ? tr : enUS;
  return format(parseISO(date), "dd MMM yyyy", { locale });
};

/**
 * Format a date to localized format with time
 * @param date - Date string or Date object
 * @returns Formatted date string with time
 */
export const formatTime = (date: string | null | undefined) => {
  if (!date) return "-";
  const locale = i18next.language === "tr" ? tr : enUS;
  return format(parseISO(date), "HH:mm", { locale });
};

/**
 * format a date to time ago
 * @param date - Date string or Date object
 * @returns Formatted date string with time ago
 */
export const formatTimeAgo = (date: string | null | undefined) => {
  if (!date) return "-";
  return formatDistanceToNow(parseISO(date), {
    locale: i18next.language === "tr" ? tr : enUS,
  });
};

/**
 * Start of day
 * @param date - Date string or Date object
 * @returns Start of day date string
 */
export const startOfDay = (date: string | Date | null | undefined): Date => {
  if (!date) return dateFnsStartOfDay(new Date());
  const dateObj = typeof date === "string" ? parseISO(date) : date;
  return dateFnsStartOfDay(dateObj);
};

/**
 * TODO: Remove this after the types package created and get the types from there
 */
interface LoadDetail {
  sales_representative: {
    full_name: string;
    gsm_number: {
      full_number: string;
    };
    email: string;
  };
}

/**
 * Format contact information
 * @param contact - Contact object
 * @returns Formatted contact information
 */
export const formatContactInfo = (
  contact: LoadDetail["sales_representative"],
) => {
  if (!contact)
    return {
      fullName: i18next.t("loads:ots.map.popup.noName"),
      phone: i18next.t("loads:ots.map.popup.noPhone"),
      email: i18next.t("loads:ots.map.popup.noEmail"),
    };

  return {
    fullName: formatName(contact.full_name),
    phone: formatPhoneNumber(contact.gsm_number),
    email: contact.email || i18next.t("loads:ots.map.popup.noEmail"),
  };
};
