import { ApiClient } from "../../infrastructure/apiClient";
import {
  GetAvailableVehiclesParams,
  GetAvailableVehiclesResponse,
  GetReservationsParams,
  GetReservationsResponse,
  GetApprovalStatusesParams,
  GetApprovalStatusesResponse,
  GetReservationDetailResponse,
  GetReservationDetailParams,
  GetReservationTypesResponse,
  ApproveReservationParams,
  RejectReservationParams,
  ExitReservationParams,
  EnterReservationParams,
  GetReservationApprovalActionsResponse,
  GetReservationApprovalActionsParams,
  GetVehicleStatusesResponse,
  GetVehicleStatusesParams,
  AddReservationParams,
  GetVehicleTypesResponse,
  AddNewReservationVehicleParams,
} from "./types";

export class ReservationService {
  constructor(private readonly apiClient: ApiClient) {}

  /**
   * Get the reservations
   * @param params - The parameters for getting reservations
   * @returns Promise<GetReservationsResponse>
   */
  async getReservations(
    params: GetReservationsParams & { language: string },
  ): Promise<GetReservationsResponse> {
    const { limit = 15, offset = 0, filters, language } = params;

    return this.apiClient.get<GetReservationsResponse>(
      `${language}/workflows/approval-requests`,
      {
        params: {
          offset,
          limit,
          ordering: filters?.ordering,
          search: filters?.search,
          approval_status: filters?.status,
        },
      },
    );
  }

  /**
   * Get the reservation detail
   * @param params - The parameters for getting reservation detail
   * @returns Promise<GetReservationDetailResponse>
   */
  async getReservationDetail(
    params: GetReservationDetailParams & { language: string },
  ): Promise<GetReservationDetailResponse> {
    const { reservation_id, language } = params;
    return this.apiClient.get<GetReservationDetailResponse>(
      `${language}/workflows/approval-requests/${reservation_id}`,
    );
  }

  /**
   * Get the available vehicles
   * @param params - The parameters for getting available vehicles
   * @returns Promise<GetAvailableVehiclesResponse>
   */
  async getAvailableVehicles(
    params: GetAvailableVehiclesParams & { language: string },
  ): Promise<GetAvailableVehiclesResponse> {
    const { search, start_time, language, available_status } = params;
    return this.apiClient.get<GetAvailableVehiclesResponse>(
      `${language}/vehicles/passenger-vehicles`,
      {
        params: {
          available_status: available_status,
          search,
          start_time,
        },
      },
    );
  }

  /**
   * Get the approval statuses
   * @param params - The parameters for getting approval statuses
   * @returns Promise<GetApprovalStatusesResponse>
   */
  async getApprovalStatuses(
    params: GetApprovalStatusesParams & { language: string },
  ): Promise<GetApprovalStatusesResponse> {
    const { language } = params;
    return this.apiClient.get<GetApprovalStatusesResponse>(
      `${language}/workflows/approval-request-statuses`,
    );
  }

  /**
   * Get the vehicle statuses
   * @param params - The parameters for getting vehicle statuses
   * @returns Promise<GetVehicleStatusesResponse>
   */
  async getVehicleStatuses(
    params: GetVehicleStatusesParams & { language: string },
  ): Promise<GetVehicleStatusesResponse> {
    const { language } = params;
    return this.apiClient.get<GetVehicleStatusesResponse>(
      `${language}/vehicles/vehicle-statuses`,
    );
  }

  /**
   * Get the reservation types
   * @param params - The parameters for getting reservation types
   * @returns Promise<GetReservationTypesResponse>
   */
  async getReservationTypes(params: {
    language: string;
  }): Promise<GetReservationTypesResponse> {
    const { language } = params;
    return this.apiClient.get<GetReservationTypesResponse>(
      `${language}/vehicles/reservations/reservation-types`,
    );
  }

  /**
   * Approve the reservation
   * @param params - The parameters for approving the reservation
   * @returns Promise<void>
   */
  async approveReservation(
    params: ApproveReservationParams & { language: string },
  ): Promise<void> {
    const { reservation_id, action_id, plate, language } = params;
    return this.apiClient.post<void>(
      `${language}/workflows/approval-requests/${reservation_id}/actions/${action_id}/approve`,
      {
        plate,
      },
    );
  }

  /**
   * Reject the reservation
   * @param params - The parameters for rejecting the reservation
   * @returns Promise<void>
   */
  async rejectReservation(
    params: RejectReservationParams & { language: string },
  ): Promise<void> {
    const { reservation_id, action_id, comment, language } = params;
    return this.apiClient.post<void>(
      `${language}/workflows/approval-requests/${reservation_id}/actions/${action_id}/reject`,
      {
        comment,
      },
    );
  }

  /**
   * Get the reservation approval actions
   * @param params - The parameters for getting reservation approval actions
   * @returns Promise<GetReservationApprovalActionsResponse>
   */
  async getReservationApprovalActions(
    params: GetReservationApprovalActionsParams & { language: string },
  ): Promise<GetReservationApprovalActionsResponse> {
    const { reservation_id, language } = params;
    return this.apiClient.get<GetReservationApprovalActionsResponse>(
      `${language}/workflows/approval-requests/${reservation_id}/actions`,
    );
  }

  /**
   * Enter the reservation
   * @param params - The parameters for entering the reservation
   * @returns Promise<void>
   */
  async enterReservation(
    params: EnterReservationParams & { language: string },
  ): Promise<void> {
    const { reservation_no, km, language } = params;
    return this.apiClient.post<void>(
      `${language}/vehicles/reservations/${reservation_no}/enter`,
      {
        km,
      },
    );
  }

  /**
   * Exit the reservation
   * @param params - The parameters for exiting the reservation
   * @returns Promise<void>
   */
  async exitReservation(
    params: ExitReservationParams & { language: string },
  ): Promise<void> {
    const { reservation_no, km, language } = params;
    return this.apiClient.post<void>(
      `${language}/vehicles/reservations/${reservation_no}/exit`,
      {
        km,
      },
    );
  }

  /**
   * Add Reservation
   * @param params - The parameters for adding the reservation
   * @returns Promise<void>
   */
  async addReservation(
    params: AddReservationParams & { language: string },
  ): Promise<void> {
    const { language } = params;
    return this.apiClient.post<void>(
      `${language}/vehicles/reservations`,
      params,
    );
  }

  /**
   * Get the vehicle types
   * @param params - The parameters for getting vehicle types
   * @returns Promise<GetVehicleTypesResponse>
   */
  async getVehicleTypes(params: {
    language: string;
  }): Promise<GetVehicleTypesResponse> {
    const { language } = params;
    return this.apiClient.get<GetVehicleTypesResponse>(
      `${language}/vehicles/vehicle-types`,
    );
  }

  /**
   * Add new reservation vehicle
   * @param params - The parameters for adding new reservation vehicle
   * @returns Promise<void>
   */
  async addNewReservationVehicle(
    params: AddNewReservationVehicleParams & { language: string },
  ): Promise<void> {
    const { language } = params;
    return this.apiClient.post<void>(`${language}/vehicles/`, params);
  }
}

export const createReservationService = (apiClient: ApiClient) =>
  new ReservationService(apiClient);
