import { BrowserRouter as Router } from "react-router-dom";
import {
  ThemeProvider,
  LanguageProvider,
  QueryProvider,
  UserProvider,
  FirebaseServiceProvider,
} from "@repo/provider";

import { ReactNode } from "react";
import { NuqsAdapter } from "nuqs/adapters/react-router";
import { Toaster } from "@repo/ui/components/ui/Toaster";
import {
  authService,
  profileService,
  env,
  analyticsService,
  performanceService,
} from "@/main";

interface ProvidersProps {
  readonly children: ReactNode;
}

/**
 * @component Providers
 * @description Application provider composition that sets up:
 * - Routing with React Router
 * - Query state management with NuQS
 * - Data fetching with React Query
 * - Firebase services (Analytics & Performance)
 * - Theme management
 * - User authentication and profile
 * - Internationalization
 */
export function Providers({ children }: ProvidersProps) {
  return (
    <Router
      future={{
        v7_relativeSplatPath: true,
        v7_startTransition: true,
      }}
    >
      <NuqsAdapter>
        <QueryProvider>
          <FirebaseServiceProvider
            analyticsService={analyticsService}
            performanceService={performanceService}
            nodeEnv={env.NODE_ENV}
          >
            <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
              <UserProvider
                authService={authService}
                profileService={profileService}
                env={env}
              >
                <LanguageProvider>
                  {children}
                  <Toaster />
                </LanguageProvider>
              </UserProvider>
            </ThemeProvider>
          </FirebaseServiceProvider>
        </QueryProvider>
      </NuqsAdapter>
    </Router>
  );
}
