import { useEffect } from "react";
import { useTranslation } from "@repo/config";
import { LanguageProviderProps } from "./types";

/**
 * @component LanguageProvider
 * @description Handles language changes
 */
export function LanguageProvider({
  children,
  onLanguageChange,
}: Readonly<LanguageProviderProps>) {
  const { i18n: i18nInstance } = useTranslation();

  useEffect(() => {
    if (onLanguageChange) {
      onLanguageChange(i18nInstance.language);
    }
  }, [i18nInstance.language, onLanguageChange]);

  return <>{children}</>;
}

// Export use Language hook
export const useLanguage = () => {
  const { i18n: i18nInstance } = useTranslation();

  const changeLanguage = async (language: string) => {
    await i18nInstance.changeLanguage(language);
  };

  return {
    currentLanguage: i18nInstance.language,
    changeLanguage,
    isRTL: i18nInstance.dir() === "rtl",
  };
};
