import { Card, CardContent } from "@repo/ui/components/ui/Card";
import { useOrderStore } from "@/store/order";
import { useTranslation } from "@repo/config";
import { toastManager } from "@repo/ui/utils/toastManager";
import { z } from "zod";
import { useOrderQuery } from "../hooks/useOrder";
import { useCompanyQuery } from "@/features/company/hooks/useCompany";
import { useState, useEffect } from "react";
import OrderDetailContentPriceOfferRejectDialog from "./OrderDetailContentPriceOfferRejectDialog";
import OrderDetailContentPriceOfferSendDialog from "./OrderDetailContentPriceOfferSendDialog";
import OrderDetailContentPriceOfferForm from "./OrderDetailContentPriceOfferForm";
import { LoadingProgress } from "@repo/ui/components/ui/LoadingProgress";
import { cn } from "@repo/ui/lib/utils";
import { getBadgeBackground, getTextColor } from "../utils/getBadgeVariant";
import { getOfferStatus, calculateTotal } from "../utils/priceOfferHelpers";
import { usePriceOfferSchema } from "../schemas/priceOfferSchema";

export default function OrderDetailContentPriceOfferCard() {
  const { selectedOrder } = useOrderStore();
  const { t } = useTranslation("orders");
  const priceOfferSchema = usePriceOfferSchema();
  const [showRejectDialog, setShowRejectDialog] = useState(false);
  const [showSendDialog, setShowSendDialog] = useState(false);
  const [formValues, setFormValues] = useState<z.infer<
    typeof priceOfferSchema
  > | null>(null);

  const {
    useOrderSendOffer,
    useOrderGetPriceOffers,
    useOrderGetPriceOffer,
    useOrderRejectDemand,
  } = useOrderQuery();

  const { mutateAsync: sendOffer, isPending } = useOrderSendOffer();

  const { mutateAsync: rejectDemand, isPending: isRejectPending } =
    useOrderRejectDemand();
  const { useCompanyServices } = useCompanyQuery();
  const { data: companyServices, isLoading: isServicesLoading } =
    useCompanyServices();

  // Get offer status
  const {
    isOfferCompleted,
    isOfferPending,
    isOfferRejected,
    isOrderDemandRejected,
    isOfferDisabled,
  } = getOfferStatus(selectedOrder);

  // Fetch existing price offers if order is disabled
  const { data: priceOffers, isLoading: isPriceOffersLoading } =
    useOrderGetPriceOffers(
      {
        order_no: selectedOrder?.freight_demand_no ?? "",
      },
      {
        enabled: isOfferDisabled && !!selectedOrder?.freight_demand_no,
      },
    );

  // Fetch first offer details if offers exist
  const { data: priceOffer, isLoading: isPriceOfferLoading } =
    useOrderGetPriceOffer(
      {
        order_no: selectedOrder?.freight_demand_no ?? "",
        offer_no: priceOffers?.results?.[0]?.freight_offer_no ?? "",
      },
      {
        enabled:
          isOfferDisabled &&
          !!selectedOrder?.freight_demand_no &&
          !!priceOffers?.results?.[0]?.freight_offer_no,
      },
    );

  // Check if all content is loading
  const isAllContentLoading =
    isPriceOffersLoading || isPriceOfferLoading || isServicesLoading;

  // Check if form data is ready
  const isFormDataReady = !isOfferDisabled || (isOfferDisabled && priceOffer);

  // Check if content is ready
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    if (!isAllContentLoading && isFormDataReady) {
      // Add a short delay for form data processing
      const timer = setTimeout(() => {
        setShowContent(true);
      }, 200);

      return () => clearTimeout(timer);
    }
    return () => setShowContent(false);
  }, [isAllContentLoading, isFormDataReady]);

  // Get status message depending on the offer status
  const getStatusMessage = () => {
    if (isOfferCompleted) {
      return t("order.detail.price_offer.status.completed");
    }
    if (isOfferPending) {
      return t("order.detail.price_offer.status.pending_approval");
    }
    if (isOfferRejected) {
      return t("order.detail.price_offer.status.rejected");
    }
    if (isOrderDemandRejected) {
      return t("order.detail.price_offer.status.order_demand_rejected", {
        reason: selectedOrder?.reject_reason,
      });
    }
    return null;
  };

  const handleSubmit = async (values: z.infer<typeof priceOfferSchema>) => {
    setFormValues(values);
    setShowSendDialog(true);
  };

  const handleSendConfirm = async () => {
    if (!formValues) return;
    await sendOffer({
      order_no: selectedOrder?.freight_demand_no ?? "",
      price: Number(formValues.price),
      currency: formValues.currency,
      description: "",
      offer_items: formValues.services
        .filter((service) => service.selected)
        .map((service) => ({
          service: service.id,
          included_in_price: service.included,
          price: service.excluded ? Number(service.price) : 0,
        })),
    });

    setShowSendDialog(false);
    toastManager.success(t("order.detail.price_offer.send.success"));
  };

  const handleReject = () => {
    setShowRejectDialog(true);
  };

  const getTitle = () => {
    if (isOfferDisabled) {
      return t("order.detail.price_offer.title_view");
    }
    if (isOrderDemandRejected) {
      return t("order.detail.price_offer.title_reject");
    }
    return t("order.detail.price_offer.title");
  };

  const handleRejectConfirm = async (reason: string) => {
    await rejectDemand({
      order_no: selectedOrder?.freight_demand_no ?? "",
      reject_reason: reason,
    });

    toastManager.success(t("order.detail.price_offer.reject.success"));
    setShowRejectDialog(false);
  };

  return (
    <>
      <Card className="h-full relative">
        <CardContent className="flex flex-col h-full p-0">
          {/* Main Content */}
          <div className="flex flex-col h-full">
            {/* Header */}
            <div className="flex items-center justify-center gap-4 pt-6 pb-4">
              <span className="h-[1px] bg-secondary/50 flex-1" />
              <div className="flex items-center gap-2 whitespace-nowrap text-muted-foreground">
                <p
                  className={cn(
                    "text-sm text-secondary font-medium",
                    selectedOrder?.freight_demand_status.slug ===
                      "order-demand-rejected" && "text-destructive",
                  )}
                >
                  {getTitle()}
                </p>
              </div>
              <span className="h-[1px] bg-secondary/50 flex-1" />
            </div>

            {/* Loading State */}
            {!showContent ? (
              <div className="flex-1 flex items-center justify-center">
                <LoadingProgress
                  title={t("order.detail.price_offer.loading")}
                  showSpinner={true}
                />
              </div>
            ) : (
              // Form Content
              <div
                className={cn(
                  "flex-1 min-h-0 transition-opacity duration-200",
                  (isOfferDisabled || isOrderDemandRejected) &&
                    "opacity-80 pointer-events-none select-none",
                )}
              >
                {companyServices?.results && (
                  <OrderDetailContentPriceOfferForm
                    onSubmit={handleSubmit}
                    onReject={handleReject}
                    isPending={isPending}
                    isOfferDisabled={isOfferDisabled}
                    isOrderDemandRejected={isOrderDemandRejected}
                    statusMessage={getStatusMessage() ?? undefined}
                    statusColor={getTextColor(
                      selectedOrder?.freight_demand_status.slug ?? "",
                    )}
                    statusBackground={getBadgeBackground(
                      selectedOrder?.freight_demand_status.slug ?? "",
                    )}
                    companyServices={companyServices.results}
                    priceOffer={
                      priceOffer
                        ? {
                            price: String(priceOffer.price),
                            currency: priceOffer.currency,
                            reject_reason: priceOffer.reject_reason,
                            updated_at: priceOffer.updated_at,
                            offer_items: priceOffer.offer_items.map(
                              (item: {
                                service: { id: string; service_name: string };
                                included_in_price: boolean;
                                price: number;
                              }) => ({
                                id: item.service.id,
                                service_name: item.service.service_name,
                                included_in_price: item.included_in_price,
                                price: String(item.price),
                              }),
                            ),
                          }
                        : undefined
                    }
                  />
                )}
              </div>
            )}
          </div>
        </CardContent>
      </Card>

      <OrderDetailContentPriceOfferRejectDialog
        open={showRejectDialog}
        onOpenChange={setShowRejectDialog}
        onConfirm={handleRejectConfirm}
        isLoading={isRejectPending}
      />

      <OrderDetailContentPriceOfferSendDialog
        open={showSendDialog}
        onOpenChange={setShowSendDialog}
        onConfirm={handleSendConfirm}
        isLoading={isPending}
        totalPrice={
          formValues ? calculateTotal(formValues.price, formValues.services) : 0
        }
        currency={formValues?.currency ?? "EUR"}
        services={
          formValues?.services.map((service) => ({
            service_name: service.service_name,
            price: service.price ?? "0",
            excluded: service.excluded,
            selected: service.selected,
          })) ?? []
        }
        basePrice={formValues?.price ?? "0"}
      />
    </>
  );
}
