import {
  Analytics,
  logEvent,
  setUserProperties,
  setUserId,
} from "firebase/analytics";
import { IAnalyticsService } from "../../types/firebase.types";
import { FirebaseService } from "../../infrastructure/firebase/firebase.service";

export class AnalyticsService implements IAnalyticsService {
  private readonly analytics: Analytics | null = null;

  constructor(firebaseService: FirebaseService) {
    this.analytics = firebaseService.getService("analytics");
  }

  public logEvent(eventName: string, params?: Record<string, any>): void {
    if (this.analytics) {
      logEvent(this.analytics, eventName, params);
    }
  }

  public setUserProperties(properties: Record<string, any>): void {
    if (this.analytics) {
      setUserProperties(this.analytics, properties);
    }
  }

  public setCurrentScreen(screenName: string): void {
    if (this.analytics) {
      logEvent(this.analytics, "screen_view", {
        firebase_screen: screenName,
        firebase_screen_class: screenName,
      });
    }
  }

  public setUserId(userId: string | null): void {
    if (this.analytics) {
      setUserId(this.analytics, userId);
    }
  }
}

export const createAnalyticsService = (
  firebaseService: FirebaseService,
): AnalyticsService => {
  return new AnalyticsService(firebaseService);
};
