import { Card, CardContent } from "@repo/ui/components/ui/Card";
import { Container, Forklift, X, Box, Weight } from "lucide-react";
import { useTranslation } from "@repo/config";
import { useOrderStore } from "@/store/order";
import Truck from "@/assets/Truck.svg?react";
import { Separator } from "@repo/ui/components/ui/Separator";
import { Badge } from "@repo/ui/components/ui/Badge";
import {
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
  Tooltip,
} from "@repo/ui/components/ui/Tooltip";

export default function OrderDetailContentOrderDetailsCard() {
  const { selectedOrder } = useOrderStore();
  const { t } = useTranslation("orders");

  return (
    <Card>
      <CardContent className="flex flex-col gap-4 pt-6 pb-4 px-0 h-full">
        {/* ORDER DETAILS */}
        <div className="flex flex-col gap-2">
          {/* HEADER */}
          <div className="flex items-center gap-4 text-secondary">
            <div className="pl-6 flex items-center gap-2 shrink-0">
              <Container className="size-5" />
              <p className="text-sm">{t("order.detail.order_details")}</p>
            </div>
            <span className="h-px w-full bg-secondary/50" />
          </div>

          {/* BODY */}
          <div className="flex flex-col gap-2 px-6">
            {/* CONTAINER LOADING TYPE */}
            <div className="flex justify-center items-center gap-2 text-muted-foreground">
              {/* SUBHEADER */}
              <Forklift className="size-5" />
              <p className="text-sm">
                {t("order.detail.container_loading_type")}
              </p>
            </div>

            {/* CONTAINER LOADING TYPE VALUE AND VEHICLE COUNT */}
            <div className="flex justify-center items-center gap-2">
              <p className="text-3xl self-start font-semibold">
                {selectedOrder?.vehicle_count}
              </p>
              <X className="size-6" />
              <div className="relative">
                <Truck />
                <p className="absolute top-0 text-white left-7 tracking-wider font-semibold">
                  {selectedOrder?.container_loading_type}
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* LOAD NAME AND LOAD WEIGHT */}
        <div className="flex flex-row justify-between px-6 w-full">
          <div className="flex-1 flex justify-start ">
            <div className="flex flex-col gap-2">
              <div className="flex gap-2 ">
                <Box className="size-5" />
                <p className="text-sm">{t("order.detail.load")}</p>
              </div>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <p className="text-sm text-center line-clamp-2">
                      {selectedOrder?.load_name}
                    </p>
                  </TooltipTrigger>
                  <TooltipContent side="bottom">
                    <p>{selectedOrder?.load_name}</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          </div>

          {/* LOAD WEIGHT */}
          <div className="flex-1 flex justify-end ">
            <div className="flex flex-col gap-2 max-w-1/2 shrink-0">
              <div className="flex gap-2">
                <Weight className="size-5" />
                <p className="text-sm">{t("order.detail.weight")}</p>
              </div>
              <p className="text-sm text-center">
                {selectedOrder?.weight} {selectedOrder?.unit}
              </p>
            </div>
          </div>
        </div>

        {/* EXPEDITION TYPE AND LOAD TYPE */}
        <div className="mt-auto flex flex-col gap-2 overflow-hidden">
          <Separator className="bg-secondary/50" />
          <div className="flex w-full 2xl:flex-row justify-between px-6">
            {/* EXPEDITION TYPE */}
            <div className="flex items-center gap-2 pt-1.5">
              <p className="text-sm leading-snug truncate">
                {t("order.detail.expedition_type")}
              </p>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <Badge className="line-clamp-1" variant="outline">
                      {selectedOrder?.expedition_type}
                    </Badge>
                  </TooltipTrigger>
                  <TooltipContent side="bottom">
                    <p>{selectedOrder?.expedition_type}</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>

            {/* LOAD TYPE */}
            <div className="flex items-center gap-2 pt-1.5">
              <p className="text-sm leading-snug truncate">
                {t("order.detail.load_type")}
              </p>
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <Badge className="line-clamp-1" variant="outline">
                      {selectedOrder?.load_type}
                    </Badge>
                  </TooltipTrigger>
                  <TooltipContent side="bottom">
                    <p>{selectedOrder?.load_type}</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
