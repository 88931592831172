import { Input } from "@repo/ui/components/ui/Input";
import { useTranslation } from "@repo/config";
import { Search } from "lucide-react";

interface BookFilterProps {
  readonly searchQuery: string;
  readonly onSearchChange: (value: string) => void;
}

export function BookFilter({ searchQuery, onSearchChange }: BookFilterProps) {
  const { t } = useTranslation("library");

  return (
    <div className="mb-4 pt-4 relative">
      <Search className="absolute left-3 top-7 size-4 text-muted-foreground" />
      <Input
        placeholder={t("searchPlaceholder")}
        value={searchQuery}
        onChange={(e) => onSearchChange(e.target.value)}
        className="max-w-sm pl-9"
      />
    </div>
  );
}
