import { z } from "zod";
import { useTranslation, format } from "@repo/config";

// Create a function to generate schema with translations
export const useAddReservationSchema = () => {
  const { t } = useTranslation("reservations");

  return z.object({
    // Start date and time
    start_date: z
      .date({
        required_error: t("error.validation.start_date_required"),
      })
      .min(new Date(new Date().setHours(0, 0, 0, 0)), {
        message: t("error.validation.start_date_past"),
      }),
    start_time: z.string({
      required_error: t("error.validation.start_time_required"),
    }),

    // Estimated usage time (in hours)
    estimated_time: z
      .string({
        required_error: t("error.validation.estimated_time_required"),
      })
      .refine((val) => !isNaN(Number(val)) && Number(val) >= 1, {
        message: t("error.validation.estimated_time_min"),
      })
      .refine((val) => !isNaN(Number(val)) && Number(val) <= 72, {
        message: t("error.validation.estimated_time_max"),
      }),

    // Reservation type
    reservation_type: z
      .string()
      .min(1, t("error.validation.reservation_type_required")),

    // Purpose of reservation
    purpose: z
      .string({
        required_error: t("error.validation.purpose_required"),
      })
      .min(10, t("error.validation.purpose_min_length"))
      .max(150, t("error.validation.purpose_max_length")),

    // Consent checkbox
    consent: z.boolean().refine((val) => val === true, {
      message: t("error.validation.consent_required"),
    }),
  });
};

// Default schema without translations (for type inference)
export const addReservationSchema = z.object({
  start_date: z.date(),
  start_time: z.string(),
  estimated_time: z.string(),
  reservation_type: z.string(),
  purpose: z.string(),
  consent: z.boolean(),
});

// Type for form values
export type AddReservationFormValues = z.infer<typeof addReservationSchema>;

// Default start date (30 minutes from now)
const defaultStartDate = (): Date => {
  const now = new Date();
  const thirtyMinutesLater = new Date(now);
  thirtyMinutesLater.setMinutes(now.getMinutes() + 30);
  // Reset seconds and milliseconds
  thirtyMinutesLater.setSeconds(0);
  thirtyMinutesLater.setMilliseconds(0);
  return thirtyMinutesLater;
};

// Default form values
export const defaultAddReservationValues: AddReservationFormValues = {
  start_date: defaultStartDate(),
  start_time: format(defaultStartDate(), "HH:mm"),
  estimated_time: "1",
  reservation_type: "",
  purpose: "",
  consent: false,
};
