import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@repo/ui/components/ui/Card";
import { formatDateTime, useTranslation } from "@repo/config";
import { Info, MapPin } from "lucide-react";
import { Separator } from "@repo/ui/components/ui/Separator";
import { GetReservationDetailResponse } from "@repo/service";

interface ReservationDetailContentSecurityInformationProps {
  reservation: GetReservationDetailResponse;
}

export default function ReservationDetailContentSecurityInformation({
  reservation,
}: Readonly<ReservationDetailContentSecurityInformationProps>) {
  const { t } = useTranslation("reservations");

  return (
    <Card className="w-full">
      <CardHeader className="px-4 pt-5 pb-2">
        <CardTitle className="text-sm md:text-base">
          {t("security_information_title")}
        </CardTitle>
      </CardHeader>
      <CardContent className="flex flex-col gap-4 px-4">
        {/* Has Exited Information */}
        <div className="flex items-center gap-2 bg-secondary-background p-2 rounded-lg">
          <Info className="size-4 shrink-0" />
          <p className="text-xs leading-5">{t("has_exited_information")}</p>
        </div>

        {/* Has Entered Information */}
        <div className="flex items-center gap-2 bg-success-background p-2 rounded-lg">
          <Info className="size-4 shrink-0" />
          <p className="text-xs leading-5">{t("has_entered_information")}</p>
        </div>

        <Separator className="" />

        {/* Has Exited Information */}
        {reservation.reservation.reservation_activities.length > 0 && (
          <div className="flex items-center gap-2">
            <MapPin className="size-4 shrink-0 text-secondary" />
            <p className="text-xs leading-5">
              {reservation.reservation.reservation_activities.map((activity) =>
                activity.activity_type.slug === "exited"
                  ? t("vehicle_exit_date", {
                      date: formatDateTime(activity.created_at),
                    })
                  : null,
              )}
            </p>
          </div>
        )}
      </CardContent>
    </Card>
  );
}
