import { AvailableVehicle, ReservationVehicle } from "@repo/service";
import { CarFront } from "lucide-react";
import { checkIfValueIsEmptyOrNullOrUndefined } from "../utils/getBadgeVariant";
import { cn } from "@repo/ui/lib/utils";
interface ChoosenVehicleCardProps {
  vehicle: AvailableVehicle | ReservationVehicle;
  className?: string;
}

export function ChoosenVehicleCard({
  vehicle,
  className,
}: Readonly<ChoosenVehicleCardProps>) {
  // Get plate value based on vehicle type
  const getPlateValue = () => {
    // ReservationVehicle has 'plate' property, AvailableVehicle has 'vehicle' property
    if ("plate" in vehicle) {
      return vehicle.plate;
    }
    return "vehicle" in vehicle ? vehicle.vehicle : "";
  };

  return (
    <div
      className={cn(
        "border-border rounded-lg bg-muted p-3 flex flex-row items-center gap-3 hover:bg-muted/80 transition-colors",
        className,
      )}
    >
      {/* Car icon & name & plate */}
      <div className="flex items-center gap-3">
        <CarFront className="size-4" />

        <div className="text-left">
          <p className="text-sm font-medium">
            {checkIfValueIsEmptyOrNullOrUndefined(vehicle.brand)}{" "}
            {checkIfValueIsEmptyOrNullOrUndefined(vehicle.model)}
          </p>
          <p className="text-xs text-muted-foreground">
            {checkIfValueIsEmptyOrNullOrUndefined(getPlateValue())}
          </p>
        </div>
      </div>
    </div>
  );
}
