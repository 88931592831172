import L from "leaflet";

export type MapLocation = {
  lat: number;
  lng: number;
};

export interface MapViewConfig {
  bounds: L.LatLngBounds;
  center: [number, number];
  zoom: number;
}

export interface MapBoundsConfig {
  padding: [number, number];
  maxZoom: number;
  animate: boolean;
  duration: number;
}

/**
 * Calculate appropriate zoom level based on distance between two points
 */
export function calculateZoomLevel(distanceInKm: number): number {
  if (distanceInKm > 1000) return 4; // continental distance
  if (distanceInKm > 500) return 5; // large country distance
  if (distanceInKm > 200) return 6; // medium country distance
  if (distanceInKm > 100) return 7; // small country distance
  if (distanceInKm > 50) return 8; // region distance
  if (distanceInKm > 25) return 9; // city distance
  if (distanceInKm > 10) return 10; // district distance
  if (distanceInKm > 5) return 11; // neighborhood distance
  if (distanceInKm > 2) return 12; // street level distance
  return 13; // max zoom for very close points
}

/**
 * Calculate map view configuration based on two locations
 */
export function calculateMapView(
  loadLocation: MapLocation,
  unloadLocation: MapLocation,
): MapViewConfig {
  const loadPoint = L.latLng(loadLocation.lat, loadLocation.lng);
  const unloadPoint = L.latLng(unloadLocation.lat, unloadLocation.lng);

  // Create bounds from both points
  const bounds = L.latLngBounds([loadPoint, unloadPoint]);

  // Add padding to bounds to ensure both markers are visible
  const paddedBounds = bounds.pad(0.2); // %20 padding

  // Calculate center from padded bounds
  const center = paddedBounds.getCenter();

  // Calculate distance and zoom
  const distance = loadPoint.distanceTo(unloadPoint) / 1000; // distance in km
  const zoom = calculateZoomLevel(distance);

  return {
    bounds: paddedBounds,
    center: [center.lat, center.lng] as [number, number],
    zoom,
  };
}

/**
 * Create a custom marker icon with specified color
 */
export function createMarkerIcon(color: string): L.DivIcon {
  return L.divIcon({
    className: "custom-marker-icon",
    html: `<div class="size-8 rounded-full ${color} flex items-center justify-center border-2 border-white shadow-md">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" class="size-4">
              <path fill-rule="evenodd" d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z" clip-rule="evenodd" />
            </svg>
          </div>`,
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });
}

/**
 * Get default map bounds configuration
 */
export function getDefaultBoundsConfig(zoom: number): MapBoundsConfig {
  return {
    padding: [50, 50],
    maxZoom: zoom,
    animate: true,
    duration: 1,
  };
}

/**
 * Default tile layer options
 */
export const DEFAULT_TILE_LAYER_OPTIONS = {
  attribution:
    '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
};
