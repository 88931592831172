/**
 * @description Application name enum
 */
export enum AppName {
  CUSTOMER = "CUSTOMER",
  EMPLOYEE = "EMPLOYEE",
  AUTH = "AUTH",
  RMC = "RMC",
}

/**
 * @description Role validation result interface
 */
export type RoleValidationResult = {
  hasAccess: boolean;
  reason?: "FORBIDDEN_ROLE" | "NEW_USER" | "INVALID_ROLE";
};

/**
 * @description Application roles
 */
export const APP_ROLES = {
  [AppName.CUSTOMER]: {
    allowed: ["MANAGER", "VIEWER", "CUSTOMER", "FORWARDER"] as const,
    forbidden: ["NEW_USER", "SUPPLIER", "DRIVER", "EMPLOYEE"] as const,
  },
  [AppName.EMPLOYEE]: {
    allowed: ["MANAGER", "EMPLOYEE", "VIEWER"] as const,
    forbidden: [
      "FORWARDER",
      "NEW_USER",
      "SUPPLIER",
      "DRIVER",
      "CUSTOMER",
    ] as const,
  },
  [AppName.RMC]: {
    allowed: ["MANAGER", "EMPLOYEE", "VIEWER"] as const,
    forbidden: [
      "FORWARDER",
      "NEW_USER",
      "SUPPLIER",
      "DRIVER",
      "CUSTOMER",
    ] as const,
  },
  [AppName.AUTH]: {
    allowed: [
      "MANAGER",
      "VIEWER",
      "CUSTOMER",
      "FORWARDER",
      "NEW_USER",
      "SUPPLIER",
      "DRIVER",
      "EMPLOYEE",
    ] as const,
    forbidden: [] as const,
  },
} as const;

/**
 * @description Role type for the application
 */
export type Role =
  | (typeof APP_ROLES)[AppName]["allowed"][number]
  | (typeof APP_ROLES)[AppName]["forbidden"][number];
