import { Env } from "@repo/config";
import Cookies from "js-cookie";

export function createTokenManager(env: Env) {
  const TOKEN_PREFIX = "auth_";
  const COOKIE_DOMAIN = env.AUTH.COOKIE_DOMAIN;
  let isProd = env.NODE_ENV === "production";

  if (COOKIE_DOMAIN.includes("abcws")) {
    isProd = false;
  }

  const getCommonOptions = () => {
    const options = {
      domain: COOKIE_DOMAIN,
      path: "/",
      secure: isProd,
      sameSite: "lax" as const,
    };

    return options;
  };

  return {
    setTokens({
      accessToken,
      refreshToken,
      expiresIn,
    }: {
      accessToken: string;
      refreshToken: string;
      expiresIn: number;
    }) {
      const commonOptions = getCommonOptions();
      const expiresAtTime = Date.now() + expiresIn * 1000;

      // 15 minutes for access token
      Cookies.set(`${TOKEN_PREFIX}access_token`, accessToken, {
        ...commonOptions,
        expires: new Date(expiresAtTime),
      });

      // 30 days for refresh token
      Cookies.set(`${TOKEN_PREFIX}refresh_token`, refreshToken, {
        ...commonOptions,
        expires: 30,
      });

      // Token expiration time
      Cookies.set(
        `${TOKEN_PREFIX}expires_at`,
        String(expiresAtTime),
        commonOptions,
      );
    },

    getAccessToken(): string | null {
      return Cookies.get(`${TOKEN_PREFIX}access_token`) ?? null;
    },

    getRefreshToken(): string | null {
      return Cookies.get(`${TOKEN_PREFIX}refresh_token`) ?? null;
    },

    isTokenExpired(): boolean {
      const expiresAt = Cookies.get(`${TOKEN_PREFIX}expires_at`);
      const accessToken = this.getAccessToken();

      if (!accessToken || !expiresAt) return true;

      const EXPIRATION_MARGIN = 30 * 1000; // 30 seconds
      const expiresAtTime = Number(expiresAt);
      const now = Date.now();

      // Explicitly handle edge cases
      return now >= expiresAtTime || expiresAtTime - now < EXPIRATION_MARGIN;
    },

    hasValidRefreshToken(): boolean {
      const refreshToken = this.getRefreshToken();
      return !!refreshToken;
    },

    clearTokens() {
      // httpOnly should be false because js-cookie is running on the client side
      const commonOptions = getCommonOptions();

      // Remove each cookie
      Cookies.remove(`${TOKEN_PREFIX}access_token`, commonOptions);
      Cookies.remove(`${TOKEN_PREFIX}refresh_token`, commonOptions);
      Cookies.remove(`${TOKEN_PREFIX}expires_at`, commonOptions);
    },
  };
}
