import { z } from "zod";
import { useTranslation } from "@repo/config";

export const useAddNewVehicleSchema = () => {
  const { t } = useTranslation("reservations");

  return z.object({
    plate: z
      .string()
      .min(1, { message: t("error.validation.plate_required") })
      .max(20, { message: t("error.validation.plate_max_length") }),
    brand: z
      .string()
      .min(1, { message: t("error.validation.brand_required") })
      .max(50, { message: t("error.validation.brand_max_length") }),
    model: z
      .string()
      .min(1, { message: t("error.validation.model_required") })
      .max(50, { message: t("error.validation.model_max_length") }),
    vehicle_type_id: z.string({
      required_error: t("error.validation.vehicle_type_required"),
    }),
  });
};

export type AddNewVehicleFormValues = z.infer<
  ReturnType<typeof useAddNewVehicleSchema>
>;
