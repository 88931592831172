import { useTranslation } from "@repo/config";
import { AlertCircle } from "lucide-react";
import { OrderDetail } from "@repo/service";
import { ScrollArea } from "@repo/ui/components/ui/ScrollArea";
import OrderDetailContentUserDetailCard from "./OrderDetailContentUserDetailCard";
import OrderDetailContentOrderDetailsCard from "./OrderDetailContentOrderDetailsCard";
import OrderDetailContentRouteMapCard from "./OrderDetailContentRouteMapCard";
import OrderDetailContentPriceOfferCard from "./OrderDetailContentPriceOfferCard";
import OrderDetailContentSkeleton from "./skeletons/OrderDetailContentSkeleton";

interface OrderDetailContentProps {
  error: any;
  isLoading: boolean;
  selectedOrder: OrderDetail | null;
}

export const OrderDetailContent = ({
  error,
  isLoading,
  selectedOrder,
}: OrderDetailContentProps) => {
  const { t } = useTranslation("orders");

  if (error) {
    return (
      <div className="flex flex-col items-center justify-center h-full">
        <AlertCircle className="h-12 w-12 text-destructive mb-3" />
        <p className="text-lg text-destructive">
          {t("error.order.detail.fetch_title")}
        </p>
      </div>
    );
  }

  if (isLoading) {
    return <OrderDetailContentSkeleton />;
  }

  if (!selectedOrder) return null;

  return (
    <div className="flex flex-col lg:flex-row gap-2 h-[calc(100dvh-10rem)]">
      {/* LEFT SIDE */}
      <ScrollArea className="lg:flex-1">
        <div className="grid grid-cols-1 2xl:grid-cols-2 gap-4 px-2">
          {/* USER DETAILS CARD */}
          <OrderDetailContentUserDetailCard />

          {/* ORDER DETAILS CARD */}
          <OrderDetailContentOrderDetailsCard />

          {/* ROUTE MAP CARD */}
          <OrderDetailContentRouteMapCard />
        </div>
      </ScrollArea>

      {/* RIGHT SIDE */}
      <div className="w-full lg:w-[400px] shrink-0 pr-2">
        {/* PRICE OFFER */}
        <OrderDetailContentPriceOfferCard />
      </div>
    </div>
  );
};
