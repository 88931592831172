import { useQuery } from "@tanstack/react-query";
import { companyService } from "@/main";
import { useCurrentLanguage } from "@repo/config";

const QUERY_KEY = "company";

export function useCompanyQuery() {
  const currentLanguage = useCurrentLanguage();

  /**
   * Get company services
   * @returns The company services query
   */
  const useCompanyServices = () => {
    return useQuery({
      queryKey: [QUERY_KEY, "services", currentLanguage],
      queryFn: () => companyService.getCompanyServices(currentLanguage),
    });
  };

  return {
    useCompanyServices,
  };
}
