import { Card, CardContent } from "@repo/ui/components/ui/Card";
import { ScrollArea } from "@repo/ui/components/ui/ScrollArea";
import { Skeleton } from "@repo/ui/components/ui/Skeleton";

export default function OrderDetailContentSkeleton() {
  return (
    <div className="flex flex-col lg:flex-row gap-2 h-[calc(100dvh-10rem)]">
      {/* LEFT SIDE */}
      <ScrollArea className="lg:flex-1">
        <div className="grid grid-cols-1 2xl:grid-cols-2 gap-4 pr-2">
          {/* USER DETAILS CARD */}
          <Card>
            <CardContent className="p-6 space-y-4">
              <div className="space-y-2">
                <Skeleton className="h-4 w-24" />
                <Skeleton className="h-8 w-full" />
              </div>
              <div className="space-y-2">
                <Skeleton className="h-4 w-32" />
                <Skeleton className="h-8 w-full" />
              </div>
              <div className="space-y-2">
                <Skeleton className="h-4 w-28" />
                <Skeleton className="h-8 w-full" />
              </div>
            </CardContent>
          </Card>

          {/* ORDER DETAILS CARD */}
          <Card>
            <CardContent className="p-6 space-y-4">
              <div className="space-y-2">
                <Skeleton className="h-4 w-28" />
                <Skeleton className="h-8 w-full" />
              </div>
              <div className="space-y-2">
                <Skeleton className="h-4 w-32" />
                <Skeleton className="h-8 w-full" />
              </div>
              <div className="space-y-2">
                <Skeleton className="h-4 w-24" />
                <Skeleton className="h-8 w-full" />
              </div>
              <div className="space-y-2">
                <Skeleton className="h-4 w-28" />
                <Skeleton className="h-8 w-full" />
              </div>
            </CardContent>
          </Card>

          {/* ROUTE MAP CARD */}
          <Card className="w-full 2xl:col-span-2">
            <CardContent className="p-0">
              <Skeleton className="h-[calc(100svh-30.5rem)] w-full rounded-lg" />
            </CardContent>
          </Card>
        </div>
      </ScrollArea>

      {/* RIGHT SIDE */}
      <div className="w-full lg:w-[400px] shrink-0">
        {/* PRICE OFFER CARD */}
        <Card className="h-full">
          <CardContent className="p-0">
            {/* Header */}
            <div className="p-6 border-b">
              <Skeleton className="h-6 w-32 mx-auto" />
            </div>

            {/* Form Content */}
            <div className="p-6 space-y-6">
              {/* Price and Currency */}
              <div className="flex gap-2">
                <Skeleton className="h-10 flex-1" />
                <Skeleton className="h-10 w-24" />
              </div>

              {/* Services */}
              <div className="space-y-4">
                <Skeleton className="h-6 w-40 mx-auto" />
                {[1, 2, 3].map((index) => (
                  <div key={index} className="space-y-4 border rounded-lg p-4">
                    <Skeleton className="h-5 w-32" />
                    <div className="grid grid-cols-2 gap-4">
                      <Skeleton className="h-10" />
                      <Skeleton className="h-10" />
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {/* Summary */}
            <div className="border-t p-4 space-y-2">
              <div className="flex justify-between">
                <Skeleton className="h-4 w-24" />
                <Skeleton className="h-4 w-20" />
              </div>
              <div className="flex justify-between">
                <Skeleton className="h-4 w-28" />
                <Skeleton className="h-4 w-20" />
              </div>
            </div>

            {/* Actions */}
            <div className="flex gap-2 p-4 border-t">
              <Skeleton className="h-10 flex-1" />
              <Skeleton className="h-10 flex-1" />
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
