import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  useTranslation,
  format,
  addHours,
  tr,
  enUS,
  useCurrentLanguage,
} from "@repo/config";
import { toastManager } from "@repo/ui/utils";

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@repo/ui/components/ui/Dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@repo/ui/components/ui/Form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@repo/ui/components/ui/Select";
import { Input } from "@repo/ui/components/ui/Input";
import { Button } from "@repo/ui/components/ui/Button";
import { Textarea } from "@repo/ui/components/ui/Textarea";
import { Checkbox } from "@repo/ui/components/ui/Checkbox";
import { InfoIcon, Loader2, Plus } from "lucide-react";
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "@repo/ui/components/ui/Alert";

// Import DatePicker and TimePicker components
import { DatePicker } from "@repo/ui/components/time-picker/DatePicker";
import { TimePicker } from "@repo/ui/components/time-picker/TimePicker";
import { TimePicker12Demo } from "@repo/ui/components/time-picker/TimePicker12h";

import { useReservationQuery } from "../hooks/useReservation";
import {
  useAddReservationSchema,
  AddReservationFormValues,
  defaultAddReservationValues,
} from "../schemas/addReservationSchema";
import { AddReservationParams } from "@repo/service";
import { documentUrls } from "../utils/documents";

export default function AddReservationDialog() {
  const { t } = useTranslation("reservations");
  const [open, setOpen] = useState(false);
  const [estimatedDeliveryDate, setEstimatedDeliveryDate] =
    useState<Date | null>(null);
  const { useReservationTypes, useAddReservation } = useReservationQuery();
  const currentLanguage = useCurrentLanguage();

  // Document dialog states
  const [showVehicleContractDialog, setShowVehicleContractDialog] =
    useState(false);
  const [showPrivacyDialog, setShowPrivacyDialog] = useState(false);
  const [showKvkkDialog, setShowKvkkDialog] = useState(false);

  // Select locale based on language
  const locale = currentLanguage === "tr" ? tr : enUS;

  // Get reservation types
  const { data: reservationTypes, isLoading: isLoadingTypes } =
    useReservationTypes();

  // Add reservation mutation
  const { mutateAsync: addReservation, isPending: isAddingReservation } =
    useAddReservation();

  // Create schema with translations
  const addReservationSchema = useAddReservationSchema();

  // Form definition
  const form = useForm<AddReservationFormValues>({
    resolver: zodResolver(addReservationSchema),
    defaultValues: defaultAddReservationValues,
  });

  // Calculate estimated delivery date
  useEffect(() => {
    const startDate = form.watch("start_date");
    const estimatedTime = form.watch("estimated_time");

    if (startDate && estimatedTime) {
      // Add estimated usage time
      const deliveryDate = addHours(startDate, Number(estimatedTime));
      setEstimatedDeliveryDate(deliveryDate);
    }
  }, [form.watch("start_date"), form.watch("estimated_time")]);

  // Form submission
  const onSubmit = async (values: AddReservationFormValues) => {
    // Validate all fields before submission
    const isValid = await form.trigger();
    if (!isValid) {
      return;
    }

    // Calculate estimated delivery date
    const deliveryDate = addHours(
      values.start_date,
      Number(values.estimated_time),
    );

    // Convert estimated_time (hours) to "hh:mm:ss" format
    const hours = Math.floor(Number(values.estimated_time));
    const minutes = Math.round((Number(values.estimated_time) - hours) * 60);
    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:00`;

    // Prepare data for API
    const requestData: AddReservationParams = {
      reservation_type: values.reservation_type,
      purpose: values.purpose,
      start_time: values.start_date.toISOString(),
      estimated_time: formattedTime,
      expected_delivery_date: deliveryDate.toISOString(),
      plate: "", // Vehicle plate is empty at this stage
      consent: values.consent,
    };

    // Send API request
    await addReservation(requestData);

    toastManager.success(t("success.add_reservation"));
    setOpen(false);
    form.reset(defaultAddReservationValues);
  };

  // Handle time change
  const handleTimeChange = (time: Date | undefined, field: any) => {
    if (time && field.value) {
      // Create a new date with the current date and selected time
      const newDate = new Date(field.value);
      newDate.setHours(time.getHours(), time.getMinutes(), time.getSeconds());

      field.onChange(newDate);
    } else if (time) {
      field.onChange(time);
    }
  };

  return (
    <>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger asChild>
          <Button size="sm" variant="secondary">
            <Plus className="size-4" />
            {t("add_reservation")}
          </Button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-lg">
          <DialogHeader>
            <DialogTitle>{t("add_reservation_dialog.title")}</DialogTitle>
            <DialogDescription>
              {t("add_reservation_dialog.description")}
            </DialogDescription>
          </DialogHeader>

          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
              {/* Start Date and Time */}
              <FormField
                control={form.control}
                name="start_date"
                render={({ field }) => (
                  <FormItem className="flex flex-col">
                    <FormLabel>{t("pick_up_date")}</FormLabel>
                    <div className="flex flex-row gap-2 items-start">
                      {/* Date Picker */}
                      <div className="flex-1">
                        <DatePicker
                          date={field.value}
                          setDate={(date) => {
                            if (date) field.onChange(date);
                          }}
                          placeholder={t("select_date")}
                          locale={locale}
                        />
                      </div>

                      {/* Time Picker */}
                      <div className="flex-1">
                        {currentLanguage === "tr" ? (
                          <TimePicker
                            date={field.value}
                            setDate={(date) => handleTimeChange(date, field)}
                            locale={locale}
                            showSeconds={false}
                            showLabel={false}
                          />
                        ) : (
                          <TimePicker12Demo
                            date={field.value}
                            setDate={(date) => handleTimeChange(date, field)}
                            locale={locale}
                            showSeconds={false}
                            showLabel={false}
                          />
                        )}
                      </div>
                    </div>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {/* Estimated Usage Time */}
              <FormField
                control={form.control}
                name="estimated_time"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t("estimated_use_time")}
                      <span className="text-xs text-muted-foreground ml-2">
                        {"("} {t("hour")} {")"}
                      </span>
                    </FormLabel>
                    <FormControl>
                      <div className="flex items-center">
                        <Button
                          type="button"
                          variant="outline"
                          size="icon"
                          className="rounded-r-none"
                          onClick={() => {
                            const currentValue = parseInt(field.value);
                            if (!isNaN(currentValue) && currentValue > 1) {
                              field.onChange(String(currentValue - 1));
                            }
                          }}
                        >
                          <span className="text-lg">-</span>
                        </Button>
                        <Input
                          type="number"
                          min={1}
                          max={72}
                          className="rounded-none z-10 text-center [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                          {...field}
                          onChange={(e) => {
                            const value = parseInt(e.target.value);
                            if (isNaN(value)) {
                              field.onChange("1");
                            } else if (value > 72) {
                              field.onChange("72");
                            } else if (value < 1) {
                              field.onChange("1");
                            } else {
                              field.onChange(String(value));
                            }
                          }}
                        />
                        <Button
                          type="button"
                          variant="outline"
                          size="icon"
                          className="rounded-l-none"
                          onClick={() => {
                            const currentValue = parseInt(field.value);
                            if (!isNaN(currentValue) && currentValue < 72) {
                              field.onChange(String(currentValue + 1));
                            }
                          }}
                        >
                          <span className="text-lg">+</span>
                        </Button>
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {/* Reservation Type */}
              <FormField
                control={form.control}
                name="reservation_type"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("reservation_type")}</FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      disabled={isLoadingTypes}
                      value={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue
                            placeholder={t("select_reservation_type")}
                          />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {isLoadingTypes ? (
                          <div className="flex items-center justify-center p-2">
                            <Loader2 className="h-4 w-4 animate-spin" />
                          </div>
                        ) : (
                          reservationTypes?.map((type) => (
                            <SelectItem key={type.slug} value={type.slug}>
                              {type.name}
                            </SelectItem>
                          ))
                        )}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {/* Purpose */}
              <FormField
                control={form.control}
                name="purpose"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("detail.description_title")}</FormLabel>
                    <FormControl>
                      <Textarea
                        placeholder={t("purpose_placeholder")}
                        className="resize-none"
                        rows={4}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              {/* Estimated Delivery Date (Info) */}
              {estimatedDeliveryDate && (
                <Alert variant="info" className="-z-10">
                  <InfoIcon className="size-4" />
                  <AlertTitle className="text-info">
                    {t("estimated_drop_off_date")}
                  </AlertTitle>
                  <AlertDescription className="text-info">
                    {format(estimatedDeliveryDate, "PPP HH:mm", { locale })}
                  </AlertDescription>
                </Alert>
              )}

              {/* Consent Checkbox */}
              <FormField
                control={form.control}
                name="consent"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md border p-4">
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <div className="space-y-1 leading-none">
                      <FormLabel>
                        {t("consent_text")}{" "}
                        <button
                          type="button"
                          onClick={() => setShowVehicleContractDialog(true)}
                          className="hover:underline text-info underline-offset-4 decoration-info"
                        >
                          {t("documents.vehicle_contract")}
                        </button>
                        {", "}
                        <button
                          type="button"
                          onClick={() => setShowPrivacyDialog(true)}
                          className="hover:underline text-info underline-offset-4 decoration-info"
                        >
                          {t("documents.privacy_policy")}
                        </button>{" "}
                        {t("documents.and")}{" "}
                        <button
                          type="button"
                          onClick={() => setShowKvkkDialog(true)}
                          className="hover:underline text-info underline-offset-4 decoration-info"
                        >
                          {t("documents.kvkk_disclosure")}
                        </button>
                      </FormLabel>
                    </div>
                  </FormItem>
                )}
              />

              <DialogFooter>
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => setOpen(false)}
                >
                  {t("cancel")}
                </Button>
                <Button type="submit" disabled={isAddingReservation}>
                  {isAddingReservation ? t("creating") : t("create")}
                </Button>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>

      {/* Vehicle Contract Dialog */}
      <Dialog
        open={showVehicleContractDialog}
        onOpenChange={setShowVehicleContractDialog}
      >
        <DialogContent className="max-w-3xl max-h-[90vh] h-[90vh]">
          <DialogHeader className="mb-4">
            <DialogTitle>{t("documents.vehicle_contract")}</DialogTitle>
          </DialogHeader>
          <div className="flex-1 -mx-6 px-6 h-full">
            <iframe
              title={t("documents.vehicle_contract")}
              src={documentUrls.vehicleContract}
              className="w-full h-[calc(90vh-7rem)]"
              allow="autoplay"
            />
          </div>
        </DialogContent>
      </Dialog>

      {/* Privacy Policy Dialog */}
      <Dialog open={showPrivacyDialog} onOpenChange={setShowPrivacyDialog}>
        <DialogContent className="max-w-3xl max-h-[90vh] h-[90vh]">
          <DialogHeader className="mb-4">
            <DialogTitle>{t("documents.privacy_policy")}</DialogTitle>
          </DialogHeader>
          <div className="flex-1 -mx-6 px-6 h-full">
            <iframe
              title={t("documents.privacy_policy")}
              src={
                currentLanguage === "en"
                  ? documentUrls.privacyUrlEN
                  : documentUrls.privacyUrl
              }
              className="w-full h-[calc(90vh-7rem)]"
              allow="autoplay"
            />
          </div>
        </DialogContent>
      </Dialog>

      {/* KVKK Dialog */}
      <Dialog open={showKvkkDialog} onOpenChange={setShowKvkkDialog}>
        <DialogContent className="max-w-3xl max-h-[90vh] h-[90vh]">
          <DialogHeader className="mb-4">
            <DialogTitle>{t("documents.kvkk_disclosure")}</DialogTitle>
          </DialogHeader>
          <div className="flex-1 -mx-6 px-6 h-full">
            <iframe
              title={t("documents.kvkk_disclosure")}
              src={
                currentLanguage === "en"
                  ? documentUrls.kvkkLinkEN
                  : documentUrls.kvkkLink
              }
              className="w-full h-[calc(90vh-7rem)]"
              allow="autoplay"
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
