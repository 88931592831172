import { Button } from "@repo/ui/components/ui/Button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenuLabel,
  DropdownMenuSeparator,
} from "@repo/ui/components/ui/DropDownMenu";
import { LayoutGrid } from "lucide-react";
import PdfEditor from "@/assets/pdf-editor.svg?react";
import Bitrix from "@/assets/bitrix24.svg?react";
import Rmc from "@/assets/rmc.svg?react";
import Booking from "@/assets/booking.svg?react";

import { authService, env } from "@/main";
import { encrypt, useTranslation } from "@repo/config";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@repo/ui/components/ui/Tooltip";
import { useFirebaseQuery } from "@repo/provider";

interface AppItem {
  id: string;
  name: string;
  icon: React.ComponentType<{ className?: string }>;
  url: string;
  requiresAuth?: boolean;
}

export function AppSwitcher() {
  const { logEventMutation } = useFirebaseQuery();
  const { t } = useTranslation();
  const apps: AppItem[] = [
    {
      id: "bitrix",
      name: t("navbar.3rd_party_apps.bitrix"),
      icon: Bitrix,
      url: import.meta.env.VITE_BITRIX_APP_URL,
      requiresAuth: false,
    },
    {
      id: "pdf_editor",
      name: t("navbar.3rd_party_apps.pdf_editor"),
      icon: PdfEditor,
      url: import.meta.env.VITE_PDF_EDITOR_APP_URL,
      requiresAuth: false,
    },
    {
      id: "booking",
      name: t("navbar.3rd_party_apps.booking"),
      icon: Booking,
      url: import.meta.env.VITE_BOOKING_APP_URL,
      requiresAuth: false,
    },
    {
      id: "rmc",
      name: t("navbar.3rd_party_apps.rmc"),
      icon: Rmc,
      url: import.meta.env.VITE_RMC_APP_URL,
      requiresAuth: false,
    },
  ];

  const handleAppClick = (app: AppItem) => {
    logEventMutation.mutate({
      eventName: "app_switcher_clicked",
      eventParams: {
        app_id: app.id,
      },
    });

    if (app.requiresAuth) {
      const token = authService.getAccessToken();
      if (!token) return;

      // 1. Encrypt the token
      const encryptedToken = encrypt(token, env.ENCRYPTION_KEY);

      // 2. URL encode the encrypted token
      const encodedToken = encodeURIComponent(encryptedToken);

      // 3. Open the app with the encoded token
      window.open(`${app.url}/?code=${encodedToken}`, "_blank");
    } else {
      window.open(app.url, "_blank");
    }
  };

  return (
    <DropdownMenu>
      <Tooltip>
        <TooltipTrigger asChild>
          <DropdownMenuTrigger asChild>
            <Button
              className="size-7 md:size-8 transition-colors"
              variant="outline"
              size="icon"
            >
              <LayoutGrid className="h-5 w-5" />
            </Button>
          </DropdownMenuTrigger>
        </TooltipTrigger>
        <TooltipContent>
          <p>{t("navbar.app_switcher.title")}</p>
        </TooltipContent>
      </Tooltip>

      <DropdownMenuContent
        align="end"
        className="w-[280px] p-0 bg-card shadow-lg border border-border"
      >
        <DropdownMenuLabel className="p-4 text-sm font-medium bg-card">
          <h4 className="mb-1 font-semibold text-card-foreground">
            {t("navbar.app_switcher.title")}
          </h4>
          <p className="text-xs text-muted-foreground">
            {t("navbar.app_switcher.description")}
          </p>
        </DropdownMenuLabel>
        <DropdownMenuSeparator className="bg-border" />
        <div className="grid grid-cols-3 gap-1 bg-card">
          {apps.map((app) => (
            <DropdownMenuItem
              key={app.id}
              title={app.name}
              onClick={() => handleAppClick(app)}
              className="group relative flex aspect-square flex-col items-center justify-center rounded-md p-2.5 cursor-pointer transition-all duration-200 hover:bg-accent/50 focus:bg-accent/50 data-[highlighted]:bg-accent/50 [&_svg]:size-11"
            >
              <div className="flex flex-col items-center gap-2 h-full w-full">
                <app.icon className="w-full h-full text-primary/80 transition-all duration-200 group-hover:scale-110 group-hover:text-primary " />
                <span className="text-xs truncate font-medium text-center text-muted-foreground transition-colors duration-200 group-hover:text-card-foreground">
                  {app.name}
                </span>
              </div>
            </DropdownMenuItem>
          ))}
        </div>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
