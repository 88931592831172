import useEmblaCarousel from "embla-carousel-react";
import { useState, useCallback, useEffect, useRef } from "react";
import { Button } from "./Button";
import { ChevronUp, ChevronDown } from "lucide-react";
import { cn } from "../../lib/utils";
import { ScrollArea } from "./ScrollArea";

/**
 * ImageSlider component for displaying a collection of images with carousel functionality.
 * Features:
 * - Vertical scrolling with mouse wheel
 * - Thumbnail navigation
 * - Progress indicator
 * - Keyboard navigation support
 * - Touch/drag support
 */

/**
 * Props interface for the ImageSlider component
 * @property {Array} images - Array of image objects containing src and alt text
 */
interface ImageSliderProps {
  images: Array<{
    src: string;
    alt: string;
    id: number;
  }>;
}

export function ImageSlider({ images }: Readonly<ImageSliderProps>) {
  // State for tracking the currently selected image index and scroll progress
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollProgress, setScrollProgress] = useState(0);
  const mainViewportRef = useRef<HTMLDivElement>(null);

  // Initialize main carousel with vertical scrolling and custom settings
  const [emblaMainRef, mainEmblaApi] = useEmblaCarousel({
    axis: "y",
    align: "center",
    containScroll: "trimSnaps",
    watchDrag: true,
    skipSnaps: true,
  });

  // Initialize thumbnail carousel with vertical scrolling and snap points
  const [thumbViewportRef, thumbEmblaApi] = useEmblaCarousel({
    axis: "y",
    dragFree: true,
    containScroll: "keepSnaps",
  });

  // Handle thumbnail click to scroll main carousel to selected image
  const onThumbClick = useCallback(
    (index: number) => {
      if (!mainEmblaApi || !thumbEmblaApi) return;
      mainEmblaApi.scrollTo(index);
    },
    [mainEmblaApi, thumbEmblaApi],
  );

  // Update selected index and thumbnail position when main carousel changes
  const onSelect = useCallback(() => {
    if (!mainEmblaApi || !thumbEmblaApi) return;
    const currentIndex = mainEmblaApi.selectedScrollSnap();
    setSelectedIndex(currentIndex);
    thumbEmblaApi.scrollTo(currentIndex);

    const progress =
      Math.max(0, Math.min(1, currentIndex / (images.length - 1))) * 100;
    setScrollProgress(progress);
  }, [mainEmblaApi, thumbEmblaApi, images.length]);

  // Handle mouse wheel events for scrolling through images
  useEffect(() => {
    const viewport = mainViewportRef.current;
    if (!viewport || !mainEmblaApi) return;

    const onWheel = (event: WheelEvent) => {
      event.preventDefault();
      const delta = Math.sign(event.deltaY);

      if (delta > 0) {
        mainEmblaApi.scrollNext();
      } else if (delta < 0) {
        mainEmblaApi.scrollPrev();
      }
    };

    viewport.addEventListener("wheel", onWheel, { passive: false });
    return () => viewport.removeEventListener("wheel", onWheel);
  }, [mainEmblaApi]);

  // Setup event listeners for carousel selection and scroll progress
  useEffect(() => {
    if (!mainEmblaApi) return;
    onSelect();
    mainEmblaApi.on("select", onSelect);
    mainEmblaApi.on("scroll", () => {
      const progress =
        Math.max(0, Math.min(1, mainEmblaApi.scrollProgress())) * 100;
      setScrollProgress(progress);
    });
    return () => {
      mainEmblaApi.off("select", onSelect);
    };
  }, [mainEmblaApi, onSelect]);

  // Navigation helper functions
  const scrollPrev = useCallback(() => {
    if (mainEmblaApi) mainEmblaApi.scrollPrev();
  }, [mainEmblaApi]);

  const scrollNext = useCallback(() => {
    if (mainEmblaApi) mainEmblaApi.scrollNext();
  }, [mainEmblaApi]);

  return (
    <div className="flex gap-4 relative">
      {/* Thumbnails */}
      <ScrollArea className="invisible w-0  md:visible md:w-24 h-[calc(100dvh-14rem)]">
        <div ref={thumbViewportRef} className="h-full">
          <div className="flex flex-col gap-2 p-1">
            {images.map((image) => (
              <button
                key={image.id}
                onClick={() => onThumbClick(image.id)}
                className={cn(
                  "relative aspect-[4/3] w-full shrink-0 rounded-sm overflow-hidden transition-all",
                  selectedIndex === image.id
                    ? "ring-2 ring-primary ring-offset-2"
                    : "opacity-60 hover:opacity-100",
                )}
                role="tab"
                aria-label={`Show image ${image.id + 1}`}
                aria-selected={selectedIndex === image.id}
                tabIndex={0}
              >
                <img
                  src={image.src}
                  alt={image.alt}
                  className="w-full h-full object-cover"
                />
              </button>
            ))}
          </div>
        </div>
      </ScrollArea>

      {/* Main Viewer */}
      <div className="flex-1 relative pr-4">
        <div className="absolute inset-0">
          <div ref={mainViewportRef} className="overflow-hidden h-full">
            <div ref={emblaMainRef} className="h-full">
              <div className="flex flex-col h-full">
                {images.map((image) => (
                  <div
                    key={image.id}
                    className="relative flex-[0_0_100%] h-full flex items-center justify-center"
                  >
                    <img
                      src={image.src}
                      alt={image.alt}
                      className="max-h-full w-auto object-contain"
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Controls */}
      <div className="absolute right-0 xl:right-4 inset-y-12 flex flex-col items-center gap-4 pointer-events-none">
        {/* Up Arrow */}
        <Button
          variant="outline"
          size="icon"
          className="pointer-events-auto rounded-full shadow-md"
          onClick={scrollPrev}
        >
          <ChevronUp className="h-6 w-6" />
        </Button>

        {/* Progress */}
        <div className="flex-1 flex items-center">
          <div className="relative h-full flex items-center justify-center">
            {/* Progress Track */}
            <div className="h-full w-[2px] rounded-full bg-muted/30" />

            {/* Progress Fill */}
            <div
              className="absolute top-0 left-0 w-[2px] rounded-full bg-primary will-change-[height] transition-[height] duration-200 ease-out"
              style={{ height: `${scrollProgress}%` }}
            />

            {/* Counter */}
            <span className="absolute -right-6 top-1/2 -translate-y-1/2 text-sm font-medium bg-background/80 px-2 py-1 rounded-full min-w-10 text-center">
              {selectedIndex + 1}/{images.length}
            </span>
          </div>
        </div>

        {/* Down Arrow */}
        <Button
          variant="outline"
          size="icon"
          className="pointer-events-auto rounded-full shadow-md"
          onClick={scrollNext}
        >
          <ChevronDown className="h-6 w-6" />
        </Button>
      </div>
    </div>
  );
}
