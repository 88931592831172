import { useOrderStore } from "@/store/order";
import {
  MapContainer,
  Popup,
  TileLayer,
  ZoomControl,
  useMap,
  GeoJSON,
  Marker,
} from "react-leaflet";
import { Card, CardContent } from "@repo/ui/components/ui/Card";
import { Badge } from "@repo/ui/components/ui/Badge";
import { MapPin, Calendar, Route } from "lucide-react";
import { useEffect, useMemo, useState, useRef } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { useTranslation, formatDateTime } from "@repo/config";
import { FullscreenControl } from "@repo/ui/components/map/FullscreenControl";
import {
  calculateMapView,
  DEFAULT_TILE_LAYER_OPTIONS,
} from "../utils/mapUtils";
import { renderToStaticMarkup } from "react-dom/server";

// Custom marker icon component
function MarkerIcon({ color }: Readonly<{ color: string }>) {
  return (
    <div
      className={`size-8 rounded-full ${color} flex items-center justify-center border-2 border-white shadow-md animate-marker-attention`}
    >
      <MapPin className="size-4 text-white" />
    </div>
  );
}

// Create marker icons with pulse animation class
const createAnimatedMarkerIcon = (color: string) =>
  L.divIcon({
    className: "custom-marker-icon",
    html: renderToStaticMarkup(<MarkerIcon color={color} />),
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });

// Custom marker component for load location
function LoadLocationMarker({
  location,
  address,
  loadingDate,
}: Readonly<{
  location: GeoJSON.FeatureCollection;
  address: string;
  loadingDate?: string;
}>) {
  const map = useMap();
  const { t } = useTranslation("orders");
  const [popupReady, setPopupReady] = useState(false);
  const popupRef = useRef<L.Popup | null>(null);
  const markerRef = useRef<L.Marker | null>(null);

  useEffect(() => {
    if (popupReady && markerRef.current && popupRef.current) {
      markerRef.current.openPopup();
    }
  }, [popupReady, map]);

  if (location.features?.[0]?.geometry?.type !== "Point") return null;

  const point = location.features[0].geometry;
  const position: L.LatLngExpression = [
    point.coordinates[1],
    point.coordinates[0],
  ];

  return (
    <Marker
      position={position}
      icon={createAnimatedMarkerIcon("bg-success")}
      ref={markerRef}
    >
      <Popup
        ref={(r) => {
          if (r) {
            popupRef.current = r;
            setPopupReady(true);
          }
        }}
        className="custom-popup"
      >
        <div className="min-w-[250px] -mx-[12px] -my-[5px]">
          <div className="p-3 border-b">
            <div className="flex items-center gap-2 dark:text-foreground">
              <MapPin className="size-4 text-success" />
              <h3 className="font-medium">{t("order.detail.load_address")}</h3>
            </div>
          </div>
          <div className="p-3 space-y-3">
            <p className="text-sm dark:text-foreground">{address}</p>
            {loadingDate && (
              <div className="flex items-center gap-2 text-xs text-muted-foreground pt-2 border-t">
                <Badge
                  variant="outline"
                  className="flex gap-1 bg-muted text-muted-foreground"
                >
                  <Calendar className="size-3" />
                  {t("order.detail.loading_date")}
                </Badge>
                <span>{formatDateTime(loadingDate)}</span>
              </div>
            )}
          </div>
        </div>
      </Popup>
    </Marker>
  );
}

export default function OrderDetailContentRouteMapCard() {
  const { selectedOrder } = useOrderStore();
  const { t } = useTranslation("orders");

  // Check if locations exist
  const hasLoadLocation =
    selectedOrder?.load_address?.location?.features?.[0]?.geometry?.type ===
    "Point"
      ? selectedOrder.load_address?.location?.features?.[0]?.geometry
          ?.coordinates
      : null;

  const hasUnloadLocation =
    selectedOrder?.unload_address?.location?.features?.[0]?.geometry?.type ===
    "Point"
      ? selectedOrder.unload_address?.location?.features?.[0]?.geometry
          ?.coordinates
      : null;

  // Calculate map view configuration
  const { bounds, center, zoom } = useMemo(
    () =>
      calculateMapView(
        hasLoadLocation
          ? {
              lat: hasLoadLocation[1],
              lng: hasLoadLocation[0],
            }
          : { lat: 0, lng: 0 },
        hasUnloadLocation
          ? {
              lat: hasUnloadLocation[1],
              lng: hasUnloadLocation[0],
            }
          : { lat: 0, lng: 0 },
      ),
    [hasLoadLocation, hasUnloadLocation],
  );

  // If no order selected, show message
  if (!selectedOrder) {
    return (
      <Card className="w-full 2xl:col-span-2">
        <CardContent className="p-6 flex items-center justify-center min-h-[400px]">
          <p className="text-muted-foreground text-sm">
            {t("order.detail.route_map.no_order")}
          </p>
        </CardContent>
      </Card>
    );
  }

  // If no locations, show message
  if (!hasLoadLocation || !hasUnloadLocation) {
    return (
      <Card className="w-full 2xl:col-span-2">
        <CardContent className="p-6 flex items-center justify-center min-h-[400px]">
          <p className="text-muted-foreground text-sm">
            {t("order.detail.route_map.no_location")}
          </p>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card className="w-full 2xl:col-span-2">
      <CardContent className="p-0 relative">
        {/* Route Info Header */}
        <div className="sticky top-0 left-0 w-full z-10">
          <div className="flex items-center gap-4 text-secondary pl-6 py-4">
            <div className="flex items-center gap-2 shrink-0">
              <Route className="size-5" />
              <h3 className="text-sm font-medium">
                {t("order.detail.route_map.route_info.title")}
              </h3>
            </div>
            <span className="h-px w-full bg-secondary/50" />
          </div>
        </div>
        <MapContainer
          center={center}
          zoom={zoom}
          scrollWheelZoom={true}
          zoomControl={false}
          className="min-h-[360px] h-[calc(100svh-33rem)] w-full rounded-lg z-0"
        >
          <TileLayer {...DEFAULT_TILE_LAYER_OPTIONS} />

          {/* Load Location Marker */}
          {selectedOrder.load_address.location && (
            <LoadLocationMarker
              location={
                selectedOrder.load_address.location as GeoJSON.FeatureCollection
              }
              address={selectedOrder.load_address.full_address}
              loadingDate={selectedOrder.loading_date}
            />
          )}

          {/* Unload Location GeoJSON */}
          {selectedOrder.unload_address.location && (
            <GeoJSON
              data={
                selectedOrder.unload_address
                  .location as GeoJSON.FeatureCollection
              }
              pointToLayer={(_, latlng) => {
                return L.marker(latlng, {
                  icon: createAnimatedMarkerIcon("bg-info"),
                });
              }}
            >
              <Popup className="custom-popup">
                <div className="min-w-[250px] -mx-3 -my-[5px]">
                  <div className="p-3 border-b">
                    <div className="flex items-center gap-2 dark:text-foreground">
                      <MapPin className="size-4 text-info" />
                      <h3 className="font-medium">
                        {t("order.detail.unload_address")}
                      </h3>
                    </div>
                  </div>
                  <div className="p-3 dark:text-foreground">
                    <p className="text-sm">
                      {selectedOrder.unload_address.full_address}
                    </p>
                  </div>
                </div>
              </Popup>
            </GeoJSON>
          )}

          <MapController bounds={bounds} zoom={zoom} />
          <ZoomControl position="bottomright" />
          <FullscreenControl position="topright" />
        </MapContainer>
      </CardContent>
    </Card>
  );
}

// Map controller component for handling view updates
function MapController({
  bounds,
  zoom,
}: {
  bounds: L.LatLngBounds;
  zoom: number;
}) {
  const map = useMap();

  useEffect(() => {
    // Ensure the map fits the bounds of both markers
    map.fitBounds(bounds, {
      padding: [50, 50],
      maxZoom: zoom,
      animate: true,
      duration: 0.5,
    });

    // Add a small delay and fit bounds again to handle any layout changes
    const timer = setTimeout(() => {
      map.invalidateSize();
      map.fitBounds(bounds, {
        padding: [50, 50],
        maxZoom: zoom,
        animate: true,
        duration: 0.5,
      });
    }, 100);

    return () => clearTimeout(timer);
  }, [map, bounds, zoom]);

  return null;
}
