import { useTranslation } from "@repo/config";
import { Card, CardContent } from "@repo/ui/components/ui/Card";
import { GetReservationDetailResponse } from "@repo/service";
import { showReservationStatusOverlay } from "../utils/reservationManagementHelper";
import ReservationsDetailContentManagementStatusOverlay from "./ReservationDetailContentManagementStatusOverlay";
import ReservationDetailContentEmployeeContent from "./ReservationDetailContentEmployeeContent";
import ReservationDetailContentSecurityContent from "./ReservationDetailContentSecurityContent";
import { usePermission } from "@repo/provider";

interface ReservationsDetailContentManagementProps {
  reservation: {
    reservation_id: string;
    start_time: string;
    action_id: string;
    action_status_slug: string;
  };
  selectedReservation: GetReservationDetailResponse;
}

export default function ReservationsDetailContentManagement({
  reservation,
  selectedReservation,
}: Readonly<ReservationsDetailContentManagementProps>) {
  const { t } = useTranslation("reservations");
  const { hasPermission } = usePermission();

  // Check if user is security
  const isSecurity = hasPermission("track_reservation");

  const isSelectedUser = hasPermission("add_vehicle_reservation");

  const reservationVehicle = selectedReservation.reservation.vehicle;

  // Check if we should show the status overlay
  const shouldShowOverlay = showReservationStatusOverlay(
    selectedReservation,
    isSecurity,
    isSelectedUser,
    reservation.action_status_slug,
  );

  return (
    <Card className="flex flex-col">
      <CardContent className="flex flex-col gap-4 pt-6 pb-4 px-0">
        {/* Header */}
        <div className="flex items-center justify-center space-x-4">
          <span className="h-[1px] bg-secondary/50 flex-1" />
          <div className="flex items-center gap-2 whitespace-nowrap text-muted-foreground">
            <p className="text-base text-secondary font-medium">
              {t("detail.management_info")}
            </p>
          </div>
          <span className="h-[1px] bg-secondary/50 flex-1" />
        </div>

        {/* Body */}
        <div className="grid grid-cols-2 gap-4 px-6">
          {shouldShowOverlay ? (
            // Show status overlay when reservation is rejected, vehicle has left, or vehicle has entered
            <div className="col-span-2 flex justify-center items-center">
              <ReservationsDetailContentManagementStatusOverlay
                reservation={selectedReservation}
                isSelectedUser={isSelectedUser}
                isSecurity={isSecurity}
              />
            </div>
          ) : (
            <>
              {!isSecurity ? (
                <div className="col-span-2 flex justify-center">
                  <div className="w-full flex flex-row justify-center items-center gap-4">
                    <ReservationDetailContentEmployeeContent
                      reservation_id={reservation.reservation_id}
                      action_id={reservation.action_id}
                      action_status_slug={reservation.action_status_slug}
                      reservationVehicle={reservationVehicle}
                    />
                  </div>
                </div>
              ) : (
                <div className="col-span-2 flex justify-center">
                  <div className="w-full flex flex-row justify-center items-center gap-4">
                    <ReservationDetailContentSecurityContent
                      reservation={selectedReservation}
                    />
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </CardContent>
    </Card>
  );
}
