import {
  Card,
  CardTitle,
  CardHeader,
  CardContent,
} from "@repo/ui/components/ui/Card";
import { useExpeditionTimes } from "@/features/reports/hooks/useExpeditionTimes";
import { useExpeditionColumns } from "./ExpeditionTimesTableColumns.tsx";
import { DataTable } from "@repo/ui/components/table/DataTable";
import { useCallback, useMemo, useTransition, useState } from "react";
import { useQueryStates, parseAsInteger, parseAsString } from "nuqs";
import {
  useTranslation,
  useCurrentLanguage,
  formatDateSafe,
  subWeeks,
  DateRange,
} from "@repo/config";
import { AlertCircle } from "lucide-react";
import {
  DEFAULT_PAGE_SIZE,
  TABLE_PAGE_SIZE_OPTIONS,
} from "../utils/constants.ts";
import { ExpeditionTime, ExpeditionTimeFilters } from "@repo/service";
import { ExpeditionTimesTableFilters } from "./ExpeditionTimesTableFilters.tsx";
import { TableTranslations } from "@repo/ui/components/table/types.ts";

// Query Parameters
const EXPEDITION_TIMES_QUERY_PARAMS = {
  PAGE: "reports_expedition_page",
  SIZE: "reports_expedition_size",
  SEARCH: "reports_expedition_search",
  ORDERING: "reports_expedition_ordering",
  TYPE: "reports_expedition_type",
  STATUS: "reports_expedition_status",
  DATE_START: "reports_expedition_date_start",
  DATE_END: "reports_expedition_date_end",
} as const;

export default function ExpeditionTimesTable() {
  const { t } = useTranslation("reports");
  const currentLanguage = useCurrentLanguage();
  const columns = useExpeditionColumns();
  const [isTransitionPending, startTransition] = useTransition();

  const [queryParams, setQueryParams] = useQueryStates(
    {
      [EXPEDITION_TIMES_QUERY_PARAMS.PAGE]: parseAsInteger.withDefault(0),
      [EXPEDITION_TIMES_QUERY_PARAMS.SIZE]:
        parseAsInteger.withDefault(DEFAULT_PAGE_SIZE),
      [EXPEDITION_TIMES_QUERY_PARAMS.SEARCH]: parseAsString.withDefault(""),
      [EXPEDITION_TIMES_QUERY_PARAMS.ORDERING]:
        parseAsString.withDefault("-end_date"),
      [EXPEDITION_TIMES_QUERY_PARAMS.TYPE]: parseAsString.withDefault(""),
      [EXPEDITION_TIMES_QUERY_PARAMS.STATUS]: parseAsString.withDefault(""),
      [EXPEDITION_TIMES_QUERY_PARAMS.DATE_START]: parseAsString.withDefault(
        formatDateSafe(subWeeks(new Date(), 1)),
      ),
      [EXPEDITION_TIMES_QUERY_PARAMS.DATE_END]: parseAsString.withDefault(
        formatDateSafe(new Date()),
      ),
    },
    {
      history: "push",
      shallow: true,
      throttleMs: 100,
    },
  );

  // Initialize dateRange from URL or default values
  const [dateRange, setDateRange] = useState<DateRange>(() => {
    const startDate = queryParams[EXPEDITION_TIMES_QUERY_PARAMS.DATE_START];
    const endDate = queryParams[EXPEDITION_TIMES_QUERY_PARAMS.DATE_END];

    return {
      from: startDate ? new Date(startDate) : subWeeks(new Date(), 1),
      to: endDate ? new Date(endDate) : new Date(),
    };
  });

  const apiParams = useMemo(
    () => ({
      page: queryParams[EXPEDITION_TIMES_QUERY_PARAMS.PAGE] + 1,
      limit: queryParams[EXPEDITION_TIMES_QUERY_PARAMS.SIZE],
      language: currentLanguage,
      filters: {
        search: queryParams[EXPEDITION_TIMES_QUERY_PARAMS.SEARCH] || undefined,
        ordering:
          queryParams[EXPEDITION_TIMES_QUERY_PARAMS.ORDERING] || undefined,
        expedition_type: queryParams[EXPEDITION_TIMES_QUERY_PARAMS.TYPE],
        is_completed: queryParams[EXPEDITION_TIMES_QUERY_PARAMS.STATUS],
        end_date__gte: queryParams[EXPEDITION_TIMES_QUERY_PARAMS.DATE_START],
        end_date__lte: queryParams[EXPEDITION_TIMES_QUERY_PARAMS.DATE_END],
      },
    }),
    [queryParams, currentLanguage],
  );

  const {
    data: expeditionTimes,
    error,
    isPending: isQueryPending,
    refetch,
  } = useExpeditionTimes(apiParams);

  const handleSearchChange = useCallback(
    (value: string) => {
      startTransition(() => {
        setQueryParams({
          [EXPEDITION_TIMES_QUERY_PARAMS.SEARCH]: value,
          [EXPEDITION_TIMES_QUERY_PARAMS.PAGE]: 0,
        });
      });
    },
    [setQueryParams],
  );

  const handlePageChange = useCallback(
    (newPage: number) => {
      startTransition(() => {
        setQueryParams({
          [EXPEDITION_TIMES_QUERY_PARAMS.PAGE]: newPage,
        });
      });
    },
    [setQueryParams],
  );

  const handlePageSizeChange = useCallback(
    (newPageSize: number) => {
      startTransition(() => {
        setQueryParams({
          [EXPEDITION_TIMES_QUERY_PARAMS.SIZE]: newPageSize,
          [EXPEDITION_TIMES_QUERY_PARAMS.PAGE]: 0,
        });
      });
    },
    [setQueryParams],
  );

  const handleOrderingChange = useCallback(
    (value: ExpeditionTimeFilters["ordering"]) => {
      startTransition(() => {
        setQueryParams(
          { [EXPEDITION_TIMES_QUERY_PARAMS.ORDERING]: value },
          { history: "push", shallow: true },
        );
      });
    },
    [setQueryParams],
  );

  const handleExpeditionTypeChange = useCallback(
    (type: string | undefined) => {
      startTransition(() => {
        setQueryParams(
          {
            [EXPEDITION_TIMES_QUERY_PARAMS.TYPE]: type,
            [EXPEDITION_TIMES_QUERY_PARAMS.PAGE]: 0,
          },
          {
            history: "push",
            shallow: true,
          },
        ).catch((error) => {
          console.error("Error updating expedition type:", error);
        });
      });
    },
    [setQueryParams],
  );

  const handleIsCompletedChange = useCallback(
    (value: string | undefined) => {
      startTransition(() => {
        setQueryParams(
          {
            [EXPEDITION_TIMES_QUERY_PARAMS.STATUS]: value,
            [EXPEDITION_TIMES_QUERY_PARAMS.PAGE]: 0,
          },
          {
            history: "push",
            shallow: true,
          },
        ).catch((error) => {
          console.error("Error updating completion status:", error);
        });
      });
    },
    [setQueryParams],
  );

  const handleDateRangeChange = useCallback(
    (range: DateRange | undefined) => {
      // if no date is selected, return to default dates
      if (!range?.from && !range?.to) {
        const defaultFrom = subWeeks(new Date(), 1);
        const defaultTo = new Date();

        setDateRange({
          from: defaultFrom,
          to: defaultTo,
        });

        startTransition(() => {
          setQueryParams({
            [EXPEDITION_TIMES_QUERY_PARAMS.DATE_START]:
              formatDateSafe(defaultFrom),
            [EXPEDITION_TIMES_QUERY_PARAMS.DATE_END]: formatDateSafe(defaultTo),
            [EXPEDITION_TIMES_QUERY_PARAMS.PAGE]: 0,
          });
        });
        return;
      }

      // if at least one date is selected, update the date range
      setDateRange(range);

      const updates = {
        [EXPEDITION_TIMES_QUERY_PARAMS.PAGE]: 0,
      } as Record<string, string | number>;

      if (range.from) {
        updates[EXPEDITION_TIMES_QUERY_PARAMS.DATE_START] = formatDateSafe(
          range.from,
        );
      }
      if (range.to) {
        updates[EXPEDITION_TIMES_QUERY_PARAMS.DATE_END] = formatDateSafe(
          range.to,
        );
      }

      startTransition(() => {
        setQueryParams((prev) => ({
          ...prev,
          ...updates,
        }));
      });
    },
    [setQueryParams],
  );

  const handleRefetch = useCallback(() => {
    startTransition(() => {
      refetch();
    });
  }, [refetch]);

  const isLoadingState = isQueryPending || isTransitionPending;
  const totalPageCount = useMemo(
    () =>
      expeditionTimes
        ? Math.ceil(
            expeditionTimes.count /
              queryParams[EXPEDITION_TIMES_QUERY_PARAMS.SIZE],
          )
        : 0,
    [expeditionTimes?.count, queryParams[EXPEDITION_TIMES_QUERY_PARAMS.SIZE]],
  );

  if (error) {
    return (
      <Card className="space-y-4 col-span-2">
        <CardHeader>
          <CardTitle>{t("expedition.times.title")}</CardTitle>
        </CardHeader>
        <CardContent>
          <div className="flex flex-col items-center justify-center py-8">
            <AlertCircle className="h-12 w-12 text-destructive mb-3" />
            <p className="text-lg font-medium text-destructive">
              {t("error.expedition.times.fetch_title")}
            </p>
          </div>
        </CardContent>
      </Card>
    );
  }

  const translations: TableTranslations = {
    loading: {
      data: t("loading.data"),
    },
    table: {
      no_records: t("table.no_records"),
      page_size: t("table.page_size"),
      page: t("table.page"),
      first_page: t("table.first_page"),
      previous_page: t("table.previous_page"),
      next_page: t("table.next_page"),
      last_page: t("table.last_page"),
      refresh: t("table.refresh"),
    },
    pagination: {
      total_records: t("pagination.total_records"),
      of: t("pagination.of"),
    },
  };

  return (
    <Card className="space-y-2 col-span-2">
      <CardHeader>
        <CardTitle>{t("expedition.times.title")}</CardTitle>
      </CardHeader>
      <CardContent>
        <DataTable<ExpeditionTime>
          columns={columns}
          data={expeditionTimes?.results ?? []}
          pageCount={totalPageCount}
          pageIndex={queryParams[EXPEDITION_TIMES_QUERY_PARAMS.PAGE]}
          pageSize={queryParams[EXPEDITION_TIMES_QUERY_PARAMS.SIZE]}
          onPageChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          onRefetch={handleRefetch}
          isPending={isLoadingState}
          totalRecords={expeditionTimes?.count ?? 0}
          translations={translations}
          TABLE_PAGE_SIZE_OPTIONS={TABLE_PAGE_SIZE_OPTIONS}
          filterComponent={
            <ExpeditionTimesTableFilters
              searchValue={
                queryParams[EXPEDITION_TIMES_QUERY_PARAMS.SEARCH] ?? ""
              }
              onSearchChange={handleSearchChange}
              expeditionType={queryParams[EXPEDITION_TIMES_QUERY_PARAMS.TYPE]}
              onExpeditionTypeChange={handleExpeditionTypeChange}
              isCompleted={queryParams[EXPEDITION_TIMES_QUERY_PARAMS.STATUS]}
              onIsCompletedChange={handleIsCompletedChange}
              dateRange={dateRange}
              onDateRangeChange={handleDateRangeChange}
              ordering={queryParams[EXPEDITION_TIMES_QUERY_PARAMS.ORDERING]}
              onOrderingChange={handleOrderingChange}
            />
          }
        />
      </CardContent>
    </Card>
  );
}
