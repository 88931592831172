import { trace } from "firebase/performance";
import type {
  FirebasePerformance,
  PerformanceTrace,
} from "@firebase/performance-types";
import { IPerformanceService } from "../../types/firebase.types";
import { FirebaseService } from "../../infrastructure/firebase/firebase.service";

export class PerformanceService implements IPerformanceService {
  private readonly performance: any = null;
  private readonly traces: Map<string, PerformanceTrace> = new Map();

  constructor(firebaseService: FirebaseService) {
    this.performance = firebaseService.getService("performance");
  }

  public startTrace(traceName: string): void {
    if (this.performance) {
      const newTrace = trace(this.performance, traceName);
      this.traces.set(traceName, newTrace);
      newTrace.start();
    }
  }

  public stopTrace(traceName: string): void {
    const currentTrace = this.traces.get(traceName);
    if (currentTrace) {
      currentTrace.stop();
      this.traces.delete(traceName);
    }
  }

  public putTraceAttribute(
    traceName: string,
    attribute: string,
    value: string,
  ): void {
    const trace = this.traces.get(traceName);
    if (trace) {
      trace.putAttribute(attribute, value);
    }
  }

  public getTrace(traceName: string): PerformanceTrace | undefined {
    return this.traces.get(traceName);
  }

  public getInstance(): FirebasePerformance | null {
    return this.performance;
  }
}

export const createPerformanceService = (
  firebaseService: FirebaseService,
): PerformanceService => {
  return new PerformanceService(firebaseService);
};
