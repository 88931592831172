import { CompanyServicesResponse } from "./types";
import type { ApiClient } from "../../infrastructure/apiClient";

export class CompanyService {
  constructor(private readonly apiClient: ApiClient) {}

  /**
   * Get company services
   * @param language - The language for the response
   * @returns Promise<CompanyServicesResponse>
   */
  async getCompanyServices(language: string): Promise<CompanyServicesResponse> {
    const ENDPOINT = `${language}/companies/services`;
    return this.apiClient.get<CompanyServicesResponse>(ENDPOINT);
  }
}

export const createCompanyService = (apiClient: ApiClient) =>
  new CompanyService(apiClient);
