import { useState } from "react";
import { GetReservationDetailResponse } from "@repo/service";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@repo/ui/components/ui/Card";
import { Button } from "@repo/ui/components/ui/Button";
import { useTranslation } from "@repo/config";
import { ChoosenVehicleCard } from "./ReservationDetailContentChoosenVehicleCard";
import { useReservationQuery } from "../hooks/useReservation";
import { toastManager } from "@repo/ui/utils";
import { LogIn, LogOut } from "lucide-react";
import {
  isVehicleHasEntered,
  isVehicleHasLeft,
  isVehicleWaitingExit,
} from "../utils/reservationManagementHelper";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@repo/ui/components/ui/Dialog";
import { Input } from "@repo/ui/components/ui/Input";
import { Label } from "@repo/ui/components/ui/Label";

interface ReservationDetailContentEnterExitCardProps {
  reservation: GetReservationDetailResponse;
}

export default function ReservationDetailContentEnterExitCard({
  reservation,
}: Readonly<ReservationDetailContentEnterExitCardProps>) {
  const { t } = useTranslation("reservations");
  const [isEnterDialogOpen, setIsEnterDialogOpen] = useState(false);
  const [isExitDialogOpen, setIsExitDialogOpen] = useState(false);
  const [kmValue, setKmValue] = useState("");

  const { useEnterReservation, useExitReservation } = useReservationQuery();

  const enterMutation = useEnterReservation({
    reservation_id: reservation.id,
    reservation_no: reservation.reservation.reservation_no,
    km: 0,
  });

  const exitMutation = useExitReservation({
    reservation_id: reservation.id,
    reservation_no: reservation.reservation.reservation_no,
    km: 0,
  });

  const handleEnter = async () => {
    if (!kmValue || isNaN(Number(kmValue))) {
      toastManager.error(t("error.invalid_km"));
      return;
    }

    try {
      await enterMutation.mutateAsync({
        reservation_no: reservation.reservation.reservation_no,
        km: Number(kmValue),
      });

      setIsEnterDialogOpen(false);
      setKmValue("");
      toastManager.success(t("success.enter"));
    } catch (error) {
      toastManager.error(t("error.enter"));
    }
  };

  const handleExit = async () => {
    if (!kmValue || isNaN(Number(kmValue))) {
      toastManager.error(t("error.invalid_km"));
      return;
    }

    try {
      await exitMutation.mutateAsync({
        reservation_no: reservation.reservation.reservation_no,
        km: Number(kmValue),
      });

      setIsExitDialogOpen(false);
      setKmValue("");
      toastManager.success(t("success.exit"));
    } catch (error) {
      toastManager.error(t("error.exit"));
    }
  };

  // Determine which buttons to show based on vehicle status
  const showEnterButton =
    !isVehicleHasEntered(reservation) && isVehicleHasLeft(reservation);
  const showExitButton = isVehicleWaitingExit(reservation);

  // If no buttons to show, don't render the card
  if (!showEnterButton && !showExitButton) {
    return null;
  }

  return (
    <>
      <Card className="flex flex-col min-w-[350px] min-h-[250px]">
        <CardHeader className="px-4 pt-5 pb-2">
          <CardTitle className="text-sm md:text-base">
            {t("security_information_title")}
          </CardTitle>
        </CardHeader>

        <CardContent className="flex flex-col gap-4 px-4 flex-1">
          {/* Vehicle Information */}
          {reservation.reservation.vehicle && (
            <ChoosenVehicleCard
              vehicle={{
                vehicle: reservation.reservation.vehicle.plate,
                brand: reservation.reservation.vehicle.brand ?? "",
                model: reservation.reservation.vehicle.model ?? "",
                id: "",
                available_status: false,
                next_available_time: null,
                has_reservation: true,
              }}
            />
          )}

          {/* Action Buttons */}
          <div className="flex flex-1 flex-col gap-2 size-full items-center justify-end">
            <Button
              variant="secondary"
              className="w-full"
              onClick={() => setIsExitDialogOpen(true)}
              disabled={exitMutation.isPending || !showExitButton}
            >
              <LogOut className="size-5 mr-2 transform rotate-180" />
              {exitMutation.isPending ? t("exiting") : t("exit")}
            </Button>

            <Button
              variant="success"
              className="w-full"
              onClick={() => setIsEnterDialogOpen(true)}
              disabled={enterMutation.isPending || !showEnterButton}
            >
              <LogIn className="size-5 mr-2" />
              {enterMutation.isPending ? t("entering") : t("enter")}
            </Button>
          </div>
        </CardContent>
      </Card>

      {/* Enter Dialog */}
      <Dialog open={isEnterDialogOpen} onOpenChange={setIsEnterDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t("enter_dialog.title")}</DialogTitle>
            <DialogDescription>
              {t("enter_dialog.description")}
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="km" className="text-right">
                {t("km")}
              </Label>
              <Input
                id="km"
                type="number"
                value={kmValue}
                onChange={(e) => setKmValue(e.target.value)}
                className="col-span-3"
                placeholder="0"
              />
            </div>
          </div>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setIsEnterDialogOpen(false)}
            >
              {t("cancel")}
            </Button>
            <Button
              variant="success"
              onClick={handleEnter}
              disabled={enterMutation.isPending || !showEnterButton}
            >
              {enterMutation.isPending ? t("entering") : t("enter")}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Exit Dialog */}
      <Dialog open={isExitDialogOpen} onOpenChange={setIsExitDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t("exit_dialog.title")}</DialogTitle>
            <DialogDescription>
              {t("exit_dialog.description")}
            </DialogDescription>
          </DialogHeader>
          <div className="grid gap-4 py-4">
            <div className="grid grid-cols-4 items-center gap-4">
              <Label htmlFor="km" className="text-right">
                {t("km")}
              </Label>
              <Input
                id="km"
                type="number"
                value={kmValue}
                onChange={(e) => setKmValue(e.target.value)}
                className="col-span-3"
                placeholder="0"
              />
            </div>
          </div>
          <DialogFooter>
            <Button
              variant="outline"
              onClick={() => setIsExitDialogOpen(false)}
            >
              {t("cancel")}
            </Button>
            <Button
              variant="secondary"
              onClick={handleExit}
              disabled={exitMutation.isPending || !showExitButton}
            >
              {exitMutation.isPending ? t("exiting") : t("exit")}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
}
