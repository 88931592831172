import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@repo/ui/components/ui/Dialog";
import { Button } from "@repo/ui/components/ui/Button";
import { useTranslation } from "@repo/config";

import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@repo/ui/components/ui/Form";
import { Input } from "@repo/ui/components/ui/Input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@repo/ui/components/ui/Select";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useReservationQuery } from "../hooks/useReservation";
import {
  useAddNewVehicleSchema,
  AddNewVehicleFormValues,
} from "../schemas/addNewVehicleSchema";
import { toastManager } from "@repo/ui/utils";
import { Plus } from "lucide-react";

interface AddNewVehicleDialogProps {
  onAdd?: () => void;
}

export function AddNewVehicleDialog({
  onAdd,
}: Readonly<AddNewVehicleDialogProps>) {
  const { t } = useTranslation("reservations");
  const [open, setOpen] = useState(false);
  const addNewVehicleSchema = useAddNewVehicleSchema();
  const { useVehicleTypes, useAddNewReservationVehicle } =
    useReservationQuery();

  const form = useForm<AddNewVehicleFormValues>({
    resolver: zodResolver(addNewVehicleSchema),
    mode: "onChange",
  });

  // Get vehicle types
  const { data: vehicleTypes } = useVehicleTypes();

  // Add vehicle mutation
  const { mutateAsync: addVehicle, isPending: isAddingVehicle } =
    useAddNewReservationVehicle();

  const onSubmit = async (values: AddNewVehicleFormValues) => {
    await addVehicle({
      plate: values.plate.trim(),
      brand: values.brand.trim(),
      model: values.model.trim(),
      vehicle_type_id: values.vehicle_type_id,
    });

    toastManager.success(t("success.vehicle_added_successfully"));
    setOpen(false);
    form.reset();
    onAdd?.();
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button size="sm" variant="outline">
          <Plus className="size-4 mr-2" />
          {t("add_vehicle")}
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-lg">
        <DialogHeader>
          <DialogTitle>{t("add_vehicle_dialog.title")}</DialogTitle>
          <DialogDescription>
            {t("add_vehicle_dialog.description")}
          </DialogDescription>
        </DialogHeader>

        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
            <div className="space-y-4">
              <FormField
                control={form.control}
                name="plate"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("add_vehicle_dialog.plate")}</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="brand"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("add_vehicle_dialog.brand")}</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="model"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>{t("add_vehicle_dialog.model")}</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="vehicle_type_id"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      {t("add_vehicle_dialog.vehicle_type")}
                    </FormLabel>
                    <Select
                      onValueChange={field.onChange}
                      defaultValue={field.value}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue
                            placeholder={t("add_vehicle_dialog.select_type")}
                          />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {vehicleTypes?.results.map((type) => (
                          <SelectItem key={type.id} value={type.id}>
                            {type.label}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <DialogFooter>
              <Button
                type="button"
                variant="outline"
                onClick={() => setOpen(false)}
              >
                {t("add_vehicle_dialog.cancel_button")}
              </Button>
              <Button type="submit" disabled={isAddingVehicle}>
                {isAddingVehicle
                  ? t("add_vehicle_dialog.adding")
                  : t("add_vehicle_dialog.add_button")}
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
