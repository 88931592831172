import { useQuery } from "@tanstack/react-query";
import { reportService } from "@/main";
import { useCurrentLanguage } from "@repo/config";

const QUERY_KEY = "expedition-types";

export const useExpeditionTypes = () => {
  const currentLanguage = useCurrentLanguage();

  return useQuery({
    queryKey: [QUERY_KEY, currentLanguage],
    queryFn: () => reportService.getExpeditionTypes(currentLanguage),
  });
};
