import { useTranslation } from "@repo/config";
import { Button } from "@repo/ui/components/ui/Button";

export const ErrorFallback = () => {
  const { t } = useTranslation("common");

  return (
    <section className="flex flex-col gap-4 items-center justify-center min-h-screen">
      <h2 className="text-3xl md:text-7xl text-center text-destructive tracking-tight font-extrabold mb-4">
        {t("error.boundary.title")}
      </h2>
      <p className="text-muted-foreground text-center mb-4">
        {t("error.boundary.message")}
      </p>

      <div className="flex gap-2">
        <Button variant="outline" onClick={() => (window.location.href = "/")}>
          {t("error.boundary.home")}
        </Button>

        <Button variant="outline" onClick={() => window.location.reload()}>
          {t("error.boundary.retry")}
        </Button>
      </div>
    </section>
  );
};
