import { Input } from "@repo/ui/components/ui/Input.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@repo/ui/components/ui/Select.tsx";
import { DateRangePicker } from "@repo/ui/components/table/DateRangePicker";
import {
  useTranslation,
  DateRange,
  enUS,
  i18n,
  tr,
  useDebounce,
} from "@repo/config";
import { ExpeditionTimeFilters } from "@repo/service";
import { useExpeditionTypes } from "@/features/reports/hooks/useExpeditionTypes";
import { Search, ArrowDownIcon, ArrowUpIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { ScrollArea, ScrollBar } from "@repo/ui/components/ui/ScrollArea";
import { Button } from "@repo/ui/components/ui/Button";
import { cn } from "@repo/ui/lib/utils";

interface TableFiltersProps {
  searchValue: string;
  onSearchChange: (value: string) => void;
  expeditionType?: ExpeditionTimeFilters["expedition_type"];
  onExpeditionTypeChange: (
    type: ExpeditionTimeFilters["expedition_type"] | undefined,
  ) => void;
  isCompleted?: ExpeditionTimeFilters["is_completed"];
  onIsCompletedChange: (
    value: ExpeditionTimeFilters["is_completed"] | undefined,
  ) => void;
  dateRange?: DateRange;
  onDateRangeChange: (date: DateRange | undefined) => void;
  ordering?: ExpeditionTimeFilters["ordering"];
  onOrderingChange: (
    value: ExpeditionTimeFilters["ordering"] | undefined,
  ) => void;
}

export const ExpeditionTimesTableFilters = ({
  searchValue,
  onSearchChange,
  expeditionType,
  onExpeditionTypeChange,
  isCompleted,
  onIsCompletedChange,
  dateRange,
  onDateRangeChange,
  ordering,
  onOrderingChange,
}: TableFiltersProps) => {
  const { t } = useTranslation("reports");
  const { data: expeditionTypes, isLoading: isLoadingTypes } =
    useExpeditionTypes();
  const [localSearch, setLocalSearch] = useState(searchValue);
  const debouncedSearch = useDebounce(localSearch, 300);

  useEffect(() => {
    if (debouncedSearch !== searchValue) {
      onSearchChange(debouncedSearch);
    }
  }, [debouncedSearch, onSearchChange, searchValue]);

  useEffect(() => {
    setLocalSearch(searchValue);
  }, [searchValue]);

  const handleOrderingClick = (field: string) => {
    if (ordering?.includes(field)) {
      // Aynı alan için ASC/DESC değiştir
      onOrderingChange?.(ordering.startsWith("-") ? field : `-${field}`);
    } else {
      // Farklı alan seçildi, varsayılan olarak DESC
      onOrderingChange?.(`-${field}`);
    }
  };

  return (
    <ScrollArea className="w-full whitespace-nowrap">
      <div className="flex overflow-x-auto items-center gap-4 w-full p-1">
        <div className="relative">
          <Input
            placeholder={t("search.placeholder")}
            value={localSearch}
            onChange={(event) => setLocalSearch(event.target.value)}
            className="max-w-sm pl-9 min-w-[200px]"
          />
          <Search className="absolute left-3 top-1/2 -translate-y-1/2 size-4 text-muted-foreground" />
        </div>

        {/* ORDERING */}
        <div className="w-[200px]">
          <Select
            value={ordering ?? "-end_date"}
            onValueChange={(value) =>
              handleOrderingClick(value.replace(/^-/, ""))
            }
          >
            <SelectTrigger>
              <SelectValue>
                <div className="flex items-center gap-2">
                  {ordering?.includes("start_date") ? (
                    <>
                      <span>{t("expedition.filter.ordering.startDate")}</span>
                      {ordering?.startsWith("-") ? (
                        <ArrowDownIcon className="size-3" />
                      ) : (
                        <ArrowUpIcon className="size-3" />
                      )}
                    </>
                  ) : (
                    <>
                      <span>{t("expedition.filter.ordering.endDate")}</span>
                      {!ordering || ordering?.startsWith("-") ? (
                        <ArrowDownIcon className="size-3" />
                      ) : (
                        <ArrowUpIcon className="size-3" />
                      )}
                    </>
                  )}
                </div>
              </SelectValue>
            </SelectTrigger>
            <SelectContent>
              {/* End Date */}
              <Button
                variant="ghost"
                className={cn(
                  "w-full justify-start gap-2",
                  (!ordering || ordering?.includes("end_date")) &&
                    "bg-accent/50 dark:bg-accent/20",
                )}
                onClick={() => handleOrderingClick("end_date")}
              >
                <span>{t("expedition.filter.ordering.endDate")}</span>
                {!ordering || ordering === "-end_date" ? (
                  <ArrowDownIcon className="size-3 ml-auto" />
                ) : ordering === "end_date" ? (
                  <ArrowUpIcon className="size-3 ml-auto" />
                ) : null}
              </Button>

              {/* Start Date */}
              <Button
                variant="ghost"
                className={cn(
                  "w-full justify-start gap-2",
                  ordering?.includes("start_date") &&
                    "bg-accent/50 dark:bg-accent/20",
                )}
                onClick={() => handleOrderingClick("start_date")}
              >
                <span>{t("expedition.filter.ordering.startDate")}</span>
                {ordering === "-start_date" ? (
                  <ArrowDownIcon className="size-3 ml-auto" />
                ) : ordering === "start_date" ? (
                  <ArrowUpIcon className="size-3 ml-auto" />
                ) : null}
              </Button>
            </SelectContent>
          </Select>
        </div>

        {/* EXPEDITION TYPE */}
        <Select
          value={expeditionType?.toString() ?? ""}
          onValueChange={(value) =>
            onExpeditionTypeChange(value === "all" ? "" : value)
          }
          disabled={isLoadingTypes}
        >
          <SelectTrigger className="w-auto max-w-[150px]">
            <SelectValue
              placeholder={t("expedition.filter.type.placeholder")}
            />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">
              {t("expedition.filter.type.all")}
            </SelectItem>
            {expeditionTypes?.results.map((type) => (
              <SelectItem
                key={type.row_id}
                value={(type.row_id - 1).toString()}
              >
                {type.name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        {/* IS COMPLETED */}
        <Select
          value={isCompleted === undefined ? "all" : isCompleted.toString()}
          onValueChange={(value) =>
            onIsCompletedChange(value === "all" ? undefined : value)
          }
        >
          <SelectTrigger className="w-auto max-w-[150px]">
            <SelectValue
              placeholder={t("expedition.filter.status.placeholder")}
            />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">
              {t("expedition.filter.status.all")}
            </SelectItem>
            <SelectItem value="YES">
              {t("expedition.filter.status.completed")}
            </SelectItem>
            <SelectItem value="NO">
              {t("expedition.filter.status.in_progress")}
            </SelectItem>
          </SelectContent>
        </Select>

        {/* DATE RANGE */}
        <div className="xl:ml-auto">
          <DateRangePicker
            date={dateRange}
            onDateChange={onDateRangeChange}
            placeholder={t("expedition.filter.date.placeholder")}
            locale={i18n.language === "tr" ? tr : enUS}
          />
        </div>
      </div>
      <ScrollBar orientation="horizontal" />
    </ScrollArea>
  );
};
