import { Routes, Route } from "react-router-dom";
import { AppGuard, RouteGuard } from "@repo/provider";
import DashboardLayout from "@/layouts/DashboardLayout";
import NotFound from "@/pages/NotFound";
import { AppName } from "@repo/config";
import { routes } from "@/main";

const AUTH_APP_URL = import.meta.env.VITE_AUTH_APP_URL; // Login app url

/**
 * @function IndexRoute
 * @description Route to check if the user has access to the app
 * @returns {JSX.Element} Route to check if the user has access to the app
 */
const IndexRoute = () => (
  <RouteGuard
    config={{
      path: "/",
      permissions: [],
      fallbackPath: "/reservations",
    }}
    appName={AppName.EMPLOYEE}
  >
    <></>
  </RouteGuard>
);

/**
 * @function ProtectedRoute
 * @description Route to check if the user has access to the route
 * @returns {JSX.Element} Route to check if the user has access to the route
 */
const ProtectedRoute = ({
  path,
  permissions,
  Component,
}: {
  path: string;
  permissions: string[];
  Component: React.ComponentType;
}) => (
  <RouteGuard
    config={{
      path,
      permissions,
    }}
    appName={AppName.EMPLOYEE}
  >
    <Component />
  </RouteGuard>
);

/**
 * @function AppRoutes
 * @description Main routes component for the application
 * @returns {JSX.Element} Main routes component
 */
export const AppRoutes = () => (
  <Routes>
    <Route
      element={
        <AppGuard appName={AppName.EMPLOYEE} authAppUrl={AUTH_APP_URL} />
      }
    >
      {/* Dashboard layout */}
      <Route path="/" element={<DashboardLayout />}>
        {/* Index route */}
        <Route index element={<IndexRoute />} />

        {/* Protected routes */}
        {Object.entries(routes).map(
          ([key, { path, permissions, component: Component }]) => (
            <Route
              key={key}
              path={path}
              element={
                <ProtectedRoute
                  path={path}
                  permissions={permissions}
                  Component={Component}
                />
              }
            />
          ),
        )}
      </Route>
    </Route>

    {/* Not found route */}
    <Route path="*" element={<NotFound />} />
  </Routes>
);

export default AppRoutes;
