import { useTranslation } from "@repo/config";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@repo/ui/components/ui/Dialog";
import { Button } from "@repo/ui/components/ui/Button";
import { Textarea } from "@repo/ui/components/ui/Textarea";
import { useState } from "react";

interface OrderDetailContentPriceOfferRejectDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onConfirm: (reason: string) => void;
  isLoading?: boolean;
}

export default function OrderDetailContentPriceOfferRejectDialog({
  open,
  onOpenChange,
  onConfirm,
  isLoading,
}: Readonly<OrderDetailContentPriceOfferRejectDialogProps>) {
  const { t } = useTranslation("orders");
  const [reason, setReason] = useState("");

  const handleConfirm = () => {
    onConfirm(reason);
    setReason("");
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {t("order.detail.price_offer.reject_dialog.title")}
          </DialogTitle>
          <DialogDescription>
            {t("order.detail.price_offer.reject_dialog.description")}
          </DialogDescription>
        </DialogHeader>

        <div className="py-4">
          <Textarea
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            placeholder={t(
              "order.detail.price_offer.reject_dialog.reason.placeholder",
            )}
            className="min-h-[120px]"
            disabled={isLoading}
          />
        </div>

        <DialogFooter>
          <Button
            type="button"
            variant="outline"
            onClick={() => onOpenChange(false)}
            disabled={isLoading}
          >
            {t("order.detail.price_offer.reject_dialog.actions.cancel")}
          </Button>
          <Button
            type="button"
            variant="destructive"
            onClick={handleConfirm}
            disabled={!reason.trim() || isLoading}
          >
            {isLoading
              ? t("order.detail.price_offer.reject_dialog.actions.rejecting")
              : t("order.detail.price_offer.reject_dialog.actions.confirm")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
