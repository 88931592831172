import {
  TooltipProvider,
  Tooltip,
  TooltipTrigger,
  TooltipContent,
} from "@repo/ui/components/ui/Tooltip";
import { DataTableColumnHeader } from "./DataTableColumnHeader";
import { Column } from "@tanstack/react-table";

interface TableHeaderWithTooltipProps<TData, TValue> {
  column: Column<TData, TValue>;
  title: string;
  tooltipContent: string;
  maxWidth?: string;
}

export const TableHeaderWithTooltip = <TData, TValue>({
  column,
  title,
  tooltipContent,
  maxWidth = "auto",
}: TableHeaderWithTooltipProps<TData, TValue>) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div className={`max-w-[${maxWidth}] truncate`}>
            <DataTableColumnHeader
              hasTooltip={true}
              column={column}
              title={title}
            />
          </div>
        </TooltipTrigger>
        <TooltipContent side="top">{tooltipContent}</TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
