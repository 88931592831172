import { createContext, useContext, useMemo } from "react";
import {
  FirebaseServiceContextType,
  FirebaseServiceProviderProps,
} from "./types";

const FirebaseServiceContext = createContext<FirebaseServiceContextType | null>(
  null,
);
/**
 * Provider component for Firebase services.
 * @param props - The provider props.
 * @returns The Firebase service provider component.
 */
export function FirebaseServiceProvider({
  analyticsService,
  performanceService,
  children,
  nodeEnv,
}: Readonly<FirebaseServiceProviderProps>) {
  /**
   * Memoized value for the context to prevent unnecessary re-renders.
   */
  const value = useMemo(
    () => ({ analyticsService, performanceService, nodeEnv }),
    [analyticsService, performanceService, nodeEnv],
  );

  return (
    <FirebaseServiceContext.Provider value={value}>
      {children}
    </FirebaseServiceContext.Provider>
  );
}

export const useFirebaseContext = () => {
  const context = useContext(FirebaseServiceContext);
  if (!context)
    throw new Error(
      "useFirebaseContext must be used within FirebaseServiceProvider",
    );
  return context;
};
