import type { ApiClient } from "../../infrastructure/apiClient";
import type {
  AccountTypeResponse,
  ProfileUpdateData,
  User,
} from "../../types/user";
import type { PermissionResponse } from "../../types";

export class ProfileService {
  constructor(
    private readonly apiClient: ApiClient,
    private readonly baseUrl: string,
  ) {
    if (!baseUrl) {
      throw new Error("ERP_BASE_URL is not defined");
    }
  }

  /**
   * Get user profile
   * @param language - Language
   * @returns Profile
   */
  async getMyProfile(language: string): Promise<User> {
    const ENDPOINT = `${language}/users/user-info`;
    return this.apiClient.get<User>(ENDPOINT);
  }

  /**
   * Update user profile
   * @param language - Language
   * @param profile - Profile
   * @returns Profile
   */
  async updateMyProfile(
    language: string,
    profile: ProfileUpdateData,
  ): Promise<User> {
    const ENDPOINT = `${language}/users/user-info`;
    return this.apiClient.patch<User>(ENDPOINT, profile);
  }

  /**
   * Get user permissions
   * @param language - Language
   * @returns Permissions
   */
  async getMyPermissions(language: string): Promise<PermissionResponse> {
    const ENDPOINT = `${language}/permissions/my`;
    return this.apiClient.get<PermissionResponse>(ENDPOINT);
  }

  /**
   * Get user ERP permissions
   * @param language - Language
   * @returns Permissions from ERP
   */
  async getMyErpPermissions(language: string): Promise<PermissionResponse> {
    const ENDPOINT = `${language}/permissions/my`;
    return this.apiClient.get<PermissionResponse>(ENDPOINT, {
      baseURL: this.baseUrl,
    });
  }

  /**
   * Get user account types
   * @param language - Language
   * @returns Account types
   */
  async getMyAccountTypes(language: string): Promise<AccountTypeResponse> {
    const ENDPOINT = `${language}/users/account-types`;
    return this.apiClient.get<AccountTypeResponse>(ENDPOINT);
  }
}

export const createProfileService = (apiClient: ApiClient, baseUrl: string) =>
  new ProfileService(apiClient, baseUrl);
