import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
} from "@repo/ui/components/ui/Sheet";
import { useTranslation } from "@repo/config";
import {
  useReservationSelection,
  useReservationStore,
} from "@/store/reservation";
import { Copy, X } from "lucide-react";
import { toastManager } from "@repo/ui/utils";
import { cn } from "@repo/ui/lib/utils";
import { Button } from "@repo/ui/components/ui/Button";
import { ReservationDetailContent } from "./ReservationDetailContent";
import { getReservationStatusColor } from "../utils/getBadgeVariant";

export const ReservationsDetailSheet = () => {
  const { t } = useTranslation("reservations");
  const { selectedReservation, isLoading, error, clearReservation } =
    useReservationSelection();
  const isSheetOpen = useReservationStore((state) => state.isSheetOpen);

  const handleClose = () => {
    clearReservation();
  };

  const handleCopyReservationNo = async () => {
    if (!selectedReservation?.reservation.reservation_no) return;

    try {
      await navigator.clipboard.writeText(
        selectedReservation.reservation.reservation_no,
      );
      toastManager.success(t("detail.copy.success"));
    } catch (error: any) {
      toastManager.error(t("detail.copy.error"), error.message);
    }
  };

  return (
    <Sheet open={isSheetOpen} onOpenChange={handleClose}>
      <SheetContent
        showClose={false}
        className="flex flex-col gap-4 min-w-[850px] h-[calc(100dvh-3.75rem)] sm:h-[calc(100dvh-5rem)] top-[60px] sm:top-[80px] rounded-tl-lg"
      >
        <SheetHeader>
          <div className="flex items-center justify-between">
            {/* Left: Title , Status Copy Freight No Button*/}
            <div className="flex items-center justify-center gap-2">
              {selectedReservation?.approval_status && (
                <div className="relative rounded-full overflow-hidden size-3.5 border border-border">
                  <div
                    className={cn(
                      "absolute size-full animate-pulse",
                      getReservationStatusColor(
                        selectedReservation.approval_status.slug,
                      ),
                    )}
                  />
                </div>
              )}
              <SheetTitle className="text-sm sm:text-lg tracking-tight">
                {t("detail.title")}
              </SheetTitle>
              {selectedReservation?.reservation.reservation_no && (
                <button
                  onClick={handleCopyReservationNo}
                  className="flex items-center gap-2 py-2 px-1  rounded-lg"
                >
                  <p className="text-xs">
                    {selectedReservation.reservation.reservation_no}
                  </p>
                  <span className="p-1" title={t("order.detail.copy.tooltip")}>
                    <Copy className="size-4 stroke-2" />
                  </span>
                </button>
              )}
            </div>

            {/* Right: Close */}
            <div className="flex items-center gap-4">
              <Button variant="outline" size="iconSm" onClick={handleClose}>
                <X className="size-4" />
              </Button>
            </div>
          </div>
        </SheetHeader>
        <SheetDescription className="hidden">
          <span className="sr-only">{t("detail.description")}</span>
          {selectedReservation?.reservation.reservation_no}
        </SheetDescription>

        {selectedReservation && (
          <ReservationDetailContent
            error={error}
            isLoading={isLoading}
            selectedReservation={selectedReservation}
          />
        )}
      </SheetContent>
    </Sheet>
  );
};
