import { documentService } from "@/main";
import { useCurrentLanguage } from "@repo/config";
import {
  AddCompanyRequiredDocumentParams,
  AddUserRequiredDocumentParams,
  DocumentApprovalStatusUpdateParams,
  UpdateCompanyRequiredDocumentParams,
  UpdateUserRequiredDocumentParams,
} from "@repo/service";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

export const useDocumentsQuery = () => {
  const currentLanguage = useCurrentLanguage();
  const queryClient = useQueryClient();

  // Add company required document
  const useAddCompanyRequiredDocument = (companyId: string) => {
    return useMutation({
      mutationFn: (params: AddCompanyRequiredDocumentParams) =>
        documentService.addCompanyRequiredDocument({
          ...params,
          language: currentLanguage,
          company_id: companyId,
        }),
      onSuccess: () => {
        // Invalidate customer detail query
        queryClient.invalidateQueries({
          queryKey: ["customers", currentLanguage, companyId],
        });

        // Invalidate customers list query
        queryClient.invalidateQueries({
          queryKey: ["customers"],
        });
      },
    });
  };

  // Add user required document
  const useAddUserRequiredDocument = (userId: string) => {
    return useMutation({
      mutationFn: (params: AddUserRequiredDocumentParams) =>
        documentService.addUserRequiredDocument({
          ...params,
          language: currentLanguage,
          user_id: userId,
        }),
      onSuccess: () => {
        // Invalidate customer detail query
        queryClient.invalidateQueries({
          queryKey: ["customers", currentLanguage, userId],
        });

        // Invalidate customers list query
        queryClient.invalidateQueries({
          queryKey: ["customers"],
        });
      },
    });
  };

  // Update company required document
  const useUpdateCompanyRequiredDocument = () => {
    return useMutation({
      mutationFn: (params: UpdateCompanyRequiredDocumentParams) =>
        documentService.updateCompanyRequiredDocument({
          ...params,
          language: currentLanguage,
        }),
    });
  };

  // Update user required document
  const useUpdateUserRequiredDocument = () => {
    return useMutation({
      mutationFn: (params: UpdateUserRequiredDocumentParams) =>
        documentService.updateUserRequiredDocument({
          ...params,
          language: currentLanguage,
        }),
    });
  };

  // Get document types
  const useGetDocumentTypes = () => {
    return useQuery({
      queryKey: ["document-types"],
      queryFn: () =>
        documentService.getDocumentTypes({ language: currentLanguage }),
    });
  };

  // Document status update
  const useDocumentApprovalStatusUpdate = () => {
    return useMutation({
      mutationFn: (params: DocumentApprovalStatusUpdateParams) =>
        documentService.documentApprovalStatusUpdate(params),
      onSuccess: (_, variables) => {
        // Extract customerId from the document_id if available
        const customerId = variables.document_id.split("_")[0];

        // Invalidate customer detail query
        queryClient.invalidateQueries({
          queryKey: ["customers", currentLanguage, customerId],
        });

        // Invalidate customers list query
        queryClient.invalidateQueries({
          queryKey: ["customers"],
        });
      },
    });
  };

  return {
    useAddCompanyRequiredDocument,
    useAddUserRequiredDocument,
    useUpdateCompanyRequiredDocument,
    useUpdateUserRequiredDocument,
    useGetDocumentTypes,
    useDocumentApprovalStatusUpdate,
  };
};
