import CryptoJS from "crypto-js";

/**
 * Encrypts data using AES encryption
 * @param data - Data to encrypt
 * @param key - Encryption key
 * @returns Encrypted string
 */
export const encrypt = (data: string, key: string): string => {
  if (!data || !key) {
    throw new Error("Data and key are required for encryption");
  }

  // Ensure consistent encoding
  const encodedData = encodeURIComponent(data);
  return CryptoJS.AES.encrypt(encodedData, key).toString();
};

/**
 * Decrypts AES encrypted data
 * @param encryptedData - Encrypted data
 * @param key - Encryption key
 * @returns Decrypted string
 */
export const decrypt = (encryptedData: string, key: string): string => {
  if (!encryptedData || !key) {
    throw new Error("Encrypted data and key are required for decryption");
  }

  try {
    // Decrypt using AES
    const bytes = CryptoJS.AES.decrypt(encryptedData, key);

    // Validate decrypted bytes
    if (bytes.sigBytes <= 0) {
      throw new Error(
        "Invalid decryption result - possible wrong key or corrupted data",
      );
    }

    const decryptedStr = bytes.toString(CryptoJS.enc.Utf8);
    if (!decryptedStr) {
      throw new Error("Decryption resulted in empty string");
    }

    // Decode the URL-encoded string
    return decodeURIComponent(decryptedStr);
  } catch (error) {
    console.error("Decryption failed:", error);
    throw new Error("Failed to decrypt data");
  }
};
