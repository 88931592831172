export const documentUrls = {
  privacyUrl:
    "https://drive.google.com/file/d/19imBT74RP4vDBcRK9KxGQRTYhm6sSdJk/preview",
  kvkkLink:
    "https://drive.google.com/file/d/1ME38zr6H9uEkWtHmyeQnKKuVX1VKA3P0/preview",
  privacyUrlEN:
    "https://drive.google.com/file/d/1UyoycXkblDR_hLWcBX6g_VrdZwRIWDGe/preview",
  kvkkLinkEN:
    "https://drive.google.com/file/d/1elRJ0UX2KRqRa3oa0Yci7LiHPTsyjd0U/preview",
  vehicleContract:
    "https://drive.google.com/file/d/1hsnmL2xEm7Hx88yFINzihs-fg3nDhih9/preview",
};
