import { z } from "zod";
import { useTranslation } from "@repo/config";
import { PRICE_OFFER_LIMITS } from "../constants/price";

export const usePriceOfferSchema = () => {
  const { t } = useTranslation("orders");

  const serviceSchema = z
    .object({
      id: z.string(),
      service_name: z.string(),
      selected: z.boolean().default(false),
      included: z.boolean().default(true),
      excluded: z.boolean().default(false),
      price: z.string().optional(),
    })
    .refine(
      (data) => {
        // if service is not selected, skip validation
        if (!data.selected) return true;

        // if service is selected and excluded, check price
        if (data.excluded) {
          const priceValue = Number(data.price);
          return (
            data.price &&
            data.price.length >= 0 &&
            priceValue > PRICE_OFFER_LIMITS.MIN_VALUE &&
            priceValue <= PRICE_OFFER_LIMITS.MAX_VALUE
          );
        }
        return true;
      },
      {
        message: t(
          "order.detail.price_offer.validation.service_price.required",
        ),
        path: ["price"],
      },
    )
    .refine(
      (data) => {
        // if service is selected and excluded, check price
        if (data.selected && data.excluded) {
          return Number(data.price) !== 0;
        }
        return true;
      },
      {
        message: t("order.detail.price_offer.validation.service_price.zero"),
        path: ["price"],
      },
    );

  return z.object({
    price: z
      .string()
      .min(1, {
        message: t("order.detail.price_offer.validation.price.required"),
      })
      .transform((val) => val.replace(/\D/g, ""))
      .refine((val) => Number(val) > PRICE_OFFER_LIMITS.MIN_VALUE, {
        message: t("order.detail.price_offer.validation.price.min"),
      })
      .refine(
        (val) => {
          const numVal = Number(val);
          return (
            numVal <= PRICE_OFFER_LIMITS.MAX_VALUE &&
            val.length <= PRICE_OFFER_LIMITS.MAX_DIGITS
          );
        },
        {
          message: t("order.detail.price_offer.validation.price.max"),
        },
      ),
    currency: z.enum(["TRY", "USD", "EUR"], {
      required_error: t(
        "order.detail.price_offer.validation.currency.required",
      ),
    }),
    services: z.array(serviceSchema),
  });
};
