import {
  GetReservationDetailResponse,
  ReservationActivities,
} from "@repo/service";
import { useTranslation, formatDateTime } from "@repo/config";
import { cn } from "@repo/ui/lib/utils";
import { Avatar, AvatarFallback } from "@repo/ui/components/ui/Avatar";
import {
  getInitialsFromName,
  getActivityBadgeVariant,
} from "../utils/getBadgeVariant";
import {
  isReservationRejected,
  isVehicleHasLeft,
  isVehicleHasEntered,
  isVehicleAssignmentPending,
  isReservationApproved,
} from "../utils/reservationManagementHelper";
import { Badge } from "@repo/ui/components/ui/Badge";
import { Car, LogOut, LogIn } from "lucide-react";
import { ChoosenVehicleCard } from "./ReservationDetailContentChoosenVehicleCard";

interface ReservationDetailContentManagementStatusOverlayProps {
  reservation: GetReservationDetailResponse;
  isSelectedUser: boolean;
  isSecurity: boolean;
}

export default function ReservationsDetailContentManagementStatusOverlay({
  reservation,
  isSelectedUser,
  isSecurity,
}: Readonly<ReservationDetailContentManagementStatusOverlayProps>) {
  const { t } = useTranslation("reservations");

  // Determine status type and styling
  let statusBackground = "bg-secondary-background";
  let statusColor = "text-secondary";
  let statusMessage = "";

  if (isReservationRejected(reservation)) {
    statusBackground = "bg-destructive-background";
    statusColor = "text-destructive";
    statusMessage = "detail.status_message.rejected";
  } else if (isVehicleHasLeft(reservation)) {
    statusMessage = "detail.status_message.vehicle_left";
  } else if (isVehicleHasEntered(reservation)) {
    statusBackground = "bg-success-background";
    statusColor = "text-success";
    statusMessage = "detail.status_message.vehicle_entered";
  } else if (
    (!isVehicleAssignmentPending(reservation) && isSelectedUser) ||
    (!isSecurity &&
      !isSelectedUser &&
      !isVehicleAssignmentPending(reservation) &&
      !isReservationApproved(reservation))
  ) {
    statusBackground = "bg-warning-background";
    statusColor = "text-warning";
    statusMessage = "detail.status_message.approval_pending";
  } else if (
    !isSecurity &&
    !isSelectedUser &&
    isVehicleAssignmentPending(reservation) &&
    !isReservationApproved(reservation)
  ) {
    statusBackground = "bg-warning-background";
    statusColor = "text-warning";
    statusMessage = "detail.status_message.vehicle_assignment_pending";
  } else if (
    !isSecurity &&
    !isSelectedUser &&
    isReservationApproved(reservation) &&
    !isVehicleHasLeft(reservation)
  ) {
    statusBackground = "bg-success-background";
    statusColor = "text-success";
    statusMessage = "detail.status_message.approved";
  }

  // Get user information for rejected reservations
  const rejectedBy = reservation.reservation.approvals.find(
    (approval) => approval.approval_status.slug === "rejected",
  );

  const userName = rejectedBy?.approved_by?.user ?? "";
  const initials = getInitialsFromName(userName);
  const rejectComment = rejectedBy?.comment ?? "";
  const rejectDate = rejectedBy?.created_at ?? "";

  // Get reservation activities
  const activities = reservation.reservation.reservation_activities || [];

  // Helper function to get activity icon
  const getActivityIcon = (activityType: string) => {
    switch (activityType) {
      case "entered":
        return <LogIn className="size-4 text-success" />;
      case "exited":
        return (
          <LogOut className="size-4 text-secondary transform rotate-180" />
        );
      default:
        return <Car className="size-4 text-muted-foreground" />;
    }
  };

  // Render content based on status
  const renderStatusContent = () => {
    if (isReservationRejected(reservation) && rejectedBy) {
      return (
        <div className="flex items-start gap-3">
          {/* Avatar */}
          <Avatar className="size-8 border border-destructive/20">
            <AvatarFallback className="bg-destructive-background text-destructive text-xs font-medium">
              {initials.toUpperCase()}
            </AvatarFallback>
          </Avatar>

          {/* Comment Content */}
          <div className="flex-1 min-w-0">
            {/* User Info & Time */}
            <div className="flex items-center gap-2 flex-wrap mb-1">
              <span className="text-sm font-semibold text-foreground">
                {userName}
              </span>
              <span className="text-xs text-muted-foreground/70">•</span>
              <time className="text-xs text-muted-foreground/70">
                {formatDateTime(rejectDate)}
              </time>
            </div>

            {/* Comment Box */}
            {rejectComment && (
              <div className="mt-2 p-3 rounded-lg bg-muted border text-sm text-foreground/90 whitespace-pre-line">
                {rejectComment}
              </div>
            )}
          </div>
        </div>
      );
    } else if (activities.length > 0) {
      return (
        <div className="space-y-4">
          <h3 className="text-sm font-medium text-foreground">
            {t("detail.vehicle_activities")}
          </h3>
          <div className="space-y-3">
            {activities.map((activity: ReservationActivities) => (
              <div
                key={activity.created_at}
                className="flex items-center gap-3 border-b pb-3 last:border-0 last:pb-0"
              >
                <div className="flex-shrink-0 h-8 w-8 rounded-full bg-muted flex items-center justify-center">
                  {getActivityIcon(activity.activity_type.slug)}
                </div>
                <div className="flex-1 min-w-0">
                  <div className="flex items-center gap-2 flex-wrap mb-1">
                    <Badge
                      variant={getActivityBadgeVariant(
                        activity.activity_type.slug,
                      )}
                    >
                      {activity.activity_type.name}
                    </Badge>
                    <span className="text-xs text-muted-foreground/70">•</span>
                    <time className="text-xs text-muted-foreground/70">
                      {formatDateTime(activity.created_at)}
                    </time>
                  </div>
                  <div className="flex items-center gap-2 mt-1">
                    <span className="text-sm text-foreground/90">
                      {t("detail.km")}:{" "}
                      <span className="font-medium">{activity.km}</span>
                    </span>
                    {activity.approved_by && (
                      <>
                        <span className="text-xs text-muted-foreground/70">
                          •
                        </span>
                        <span className="text-sm text-foreground/90">
                          {t("detail.by")}:{" "}
                          <span className="font-medium">
                            {activity.approved_by}
                          </span>
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    } else {
      return (
        <div className="text-sm flex flex-col gap-2 justify-center items-center py-2 text-foreground/90">
          {isReservationApproved(reservation) &&
            reservation.reservation.vehicle?.plate && (
              <ChoosenVehicleCard
                className="border"
                vehicle={reservation.reservation.vehicle}
              />
            )}
          {getStatusMessageWithName()}
        </div>
      );
    }
  };

  // Helper function to get status message with name
  const getStatusMessageWithName = () => {
    const findApproval = reservation.reservation.approvals.find(
      (approval) => approval.approval_status.slug === "pending",
    );

    const userName = findApproval?.approved_by?.user ?? "";

    if (
      (!isSelectedUser && !isSecurity && !isReservationApproved(reservation)) ||
      (!isVehicleAssignmentPending(reservation) && isSelectedUser)
    ) {
      return t(statusMessage, { name: userName });
    }

    return t(statusMessage);
  };

  return (
    <div className="w-full max-w-2xl bg-background rounded-lg overflow-hidden shadow-sm border">
      {/* Status Header */}
      <div
        className={cn("p-3 flex items-center gap-2 border-b", statusBackground)}
      >
        <span className={cn("text-sm font-medium", statusColor)}>
          {isReservationRejected(reservation) ||
          (!isVehicleAssignmentPending(reservation) && isSelectedUser) ||
          (!isSecurity &&
            !isSelectedUser &&
            !isReservationApproved(reservation))
            ? reservation.approval_status.name
            : reservation.vehicle_status.name}
        </span>
      </div>

      {/* Status Content */}
      <div className="p-4">{renderStatusContent()}</div>
    </div>
  );
}
