import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { reservationService } from "@/main";
import {
  AddReservationParams,
  ApproveReservationParams,
  GetAvailableVehiclesParams,
  GetReservationDetailParams,
  RejectReservationParams,
  GetApprovalStatusesParams,
  GetVehicleStatusesParams,
  GetReservationApprovalActionsParams,
  EnterReservationParams,
  ExitReservationParams,
  AddNewReservationVehicleParams,
} from "@repo/service";
import { useCurrentLanguage } from "@repo/config";
import { RESERVATIONS_QUERY_KEY } from "./useReservations";

const QUERY_KEY = "reservation";

export function useReservationQuery() {
  const currentLanguage = useCurrentLanguage();
  const queryClient = useQueryClient();

  /**
   * Get the reservation detail
   * @param params - reservation_no
   * @returns The reservation detail
   */
  const useReservationDetail = (params: GetReservationDetailParams) => {
    const { reservation_id } = params;
    return useQuery({
      queryKey: [QUERY_KEY, "detail", reservation_id, currentLanguage],
      queryFn: () =>
        reservationService.getReservationDetail({
          reservation_id,
          language: currentLanguage,
        }),
      enabled: !!reservation_id,
    });
  };

  /**
   * Get the available vehicles
   * @param params - plate, start_time
   * @returns The available vehicles
   */
  const useAvailableVehicles = (params: GetAvailableVehiclesParams) => {
    const startTime = params.start_time;
    return useQuery({
      queryKey: [
        QUERY_KEY,
        "available-vehicles",
        params.search,
        params.available_status,
        startTime,
        currentLanguage,
      ],
      queryFn: () =>
        reservationService.getAvailableVehicles({
          ...params,
          language: currentLanguage,
        }),
      enabled: !!startTime,
    });
  };

  /**
   * Get the reservation types
   * @returns The reservation types
   */
  const useReservationTypes = () => {
    return useQuery({
      queryKey: [QUERY_KEY, "reservation-types", currentLanguage],
      queryFn: () =>
        reservationService.getReservationTypes({
          language: currentLanguage,
        }),
    });
  };

  /**
   * Get the approval statuses
   * @returns The approval statuses
   */
  const useApprovalStatuses = (params?: GetApprovalStatusesParams) => {
    return useQuery({
      queryKey: [
        QUERY_KEY,
        "approval-statuses",
        currentLanguage,
        params?.search,
        params?.ordering,
      ],
      queryFn: () =>
        reservationService.getApprovalStatuses({
          ...params,
          language: currentLanguage,
        }),
    });
  };

  /**
   * Get the vehicle statuses
   * @returns The vehicle statuses
   */
  const useVehicleStatuses = (params?: GetVehicleStatusesParams) => {
    return useQuery({
      queryKey: [
        QUERY_KEY,
        "vehicle-statuses",
        currentLanguage,
        params?.search,
        params?.ordering,
      ],
      queryFn: () =>
        reservationService.getVehicleStatuses({
          ...params,
          language: currentLanguage,
        }),
    });
  };

  /**
   * Approve the reservation
   * @param params - reservation_no
   * @returns The approved reservation
   */
  const useApproveReservation = (params: ApproveReservationParams) => {
    return useMutation({
      mutationFn: (mutationParams: ApproveReservationParams) =>
        reservationService.approveReservation({
          ...params,
          ...mutationParams,
          language: currentLanguage,
        }),

      onSuccess: () => {
        // Invalidate reservations query to refresh the table
        queryClient.invalidateQueries({ queryKey: [RESERVATIONS_QUERY_KEY] });

        // Invalidate reservation detail
        queryClient.invalidateQueries({
          queryKey: [
            QUERY_KEY,
            "detail",
            params.reservation_id,
            currentLanguage,
          ],
        });

        // Invalidate reservation approval actions
        queryClient.invalidateQueries({
          queryKey: [
            QUERY_KEY,
            "reservation-approval-actions",
            currentLanguage,
            params.reservation_id,
          ],
        });

        // Invalidate available vehicles
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY, "available-vehicles"],
        });
      },
    });
  };

  /**
   * Reject the reservation
   * @param params - reservation_no, reject_reason
   * @returns The rejected reservation
   */
  const useRejectReservation = (params: RejectReservationParams) => {
    return useMutation({
      mutationFn: (mutationParams: RejectReservationParams) =>
        reservationService.rejectReservation({
          ...params,
          ...mutationParams,
          language: currentLanguage,
        }),

      onSuccess: () => {
        // Invalidate reservations query to refresh the table
        queryClient.invalidateQueries({ queryKey: [RESERVATIONS_QUERY_KEY] });

        // Invalidate reservation detail
        queryClient.invalidateQueries({
          queryKey: [
            QUERY_KEY,
            "detail",
            params.reservation_id,
            currentLanguage,
          ],
        });

        // Invalidate reservation approval actions
        queryClient.invalidateQueries({
          queryKey: [
            QUERY_KEY,
            "reservation-approval-actions",
            currentLanguage,
            params.reservation_id,
          ],
        });

        // Invalidate available vehicles
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY, "available-vehicles"],
        });
      },
    });
  };

  /**
   * Get the reservation approval actions
   * @param params - reservation_id
   * @returns The reservation approval actions
   */
  const useReservationApprovalActions = (
    params: GetReservationApprovalActionsParams,
  ) => {
    return useQuery({
      queryKey: [
        QUERY_KEY,
        "reservation-approval-actions",
        currentLanguage,
        params.reservation_id,
      ],
      queryFn: () =>
        reservationService.getReservationApprovalActions({
          ...params,
          language: currentLanguage,
        }),
      enabled: !!params.reservation_id,
    });
  };

  /**
   * Deliver the reservation
   * @param params - reservation_no
   * @returns The delivered reservation
   */
  const useEnterReservation = (
    params: EnterReservationParams & { reservation_id: string },
  ) => {
    return useMutation({
      mutationFn: (mutationParams: EnterReservationParams) =>
        reservationService.enterReservation({
          ...params,
          ...mutationParams,
          language: currentLanguage,
        }),
      onSuccess: () => {
        // Invalidate reservations query to refresh the table
        queryClient.invalidateQueries({ queryKey: [RESERVATIONS_QUERY_KEY] });

        // Invalidate reservation detail
        queryClient.invalidateQueries({
          queryKey: [
            QUERY_KEY,
            "detail",
            params.reservation_id,
            currentLanguage,
          ],
        });

        // Invalidate reservation approval actions
        queryClient.invalidateQueries({
          queryKey: [
            QUERY_KEY,
            "reservation-approval-actions",
            currentLanguage,
            params.reservation_id,
          ],
        });
      },
    });
  };

  /**
   * Exit the reservation
   * @param params - reservation_no
   * @returns The exited reservation
   */
  const useExitReservation = (
    params: ExitReservationParams & { reservation_id: string },
  ) => {
    return useMutation({
      mutationFn: (mutationParams: ExitReservationParams) =>
        reservationService.exitReservation({
          ...params,
          ...mutationParams,
          language: currentLanguage,
        }),
      onSuccess: () => {
        // Invalidate reservations query to refresh the table
        queryClient.invalidateQueries({ queryKey: [RESERVATIONS_QUERY_KEY] });

        // Invalidate reservation detail
        queryClient.invalidateQueries({
          queryKey: [
            QUERY_KEY,
            "detail",
            params.reservation_id,
            currentLanguage,
          ],
        });

        // Invalidate reservation approval actions
        queryClient.invalidateQueries({
          queryKey: [
            QUERY_KEY,
            "reservation-approval-actions",
            currentLanguage,
            params.reservation_id,
          ],
        });
      },
    });
  };

  /**
   * Add the reservation
   * @param params - reservation_no
   * @returns The added reservation
   */
  const useAddReservation = () => {
    return useMutation({
      mutationFn: (mutationParams: AddReservationParams) =>
        reservationService.addReservation({
          ...mutationParams,
          language: currentLanguage,
        }),
      onSuccess: () => {
        // Invalidate reservations query to refresh the table
        queryClient.invalidateQueries({ queryKey: [RESERVATIONS_QUERY_KEY] });
      },
    });
  };

  /**
   * Get the vehicle types
   * @returns The vehicle types
   */
  const useVehicleTypes = () => {
    return useQuery({
      queryKey: [QUERY_KEY, "vehicle-types", currentLanguage],
      queryFn: () =>
        reservationService.getVehicleTypes({
          language: currentLanguage,
        }),
    });
  };

  /**
   * Add new reservation vehicle
   * @param params - plate, brand, model, vehicle_type_id
   * @returns The added reservation vehicle
   */
  const useAddNewReservationVehicle = () => {
    return useMutation({
      mutationFn: (mutationParams: AddNewReservationVehicleParams) =>
        reservationService.addNewReservationVehicle({
          ...mutationParams,
          language: currentLanguage,
        }),
      onSuccess: () => {
        // Invalidate available vehicles
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY, "available-vehicles"],
        });
      },
    });
  };

  return {
    useReservationDetail,
    useAvailableVehicles,
    useReservationTypes,
    useApprovalStatuses,
    useVehicleStatuses,
    useApproveReservation,
    useEnterReservation,
    useExitReservation,
    useRejectReservation,
    useReservationApprovalActions,
    useAddReservation,
    useVehicleTypes,
    useAddNewReservationVehicle,
  };
}
