import { Input } from "@repo/ui/components/ui/Input";
import { useTranslation, useDebounce } from "@repo/config";
import { Search } from "lucide-react";
import { useState, useEffect } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@repo/ui/components/ui/Select";
import { useCustomerDocumentQuery } from "../hooks/useCustomerDocument";
import { getDocumentStatusBadgeColor } from "../utils/getDocumentStatusColors";

interface CustomersTableFiltersProps {
  searchValue: string;
  onSearchChange: (value: string) => void;
  documentStatusValue?: string;
  onDocumentStatusChange?: (value: string) => void;
}

export const CustomersTableFilters = ({
  searchValue,
  onSearchChange,
  documentStatusValue,
  onDocumentStatusChange,
}: CustomersTableFiltersProps) => {
  const { t } = useTranslation("customers");
  const [localSearch, setLocalSearch] = useState(searchValue);
  const debouncedSearch = useDebounce(localSearch, 500);
  const { useCustomerDocumentStatuses } = useCustomerDocumentQuery();

  const { data: documentStatuses } = useCustomerDocumentStatuses();

  useEffect(() => {
    if (debouncedSearch !== searchValue) {
      onSearchChange(debouncedSearch);
    }
  }, [debouncedSearch, onSearchChange, searchValue]);

  useEffect(() => {
    setLocalSearch(searchValue);
  }, [searchValue]);

  return (
    <div className="flex overflow-x-auto items-center gap-4 w-full p-1">
      <div className="flex flex-wrap items-center gap-4 w-full">
        {/* Search input */}
        <div className="relative">
          <Input
            placeholder={t("customers.search.placeholder")}
            value={localSearch}
            onChange={(event) => setLocalSearch(event.target.value)}
            className="max-w-sm pl-9"
          />
          <Search className="absolute left-3 top-1/2 -translate-y-1/2 size-4 text-muted-foreground" />
        </div>

        {/* Document Status filter */}
        <div className="w-[200px]">
          <Select
            value={documentStatusValue ?? "all"}
            onValueChange={(value) =>
              onDocumentStatusChange?.(value === "all" ? "" : value)
            }
          >
            <SelectTrigger>
              <SelectValue
                placeholder={t("customers.filters.document_status.placeholder")}
              >
                {documentStatusValue && documentStatuses?.results ? (
                  <div className="flex items-center gap-2">
                    <span className="relative flex size-2">
                      <span
                        className={`relative inline-flex rounded-full size-2 ${getDocumentStatusBadgeColor(documentStatusValue)}`}
                      />
                    </span>
                    {
                      documentStatuses.results.find(
                        (status) => status.slug === documentStatusValue,
                      )?.status_name
                    }
                  </div>
                ) : null}
              </SelectValue>
            </SelectTrigger>
            <SelectContent>
              <SelectItem
                value="all"
                className="flex items-center gap-2 py-2 cursor-pointer"
              >
                <div className="flex items-center gap-2 flex-1">
                  <span className="relative flex size-2">
                    <span className="relative inline-flex rounded-full size-2 bg-muted" />
                  </span>
                  <span className="flex-1 line-clamp-1">
                    {t("customers.filters.document_status.all")}
                  </span>
                </div>
              </SelectItem>
              {documentStatuses?.results.map((status) => (
                <SelectItem
                  key={status.row_id}
                  value={status.slug}
                  className="flex items-center gap-2 py-2 cursor-pointer"
                >
                  <div className="flex items-center gap-2 flex-1">
                    <span className="relative flex size-2">
                      <span
                        className={`relative inline-flex rounded-full size-2 ${getDocumentStatusBadgeColor(status.slug)}`}
                      />
                    </span>
                    <span className="flex-1 line-clamp-1">
                      {status.status_name}
                    </span>
                  </div>
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </div>
    </div>
  );
};
