import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import type { ProfileService, ProfileUpdateData } from "@repo/service";
import { AUTH_KEYS } from "./useAuthQuery";
import { useMemo } from "react";

/**
 * Interface for useProfileQuery hook parameters
 */
interface UseProfileQueryProps {
  /** Service for handling profile operations */
  profileService: ProfileService;
  /** Current language setting */
  language: string;
  /** Flag indicating if user is authenticated */
  isAuthenticated: boolean;
}

/**
 * Custom hook to manage user profile data
 *
 * This hook handles:
 * - Fetching user profile data
 * - Validating user access to specific apps
 * - Refreshing profile data when needed
 *
 * @param {UseProfileQueryProps} props - Hook parameters
 * @returns {Object} Profile query, validation, and refresh functions
 */
export function useProfileQuery({
  profileService,
  language,
  isAuthenticated,
}: UseProfileQueryProps) {
  const queryClient = useQueryClient();

  /**
   * Query for fetching user profile data
   */
  const profileQuery = useQuery({
    queryKey: [...AUTH_KEYS.profile, "me"],
    queryFn: () => profileService.getMyProfile(language),
    retry: false,
    enabled: isAuthenticated,
    staleTime: 5 * 60 * 1000, // 5 minutes
  });

  /**
   * Query for updating user profile data
   */
  const updateProfileQuery = useMutation({
    mutationFn: (profile: ProfileUpdateData) =>
      profileService.updateMyProfile(language, profile),
    onSuccess: () => {
      // Invalidate profile and permissions queries
      queryClient.invalidateQueries({ queryKey: [...AUTH_KEYS.profile, "me"] });
      queryClient.invalidateQueries({
        queryKey: [...AUTH_KEYS.profile, "permissions"],
      });
    },
  });

  /**
   * Permissions query
   */
  const permissionsQuery = useQuery({
    queryKey: [...AUTH_KEYS.profile, "permissions"],
    queryFn: () => profileService.getMyPermissions(language),
    enabled: isAuthenticated,
    staleTime: 2 * 60 * 1000, // 2 minutes
  });

  /**
   * ERP permissions query
   */
  const erpPermissionsQuery = useQuery({
    queryKey: [...AUTH_KEYS.profile, "erpPermissions"],
    queryFn: () => profileService.getMyErpPermissions(language),
    enabled: isAuthenticated,
    staleTime: 2 * 60 * 1000, // 2 minutes
  });

  // Merged permissions
  const mergedPermissions = useMemo(() => {
    const mainPerms = permissionsQuery.data ?? [];
    const erpPerms = erpPermissionsQuery.data ?? [];

    // Merge permissions and remove duplicates
    return Array.from(new Set([...mainPerms, ...erpPerms]));
  }, [permissionsQuery.data, erpPermissionsQuery.data]);

  // Check loading status
  const isLoading = permissionsQuery.isLoading ?? erpPermissionsQuery.isLoading;
  const error = permissionsQuery.error ?? erpPermissionsQuery.error;

  /**
   * Forces a refresh of the user profile data
   */
  const refreshProfile = () => {
    if (isAuthenticated) {
      queryClient.invalidateQueries({ queryKey: AUTH_KEYS.profile });
    }
  };

  /**
   * Get user account types
   */
  const accountTypesQuery = useQuery({
    queryKey: [...AUTH_KEYS.profile, "accountTypes"],
    queryFn: () => profileService.getMyAccountTypes(language),
    enabled: isAuthenticated,
    staleTime: 2 * 60 * 1000, // 2 minutes
  });

  return {
    profileQuery,
    permissionsQuery: {
      ...permissionsQuery,
      data: mergedPermissions,
      isLoading,
      error,
    },
    erpPermissionsQuery,
    updateProfileQuery,
    accountTypesQuery,
    refreshProfile,
  };
}
