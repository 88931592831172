import { useMemo, useState, useEffect, Suspense } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@repo/ui/components/ui/Card";
import { BOOKS, Book } from "@/features/library/books";
import { useTranslation, useDebounce } from "@repo/config";
import { BookList } from "@/features/library/components/BookList";
import { BookFilter } from "@/features/library/components/BookFilter";
import { parseAsString, useQueryState } from "nuqs";
import { useFirebaseQuery } from "@repo/provider";

const DEBOUNCE_DELAY = 500;
const QUERY_PARAMS = {
  SEARCH: "library_search",
  SELECTED_BOOK: "library_book",
} as const;

export default function LibraryPage() {
  return (
    <Suspense fallback={<></>}>
      <LibraryContent />
    </Suspense>
  );
}

function LibraryContent() {
  const { logEventMutation } = useFirebaseQuery();
  const { t } = useTranslation("library");
  const [searchQuery, setSearchQuery] = useQueryState(
    QUERY_PARAMS.SEARCH,
    parseAsString.withDefault(""),
  );
  const [selectedBookId, setSelectedBookId] = useQueryState(
    QUERY_PARAMS.SELECTED_BOOK,
    parseAsString.withDefault(""),
  );
  const [localSearch, setLocalSearch] = useState(searchQuery ?? "");
  const debouncedSearch = useDebounce(localSearch, DEBOUNCE_DELAY);

  // Log page view on component mount only
  useEffect(() => {
    logEventMutation.mutate({
      eventName: "page_view",
      eventParams: {
        page_title: document.title,
        page_location: window.location.href,
        page_path: window.location.pathname,
      },
    });
  }, []); // Empty dependency array means this runs once on mount

  useEffect(() => {
    setSearchQuery(debouncedSearch || null);

    // Only log search if there is a search query
    if (debouncedSearch) {
      logEventMutation.mutate({
        eventName: "library_search",
        eventParams: {
          search_term: debouncedSearch,
          results_count: filteredBooks.length,
          timestamp: new Date().toISOString(),
        },
      });
    }
  }, [debouncedSearch]);

  useEffect(() => {
    if (!searchQuery) {
      setLocalSearch("");
    }
  }, [searchQuery]);

  const filteredBooks = useMemo(() => {
    const query = debouncedSearch.toLowerCase();
    return BOOKS.filter(
      (book) =>
        book.title.toLowerCase().includes(query) ||
        book.author?.toLowerCase().includes(query),
    );
  }, [debouncedSearch]);

  const selectedBook = useMemo(() => {
    return BOOKS.find((book) => book.id === selectedBookId);
  }, [selectedBookId]);

  const handleBookClick = (book: Book) => {
    setSelectedBookId(book.id);

    // Track book selection
    logEventMutation.mutate({
      eventName: "book_selected",
      eventParams: {
        book_id: book.id,
        book_title: book.title,
        book_author: book.author,
        timestamp: new Date().toISOString(),
      },
    });
  };

  const handleCloseBook = () => {
    setSelectedBookId(null);
  };

  return (
    <Card className="h-[calc(100svh-80px)] md:h-[calc(100svh-102px)] overflow-hidden">
      <CardHeader>
        <CardTitle>{t("title")}</CardTitle>
      </CardHeader>
      <CardContent className="pb-0">
        <BookFilter searchQuery={localSearch} onSearchChange={setLocalSearch} />
        <BookList
          books={filteredBooks}
          onBookClick={handleBookClick}
          selectedBook={selectedBook}
          onCloseBook={handleCloseBook}
        />
      </CardContent>
    </Card>
  );
}
