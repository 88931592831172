import { cn } from "../../lib/utils";
import Logo from "../../assets/images/logo.png";

interface CompanyLogoProps {
  readonly className?: string;
  readonly logo?: string;
  readonly alt?: string;
  readonly onClick?: () => void;
  readonly width?: string;
  readonly maxHeight?: string;
}

export function CompanyLogo({
  className,
  logo,
  alt = "Logo",
  onClick,
  width = "w-40",
  maxHeight = "max-h-16",
}: CompanyLogoProps) {
  return (
    <div
      className={cn(
        "flex items-center min-h-16 justify-center",
        maxHeight,
        className,
      )}
    >
      <button
        onClick={onClick}
        className="flex w-full items-center justify-center cursor-pointer"
      >
        <img src={logo ?? Logo} alt={alt} className={width} />
      </button>
    </div>
  );
}
