import { cn } from "@repo/ui/lib/utils";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@repo/ui/components/ui/Tooltip";

interface TableCellContentProps {
  content: string;
  className?: string;
  maxWidth?: string;
  showTooltip?: boolean;
}

export const TableCellContent = ({
  content,
  className,
  maxWidth = "max-w-[200px]",
  showTooltip = true,
}: TableCellContentProps) => {
  if (!content) return "-";

  const cellContent = (
    <span
      className={cn(
        "block truncate", // cut off text if it's too long
        maxWidth, // maximum width
        "text-sm", // font size
        className,
      )}
    >
      {content}
    </span>
  );

  if (showTooltip) {
    return (
      <TooltipProvider>
        <Tooltip delayDuration={300}>
          <TooltipTrigger asChild>{cellContent}</TooltipTrigger>
          <TooltipContent
            side="bottom"
            align="start"
            className="max-w-[300px] break-words"
          >
            {content}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  }

  return cellContent;
};
