import { useMutation, useQuery } from "@tanstack/react-query";
import { orderService } from "@/main";
import {
  GetOrderParams,
  GetPriceOfferParams,
  GetPriceOffersParams,
  RejectOrderDemandParams,
  SendPriceOfferParams,
} from "@repo/service";
import { useCurrentLanguage } from "@repo/config";
import { useQueryClient } from "@tanstack/react-query";

const QUERY_KEY = "order";

export function useOrderQuery() {
  const queryClient = useQueryClient();
  const currentLanguage = useCurrentLanguage();

  /**
   * Get the order
   * @param params - order_no
   * @returns The order
   */
  const useOrder = (params: GetOrderParams) => {
    const orderNo = params.orderNo;
    return useQuery({
      queryKey: [QUERY_KEY, orderNo, currentLanguage],
      queryFn: () =>
        orderService.getOrder({
          ...params,
          language: currentLanguage,
        }),
      enabled: !!orderNo,
    });
  };

  /**
   * Get the order statuses
   * @returns The order statuses
   */
  const useOrderStatuses = () => {
    return useQuery({
      queryKey: [QUERY_KEY, "order-statuses", currentLanguage],
      queryFn: () =>
        orderService.getOrderStatuses({
          language: currentLanguage,
        }),
    });
  };

  /**
   * Send the price offer
   * @param params - order_no, offer_items, price, currency, description
   * @returns The price offer
   */
  const useOrderSendOffer = () => {
    return useMutation({
      mutationKey: [QUERY_KEY],
      mutationFn: (params: SendPriceOfferParams) =>
        orderService.sendPriceOffer({
          ...params,
          language: currentLanguage,
        }),
      onSuccess: (_, variables) => {
        // Invalidate orders query to trigger a refetch
        queryClient.invalidateQueries({ queryKey: ["orders"] });

        // Invalidate the selected order to update its status
        queryClient.invalidateQueries({
          queryKey: ["order", variables.order_no],
        });
      },
    });
  };

  /**
   * Get the price offer
   * @param params - order_no
   * @param options - The query options
   * @returns The price offer
   */
  const useOrderGetPriceOffers = (
    params: GetPriceOffersParams,
    options?: { enabled?: boolean },
  ) => {
    const orderNo = params.order_no;
    return useQuery({
      queryKey: [QUERY_KEY, "price-offers", orderNo, currentLanguage],
      queryFn: () =>
        orderService.getPriceOffers({
          ...params,
          language: currentLanguage,
        }),
      enabled: options?.enabled ?? true,
    });
  };

  /**
   * Get the price offer
   * @param params - order_no and offer_no
   * @param options - The query options
   * @returns The price offer
   */
  const useOrderGetPriceOffer = (
    params: GetPriceOfferParams,
    options?: { enabled?: boolean },
  ) => {
    const orderNo = params.order_no;
    const offerNo = params.offer_no;
    return useQuery({
      queryKey: [QUERY_KEY, "price-offer", orderNo, offerNo, currentLanguage],
      queryFn: () =>
        orderService.getPriceOffer({
          ...params,
          language: currentLanguage,
        }),
      enabled: options?.enabled ?? true,
    });
  };

  /**
   * Reject the order demand
   * @param params - order_no and description
   * @returns The order demand
   */
  const useOrderRejectDemand = () => {
    return useMutation({
      mutationKey: [QUERY_KEY],
      mutationFn: (params: RejectOrderDemandParams) =>
        orderService.rejectOrderDemand({
          ...params,
          language: currentLanguage,
        }),
      onSuccess: (_, variables) => {
        // Invalidate orders query to trigger a refetch
        queryClient.invalidateQueries({ queryKey: ["orders"] });

        // Invalidate the selected order to update its status
        queryClient.invalidateQueries({
          queryKey: ["order", variables.order_no],
        });
      },
    });
  };

  return {
    useOrder,
    useOrderStatuses,
    useOrderSendOffer,
    useOrderGetPriceOffers,
    useOrderGetPriceOffer,
    useOrderRejectDemand,
  };
}
