import { Link } from "react-router-dom";
import { buttonVariants } from "@repo/ui/components/ui/Button";
import { useTranslation } from "@repo/config";
import { useFirebaseQuery } from "@repo/provider";
import { useEffect } from "react";

/**
 * @function NotFound
 * @description Not found page component
 * @returns {JSX.Element} Not found page component
 */
const NotFound = () => {
  const { t } = useTranslation("common");
  const { logEventMutation } = useFirebaseQuery();

  useEffect(() => {
    logEventMutation.mutate({
      eventName: "page_view",
      eventParams: {
        page_title: document.title,
        page_location: window.location.href,
        page_path: window.location.pathname,
        page_status: "404",
      },
    });
  }, []); // Empty dependency array means this runs once on mount

  return (
    <section className="flex flex-col items-center justify-center min-h-screen">
      <div className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
        <div className="mx-auto max-w-screen-sm text-center">
          <h1 className="mb-4text-3xl md:text-7xl tracking-tight font-extrabold lg:text-9xl text-destructive">
            404
          </h1>
          <h1 className="text-2xl sm:text-4xl font-bold mb-4">
            {t("not_found.title")}
          </h1>
          <p className="text-sm sm:text-xl mb-8">
            {t("not_found.description")}
          </p>
          <Link to="/" className={buttonVariants({ variant: "outline" })}>
            {t("not_found.button")}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default NotFound;
