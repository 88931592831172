import { Card, CardContent } from "@repo/ui/components/ui/Card";
import { File, Download, Eye } from "lucide-react";
import { AppName, formatDateTime, useTranslation } from "@repo/config";
import { Badge } from "@repo/ui/components/ui/Badge";
import { GetCustomerDetailResponse } from "@repo/service";
import { getDocumentBadgeVariant } from "../utils/getDocumentStatusColors";
import { Separator } from "@repo/ui/components/ui/Separator";
import { getAwaitingDocumentsCount } from "../utils/getAwaitingDocumentsCount";
import CustomerDetailContentAddRequiredDocumentDialog from "./CustomerDetailContentAddRequiredDocumentDialog";
import { useState } from "react";
import { withPermission } from "@repo/provider";
import { Button } from "@repo/ui/components/ui/Button";
import CustomerDocumentPreviewDialog from "./CustomerDocumentPreviewDialog";

interface CustomerDetailContentDocumentsProps {
  customer: GetCustomerDetailResponse;
}

export default function CustomerDetailContentDocuments({
  customer,
}: Readonly<CustomerDetailContentDocumentsProps>) {
  const { t } = useTranslation("customers");
  const [isAddDocumentOpen, setIsAddDocumentOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<{
    url: string;
    name: string;
    id: string;
    status: string;
  } | null>(null);

  const awaitingDocumentsCount = getAwaitingDocumentsCount(customer);

  // Protected Customer Detail Content Add Required Document Dialog
  const ProtectedCustomerDetailContentAddRequiredDocumentDialog =
    withPermission(CustomerDetailContentAddRequiredDocumentDialog, {
      requiredPermissions: ["add_document"],
      targetApp: AppName.EMPLOYEE,
    });

  return (
    <Card>
      <CardContent className="pb-4 pt-6 px-0 gap-4 flex flex-col h-full">
        {/* HEADER  */}
        <div className="flex items-center gap-2 text-secondary overflow-hidden pl-6">
          <div className="flex items-center gap-2 shrink-0">
            <File className="size-5" />
            <p className="text-sm">{t("customers.detail.documents")}</p>
          </div>
          <Separator className="bg-secondary/50" />
        </div>

        <div className="flex items-center w-full gap-4 text-secondary px-6">
          {/* Awaiting Documents Count */}
          {awaitingDocumentsCount > 0 && (
            <Badge
              variant="warningOutline"
              className="flex bg-warning-background text-warning rounded-full px-2 py-1 items-center justify-center gap-2"
            >
              <p className="text-sm">
                {t("customers.detail.awaiting_documents_count", {
                  count: awaitingDocumentsCount,
                })}
              </p>
            </Badge>
          )}

          <ProtectedCustomerDetailContentAddRequiredDocumentDialog
            customer={customer}
            open={isAddDocumentOpen}
            onOpenChange={setIsAddDocumentOpen}
          />
        </div>

        {/* BODY */}
        <div className="flex flex-col gap-4 px-6">
          {customer.documents.map((document) => (
            <div
              key={document.id}
              className="flex items-center justify-between gap-4 p-3 rounded-lg border"
            >
              <div className="flex items-center gap-3">
                <File className="size-5 text-muted-foreground" />
                <div className="space-y-1">
                  <p className="text-sm font-medium">{document.name}</p>
                  <div className="flex items-center gap-2">
                    <Badge
                      variant={getDocumentBadgeVariant(document.status.slug)}
                    >
                      {document.status.name}
                    </Badge>
                    <p className="text-xs text-muted-foreground">
                      {formatDateTime(document.updated_at)}
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex items-center gap-2">
                {document.file && (
                  <>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() =>
                        setSelectedDocument({
                          url: document.file,
                          name: document.name,
                          id: document.id,
                          status: document.status.slug,
                        })
                      }
                      title={t("customers.detail.view_document")}
                    >
                      <Eye className="size-4" />
                    </Button>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => window.open(document.file, "_blank")}
                      title={t("customers.detail.download_document")}
                    >
                      <Download className="size-4" />
                    </Button>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </CardContent>
      <CustomerDocumentPreviewDialog
        open={!!selectedDocument}
        onOpenChange={(open: boolean) => !open && setSelectedDocument(null)}
        documentUrl={selectedDocument?.url ?? ""}
        documentName={selectedDocument?.name ?? ""}
        documentId={selectedDocument?.id ?? ""}
        documentStatus={selectedDocument?.status ?? ""}
        customerId={customer.id}
      />
    </Card>
  );
}
