import OrdersTableSkeleton from "@/components/skeletons/OrdersTableSkeleton";
import { Suspense, useEffect } from "react";
import { withPermission, useFirebaseQuery } from "@repo/provider";
import { AppName } from "@repo/config";
import { ReservationsDetailSheet } from "@/features/reservations/components/ReservationDetailSheet";
import ReservationsTable from "@/features/reservations/components/ReservationsTable";

export default function Reservations() {
  const { logEventMutation } = useFirebaseQuery();

  useEffect(() => {
    logEventMutation.mutate({
      eventName: "page_view",
      eventParams: {
        page_title: document.title,
        page_location: window.location.href,
        page_path: window.location.pathname,
      },
    });
  }, []); // Empty dependency array means this runs once on mount

  const ProtectedReservationsTable = withPermission(ReservationsTable, {
    requiredPermissions: [], // every employee can see reservation list
    targetApp: AppName.EMPLOYEE,
  });

  const ProtectedReservationsDetailSheet = withPermission(
    ReservationsDetailSheet,
    {
      requiredPermissions: [], // every employee can see reservation details
      targetApp: AppName.EMPLOYEE,
    },
  );

  return (
    <Suspense fallback={<OrdersTableSkeleton />}>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 pb-4">
        <ProtectedReservationsTable />
        <ProtectedReservationsDetailSheet />
      </div>
    </Suspense>
  );
}
