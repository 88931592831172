import { GetCustomerDetailResponse } from "@repo/service";
import { Card, CardContent } from "@repo/ui/components/ui/Card";
import { AppName, useTranslation } from "@repo/config";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@repo/ui/components/ui/Tabs";
import { CustomerDetailContentUserCard } from "./CustomerDetailContentUserCard";
import { CustomerDetailContentCompanyCard } from "./CustomerDetailContentCompanyCard";
import CustomerDetailContentDocuments from "./CustomerDetailContentDocuments";
import { ScrollArea } from "@repo/ui/components/ui/ScrollArea";
import { withPermission } from "@repo/provider";

interface CustomerDetailContentProps {
  customer: GetCustomerDetailResponse;
}

export default function CustomerDetailContent({
  customer,
}: Readonly<CustomerDetailContentProps>) {
  const { t } = useTranslation("customers");

  const ProtectedCustomerDetailContentDocuments = withPermission(
    CustomerDetailContentDocuments,
    {
      requiredPermissions: ["view_document"],
      targetApp: AppName.EMPLOYEE,
    },
  );

  return (
    <ScrollArea className="h-[calc(100dvh-6rem)]">
      <div className="flex flex-col gap-4 px-2">
        <Card>
          <CardContent className="p-0">
            <Tabs defaultValue="user" className="w-full">
              <TabsList className="w-full justify-start rounded-none border-b bg-transparent p-0">
                <TabsTrigger
                  value="user"
                  className="rounded-none border-b-2 border-b-transparent bg-transparent px-4 py-3 data-[state=active]:border-b-secondary data-[state=active]:bg-transparent"
                >
                  {t("customers.detail.tabs.user")}
                </TabsTrigger>
                <TabsTrigger
                  value="company"
                  disabled={!customer.user_company}
                  className="rounded-none border-b-2 border-b-transparent bg-transparent px-4 py-3 data-[state=active]:border-b-secondary data-[state=active]:bg-transparent"
                >
                  {t("customers.detail.tabs.company")}
                </TabsTrigger>
              </TabsList>

              <div className="pt-6 pb-4">
                <TabsContent value="user" className="m-0">
                  <CustomerDetailContentUserCard customer={customer} />
                </TabsContent>
                <TabsContent value="company" className="m-0">
                  {customer.user_company && (
                    <CustomerDetailContentCompanyCard customer={customer} />
                  )}
                </TabsContent>
              </div>
            </Tabs>
          </CardContent>
        </Card>

        <ProtectedCustomerDetailContentDocuments customer={customer} />
      </div>
    </ScrollArea>
  );
}
