import { CarFront, Clock, Calendar } from "lucide-react";
import { Button } from "@repo/ui/components/ui/Button";
import { useTranslation, formatDate, formatTime } from "@repo/config";
import { cn } from "@repo/ui/lib/utils";
import { AvailableVehicle } from "@repo/service";
import { checkIfValueIsEmptyOrNullOrUndefined } from "../utils/getBadgeVariant";
import { Badge } from "@repo/ui/components/ui/Badge";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@repo/ui/components/ui/Tooltip";

interface ReservationDetailContentVehicleCardProps {
  vehicle: AvailableVehicle;
  onSelect?: () => void;
  isSelected?: boolean;
  isAvailable?: boolean;
}

export function VehicleCard({
  vehicle,
  onSelect,
  isSelected,
  isAvailable,
}: Readonly<ReservationDetailContentVehicleCardProps>) {
  const { t } = useTranslation("reservations");

  // Determine background class based on availability and selection state
  const getBgClass = () => {
    if (!isAvailable) return "bg-muted/30 hover:bg-muted/40 border-dashed";
    return isSelected ? "bg-muted/80" : "bg-muted hover:bg-muted/80";
  };

  return (
    <div
      className={cn(
        "border-border rounded-lg p-3 flex flex-row items-center gap-3 w-full transition-colors",
        getBgClass(),
      )}
    >
      {/* Car icon & name & plate */}
      <div className="flex items-center gap-3">
        <CarFront
          className={cn("size-4", !isAvailable && "text-muted-foreground")}
        />
        <div className="text-left">
          <p
            className={cn(
              "text-sm font-medium",
              !isAvailable && "text-muted-foreground",
            )}
          >
            {checkIfValueIsEmptyOrNullOrUndefined(vehicle.brand)}{" "}
            {checkIfValueIsEmptyOrNullOrUndefined(vehicle.model)}
          </p>
          <p className="text-xs text-muted-foreground">
            {checkIfValueIsEmptyOrNullOrUndefined(vehicle.vehicle)}
          </p>

          {/* Next available time display for unavailable vehicles */}
          {!isAvailable && vehicle.next_available_time && (
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div className="flex items-center gap-1 mt-1 cursor-help">
                    <Clock className="size-3 text-muted-foreground" />
                    <p className="text-xs text-muted-foreground truncate max-w-[120px]">
                      {formatDate(vehicle.next_available_time)}
                    </p>
                  </div>
                </TooltipTrigger>
                <TooltipContent side="bottom" className="p-2">
                  <div className="flex flex-col gap-1">
                    <p className="text-xs font-medium">
                      {t("vehicles.available_at")}:
                    </p>
                    <div className="flex items-center gap-2">
                      <Calendar className="size-3" />
                      <p className="text-xs">
                        {formatDate(vehicle.next_available_time)}
                      </p>
                    </div>
                    <div className="flex items-center gap-2">
                      <Clock className="size-3" />
                      <p className="text-xs">
                        {formatTime(vehicle.next_available_time)}
                      </p>
                    </div>
                  </div>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          )}
        </div>
      </div>

      {/* Selection button for available vehicles */}
      {isAvailable && onSelect && (
        <Button
          className="ml-auto"
          variant="secondary"
          size="sm"
          onClick={onSelect}
          disabled={isSelected}
        >
          {isSelected ? t("selected") : t("choose")}
        </Button>
      )}

      {/* Status badge for unavailable vehicles */}
      {!isAvailable && (
        <Badge variant="outline" className="ml-auto">
          {t("vehicles.unavailable_status")}
        </Badge>
      )}
    </div>
  );
}
