import {
  Dialog,
  DialogTitle,
  DialogHeader,
  DialogDescription,
  DialogContent,
  DialogTrigger,
} from "../ui/Dialog";
import { Button } from "../ui/Button";
import { HelpCircle } from "lucide-react";
import { ImageSlider } from "../ui/ImageSlider";

/**
 * FeatureGuide component for displaying a guided tour of features with images.
 * Features:
 * - Modal dialog presentation
 * - Image carousel navigation
 * - Customizable trigger button
 * - Support for different display variants (default/sidebar)
 */

/**
 * Props interface for the FeatureGuide component
 * @property {string} title - Title of the feature guide
 * @property {string} description - Description text explaining the feature
 * @property {Array} images - Array of image objects for the guide
 * @property {string} variant - Display variant ('default' or 'sidebar')
 * @property {string} triggerText - Optional text for the trigger button
 * @property {Function} onOpen - Callback function to be called when the dialog is opened
 * @property {Function} onClose - Callback function to be called when the dialog is closed
 */
interface FeatureGuideProps {
  title: string;
  description: string;
  images: {
    src: string;
    alt: string;
    id: number;
  }[];
  variant?: "default" | "sidebar";
  triggerText?: string;
  onOpen?: () => void;
  onClose?: () => void;
}

/**
 * Main component for displaying feature guides with image carousel
 * Provides a modal dialog with image navigation and descriptive content
 */
export function FeatureGuide({
  title,
  description,
  images,
  variant = "default",
  triggerText,
  onOpen,
  onClose,
}: Readonly<FeatureGuideProps>) {
  // Render trigger button based on variant type
  const renderTrigger = () => {
    if (variant === "sidebar") {
      return (
        <Button variant="ghost" className="w-full justify-start">
          <HelpCircle className="mr-2 h-4 w-4" />
          {triggerText}
        </Button>
      );
    }

    return (
      <Button variant="ghost" size="icon" className="size-6">
        <HelpCircle className="h-4 w-4" />
      </Button>
    );
  };

  return (
    <Dialog onOpenChange={(open) => (open ? onOpen?.() : onClose?.())}>
      <DialogTrigger asChild>{renderTrigger()}</DialogTrigger>

      <DialogContent className="sm:max-w-[110rem] lg:max-h-[75rem] p-0 gap-0 overflow-hidden">
        <DialogHeader className="p-6 pb-2">
          <DialogTitle>{title}</DialogTitle>
          <DialogDescription>{description}</DialogDescription>
        </DialogHeader>

        <div className="p-6 pt-2">
          <ImageSlider images={images} />
        </div>
      </DialogContent>
    </Dialog>
  );
}
