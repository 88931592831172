import { Settings, Info, LogOut } from "lucide-react";
import {
  DropdownMenuSeparator,
  DropdownMenuItem,
} from "@repo/ui/components/ui/DropDownMenu";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@repo/ui/components/ui/AlertDialog";
import { buttonVariants } from "@repo/ui/components/ui/Button";
import { useAuthQuery } from "@repo/provider";
import { authService, env } from "@/main";
import { useTranslation } from "@repo/config";
import { useNavigate } from "react-router-dom";

export default function SidebarUserCardActions() {
  const { logoutMutation } = useAuthQuery(authService, env);
  const navigate = useNavigate();
  const { t } = useTranslation("common");

  const onSettingsClick = () => {
    navigate("/settings");
  };

  const onInformationClick = () => {};

  return (
    <div>
      <DropdownMenuItem className="cursor-pointer" onClick={onSettingsClick}>
        {/* SETTINGS */}
        <Settings className="size-4 mr-2" />
        <span>{t("sidebar.menu.settings")}</span>
      </DropdownMenuItem>
      <DropdownMenuItem className="cursor-pointer" onClick={onInformationClick}>
        {/* INFORMATION */}
        <Info className="size-4 mr-2" />
        <span>{t("sidebar.menu.information")}</span>
      </DropdownMenuItem>
      <DropdownMenuSeparator />
      <DropdownMenuItem
        className="text-destructive focus:text-destructive cursor-pointer"
        onSelect={(e) => {
          // prevent dropdown menu from closing
          e.preventDefault();
        }}
      >
        {/* LOGOUT ALERT DIALOG */}
        <AlertDialog>
          <AlertDialogTrigger asChild>
            <div className="flex items-center w-full">
              <LogOut className="size-4 mr-4" />
              <span>{t("auth.logout.title")}</span>
            </div>
          </AlertDialogTrigger>
          <AlertDialogContent>
            <AlertDialogHeader>
              <AlertDialogTitle>{t("auth.logout.title")}</AlertDialogTitle>
              <AlertDialogDescription>
                {t("auth.logout.description")}
              </AlertDialogDescription>
            </AlertDialogHeader>
            <AlertDialogFooter>
              <AlertDialogCancel>{t("actions.cancel")}</AlertDialogCancel>
              <AlertDialogAction
                onClick={() => logoutMutation.mutate()}
                className={buttonVariants({ variant: "destructive" })}
                disabled={logoutMutation.isPending}
              >
                {logoutMutation.isPending
                  ? t("auth.logout.loading")
                  : t("auth.logout.confirm")}
              </AlertDialogAction>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </DropdownMenuItem>
    </div>
  );
}
