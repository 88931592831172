import { useTranslation } from "@repo/config";
import { Book } from "../books";
import { BookCard } from "./BookCard";
import { ScrollArea } from "@repo/ui/components/ui/ScrollArea";

interface BookListProps {
  readonly books: Book[];
  readonly onBookClick: (book: Book) => void;
  readonly selectedBook: Book | undefined;
  readonly onCloseBook: () => void;
}

export function BookList({
  books,
  onBookClick,
  selectedBook,
  onCloseBook,
}: BookListProps) {
  const { t } = useTranslation("library");

  if (books.length === 0) {
    return (
      <div className="text-center py-8">
        <p className="text-muted-foreground">{t("noBooks")}</p>
      </div>
    );
  }

  return (
    <ScrollArea
      type="always"
      className="h-[calc(100vh-15rem)] md:h-[calc(100vh-17rem)]"
    >
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 gap-6">
        {books.map((book) => (
          <BookCard
            key={book.id}
            book={book}
            bookImage={book.coverImage ?? ""}
            onBookClick={onBookClick}
            isOpen={selectedBook?.id === book.id}
            onClose={onCloseBook}
          />
        ))}
      </div>
    </ScrollArea>
  );
}
