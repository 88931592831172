import { useMap } from "react-leaflet";
import { useEffect } from "react";
import L from "leaflet";

interface FullscreenControlProps {
  position?: "topleft" | "topright" | "bottomleft" | "bottomright";
  title?: string;
  className?: string;
}

export function FullscreenControl({
  position = "topleft",
  title = "Tam ekran",
  className = "w-8 h-8 bg-card hover:bg-card/80 flex items-center justify-center rounded-sm border border-border",
}: FullscreenControlProps) {
  const map = useMap();

  useEffect(() => {
    // Create custom fullscreen button
    const FullscreenButton = L.Control.extend({
      onAdd: () => {
        const button = L.DomUtil.create("div", "leaflet-bar leaflet-control");
        button.innerHTML = `
          <button
            class="${className}"
            title="${title}"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
              <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" />
            </svg>
          </button>
        `;

        button.onclick = () => {
          if (!document.fullscreenElement) {
            map.getContainer().requestFullscreen();
          } else {
            document.exitFullscreen();
          }
        };

        return button;
      },
    });

    // Add button to map
    const fullscreenButton = new FullscreenButton({ position }).addTo(map);

    // Handle fullscreen change events
    const handleFullscreenChange = () => {
      if (document.fullscreenElement) {
        map.getContainer().classList.add("fullscreen-map");
        map.invalidateSize();
      } else {
        map.getContainer().classList.remove("fullscreen-map");
        map.invalidateSize();
      }
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      map.removeControl(fullscreenButton);
    };
  }, [map, position, className, title]);

  return null;
}
