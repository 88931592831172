import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@repo/ui/components/ui/Dialog";
import { useTranslation, useCurrentLanguage } from "@repo/config";
import { Button } from "@repo/ui/components/ui/Button";
import { Check, X, File } from "lucide-react";
import { showActionButtons } from "../utils/showActionButtons";
import { useDocumentsQuery } from "@/features/documents/hooks/useDocuments";
import { toastManager } from "@repo/ui/utils";
import { cn } from "@repo/ui/lib/utils";

interface CustomerDocumentPreviewDialogProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  documentUrl: string;
  documentName: string;
  documentId: string;
  documentStatus: string;
  customerId: string;
}

export default function CustomerDocumentPreviewDialog({
  open,
  onOpenChange,
  documentUrl,
  documentName,
  documentId,
  documentStatus,
}: Readonly<CustomerDocumentPreviewDialogProps>) {
  const { t } = useTranslation("customers");
  const currentLanguage = useCurrentLanguage();
  const { useDocumentApprovalStatusUpdate } = useDocumentsQuery();
  const documentApprovalStatusUpdate = useDocumentApprovalStatusUpdate();

  const handleStatusUpdate = async (status: "approved" | "rejected") => {
    await documentApprovalStatusUpdate.mutateAsync({
      document_id: documentId,
      language: currentLanguage,
      status,
    });

    // Show success message
    toastManager.success(
      t(
        status === "approved"
          ? "customers.detail.document_approved"
          : "customers.detail.document_rejected",
      ),
    );

    onOpenChange(false);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="max-w-4xl h-[90dvh] flex flex-col p-0 gap-0">
        <DialogHeader className="flex-shrink-0 px-6 py-4 border-b">
          <div className="flex items-center gap-3">
            <div>
              <File className="size-5 text-muted-foreground" />
            </div>
            <div className="space-y-1">
              <DialogTitle>{documentName}</DialogTitle>
              {showActionButtons(documentStatus) && (
                <p className="text-sm text-muted-foreground">
                  {t("customers.detail.document_needs_review")}
                </p>
              )}
            </div>
          </div>
        </DialogHeader>

        <div className="flex-1 min-h-0 relative w-full bg-muted/30">
          <object
            data={documentUrl}
            type="application/pdf"
            className="w-full h-full"
            style={{ height: "calc(90dvh - 85px)" }}
          >
            <div className="flex flex-col items-center justify-center py-8 text-destructive">
              <p className="text-lg font-medium">
                {t("customers.detail.document_preview_error")}
              </p>
            </div>
          </object>

          {showActionButtons(documentStatus) && (
            <div className="absolute bottom-6 left-1/2 -translate-x-1/2 flex items-center justify-center gap-3 py-2.5 px-6 rounded-lg shadow-lg bg-background border">
              <Button
                variant="destructiveOutline"
                size="sm"
                onClick={() => handleStatusUpdate("rejected")}
                disabled={documentApprovalStatusUpdate.isPending}
                className={cn(
                  "min-w-32",
                  documentApprovalStatusUpdate.isPending && "opacity-50",
                )}
              >
                <X className="size-4 mr-2" />
                {t("customers.detail.reject_document")}
              </Button>
              <Button
                variant="success"
                size="sm"
                onClick={() => handleStatusUpdate("approved")}
                disabled={documentApprovalStatusUpdate.isPending}
                className={cn(
                  "min-w-32",
                  documentApprovalStatusUpdate.isPending && "opacity-50",
                )}
              >
                <Check className="size-4 mr-2" />
                {t("customers.detail.approve_document")}
              </Button>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
