import { LucideIcon } from "lucide-react";
import {
  useLocation,
  useSearchParams,
  Link,
  useNavigate,
} from "react-router-dom";
import { DashboardSidebar } from "@repo/ui/components/layout/DashboardSidebar";
import { CompanyLogo } from "@repo/ui/components/brand/CompanyLogo";
import { AppName, useTranslation } from "@repo/config";
import { routes } from "@/main";
import { usePermission, useUser } from "@repo/provider";
import SidebarUserCardActions from "./SidebarUserCardActions";

interface SidebarItemType {
  title: string;
  url: string;
  icon: LucideIcon;
}

/**
 * Dashboard sidebar
 * @returns
 */
export default function Sidebar() {
  const { t } = useTranslation("common");
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { user } = useUser();
  const navigate = useNavigate();

  /**
   * Get URL with params
   * @param url
   * @returns
   */
  const getUrlWithParams = (url: string) => {
    const currentParams = new URLSearchParams(searchParams);
    const currentPath = location.pathname;

    // If the URL is not the current path, remove the params
    if (url !== currentPath) {
      const params = Array.from(currentParams.entries());
      params.forEach(([key]) => {
        const pagePrefix = url.substring(1);
        if (!key.startsWith(pagePrefix)) {
          currentParams.delete(key);
        }
      });
    }

    // Get the query string
    const queryString = currentParams.toString();
    return queryString ? `${url}?${queryString}` : url;
  };

  /**
   * Handle logo click
   */
  const handleLogoClick = () => {
    navigate("/");
  };

  /**
   * Sidebar Permission Check
   * Create sidebar items from route config and check permissions
   */
  const sidebarItems = Object.entries(routes)
    .filter(([, config]) => config.showInSidebar)
    .map(([, config]) => {
      const { canAccess } = usePermission(config.permissions, AppName.EMPLOYEE);

      // If user does not have permission, do not show in sidebar
      if (!canAccess || !config.icon) return null;

      const item: SidebarItemType = {
        title: t(config.title),
        url: config.path,
        icon: config.icon,
      };

      return item;
    })
    .filter((item): item is SidebarItemType => item !== null); // Type guard to filter null

  const isActiveUrl = (url: string) => {
    return location.pathname.startsWith(url);
  };

  const renderLink = ({
    url,
    children,
  }: {
    url: string;
    children: React.ReactNode;
  }) => <Link to={url}>{children}</Link>;

  return (
    <DashboardSidebar
      items={sidebarItems}
      logo={<CompanyLogo onClick={handleLogoClick} />}
      groupLabel={t("sidebar.group.label")}
      isActiveUrl={isActiveUrl}
      getLinkUrl={getUrlWithParams}
      renderLink={renderLink}
      actions={<SidebarUserCardActions />}
      user={
        user || {
          first_name: "",
          last_name: "",
          email: "",
          avatar: "",
        }
      }
    />
  );
}
