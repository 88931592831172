import { cn } from "../../lib/utils";
import { Loader2 } from "lucide-react";

interface LoadingProgressProps {
  readonly icon?: React.ReactNode;
  readonly title?: string;
  readonly description?: string;
  readonly className?: string;
  readonly progress?: number;
  readonly showSpinner?: boolean;
  readonly showTruck?: boolean;
}

export function LoadingProgress({
  icon,
  title = "Loading",
  description,
  className,
  progress,
  showSpinner = true,
  showTruck = false,
}: LoadingProgressProps) {
  if (!showTruck) {
    return (
      <div
        className={cn(
          "absolute inset-0 flex items-center justify-center dark:backdrop-filter-none dark:bg-background/80 bg-background/30 backdrop-blur-sm backdrop-filter transition-colors",
          className,
        )}
      >
        <div className="flex flex-col items-center justify-center space-y-6 max-w-sm mx-auto p-8">
          <div className="relative">
            {icon ||
              (showSpinner && (
                <div className="relative">
                  <Loader2 className="size-6 animate-spin text-primary" />
                  <div className="absolute inset-0 animate-pulse rounded-full" />
                </div>
              ))}
          </div>

          <div className="space-y-3 text-center">
            <h3 className="text-base font-semibold text-foreground animate-fade-in-up">
              {title}
            </h3>
            {description && (
              <p className="text-sm text-muted-foreground animate-fade-in-up-delayed">
                {description}
              </p>
            )}
          </div>

          <div className="w-64 space-y-2">
            <div className="h-1.5 bg-muted rounded-full overflow-hidden">
              {progress !== undefined ? (
                <div
                  className="h-full bg-primary transition-all duration-500 ease-in-out"
                  style={{ width: `${progress}%` }}
                />
              ) : (
                <div className="h-full w-1/3 bg-primary animate-progress-infinite" />
              )}
            </div>
            {progress !== undefined && (
              <p className="text-xs text-muted-foreground text-center animate-fade-in-up">
                {Math.round(progress)}%
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="loader-wrapper">
      <div className="truck-wrapper">
        <div className="truck">
          <div className="truck-container"></div>
          <div className="glases"></div>
          <div className="bonet"></div>
          <div className="base"></div>
          <div className="base-aux"></div>
          <div className="wheel-back"></div>
          <div className="wheel-front"></div>
          <div className="smoke"></div>
        </div>
        <div className="progress-bar"></div>
      </div>
    </div>
  );
}
