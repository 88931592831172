import { useEffect } from "react";
import type { AuthService } from "@repo/service";

/**
 * Interface for useTokenRefresh hook parameters
 */
interface TokenRefreshParams {
  /** Flag indicating if user is authenticated */
  isAuthenticated: boolean;
  /** Service for handling authentication operations */
  authService: AuthService;
  /** Callback to refresh user profile data */
  refreshProfile: () => void;
  /** Callback to handle logout operation */
  onLogout: () => void;
}

/**
 * Custom hook to handle token refresh operations
 *
 * This hook manages the automatic refresh of authentication tokens:
 * - Checks token expiration every 30 seconds
 * - Attempts to refresh expired tokens
 * - Handles logout on refresh failures
 * - Cleans up intervals on unmount
 */
export function useTokenRefresh({
  isAuthenticated,
  authService,
  refreshProfile,
  onLogout,
}: TokenRefreshParams): void {
  useEffect(() => {
    // Skip if user is not authenticated
    if (!isAuthenticated) return;

    let intervalId: ReturnType<typeof setInterval>;

    /**
     * Checks token expiration and handles refresh
     * @returns {Promise<void>}
     */
    const checkTokenAndRefresh = async (): Promise<void> => {
      try {
        // Check if current token is expired
        if (authService.isTokenExpired()) {
          // Attempt to refresh the token without showing toast
          await authService.refreshToken(true);

          // Check if token was successfully refreshed
          const hasValidToken = !authService.isTokenExpired();

          if (hasValidToken) {
            // Update user profile after successful token refresh
            refreshProfile();
          } else {
            // Logout if token refresh didn't result in a valid token
            onLogout();
          }
        }
      } catch (error) {
        // Handle any errors during refresh by logging out
        onLogout();
      }
    };

    // Initial check after 5 seconds
    const timeoutId = setTimeout(() => {
      checkTokenAndRefresh();
      // Set up periodic checks every 5 minutes
      intervalId = setInterval(checkTokenAndRefresh, 300000);
    }, 5000);

    // Cleanup function to clear timers
    return () => {
      clearTimeout(timeoutId);
      if (intervalId) clearInterval(intervalId);
    };
  }, [isAuthenticated, authService, refreshProfile, onLogout]);
}
