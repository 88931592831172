import { ApiClient } from "../../infrastructure/apiClient";
import {
  GetCustomerDocumentStatusesResponse,
  GetCustomersParams,
  GetCustomersResponse,
  GetCustomerDetailResponse,
  GetCustomerDetailParams,
} from "./types";

export class CustomerService {
  constructor(private readonly apiClient: ApiClient) {}

  /**
   * Get the freight demands
   * @param params - The parameters for getting freight demands
   * @returns Promise<OrdersResponse>
   */
  async getCustomers(
    params: GetCustomersParams & { language: string },
  ): Promise<GetCustomersResponse> {
    const { page, limit = 15, filters, language } = params;
    const offset = (page - 1) * limit;

    return this.apiClient.get<GetCustomersResponse>(
      `${language}/users/customers`,
      {
        params: {
          offset,
          limit,
          search: filters?.search,
          document_status: filters?.document_status,
        },
      },
    );
  }

  /**
   * Get the customer document statuses
   * @param params - The parameters for getting customer document statuses
   * @returns Promise<GetCustomerDocumentStatusesResponse>
   */
  async getCustomerDocumentStatuses(params: {
    language: string;
  }): Promise<GetCustomerDocumentStatusesResponse> {
    const { language } = params;
    return this.apiClient.get<GetCustomerDocumentStatusesResponse>(
      `${language}/users/customers/document-statuses`,
    );
  }

  /**
   * Get the customer detail
   * @param params - The parameters for getting customer detail
   * @returns Promise<GetCustomerDetailResponse>
   */
  async getCustomerDetail(
    params: GetCustomerDetailParams & { language: string },
  ): Promise<GetCustomerDetailResponse> {
    const { language, customer_id } = params;
    return this.apiClient.get<GetCustomerDetailResponse>(
      `${language}/users/customers/${customer_id}`,
    );
  }
}

export const createCustomerService = (apiClient: ApiClient) =>
  new CustomerService(apiClient);
