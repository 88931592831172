import { ApiClient } from "../../infrastructure/apiClient";
import {
  AddCompanyRequiredDocumentParams,
  AddUserRequiredDocumentResponse,
  GetDocumentTypesResponse,
  UpdateCompanyRequiredDocumentParams,
  UpdateUserRequiredDocumentParams,
  AddUserRequiredDocumentParams,
  DocumentApprovalStatusUpdateParams,
  DocumentApprovalStatusUpdateResponse,
  AddCompanyRequiredDocumentResponse,
} from "./types";

export class DocumentService {
  constructor(private readonly apiClient: ApiClient) {}

  /**
   * Add a company required document
   * @param params - The parameters for adding a company required document
   * @returns Promise<AddCompanyRequiredDocumentResponse>
   */
  async addCompanyRequiredDocument(
    params: AddCompanyRequiredDocumentParams & {
      language: string;
      company_id: string;
    },
  ): Promise<AddCompanyRequiredDocumentResponse> {
    const { language, company_id, ...requestBody } = params;
    return this.apiClient.post<AddCompanyRequiredDocumentResponse>(
      `${language}/companies/${company_id}/documents`,
      requestBody,
    );
  }

  /**
   * Add a user required document
   * @param params - The parameters for adding a user required document
   * @returns Promise<AddUserRequiredDocumentResponse>
   */
  async addUserRequiredDocument(
    params: AddUserRequiredDocumentParams & {
      language: string;
      user_id: string;
    },
  ): Promise<AddUserRequiredDocumentResponse> {
    const { language, user_id, ...requestBody } = params;
    return this.apiClient.post<AddUserRequiredDocumentResponse>(
      `${language}/users/${user_id}/documents`,
      requestBody,
    );
  }

  /**
   * Update the company required document
   * @param params - The parameters for updating the company required document
   * @returns Promise<AddCompanyRequiredDocumentResponse>
   */
  async updateCompanyRequiredDocument(
    params: UpdateCompanyRequiredDocumentParams,
  ): Promise<AddCompanyRequiredDocumentResponse> {
    const { language, company_id, document_id } = params;
    return this.apiClient.patch<AddCompanyRequiredDocumentResponse>(
      `${language}/companies/${company_id}/documents/${document_id}`,
    );
  }

  /**
   * Update the user required document
   * @param params - The parameters for updating the user required document
   * @returns Promise<AddUserRequiredDocumentResponse>
   */
  async updateUserRequiredDocument(
    params: UpdateUserRequiredDocumentParams,
  ): Promise<AddUserRequiredDocumentResponse> {
    const { language, user_id, document_id } = params;
    return this.apiClient.patch<AddUserRequiredDocumentResponse>(
      `${language}/users/${user_id}/documents/${document_id}`,
    );
  }

  /**
   * Get the document types
   * @param params - The parameters for getting the document types
   * @returns Promise<GetDocumentTypesResponse>
   */
  async getDocumentTypes(params: {
    language: string;
  }): Promise<GetDocumentTypesResponse> {
    const { language } = params;
    return this.apiClient.get<GetDocumentTypesResponse>(
      `${language}/contents/document-types`,
    );
  }

  /**
   * Document Status Update
   * @param params - The parameters for updating the document status
   * @returns Promise<DocumentStatusUpdateResponse>
   */
  async documentApprovalStatusUpdate(
    params: DocumentApprovalStatusUpdateParams & {
      language: string;
      document_id: string;
    },
  ): Promise<DocumentApprovalStatusUpdateResponse> {
    const { language, document_id, status } = params;
    return this.apiClient.patch<DocumentApprovalStatusUpdateResponse>(
      `${language}/contents/document-approval/${document_id}/status/`,
      { status },
    );
  }
}

export const createDocumentService = (apiClient: ApiClient) =>
  new DocumentService(apiClient);
